import {Component} from '@angular/core';
import {ProductConfigService} from "../model/product-config.service";
import {HotToastService} from "@ngxpert/hot-toast";
import {
  ConfigCompareRow,
  ProductComparisonRequest,
  ProductComparisonResponse,
  ProductConfigIdInfo
} from "../model/product-config-api";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {EMPTY, switchMap} from "rxjs";
import {catchError, finalize, tap} from "rxjs/operators";

@Component({
  selector: 'app-product-config-compare',
  templateUrl: './product-config-compare.component.html',
  styleUrl: './product-config-compare.component.scss'
})
export class ProductConfigCompareComponent {

  loading = false
  request: ProductComparisonRequest = new ProductComparisonRequest([])
  showOnlyComparisonDifferences = false

  header: ProductConfigIdInfo[] = []
  rows: ConfigCompareRow[] = []

  constructor(
    private service: ProductConfigService,
    private toast: HotToastService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(takeUntilDestroyed()).pipe(
      switchMap(params => {
        this.request.configIds = params.p
        this.service.addToComparison(params.p)
        return this.evaluate()
      })).subscribe()
  }

  remove(id: string): void {
    const index = this.request.configIds.indexOf(id)
    if (index !== -1) {
      this.request.configIds.splice(index, 1)
      this.service.removeFromComparison(id)

      if (this.request.configIds.length < 2) {
        localStorage.removeItem('comparisonData')
        this.request.configIds = []
        this.service.clearComparisonPids()
      } else {
        localStorage.setItem('comparisonData', JSON.stringify(this.request.configIds))
      }

      this.evaluate().subscribe()
    }
  }

  toggleComparisonValues(): void {
    this.showOnlyComparisonDifferences = !this.showOnlyComparisonDifferences
  }

  clearComparison(): void {
    this.request.configIds = []
    this.service.clearComparisonPids()
    localStorage.removeItem('comparisonData')
    this.evaluate().subscribe()
  }


  private evaluate() {
    this.loading = true
    return this.service.compare(this.request).pipe(
      tap(response => this.handleData(response)),
      catchError(() => this.handlerError()),
      finalize(() => this.loading = false)
    )

  }

  private handleData(d: ProductComparisonResponse): void {
    this.rows = d.rows
    this.header = d.header
  }

  private handlerError() {
    this.rows = []
    this.header = []
    this.toast.error("Something went wrong")
    return EMPTY;
  }
}
