<div [ngClass]="{'filter grayscale opacity-50': isGrayedOut()}"
     class="flex h-full items-stretch">
  <div
    class="inline-flex items-center px-1.5 py-1 bg-gray-200 rounded-lg">
    <span [ngClass]="color()" class="w-3 h-3 rounded-full mr-2"></span>
    <span class="text-sm text-black">{{ count() }} {{ text() }}</span>
  </div>
  @if (label()) {
    <div class="flex flex-col justify-center pl-1.5"><span>{{ label() }}</span></div>
  }
</div>
