<mat-card>
  <mat-card-header class="flex items-center content-center justify-between">
    <mat-card-title>{{'contactPersonTitle' | translate}}</mat-card-title>
    <mat-card-actions class="flex flex-row gap-2">
      <button mat-stroked-button *ngIf="!editMode" (click)="editMode=!editMode">
        <mat-icon>edit</mat-icon>
        {{'action.Edit' | translate}}
      </button>

      <ng-container *ngIf="editMode">
        <button mat-stroked-button (click)="save()">
          <mat-icon>save</mat-icon>
          {{'action.Save' | translate}}
        </button>

        <button mat-stroked-button (click)="editMode=!editMode">
          {{'cancel' | translate}}
        </button>
      </ng-container>
    </mat-card-actions>
  </mat-card-header>
  <mat-card-content>
    <mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
    <app-company-contact-form [readonly]="!editMode" [form]="form"></app-company-contact-form>
  </mat-card-content>


</mat-card>
