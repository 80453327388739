import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { System } from "../model/system-api";
import { SystemService } from "../model/system.service";
import { HotToastService } from "@ngxpert/hot-toast";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-delete-system-dialog',
  templateUrl: './delete-system-dialog.component.html',
  styleUrls: ['./delete-system-dialog.component.scss']
})
export class DeleteSystemDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: System,
    private dialogRef: MatDialogRef<DeleteSystemDialogComponent>,
    private service: SystemService,
    private toast: HotToastService,
    private translate: TranslateService
  ) {
  }


  submit() {
    if (!this.data.id) return

    this.service.deleteSystem(this.data.id).subscribe({
      next: (data) => {
        if (data)
          this.dialogRef.close(true)
        else
          this.handleError('system.Dialog.Delete.ContainsElementsMessage')
      },
      error: () => this.handleError('system.Dialog.Delete.ErrorMessage')
    })
  }

  private handleError(message: string) {
    this.toast.error(this.translate.instant(message))
    this.dialogRef.close(false)
  }
}
