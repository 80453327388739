<mat-dialog-content class="mat-typography">
  <mat-accordion multi="true">
    <ng-container *ngFor="let category of categories">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="flex flex-row gap-4 justify-between items-center">
            <mat-checkbox color="accent" (change)="selectCategory(category)"
                          (click)="$event.stopPropagation()"
                          [checked]="selectedCategories.has(category.name)"></mat-checkbox>
            <div>{{ category.name }}</div>
          </div>
        </mat-expansion-panel-header>
        <ng-container>
          <mat-list *ngFor="let prop of category.properties">
            <mat-list-item>
              <div class="flex flex-row gap-4 justify-between items-center">
                <div> {{ prop.name }}</div>
                <mat-slide-toggle color="primary" (change)="selectProperty($event.checked, prop)"
                                  [checked]="selectedProperties.has(prop.name)"></mat-slide-toggle>
              </div>
            </mat-list-item>
          </mat-list>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="selectedProperties">OK</button>
</mat-dialog-actions>
