import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import {
  ActivateElectronicModuleRequest, OnboardRequest,
  Process,
  ProcessResponse,
  TransitionRequest
} from "./process-api";
import { Page } from "../../utils/page";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProcessService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone) {
    super(http, 'api/process', logging, zone);
  }

  getById(id: number): Observable<Process> {
    return this.get('' + id)
  }

  getForUser(page: number, size: number): Observable<Page<Process>> {
    return this.getPaged('user', page, size)
  }

  getForUserCompany(page: number, size: number): Observable<Page<Process>> {
    return this.getPaged('company', page, size)
  }

  activate(equipmentId: number, request: ActivateElectronicModuleRequest): Observable<ProcessResponse> {
    return this.post('activate/' + equipmentId, request)
  }

  transition(request: TransitionRequest): Observable<ProcessResponse> {
    return this.post('transition' , request)
  }

  onboard(request: OnboardRequest): Observable<ProcessResponse> {
    return this.post('onboard' , request)
  }
}
