import {Component, Input} from '@angular/core';
import {GeneralAttributes, Product} from "../model/product-api";
import {TableCardEntry} from "../../../component/card/model/card-api";

@Component({
  selector: 'app-product-info-comp',
  templateUrl: './product-info-comp.component.html',
  styleUrls: ['./product-info-comp.component.scss']
})
export class ProductInfoCompComponent {

  title = 'CompInformation'
  data: TableCardEntry[] = []

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setInfo(product)
  }

  setInfo(product: Product) {
    const attributes = product?.attributes

    this.addGeneral(attributes?.general)

    if (attributes?.roHS && attributes?.roHS?.casNumbers.length) {
      this.pushData("casNumber", attributes?.roHS.casNumbers.toString() || '')
    }

    if (attributes?.production?.deliveryTime) {
      this.pushData("deliveryTime", attributes?.production?.deliveryTime ?? '')
    }
  }

  private addGeneral(general: GeneralAttributes | null | undefined) {
    if (!general) return
    const entries: { [key: string]: string } = {
      "oilCharge": general?.oilCharge || '',
      "connectionSuctionLine": general?.connectorSuction || '',
      "connectionDischargeLine": general?.connectorDischarge || '',
      "displacement1450": general?.displacement.get('1450hz') || general?.displacement.get('50Hz') || general?.displacement.get('1450RPM 50Hz') || '',
      "displacement1750": general?.displacement.get('1750hz') || general?.displacement.get('60Hz') || general?.displacement.get('1750RPM 50Hz') || '',
      "weight": general?.weight || '',
      "cylinderCount": general?.cylinderCount || '',
    }
    for (const key in entries) {
      this.pushData(key, entries[key])
    }
  }

  private pushData(key: string, value: string | undefined) {
    this.data.push(
      new TableCardEntry(key, value || ""),
    )
  }
}
