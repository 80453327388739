import { Component, Input } from '@angular/core';
import { SiteInfo } from "../model/site-api";

@Component({
  selector: 'app-site-board-content-list-entry',
  templateUrl: './site-board-content-list-entry.component.html',
  styleUrls: ['./site-board-content-list-entry.component.scss']
})
export class SiteBoardContentListEntryComponent {
  @Input() data: SiteInfo | undefined
}
