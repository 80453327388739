<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
@if (series) {
  <div class="flex flex-col gap-2">
    <div class="flex flex-row gap-2">
      <div>
        <img class="drawing mx-auto"
             src="https://www.bitzer.de/shared_media/product_images/images_f_p/{{series.picture}}"
             appDefaultImage defaultImage="assets/img/picto_j_01_recip.png" alt="Product Series Image"
        />
      </div>
      <mat-divider vertical></mat-divider>
      <div class="flex-auto p-2">
        {{ series.description }}
      </div>

    </div>
  </div>
}
