import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekNumber',
  standalone: true
})
export class WeekNumberPipe implements PipeTransform {

  transform(isoDateString: string): unknown {
    const date = new Date(isoDateString);
    const [year, week] = this.getWeekNumber(date);
    return `${week}/${year}`;
  }

  private getWeekNumber(date: Date): number[] {
    const target = new Date(date.valueOf());
    target.setHours(0, 0, 0, 0);

    // Set the target date to the nearest Thursday (ISO standard week starts on Monday)
    target.setDate(target.getDate() + 3 - (target.getDay() + 6) % 7);

    const firstThursday = new Date(target.getFullYear(), 0, 4);
    const weekNumber = 1 + Math.round(((target.getTime() - firstThursday.getTime()) / 86400000 - 3 + (firstThursday.getDay() + 6) % 7) / 7);

    return [target.getFullYear(), weekNumber];
  }
}
