import { Component, Input } from '@angular/core';
import { CsticInfo, ProductConfigIdInfo } from "../model/product-config-api";

@Component({
  selector: 'app-product-config-details-cstics',
  templateUrl: './product-config-details-cstics.component.html',
  styleUrl: './product-config-details-cstics.component.scss'
})
export class ProductConfigDetailsCsticsComponent {
  @Input() pid!: ProductConfigIdInfo;

  sorted() {
    return function (a: CsticInfo, b: CsticInfo) {
      return a.rank - b.rank
    };
  }
}
