export interface Wishlist {
  id: number,
  name: string,
  ownerId: string,
  note: string,
}

export class WishlistChangeRequest {
  constructor(
    public name: string,
    public ownerId: string,
    public note: string
  ) {
  }
}

export interface WishlistEntry {
  id: number,
  reference: string,
  name: string,
  amount: number,
  note: string,
  wishlistId: number
}

export class WishlistEntryChangeRequest {
  constructor(
    public reference: string,
    public name: string,
    public amount: number,
    public note: string
  ) {
  }
}

export interface WishlistAccessEntry {
  id: number,
  customerId: string,
  canRead: boolean,
  canWrite: boolean
}

export interface WishlistInfo {
  wishlist: Wishlist,
  writeable: boolean,
  owned: boolean
}

export class WishlistAccessPatchRequest {
  constructor(
    public customerId: string,
    public value: boolean
  ) {
  }
}
