import { Component, ComponentFactoryResolver, ElementRef, Injector, ViewChild } from '@angular/core';
import * as L from "leaflet";
import { latLng } from "leaflet";
import { CompressorStatus } from "../model/state-api";
import { SiteService } from "../model/site.service";
import { SiteInfo, SiteMapEntry } from "../model/site-api";
import { TranslateService } from "@ngx-translate/core";
import { Page } from "../../utils/page";
import { FilterService } from "../../filter/model/filter.service";
import { interval, Subscription } from "rxjs";
import { SiteMapPopUpComponent } from "../site-map-pop-up/site-map-pop-up.component";

@Component({
  selector: 'app-site-board-content-map',
  templateUrl: './site-board-content-map.component.html',
  styleUrls: ['./site-board-content-map.component.scss']
})
export class SiteBoardContentMapComponent {

  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef<HTMLDivElement>;

  reloading: boolean = false
  data: SiteMapEntry[] = []
  private map: L.Map | undefined
  private markers: L.LayerGroup | undefined
  private filterSubscription: Subscription | undefined
  private reloadSubscription: Subscription | undefined

  constructor(
    private filterService: FilterService,
    private service: SiteService,
    private translate: TranslateService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector
  ) {
  }

  ngOnInit() {
    this.initMap()
    this.reload()
    this.filterSubscription = this.filterService.activeFilter.subscribe(value => {
      if (this.filterService.activated) this.reload()
    })
    this.reloadSubscription = interval(180000).subscribe(_ => this.reload())
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe()
      this.filterSubscription = undefined
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe()
      this.reloadSubscription = undefined
    }
    if (this.map) {
      this.map = undefined
    }
  }


  private reload() {
    if (this.reloading) return
    this.reloading = true

    let page = 0
    let size = 1000
    if (this.filterService.activated) {
      let filter = this.filterService.activeFilter.value
      this.service.getFilteredSiteMapEntries(filter, page, size).subscribe(d => this.handleData(d))
    } else {
      this.service.getSiteMapEntries(page, size).subscribe(d => this.handleData(d))
    }
  }

  private handleData(d: Page<SiteMapEntry>) {
    if (this.markers) this.markers.clearLayers()
    this.data = d.content
    this.data.forEach(d => this.addEntry(d))
    let first = this.data[0]
    if (first && this.map && first.location) this.map.panTo(latLng(first.location.location.coordinates[1], first.location.location.coordinates[0]))
    this.reloading = false
  }

  private addEntry(entry: SiteMapEntry) {
    let site = entry.info
    let location = entry.location
    if (!location || !location.location) return
    const color = this.getStatusColor(site)
    const icon = this.setMarkerIcon(color)

    const layer = L.marker(latLng(location.location.coordinates[1], location.location.coordinates[0]), { icon: icon })
      .bindPopup(this.createPopupContent(site))
      .addTo(this.markers!)
      .on('click', () => {
        layer.openPopup()
      })
  }

  private getStatusColor(site: SiteInfo): string {
    let eventState = site.eventState
    if (!eventState.online || site.eventState.status === CompressorStatus.UNKNOWN) {
      return '#6C6C6C'
    } else if (eventState.status === CompressorStatus.FAULT) {
      return '#D22525'
    } else if (eventState.status === CompressorStatus.RUNNING) {
      return '#1FBE1F'
    } else if (eventState.status === CompressorStatus.STOPPED) {
      return '#000000'
    } else {
      return '#008AFF'
    }
  }

  private createPopupContent(siteInfo: SiteInfo) {
    const component = this.resolver.resolveComponentFactory(SiteMapPopUpComponent).create(this.injector);
    component.instance.site = siteInfo
    component.changeDetectorRef.detectChanges()
    return component.location.nativeElement
  }

  private initMap() {
    this.map = L.map(this.mapContainer.nativeElement, {
      center: latLng(51, 10),
      zoom: 6
    })

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map)

    this.markers = L.layerGroup().addTo(this.map)
    this.addLegend()
  }

  private addLegend() {
    let legend = new L.Control({ position: "topright" });
    const self = this

    legend.onAdd = function () {

      let div = L.DomUtil.create("div", "legend");
      div.innerHTML += '<h4 class="legend-title">' + self.translate.instant('status') + '</h4>';
      div.innerHTML += '<i class="legend-icon" style="background: #6C6C6C"></i><span class="legend-text">' + self.translate.instant('ioTStatusText_Offline') + '</span><br>';
      div.innerHTML += '<i class="legend-icon" style="background: #1FBE1F"></i><span class="legend-text">' + self.translate.instant('ioTStatusText_Online') + '/' + self.translate.instant('ioTStatusText_Running') + '</span><br>';
      div.innerHTML += '<i class="legend-icon" style="background: #000000"></i><span class="legend-text">' + self.translate.instant('ioTStatusText_Stopped') + '</span><br>';
      div.innerHTML += '<i class="legend-icon" style="background: #D22525"></i><span class="legend-text">' + self.translate.instant('ioTStatusText_Fault') + '</span><br>';

      return div;
    };

    legend.addTo(this.map!);
  }


  private setMarkerIcon(color?: string) {
    const markerHtmlStyles = `
    background-color: ${ color || '#008AFF' };
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    cursor: pointer;
    border: 1px solid #FFFFFF`

    return L.divIcon({
      iconSize: [10, 10],
      iconAnchor: [0, 0],
      html: `<span style="${ markerHtmlStyles }" />`
    })
  }


}
