import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ResourceHierarchyNode} from "../../../../hierarchy/model/hierarchy-api";
import {Equipment, EquipmentInfo} from "../../../model/equipment-api";
import {PageEvent} from "@angular/material/paginator";
import {EquipmentService} from "../../../model/equipment.service";
import {Page} from "../../../../utils/page";
import {System} from "../../../../system/model/system-api";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-operational-graph-equipment-list',
  templateUrl: './operational-graph-equipment-list.component.html',
  styleUrl: './operational-graph-equipment-list.component.scss'
})
export class OperationalGraphEquipmentListComponent implements OnDestroy {

  @Input()
  set systemData(data: System) {
    if (!data) return
    this.system = data
    this.loading = true
    this.loadSystemEquipments(this.pageIndex, this.pageSize)
  }

  @Input()
  set selectedEquipmentData(data: Equipment[]) {
    if (!data.length) return
    this.selectedEquipments = data
  }
  @Output() equipmentSelect: EventEmitter<Equipment> = new EventEmitter()

  system: System | undefined
  hierarchy: ResourceHierarchyNode[] = []
  equipments: EquipmentInfo[] = []
  selectedEquipments: Equipment[] = []
  subscriptions: Subscription[] = []
  loading: boolean = false
  pageIndex: number = 0
  pageSize: number = 5
  totalSize: number = -1

  constructor(
    private service: EquipmentService
  ) { }

  ngOnDestroy() {
    if (!this.subscriptions.length) return
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }

  onEquipmentSelect(equipment: Equipment) {
    this.equipmentSelect.emit(equipment)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loading = true
    this.loadSystemEquipments(event.pageIndex, event.pageSize)
  }

  checkStatus(equipment: Equipment) {
    return this.selectedEquipments.find(e => e.id === equipment.id) !== undefined
  }

  private loadSystemEquipments(page: number, size: number) {
    if (!this.system) return
    const sub = this.service.getSystemEquipments(this.system.id, page, size).subscribe(data => this.handleData(data))
    this.subscriptions.push(sub)
  }

  private loadEquipmentPath(equipmentId: number) {
    const sub = this.service.getPath(equipmentId).subscribe(data => this.handlePathData(data))
    this.subscriptions.push(sub)
  }

  private handlePathData(data: ResourceHierarchyNode[]) {
    this.hierarchy = data
  }

  private handleData(data: Page<EquipmentInfo>) {
    this.equipments = data.content
    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.totalSize = data.totalSize
    this.loading = false
    if (!this.equipments.length) return
    this.loadEquipmentPath(this.equipments[0].equipment.id)
  }
}
