import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommerceRoutingModule } from './commerce-routing.module';
import { CartInfoComponent } from "./cart-info/cart-info.component";
import { MaterialModule } from "../material/material.module";
import { CartDetailsComponent } from './cart-details/cart-details.component';
import { TranslateModule } from "@ngx-translate/core";
import { CartDetailsEntryListComponent } from './cart-details-entry-list/cart-details-entry-list.component';
import { CartDeleteDialogComponent } from './cart-delete-dialog/cart-delete-dialog.component';
import { CartCheckoutDialogComponent } from './cart-checkout-dialog/cart-checkout-dialog.component';
import { AddToCartButtonComponent } from './add-to-cart-button/add-to-cart-button.component';
import { AddToCartDialogComponent } from './add-to-cart-dialog/add-to-cart-dialog.component';
import { ComponentModule } from "../component/component.module";
import { CartShareDialogComponent } from './cart-share-dialog/cart-share-dialog.component';
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedCartInfoComponent } from './shared-cart-info/shared-cart-info.component';
import { SharedCartImportDialogComponent } from './shared-cart-import-dialog/shared-cart-import-dialog.component';


@NgModule({
  declarations: [
    CartInfoComponent,
    CartDetailsComponent,
    CartDetailsEntryListComponent,
    CartDeleteDialogComponent,
    CartCheckoutDialogComponent,
    AddToCartButtonComponent,
    AddToCartDialogComponent,
    CartShareDialogComponent,
    SharedCartInfoComponent,
    SharedCartImportDialogComponent
  ],
  exports: [
    CartInfoComponent,
    AddToCartButtonComponent
  ],
    imports: [
        CommonModule,
        CommerceRoutingModule,
        MaterialModule,
        TranslateModule,
        ComponentModule,
        CdkCopyToClipboard,
        ReactiveFormsModule
    ]
})
export class CommerceModule {
}
