<button mat-menu-item (click)="filterService.setAlarmFilter()">
  <mat-icon [ngClass]="{ 'selected-filter': filterService.activeFilter.value.alarmFilter }">warning</mat-icon>
  {{'products.toolbar.ToggleAlarmFilter' | translate}}
</button>

<button mat-menu-item (click)="filterService.setOnlineFilter()">
  <mat-icon [ngClass]="{ 'selected-filter': filterService.activeFilter.value.onlineFilter }">cell_tower</mat-icon>
  {{'products.toolbar.ToggleOnlineFilter' | translate}}
</button>
<mat-divider></mat-divider>

<div class="flex flex-col gap-2">
  <span mat-menu-item>{{'lastAlarms' | translate}}</span>

  <button mat-menu-item (click)="this.filterService.setLastAlarmsFilter(1)">
    <mat-icon *ngIf="filterService.activeFilter.value.lastAlarms === 1; else emptySpace">done</mat-icon>
    {{'today' | translate}}
  </button>

  <button mat-menu-item (click)="this.filterService.setLastAlarmsFilter(7)">
    <mat-icon *ngIf="filterService.activeFilter.value.lastAlarms === 7; else emptySpace">done</mat-icon>
    7 {{'days' | translate}}
  </button>

  <button mat-menu-item (click)="this.filterService.setLastAlarmsFilter(28)">
    <mat-icon *ngIf="filterService.activeFilter.value.lastAlarms === 28; else emptySpace">done</mat-icon>
    28 {{'days' | translate}}
  </button>

</div>

<ng-template #emptySpace>
  <div style="flex: 0 0 36px;"></div>
</ng-template>
