import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FeedbackService } from "../model/feedback.service";
import { FeedbackEntry } from "../model/feedback-api";
import { HotToastService } from "@ngxpert/hot-toast";

@Component({
  selector: 'app-feedback-entry-dialog',
  templateUrl: './feedback-entry-dialog.component.html',
  styleUrl: './feedback-entry-dialog.component.scss'
})
export class FeedbackEntryDialogComponent {

  reloading: boolean = false
  entry!: FeedbackEntry

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private dialogRef: MatDialogRef<FeedbackEntryDialogComponent>,
    private service: FeedbackService,
    private toast: HotToastService
  ) {
    this.reloading = true
    this.service.getEntry(data).subscribe({
      next: value => this.handleData(value),
      error: err => this.handleError(err)
    })
  }

  private handleData(value: FeedbackEntry) {
    this.entry = value
    this.reloading = false
  }

  private handleError(err: any) {
    this.toast.error(err)
    this.dialogRef.close()
  }
}
