<form class="filter">
  <mat-form-field [ngClass]="{'filter-option-selected': selectedOption && !filter().inline}"
                  [subscriptSizing]="filter().inline ? 'dynamic': 'fixed'"
                  appearance="outline"
                  class="dense-1">
    <mat-label class="text-base">
      <span>
         {{ filter().name }}
      </span>
      @if (filter().nameSuffix) {
        <span>({{ filter().nameSuffix }})</span>
      }
    </mat-label>

    @switch (filter().type) {
      @case (FilterType.AutoSelect) {
        <input [formControl]="formControlInstance"
               [matAutocomplete]="auto"
               aria-label="Filter"
               matInput
               type="text">
        <mat-autocomplete #auto="matAutocomplete"
                          (optionSelected)="onOptionSelected($event.option.value)"
                          [displayWith]="displayFn.bind(this)">
          @for (option of filter().options; track option) {
            <mat-option [value]="option">
              <ng-container [ngTemplateOutlet]="optionTemplate()"
                            [ngTemplateOutletContext]="{$implicit:option}">
              </ng-container>
            </mat-option>

          }
        </mat-autocomplete>
      }
      @case (FilterType.Select) {
        <mat-select multiple [formControl]="formControlInstance"
                    (selectionChange)="onOptionSelected($event.value)">
          @for (option of filter().options; track option) {

            <mat-option [value]="option">
              <ng-container [ngTemplateOutlet]="optionTemplate()"
                            [ngTemplateOutletContext]="{$implicit:option}">
              </ng-container>
            </mat-option>

          }
        </mat-select>
      }
    }

    @if (filter().clearable) {
      <div matSuffix>
        @if (formControlInstance.value) {
          <button aria-label="Example icon button with a open in new tab icon"
                  mat-icon-button (click)="clear($event)">
            <mat-icon>close</mat-icon>
          </button>
        }
      </div>
    }

  </mat-form-field>
</form>

<ng-template #defaultOption let-option>
                <span>
                  {{ option.displayValue | translate }}
                </span>
  @if (option.displayValueSuffix) {
    <span class="badge min-w-9">{{
        option.displayValueSuffix
      }}</span>
  }
</ng-template>
