import { Component } from '@angular/core';
import { CommerceCartService } from "../model/commerce-cart.service";
import { AuthService } from "../../auth/auth.service";
import { COMMERCE_READ } from "../../auth/roles";

@Component({
  selector: 'app-cart-info',
  templateUrl: './cart-info.component.html',
  styleUrl: './cart-info.component.scss'
})
export class CartInfoComponent {

  constructor(public service: CommerceCartService, private authService: AuthService) {
  }

  ngOnInit() {
    this.reload()
  }

  reload() {
    if (!this.hasCommerceReadPermissions()) return
    this.service.reload()
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ)
  }

}
