<mat-card class="flex flex-col justify-center mw-100 gap-2 compressor-card">
  <mat-card-header class="mb-3">
    <mat-card-title>{{'action.Edit' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="equipmentData">
    <form [formGroup]="equipmentData">
      <div class="flex flex-col">
        <mat-form-field class="smaller-gap">
          <mat-label>{{'name' | translate}}</mat-label>
          <input matInput formControlName="name" type="string">
        </mat-form-field>
        <div></div>

        <mat-form-field class="smaller-gap">
          <mat-label>{{'refrigerant' | translate}}</mat-label>
          <mat-select panelClass="mySelectedPanel" formControlName="refrigerant">
            <mat-option *ngFor="let refrigerant of refrigerants" [value]="refrigerant">
              {{refrigerant}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div></div>

        <mat-form-field class="smaller-gap">
          <mat-label>{{'serialNumber' | translate}}</mat-label>
          <input matInput formControlName="serialnumber" type="string" (focusout)="findType()">
        </mat-form-field>
        <div></div>

        <mat-form-field class="smaller-gap">
          <mat-label>{{'type' | translate}}</mat-label>
          <input matInput formControlName="type" type="string"
                 (keyup)="onKeyUp(searchText.value)" #searchText
                 [matAutocomplete]="auto">
          <mat-spinner matSuffix [diameter]="18" style="margin-left: 8px" *ngIf="loading"></mat-spinner>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredTypes | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div></div>

        <mat-form-field class="smaller-gap">
          <mat-label>{{'electronicModuleType' | translate}}</mat-label>
          <mat-select panelClass="mySelectedPanel" formControlName="electronicModuleType" #moduleType
                      (selectionChange)="onSerialNumberChange(serialNumber.value); onModuleTypeChange(moduleType.value)">
            <mat-option *ngFor="let eModule of electronicModules" [value]="eModule">
              {{eModule}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div></div>

        <mat-form-field class="mb-2 mb-sm-0">
          <mat-label>{{'electronicModuleSN' | translate}}</mat-label>
          <input matInput formControlName="electronicModuleSN" type="string" #serialNumber
                 (keyup)="onSerialNumberChange(serialNumber.value)" (change)="onSerialNumberChange(serialNumber.value)">
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="footer flex flex-row justify-between items-center content-center">
      <button mat-button matDialogClose class="ms-2">{{'cancel' | translate}}</button>
      <button mat-button [disabled]="!equipmentData?.valid" class="me-2"
              (click)="update()">{{'action.Save' | translate}}</button>
    </div>
  </mat-card-actions>
</mat-card>
