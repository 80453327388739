export class WeatherSeriesRequest {
  constructor(
    public from: string,
    public to: string,
  ) {
  }
}

export interface WeatherSeriesEntry {
  trackingId: number,
  timestamp: string,
  temp: number,
  pressure: number,
  humidity: number
}
