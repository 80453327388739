import { GeoJsonObject } from "geojson";
import {System} from "../../system/model/system-api";
import {Site} from "../../site/model/site-api";

export interface Registration {
  sites: RegisterSite[]
}

export const defaultRegistration: Registration = {
  sites: []
}

export interface RegisterSite {
  key: string,
  site: Site | undefined,
  request: RegisterSiteContent | undefined,
  systems: RegisterSystem[]
}

export interface RegisterSiteContent {
  name: string,
  street: string,
  streetNumber: string,
  zip: string,
  city: string,
  country: string,
  region: string,
  location: GeoJsonObject,
}

export interface RegisterSystem {
  key: string,
  system: System | undefined,
  request: RegisterSystemContent | undefined,
  equipment: RegisterEquipment[]
}

export interface RegisterSystemContent {
  name: string,
  type: string,
  refrigerant: string,
  timeZone: string,
}

export interface RegisterEquipment {
  id: string,
  name: string,
  refrigerant: string,
  serialNumber: string,
  type: string,
  module: RegisterElectronicModule
}

export interface RegisterElectronicModule {
  id: string,
  type: string,
  serialNumber: string
}

export class RegisterSiteRequest {
  constructor(
    public name: string,
    public street: string = "",
    public streetNumber: string = "",
    public zip: string = "",
    public city: string = "",
    public country: string = "",
    public region: string = "",
    public location: GeoJsonObject | null | undefined,
    public systems: RegisterSystemRequest[] = []
  ) {
  }
}

export class RegisterSystemRequest {
  constructor(
    public name: string,
    public type: string,
    public refrigerant: string,
    public timezone: string = "",
    public compressors: RegisterEquipmentRequest[] = []
  ) {
  }
}

export class RegisterEquipmentRequest {
  constructor(
    public name: string,
    public refrigerant: string,
    public serialNumber: string,
    public type: string,
    public module?: RegisterElectronicModuleRequest
  ) {
  }
}

export class RegisterElectronicModuleRequest {
  constructor(
    public type: string,
    public serialNumber: string
  ) {
  }
}

export interface EquipmentRegistrationResult {

}

export interface RegistrationStatus {
  code: number,
  description: {
    key: string,
    text: string
  }
}
