import { NgModule } from "@angular/core";
import { ActivationDialogComponent } from "./activation-dialog/activation-dialog.component";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivationConfigDialogComponent } from './activation-config-dialog/activation-config-dialog.component';
import { ActivationEditDialogComponent } from './activation-edit-dialog/activation-edit-dialog.component';

@NgModule({
  declarations: [
    ActivationDialogComponent,
    ActivationConfigDialogComponent,
    ActivationEditDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ActivationDialogComponent
  ]
})
export class ActivationModule { }
