import { Injectable, NgZone } from '@angular/core';
import { RegisterEquipmentRequest, RegisterSiteRequest, RegisterSystemRequest, Registration, } from "./registration-api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseService } from "../../utils/base-service";
import { LoggingService } from "../../utils/logging/logging.service";
import { Process, ProcessResponse } from "../../process/model/process-api";
import { AuthService } from "../../auth/auth.service";
import { REGISTRATION_CREATE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/logic/registration', logging, zone);
  }

  siteCreate(request: RegisterSiteRequest): Observable<Registration> {
    return this.post('site', request)
  }

  siteUpdate(siteId: String, request: RegisterSiteRequest): Observable<Registration> {
    return this.put("site/" + siteId, request)
  }

  siteRemove(siteId: String): Observable<Registration> {
    return this.delete("site/" + siteId)
  }

  systemCreate(request: RegisterSystemRequest, siteId: String): Observable<Registration> {
    return this.post("system/" + siteId, request)
  }

  systemUpdate(systemId: String, request: RegisterSystemRequest): Observable<Registration> {
    return this.put("system/" + systemId, request)
  }

  systemRemove(systemId: String): Observable<Registration> {
    return this.delete("system/" + systemId)
  }

  equipmentCreate(request: RegisterEquipmentRequest, systemId: String): Observable<Registration> {
    return this.post("equipment/" + systemId, request)
  }

  equipmentUpdate(equipmentId: String, request: RegisterEquipmentRequest): Observable<Registration> {
    return this.put("equipment/" + equipmentId, request)
  }

  equipmentRemove(equipmentId: String): Observable<Registration> {
    return this.delete("equipment/" + equipmentId)
  }

  getRegistration(): Observable<Registration> {
    return this.get("")
  }

  clear(): Observable<Registration> {
    return this.delete("")
  }

  submit(): Observable<ProcessResponse> {
    return this.post("", {})
  }

  getProcess(): Observable<Process> {
    return this.get("process")
  }

  selectSite(siteId: number): Observable<Registration> {
    return this.post("site/" + siteId + "/select", {})
  }

  selectSystem(systemId: number): Observable<Registration> {
    return this.post("system/" + systemId + "/select", {})
  }

  hasRegistrationPermissions(): boolean {
    return this.authService.hasRole(REGISTRATION_CREATE)
  }
}
