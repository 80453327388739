import { Component } from '@angular/core';
import { CommerceCartService } from "../model/commerce-cart.service";
import { MatDialog } from "@angular/material/dialog";
import { CartDeleteDialogComponent } from "../cart-delete-dialog/cart-delete-dialog.component";
import { CartCheckoutDialogComponent } from "../cart-checkout-dialog/cart-checkout-dialog.component";
import { COMMERCE_READ } from "../../auth/roles";
import { AuthService } from "../../auth/auth.service";
import { CartShareDialogComponent } from "../cart-share-dialog/cart-share-dialog.component";

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrl: './cart-details.component.scss'
})
export class CartDetailsComponent {


  constructor(public service: CommerceCartService, private dialog: MatDialog, private authService: AuthService) {
  }

  ngOnInit() {
    this.reload()
  }

  reload() {
    if (!this.hasCommerceReadPermissions()) return
    this.service.reload()
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ)
  }


  checkout() {
    this.dialog.open(CartCheckoutDialogComponent, {
      width: '85%',
      data: this.service.cart
    }).afterClosed().subscribe(_ => this.service.reload())
  }

  share() {
    this.dialog.open(CartShareDialogComponent, {
      data: this.service.cart
    }).afterClosed().subscribe(_ => this.service.reload())
  }

  deleteAll() {
    const dialogRef = this.dialog.open(CartDeleteDialogComponent)
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) return
      this.service.deleteAllEntries().subscribe()
    })
  }

}
