import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { HeaderToolbarActions, HeaderToolbarContent, HeaderToolbarFilter, HeaderToolbarIcon } from "./header-toolbar-directives";
import { HeaderToolbarComponent } from "./header-toolbar.component";
import { MaterialModule } from "../../material/material.module";


@NgModule({
  declarations: [
    HeaderToolbarActions,
    HeaderToolbarContent,
    HeaderToolbarIcon,
    HeaderToolbarFilter,
    HeaderToolbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MaterialModule,
    RouterLink,
  ],
  exports: [
    HeaderToolbarActions,
    HeaderToolbarContent,
    HeaderToolbarIcon,
    HeaderToolbarFilter,
    HeaderToolbarComponent
  ]
})
export class HeaderToolbarModule {
}
