<mat-card class="px-3">
  <nav [tabPanel]="tabPanel" color="accent" mat-tab-nav-bar>
    <a #rla="routerLinkActive" *ngFor="let link of navLinks"
       [active]="rla.isActive"
       [queryParams]="{product: product?.id}"
       [routerLink]="link.path"
       mat-tab-link
       routerLinkActive>
      {{ link.label | translate }}
    </a>
  </nav>
  <mat-divider></mat-divider>
  <mat-tab-nav-panel #tabPanel class="pb-3">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</mat-card>
