import {Component, Input, output} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatChipSelectionChange, MatChipsModule} from "@angular/material/chips";
import {DocumentTypeResultEntry} from "../../../portal/search/model/search-api";
import {TranslateModule} from "@ngx-translate/core";

export interface SelectionChange {
  selectedTypes: string[];
}

export type FilterChipItemEntry = DocumentTypeResultEntry;

export interface FilterChipItems {
  selectedItems: string[];
  availableItems: FilterChipItemEntry[];
}

@Component({
  selector: 'app-filter-chips',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatChipsModule, TranslateModule],
  templateUrl: './filter-chips.component.html',
  styleUrl: './filter-chips.component.scss'
})
export class FilterChipsComponent {
  types: DocumentTypeResultEntry[] = []
  availableTypes: DocumentTypeResultEntry[] = [];
  formControl = new FormControl<string[]>([]);
  selectionChanged = output<SelectionChange>();

  @Input()
  set availableItems(data: DocumentTypeResultEntry[]) {
    this.types = data || [];
    this.updateAvailableTypes();
  }

  @Input()
  set selectedItems(data: string[]) {
    this.formControl.setValue(data);
  }

  handleChipSelectionChange(event: MatChipSelectionChange, type: DocumentTypeResultEntry) {
    if (!event.isUserInput) return;

    event.selected ? this.selectType(type.type) : this.unselectType(type.type);
  }

  selectType(type: string) {
    const selectedTypes = this.formControl.value || [];
    if (!selectedTypes.includes(type)) {
      this.formControl.setValue([...selectedTypes, type]);
      this.notifySelectionChanged();
    }
  }

  unselectType(type: string) {
    const selectedTypes = this.formControl.value || [];
    const updatedTypes = selectedTypes.filter(t => t !== type);
    if (updatedTypes.length !== selectedTypes.length) {
      this.formControl.setValue(updatedTypes);
      this.notifySelectionChanged();
    }
  }

  isSelected(type: string) {
    return this.formControl.value?.includes(type) || false;
  }

  private updateAvailableTypes() {
    const selectedTypes = this.formControl.value || [];
    this.availableTypes = this.types.filter(t => !selectedTypes.includes(t.type));
  }

  private notifySelectionChanged() {
    const selectedTypes = this.formControl.value || [];
    this.selectionChanged.emit({selectedTypes});
  }
}
