import { Injectable, NgZone } from '@angular/core';
import { BaseService, PatchRequest } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { AuthService } from "../../auth/auth.service";
import {
  TemporalAccessEntryEquipment,
  TemporalAccessEntryItem,
  TemporalAccessEquipmentRequest,
  TemporalAccessEquipmentResponse,
  TemporalAccessItemRequest,
  TemporalAccessItemResponse
} from "./access-api";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TemporalAccessService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/access/temporal', logging, zone)
    this.retryCount = 0
  }

  createTemporalAccessItem(request: TemporalAccessItemRequest): Observable<TemporalAccessItemResponse> {
    return this.post('item', request)
  }

  acceptTemporalAccessItem(hash: string): Observable<TemporalAccessEntryItem> {
    return this.post('item/accept', new PatchRequest(hash))
  }

  createTemporalAccessEquipment(request: TemporalAccessEquipmentRequest): Observable<TemporalAccessEquipmentResponse> {
    return this.post('equipment', request)
  }

  acceptTemporalAccessEquipment(hash: string): Observable<TemporalAccessEntryEquipment> {
    return this.post('equipment/accept', new PatchRequest(hash))
  }
}
