<div class="title m-3">{{ 'alarmsPerWeekday' | translate }}</div>

<table>
  <thead>
    <tr>
      <th>{{ 'dailyHours' | translate }}</th>
      @for (day of days; track $index) {
        <th>{{ day | translate }}</th>
      }
    </tr>
  </thead>
  <tbody>
    @for(data of tableData; track $index) {
      <tr>
        <td>{{ data.time }}</td>
        @for (d of data.alarms; track $index) {
          <td>
            <app-distribution-entry [entriesData]="d"></app-distribution-entry>
          </td>
        }
      </tr>
    }
  </tbody>
</table>

