import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CategoryRoutingModule } from "./category-routing.module";
import { CategorySelectComponent } from './category-select/category-select.component';
import { CategorySelectTreeComponent } from './category-select-tree/category-select-tree.component';


@NgModule({
  declarations: [
    CategorySelectComponent,
    CategorySelectTreeComponent
  ],
  exports: [
    CategorySelectComponent
  ],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
  ]
})
export class CategoryModule {
}
