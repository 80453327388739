import {Component, OnInit} from '@angular/core';
import { ProductConfigBoardService } from "../model/product-config-board.service";
import { StatusIconMap } from "../../material/material-status/material-status.component";
import { MatSelectChange } from "@angular/material/select";
import {FindResult} from "../../sales-order/model/sales-order-api";
import {Page} from "../../../utils/page";

@Component({
  selector: 'app-product-config-filter',
  templateUrl: './product-config-filter.component.html',
  styleUrl: './product-config-filter.component.scss'
})
export class ProductConfigFilterComponent implements OnInit {

  availableStatus = Object.values(StatusIconMap).map(m => m.text)


  constructor(public service: ProductConfigBoardService) {
  }

  ngOnInit() {
    this.service.findMaterial('').subscribe({
      next: (value) => this.handleMaterials(value)
    });
  }

  private handleMaterials(value: Page<FindResult>) {
    this.service.matNrKMats = value?.content || []
  }

  resetFilter() {
    this.service.resetFilter()
  }

  handleStatusSelectionChanged(event: MatSelectChange) {
    let values = event.value as string[]
    if (values) this.service.setStatusFilter(values)
  }

  handleMatNrKMatSelectionChanged(event: MatSelectChange) {
    let values = event.value as string[]
    if (values) this.service.setMatNrKMatsFilter(values)
  }

  handleTypeSelectionChanged(event: MatSelectChange) {
    let values = event.value as string[]
    if (values) this.service.setTypeFilter(values)
  }
}
