import { Component, Input } from '@angular/core';
import { AuthService } from "../../auth/auth.service";
import { CartEntryChangeResponse } from "../model/commerce-api";
import { HotToastService } from "@ngxpert/hot-toast";
import { CommerceCartService } from "../model/commerce-cart.service";
import { COMMERCE_ADMIN, COMMERCE_READ } from "../../auth/roles";

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrl: './add-to-cart-button.component.scss'
})
export class AddToCartButtonComponent {

  @Input() code: string = ''
  @Input() quantity: number = 1
  @Input() mode: 'fab' | 'button' = 'fab'
  reloading: boolean = false

  constructor(
    private authService: AuthService,
    private service: CommerceCartService,
    private toast: HotToastService
  ) {
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ) || this.authService.hasRole(COMMERCE_ADMIN)
  }

  addToCart() {
    this.reloading = true
    this.service.addToCart(this.code, this.quantity).subscribe({
      next: value => this.handleData(value),
      error: err => this.handleError(err)
    })
  }

  private handleData(value: CartEntryChangeResponse) {

    this.reloading = false
  }

  private handleError(err: any) {
    this.toast.error("Something went wrong")
    this.reloading = false
  }
}
