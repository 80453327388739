import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SparePartCategory } from "../../product/model/product-api";

@Component({
  selector: 'app-spare-parts-categories',
  templateUrl: './spare-parts-categories.component.html',
  styleUrls: ['./spare-parts-categories.component.scss']
})
export class SparePartsCategoriesComponent {

  @Input() categories: SparePartCategory[] = []
  @Output() select: EventEmitter<SparePartCategory> = new EventEmitter<SparePartCategory>()


  selectCategory(category: SparePartCategory) {
    this.select.emit(category)
  }

  getMedia(media: string[]) {
    return media.find(url => url.includes(".svg"))
  }
}
