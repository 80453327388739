import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appFilterOption]',
  standalone: true
})
export class FilterOptionDirective {

  constructor(public templateRef: TemplateRef<unknown>) {
  }

}
