import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SparePartsCategoryService} from "../model/spare-parts-category.service";
import {Subscription} from "rxjs";
import {WishlistService} from "../../wishlist/model/wishlist.service";
import {WishlistEntry} from "../../wishlist/model/wishlist-api";

@Component({
  selector: 'app-spare-parts-category-details',
  templateUrl: './spare-parts-category-details.component.html',
  styleUrls: ['./spare-parts-category-details.component.scss']
})
export class SparePartsCategoryDetailsComponent implements OnInit, OnDestroy {

  @Input() imageMaxHeight: string = ''

  displayedColumns = ['text', 'content']
  content: SparePartPropertyEntry[] = []
  subscriptions = new Subscription()
  wishlistActive: WishlistEntry[] = []

  constructor(
    public service: SparePartsCategoryService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.service.selectedSparePartChanged.subscribe(() => this.updateTableOfContent()))
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  private updateTableOfContent() {
    let sparePart = this.service.selectedSparePart
    if (sparePart) {
      this.content = [
        {text: 'spareParts.id', content: sparePart.id},
        {text: 'spareParts.description', content: sparePart.description},
        {text: 'spareParts.use', content: sparePart.usage},
        {text: 'spareParts.info', content: sparePart.information},
        {text: 'spareParts.weight', content: sparePart.weight},
        {text: 'spareParts.dimension', content: sparePart.dimensions},
        {text: 'spareParts.quantity', content: sparePart.quantity},
        {text: 'spareParts.year', content: sparePart.fromYear}
      ]
    } else {
      this.content = []
    }
  }
}


interface SparePartPropertyEntry {
  text: string
  content: any
}
