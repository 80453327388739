import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductConfigRoutingModule} from './product-config-routing.module';
import {ProductConfigBoardComponent} from './product-config-board/product-config-board.component';
import {
  ProductConfigProductDetailsComponent
} from './product-config-product-details/product-config-product-details.component';
import {HeaderToolbarModule} from "../../component/header-toolbar/header-toolbar.module";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from 'src/app/shared/share.module';
import {
  ProductConfigCompareComponent
} from './product-config-compare/product-config-compare.component';
import {CommerceModule} from "../../commerce/commerce.module";
import {
  ProductConfigSelectComponent
} from './product-config-select/product-config-select.component';
import {
  ProductConfigSelectResultComponent
} from './product-config-select-result/product-config-select-result.component';
import {
  ProductConfigSelectResultPanelComponent
} from './product-config-select-result-panel/product-config-select-result-panel.component';
import {
  ProductConfigSelectInputComponent
} from './product-config-select-input/product-config-select-input.component';
import {ProductConfigTableComponent} from './product-config-table/product-config-table.component';
import {
  ProductConfigDetailsComponent
} from './product-config-details/product-config-details.component';
import {
  ProductConfigFilterComponent
} from './product-config-filter/product-config-filter.component';
import {
  ProductConfigDetailsHeaderComponent
} from './product-config-details-header/product-config-details-header.component';
import {
  ProductConfigDetailsTitleComponent
} from './product-config-details-title/product-config-details-title.component';
import {
  ProductConfigDetailsCsticsComponent
} from './product-config-details-cstics/product-config-details-cstics.component';
import {MaterialStatusComponent} from "../material/material-status/material-status.component";
import {MaterialModule} from "../../material/material.module";
import {StatusChipComponent} from "../../component/status-chip/status-chip.component";
import {WeekNumberPipe} from "../../component/week-number.pipe";
import {
  DateRangePickerComponent
} from "../../component/filter/date-range-picker/date-range-picker.component";
import {FilterComponent} from "../../component/filter/filter/filter.component";
import {FilterOptionDirective} from "../../component/filter/filter-option.directive";
import {HasRoleDirective} from "../../auth/has-role.directive";
import {ReactiveFormsModule} from "@angular/forms";
import {
  ProductConfigWishlistComponent
} from './product-config-wishlist/product-config-wishlist.component';
import {ProductConfigCartComponent} from './product-config-cart/product-config-cart.component';
import {ComponentModule} from "../../component/component.module";
import {
  ProductConfigCompareButtonComponent
} from "./product-config-compare-button/product-config-compare-button.component";
import {ProductConfigMainComponent} from "./product-config-main/product-config-main.component";
import {FloatingButtonComponent} from "../../component/floating-button/floating-button.component";


@NgModule({
  declarations: [
    ProductConfigBoardComponent,
    ProductConfigProductDetailsComponent,
    ProductConfigCompareComponent,
    ProductConfigSelectComponent,
    ProductConfigSelectResultComponent,
    ProductConfigSelectResultPanelComponent,
    ProductConfigSelectInputComponent,
    ProductConfigTableComponent,
    ProductConfigDetailsComponent,
    ProductConfigFilterComponent,
    ProductConfigDetailsHeaderComponent,
    ProductConfigDetailsTitleComponent,
    ProductConfigDetailsCsticsComponent,
    ProductConfigWishlistComponent,
    ProductConfigCartComponent,
    ProductConfigMainComponent
  ],
  imports: [
    CommonModule,
    ProductConfigRoutingModule,
    HeaderToolbarModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    CommerceModule,
    ReactiveFormsModule,
    MaterialStatusComponent,
    StatusChipComponent,
    WeekNumberPipe,
    DateRangePickerComponent,
    FilterComponent,
    FilterOptionDirective,
    HasRoleDirective,
    ComponentModule,
    ProductConfigCompareButtonComponent,
    FloatingButtonComponent
  ],
  exports: [
    ProductConfigWishlistComponent
  ]
})
export class ProductConfigModule {
}
