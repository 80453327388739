import { Site } from "../../site/model/site-api";
import { System } from "../../system/model/system-api";
import { Equipment } from "../../equipment/model/equipment-api";

export interface ShareItem {
  itemId: number,
  name: string,
  canRead: boolean,
  canWrite: boolean,
  grantToShare: boolean,
}

export interface SiteShareItem {
  site: ShareItem,
  children: SiteShareItem[],
  systems: SystemShareItem[]
}

export interface SystemShareItem {
  system: ShareItem,
  type: string,
  equipments: ShareItem[]
}

export interface ItemAccessEntryChangeRequest {
  companyId: number,
  canRead: boolean,
  canWrite: boolean
}


export interface AccessEntryItem {
  itemId: number,
  companyId: number,
  canRead: boolean,
  canWrite: boolean,
  grantToShare: boolean,
  shareLicense?: boolean
}

export interface AccessEntryEquipment {
  equipmentId: number,
  companyId: number,
  canRead: boolean,
  canWrite: boolean,
  grantToShare: boolean,
  shareLicense?: boolean
}

export interface SiteTree {
  site: Site,
  children: SiteTree[],
  systems: SystemTree[]
}


export interface SystemTree {
  system: System,
  equipments: Equipment[]
}

export class ValidateShareEdit {
  constructor(
    public itemIds: number[],
    public equipmentIds: number[],
  ) {
  }
}


export interface ValidationResult {
  editable: boolean
}


export class SiteShareStatus {
  constructor(
    public site: Site,
    public canRead: boolean,
    public canWrite: boolean,
    public grantToShare: boolean,
    public children: SiteShareStatus[],
    public systems: SystemShareStatus[]
  ) {
  }
}

export class SystemShareStatus {
  constructor(
    public system: System,
    public canRead: boolean,
    public canWrite: boolean,
    public grantToShare: boolean,
    public equipments: EquipmentShareStatus[]
  ) {
  }
}

export class EquipmentShareStatus {
  constructor(
    public equipment: Equipment,
    public canRead: boolean,
    public canWrite: boolean,
    public grantToShare: boolean,
  ) {
  }
}

export class ShareChangeRequest {
  constructor(
    public items: ItemShareChangeRequest[],
    public equipment: EquipmentShareChangeRequest[]
  ) {
  }
}

export class ItemShareChangeRequest {
  constructor(
    public itemId: number,
    public request: AccessEntryChangeRequest[]
  ) {
  }
}


export class EquipmentShareChangeRequest {
  constructor(
    public equipmentId: number,
    public shareLicense: boolean,
    public request: AccessEntryChangeRequest[]
  ) {
  }
}

export class AccessEntryChangeRequest {
  constructor(
    public canRead: boolean,
    public canWrite: boolean,
    public grantToShare: boolean,
    public companyId: number
  ) {
  }
}

export class TemporalAccessItemRequest {
  constructor(
    public itemId: number,
    public period: string
  ) {
  }
}

export class TemporalAccessEquipmentRequest {
  constructor(
    public equipmentId: number,
    public period: string
  ) {
  }
}

export interface TemporalAccessItemResponse {
  entry: TemporalAccessEntryItem,
  url: string
}

export interface TemporalAccessEntryItem {
  itemId: number,
  companyId: number,
  created: string,
  validUntil: string,
  status: string,
}

export interface TemporalAccessEquipmentResponse {
  entry: TemporalAccessEntryEquipment,
  url: string
}

export interface TemporalAccessEntryEquipment {
  equipmentId: number,
  companyId: number,
  created: string,
  validUntil: string,
  status: string,
}
