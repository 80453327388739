<h2 mat-dialog-title class="text-center"> {{ 'selectProperty' | translate }} </h2>
<mat-dialog-content class="mat-typography">
  <form>
    <mat-form-field class="example-full-width">
      <input type="text" matInput [matAutocomplete]="auto" [formControl]="propertyControl">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayDefinition.bind(this)">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option.name">{{ getPropertyName(option.name) }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="float-end p-3">
  <button mat-flat-button (click)="select()">{{ 'ok' | translate }}</button>
  <button mat-flat-button (click)="cancel()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
