import {Component, Input} from '@angular/core';
import {System} from "../model/system-api";
import {Router} from "@angular/router";
import {Equipment, EquipmentInfo} from "../../equipment/model/equipment-api";
import {EquipmentService} from "../../equipment/model/equipment.service";
import {Page} from "../../utils/page";
import {PageEvent} from "@angular/material/paginator";
import {FilterService} from "../../filter/model/filter.service";
import {interval, Subscription} from "rxjs";
import {SystemService} from "../model/system.service";
import {HotToastService} from "@ngxpert/hot-toast";
import {RegistrationProcessService} from "../../registration/model/registration-process.service";
import {PropertyService} from "../../property/model/property.service";
import {PropertyDouble, PropertyName} from "../../property/model/property-api";
import {LicenseService} from "../../accounting/model/license.service";
import {LicenseInfo} from "../../accounting/model/license-api";

@Component({
  selector: 'app-system-details-equipment-list',
  templateUrl: './system-details-equipment-list.component.html',
  styleUrls: ['./system-details-equipment-list.component.scss']
})
export class SystemDetailsEquipmentListComponent {
  system: System | undefined
  equipment: EquipmentInfo[] = []
  properties: Map<number, Map<string, PropertyDouble>> = new Map()
  displayedColumns: string[] = ['name', 'type', 'lastAlarms', 'status', 'capacity', 'condTemp', 'evapTemp', 'gasTemp', 'actions']
  licenses: Map<number, LicenseInfo> = new Map()
  reloading: boolean = false
  equipmentImage: string = 'assets/img/comp.png'
  pageIndex: number = 0
  pageSize: number = 8
  totalSize: number = -1
  private filterSubscription: Subscription | undefined
  private reloadSubscription: Subscription | undefined

  constructor(
    private equipmentService: EquipmentService,
    private propertyService: PropertyService,
    public registrationService: RegistrationProcessService,
    private router: Router,
    private filterService: FilterService,
    public systemService: SystemService,
    private toastService: HotToastService,
    private licenseService: LicenseService
  ) {
    this.subscribeToSubjects()
  }

  @Input()
  set data(data: System | undefined) {
    this.system = data
    this.updateData()
  }

  loadSystemsOnScroll(page: number) {
    this.loadPage(page, this.pageSize)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loadPage(event.pageIndex, event.pageSize)
  }

  setLastProperties(thingId: number) {
    let filter = [
      "BDN.Capacity",
      "BDN.CondTemp",
      "BDN.EvapTemp",
      "BDN.OilDiscGasTemp"]
    this.propertyService.getLastThingDoubleProperties(thingId, filter).subscribe(it => {
      this.properties.set(thingId, it)
    })
  }

  getLastPropertiesValues(thingId: number, index: number) {
    let equipmentProperties = this.properties.get(thingId)
    if (!equipmentProperties || equipmentProperties.size == 0) return ''
    let values: Map<string, PropertyDouble> = new Map(Object.entries(equipmentProperties))
    return values.get(PropertyName[index])?.value || ''
  }

  onAddEquipment() {
    if (!this.system) return
    const currentUrl = this.router.url
    localStorage.setItem('previousLocation', currentUrl)
    this.registrationService.selectSystem(this.system.id).subscribe({
      next: (value) => this.router.navigate(['registration']).then(),
      error: err => this.toastService.error("Error on system creation")
    })
  }

  openEquipmentAlarms(e: Event, equipment: Equipment) {
    e.stopPropagation()
    this.router.navigate(['equipment', equipment.id, 'alarms']).then()
  }

  openEquipmentReport(e: Event, equipment: Equipment) {
    e.stopPropagation()
    this.router.navigate(['equipment', equipment.id, 'operational', 'report']).then()
  }

  openEquipmentGraph(e: Event, equipment: Equipment) {
    e.stopPropagation()
    this.router.navigate(['equipment', equipment.id, 'operational', 'graph']).then()
  }

  openEquipmentDetailsPage(equipment: EquipmentInfo) {
    this.router.navigate(['equipment', equipment.equipment.id]).then()
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe()
      this.filterSubscription = undefined
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe()
      this.reloadSubscription = undefined
    }
  }

  private updateData() {
    this.loadPage(this.pageIndex, this.pageSize)
  }

  private loadEquipmentValues() {
    this.licenses = new Map()
    this.equipment.forEach(data => {
      this.setLastProperties(data.equipment.thingId)
      this.getLicense(data)
    })
  }

  private loadPage(page: number, size: number) {
    if (!this.system) return
    if (this.reloading) return
    this.reloading = true
    if (this.filterService.activated) {
      this.equipmentService.getFilteredSystemEquipments(this.system.id, this.filterService.activeFilter.value, page, size).subscribe(p => this.handleData(p))
    } else {
      this.equipmentService.getSystemEquipments(this.system.id, page, size).subscribe(p => this.handleData(p))
    }
  }

  private handleData(p: Page<EquipmentInfo>) {
    this.equipment = p.content
    this.pageIndex = p.pageable.number
    this.pageSize = p.pageable.size
    this.totalSize = p.totalSize
    this.loadEquipmentValues()
    this.reloading = false
  }

  private subscribeToSubjects() {
    this.filterSubscription = this.filterService.activeFilter.subscribe(value => {
      if (this.filterService.activated) this.loadPage(0, this.pageSize)
    })

    this.reloadSubscription = interval(180000).subscribe(_ => this.loadPage(this.pageIndex, this.pageSize))
  }

  private getLicense(equipment: EquipmentInfo) {
    if (!this.equipment) return
    this.licenseService.findByEquipment(equipment.equipment.id).subscribe(info => {
      if (!info) return
      this.licenses.set(equipment.equipment.id, info)
    })
  }
}
