@if (data) {
  <h1 mat-dialog-title>{{ "cart.dialog.delete.entry.title" | translate }}
    <div *ngIf="data">{{ data.description }}</div>
  </h1>
  <div mat-dialog-content>
    <p>{{ "cart.dialog.delete.entry.question" | translate }}</p>
  </div>
} @else {
  <h1 mat-dialog-title>{{ "cart.dialog.delete.title" | translate }}
  </h1>
  <div mat-dialog-content>
    <p>{{ "cart.dialog.delete.question" | translate }}</p>
  </div>
}
<mat-dialog-actions align="end">
  <div class="flex flex-row gap-2">
    <button color="warn" mat-button mat-dialog-close mat-stroked-button>
      <mat-icon>cancel</mat-icon>
      {{ "no" | translate }}
    </button>
    <button [mat-dialog-close]="true" color="primary" mat-button mat-stroked-button>
      <mat-icon>check</mat-icon>
      {{ "yes" | translate }}
    </button>
  </div>
</mat-dialog-actions>
