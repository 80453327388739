export class MenuItem {
  constructor(
    public url: string,
    public text: string,
    public icon: string,
    public roles: string[] = [],
    public children: MenuItem[] = [],
    public anonymous: boolean = false
  ) {
  }
}
