import {Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {EquipmentInfo} from "../model/equipment-api";
import {EChartsOption, init} from "echarts";
import {EnvelopeService} from "../../property/model/envelope.service";
import {PropertyService} from "../../property/model/property.service";
import { EnvelopeInfo } from "../../property/model/property-api";

@Component({
  selector: 'app-equipment-envelope',
  templateUrl: './equipment-envelope.component.html',
  styleUrls: ['./equipment-envelope.component.scss']
})
export class EquipmentEnvelopeComponent implements OnDestroy {


  @Input()
  set data(data: EquipmentInfo | undefined) {
    this.loading = true
    this.equipment = data
    if (this.equipment) {
      this.updateData()
    }
  }

  equipment: EquipmentInfo | undefined
  private envelope: EnvelopeInfo | undefined

  @ViewChild('chartContainer') chartContainer!: ElementRef

  chartInstance: echarts.ECharts | null = null
  chartOptions: EChartsOption = {}
  loading = false

  constructor(
    private service: EnvelopeService,
    private propertyService: PropertyService
  ) {
  }

  ngOnDestroy() {
    this.disposeChart();
  }


  private updateData() {
    if (!this.equipment) {
      this.loading = false
      return
    }
    this.disposeChart()

    if (!this.envelope) {
      this.service.getEnvelope(this.equipment.equipment.id).subscribe((data) => {
        if (!data || !data.points.length) {
          this.loading = false
          return
        }
        this.envelope = data
        this.getProperties(data)
      })
    } else {
      this.getProperties(this.envelope)
    }
  }

  private getProperties(data: EnvelopeInfo) {
    this.propertyService.getEnvelopePropertyEntries(this.equipment!!.equipment.id, data.type).subscribe(
      properties => {
        this.chartInstance = init(this.chartContainer.nativeElement)
        this.chartInstance.setOption(this.service.createChartOptions(data, properties))
        window.addEventListener('resize', () => this.chartInstance?.resize())
        this.loading = false
      })
  }


  private disposeChart() {
    if (this.chartInstance) {
      this.service.resetAxisLabels()
      this.chartInstance.dispose()
      this.chartInstance = null
    }
  }

}
