import { Injectable } from '@angular/core';
import { ProductService } from "./product.service";
import { Product } from "./product-api";
import { Observable, take } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  private product: Observable<Product> | undefined
  private productId: string | undefined

  constructor(
    private productService: ProductService,
    private router: Router
  ) {}

  setProduct(productId: string) {
    if(!productId.length) return
    this.product = this.productService.getProduct(productId).pipe(take(1), shareReplay())
    this.productId = productId
    return this.product
  }

  getProduct(productId: string) {
    if(this.productId != productId) return
    return this.product
  }

  getCompositeAttributes(productId: string) {
    return this.productService.getCompositeAttributes(productId)
  }

  isProductDetailsRoute() {
    return this.router.url.includes('/product')
  }
}
