export enum ConsentEventType {
  UC_UI_CMP_EVENT = 'UC_UI_CMP_EVENT',
}

export enum ConsentEventSubType {
  SAVE = 'SAVE',
  ACCEPT_ALL = 'ACCEPT_ALL',
}

export enum ConsentServiceName {
  LOG_ROCKET = "LogRocket",
  GOOGLE_ANALYTICS = "Google Tag Manager",
}

export enum ConsentCookie {
  GOOGLE_ANALYTICS = "google_analytics",
}
