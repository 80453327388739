import {Injectable, NgZone, signal} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseService} from "../../../utils/base-service";
import {LoggingService} from "../../../utils/logging/logging.service";
import {
  ProductComparisonRequest,
  ProductComparisonResponse,
  ProductConfigId,
  ProductConfigIdInfo,
  ProductConfigSearchRequest,
  ProductConfigSearchResponse,
  ProductConfigSelectRequest,
  ProductConfigSelectResult
} from "./product-config-api";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "../../../utils/page";
import {ExportService} from "../../export/model/export.service";
import {FindResult} from "../../sales-order/model/sales-order-api";

@Injectable({
  providedIn: 'root'
})
export class ProductConfigService extends BaseService {
  private comparisonIds = signal<string[]>([]);
  comparisonPids = this.comparisonIds.asReadonly()

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private translateService: TranslateService,
    private exportService: ExportService
  ) {
    super(http, 'api/knowledge/pid', logging, zone)
    this.retryCount = 0
  }

  selectStandard(productId: string): Observable<ProductConfigSelectResult> {
    let params = new HttpParams()
    .append('lang', this.translateService.currentLang)
    .append('productId', productId)
    return this.post('select/standard', {}, params)
  }

  select(request: ProductConfigSelectRequest): Observable<ProductConfigSelectResult> {
    let params = new HttpParams()
    .append('lang', this.translateService.currentLang)
    return this.post('select', request, params)
  }

  compare(request: ProductComparisonRequest): Observable<ProductComparisonResponse> {
    let params = new HttpParams()
    .append('lang', this.translateService.currentLang)

    return this.post('compare', request, params)
  }

  getProductConfigId(id: string): Observable<ProductConfigId> {
    return this.get('' + id)
  }

  getProductConfigIdInfo(id: string): Observable<ProductConfigIdInfo> {
    let params = new HttpParams()
    .append('lang', this.translateService.currentLang)
    return this.get('' + id + '/info', params)
  }

  getAllProductConfigIds(page: number, size: number): Observable<Page<ProductConfigId>> {
    return this.getPaged('', page, size)
  }

  search(request: ProductConfigSearchRequest, page: number, size: number): Observable<ProductConfigSearchResponse> {
    let params = new HttpParams()
    .append('page', page)
    .append('size', size)
    return this.post<ProductConfigSearchResponse>('search', request, params)
  }

  findMaterial(query: string, page: number, size: number): Observable<Page<FindResult>> {
    let params = new HttpParams()
    params = params.append('query', query)
    params = params.append('lang', this.translateService.currentLang)
    return this.getPaged('find/material', page, size, params)
  }

  exportPidData(id: string) {
    return this.exportService.exportSinglePidExcel(id);
  }

  addToComparison(pid: string | string[]): void {
    const pids = Array.isArray(pid) ? pid : [pid]

    const newPids = pids.filter(value => !this.comparisonPids().includes(value));
    this.comparisonIds.update(ids => [...ids, ...newPids])

  }

  clearComparisonPids(): void {
    this.comparisonIds.set([])
  }

  removeFromComparison(pid: string) {
    const ids = this.comparisonIds().filter(id => id !== pid)
    this.comparisonIds.set(ids)
  }
}
