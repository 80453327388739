import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";

@Component({
  selector: 'app-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.scss']
})
export class DocumentUploadDialogComponent {

  submitUpload: Subject<void> = new Subject()

  constructor(
    @Inject(MAT_DIALOG_DATA) public itemId: number,
    public dialogRef: MatDialogRef<DocumentUploadDialogComponent>
  ) {
  }

  handleUploadFinished() {
    this.dialogRef.close()
  }

  onUpload() {
    this.submitUpload.next()
  }
}
