import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FeedbackService } from "../model/feedback.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FeedbackRequest } from "../model/feedback-api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrl: './feedback-dialog.component.scss'
})
export class FeedbackDialogComponent {

  fg: FormGroup = this.fb.group({
    comment: this.fb.control(''),
    score: this.fb.control('', [Validators.required])
  })

  points = this.service.createPoints(6)
  processing: boolean = false
  private url!: string

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string },
    private service: FeedbackService,
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.url = this.router.url
  }


  submit() {
    if (!this.fg.valid) return
    this.processing = true

    let values = this.fg.value
    this.service.getPosition().then(pos => this.sendFeedback(values, pos))
  }

  private sendFeedback(values: any, pos: any) {
    let request = new FeedbackRequest(
      values.score, values.comment,
      this.url, window.navigator.userAgent,
      this.translate.currentLang,
      pos.lat, pos.lon
    )

    this.service.feedback(request).subscribe({
      next: value => this.dialogRef.close(),
      error: err => this.dialogRef.close()
    })
  }
}
