import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyCategorySelectorComponent } from "./property-category-selector/property-category-selector.component";
import { PropertyCategorySelectorDialogComponent } from "./property-category-selector-dialog/property-category-selector-dialog.component";
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    PropertyCategorySelectorComponent,
    PropertyCategorySelectorDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ],
  exports: [
    PropertyCategorySelectorComponent,
    PropertyCategorySelectorDialogComponent
  ]
})
export class PropertyModule {
}
