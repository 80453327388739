@if (equipment) {
  <mat-card>
    <mat-card-header>
      <h5>{{ equipment.productId + ' - ' + equipment.name }}</h5>
    </mat-card-header>
    <mat-card-content class="chart-container">
      <div echarts
           [options]="primaryInitialValue"
           [merge]="primaryDynamicData"
           (chartClick)="handlePrimaryChartClick($event)"
           (chartInit)="setPrimaryChart($event)"
           class="primary-chart"></div>
      <div echarts
           [options]="secondaryInitialValue"
           [merge]="secondaryDynamicData"
           (chartInit)="setSecondaryChart($event)"
           class="secondary-chart"></div>
    </mat-card-content>
  </mat-card>
}
