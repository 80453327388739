import { Component, Input } from '@angular/core';
import { TableCardEntry } from "../model/card-api";

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss']
})
export class TableCardComponent {

  @Input()
  title: string = ''

  @Input()
  data: TableCardEntry[] = []

}
