import { Injectable, NgZone } from '@angular/core';
import { Observable } from "rxjs";
import { BaseService } from "../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import {
  EnvelopePropertyEntry,
  PropertyDouble,
  PropertyDoubleEdges,
  PropertySearchRequest,
  PropertyText
} from "./property-api";
import { Page } from "../../utils/page";

@Injectable({
  providedIn: 'root'
})
export class PropertyService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/property', logging, zone)
    this.retryCount = 1
  }


  getLastThingDoubleProperties(thingId: number, filter: string[]): Observable<Map<string, PropertyDouble>> {
    return this.put('last/' + thingId + '/filter/numerical', filter)
  }

  getLastThingTextProperties(thingId: number, filter: string[]): Observable<Map<string, PropertyText>> {
    return this.put('last/' + thingId + '/filter/text', filter)
  }

  getDoubleProperties(equipmentId: number, request: PropertySearchRequest, page: number, size: number): Observable<Page<PropertyDouble>> {
    return this.putPaged(equipmentId + '/properties/double', request, page, size)
  }

  getEnvelopePropertyEntries(equipmentId: number, siUnit: string): Observable<EnvelopePropertyEntry[]> {
    let params = new HttpParams()
    params = params.append('siUnit', siUnit)
    return this.get(equipmentId + '/envelope/properties', params)
  }

  getDoublePropertyEdges(equipmentId: number, request: PropertySearchRequest): Observable<PropertyDoubleEdges> {
    return this.put(equipmentId + '/properties/double/edge', request)
  }
}
