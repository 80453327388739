<app-header-toolbar [title]="site?.name">
  <app-header-toolbar-icon>
    <app-site-image [site]="site"></app-site-image>
  </app-header-toolbar-icon>
  <app-header-toolbar-filter *ngIf="(isHandset$ | async) == false">
    <app-generic-filter></app-generic-filter>
  </app-header-toolbar-filter>
  <app-header-toolbar-actions>
    <button mat-icon-button *ngIf="shareService.hasSharingEditPermissions() && grantToShare && !(isHandset$ | async)"
            (click)="openShareDialog()"
            matTooltip="{{'action.Share' | translate}}"
    >
      <mat-icon>share</mat-icon>
    </button>
    @if (hasBetaAccess()) {
      <button mat-icon-button *ngIf="shareService.hasSharingEditPermissions() && grantToShare && !(isHandset$ | async)"
              (click)="openTemporalAccessDialog()"
              matTooltip="{{'access.temporal.toolTip' | translate}}"
      >
        <mat-icon>add_link</mat-icon>
      </button>
    }

    <button mat-icon-button [matMenuTriggerFor]="manage" *ngIf="service.hasSiteEditPermissions()"
            matTooltip="{{'menu.ShowMenu' | translate}}"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-menu #manage="matMenu">
  <ng-container *ngIf="canWrite">
    <button mat-menu-item (click)="changeSite()">
      <mat-icon>edit</mat-icon>
      {{ 'site.Rename' | translate }}
    </button>
    <button mat-menu-item (click)="deleteSite()">
      <mat-icon>delete</mat-icon>
      {{ 'action.Delete' | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="isHandset$ | async">
    <mat-divider></mat-divider>
    <button mat-menu-item *ngIf="shareService.hasSharingEditPermissions() && grantToShare"
            (click)="openShareDialog()">
      <mat-icon>share</mat-icon>
      {{ 'action.Share' | translate }}
    </button>
    <mat-divider></mat-divider>
    <app-generic-filter-menu></app-generic-filter-menu>
  </ng-container>
</mat-menu>


<div class="flex flex-col px-3 gap-2 pt-2">
  <app-hierarchy [data]="hierarchy" *ngIf="hierarchy?.children?.length"></app-hierarchy>
  <app-site-details-site-list [data]="site"></app-site-details-site-list>
  <app-site-details-system-list [data]="site"></app-site-details-system-list>


  <div class="flex flex-col lg:flex-row gap-2">
    <app-site-details-map [data]="site" [canWrite]=canWrite style="flex: 1 1 calc(50% - 0.5rem)"></app-site-details-map>
    <div class="flex flex-col gap-2" style="flex: 1 1 calc(50% - 0.5rem); height: 100%">
      <app-site-details-contact [data]="site" [canWrite]=canWrite
                                style="flex: 1 1 calc(50% - 0.5rem)"></app-site-details-contact>
      <app-site-details-address [data]="site" [canWrite]=canWrite
                                style="flex: 1 1 calc(50% - 0.5rem)"></app-site-details-address>
    </div>
  </div>

</div>
