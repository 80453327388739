<mat-expansion-panel hideToggle class="flex-auto" expanded="true" #type>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ 'document_type' | translate }}
    </mat-panel-title>
    <mat-panel-description>
      <div class="flex-auto"></div>
      <mat-icon>filter_list</mat-icon>
    </mat-panel-description>

  </mat-expansion-panel-header>
  <mat-chip-listbox [formControl]="formControl" multiple>
    @for (type of types; track type) {
      <mat-chip-option (selectionChange)="handleChipSelectionChange($event, type)" [value]="type.type" [selected]="isSelected(type.type)">
        <div class="flex flex-row gap-4 justify-between items-center">
          <div style="overflow-wrap: anywhere;">{{ type.typeName || type.type  | translate}}</div>
          <div *ngIf="type.amount > 0" class="amount">{{ type.amount }}</div>
        </div>
      </mat-chip-option>
    }
  </mat-chip-listbox>

</mat-expansion-panel>
