<mat-toolbar color="primary">
  <button mat-icon-button (click)="back()">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <div class="flex-auto flex flex-row">
    <mat-form-field class="search dense-1 flex-auto" appearance="outline" subscriptSizing="dynamic">
      <input matInput type="string" (keyup)="onSearchChange(searchText.value)" (keydown.enter)="search(searchText.value)" #searchText [value]="service.query">
    </mat-form-field>
  </div>
  <button mat-icon-button (click)="filterPanelVisible = !filterPanelVisible">
    <mat-icon>filter_alt</mat-icon>
  </button>

  <div>
    <mat-chip-listbox class="ms-1" [formControl]="selectedTypes" multiple (change)="typeFilterChanged($event)">
      <mat-chip-option *ngFor="let type of service.availableTypes" [value]="type" color="accent">{{ 'ResourceType.' + type | translate }}</mat-chip-option>
    </mat-chip-listbox>
  </div>

  <app-generic-filter class="ps-3"></app-generic-filter>
</mat-toolbar>

<form [formGroup]="filter">
  <div class="flex flex-col lg:flex-row items-start p-2 gap-2" *ngIf="filterPanelVisible">

    <mat-form-field>
      <mat-label>{{'products.filter.RefrigerationFilter.Label' | translate}}</mat-label>
      <input matInput formControlName="refrigerationFilter" (keyup.enter)="applyFilter()">
      <mat-hint>{{'products.filter.RefrigerationFilter.Hint' | translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'products.filter.TypeFilter.Label' | translate}}</mat-label>
      <mat-select formControlName="systemTypeFilter" (selectionChange)="applyFilter()">
        <mat-option value=""></mat-option>
        <mat-option value="LT">{{'products.filter.TypeFilter.LT' | translate}}</mat-option>
        <mat-option value="MT">{{'products.filter.TypeFilter.MT' | translate}}</mat-option>
        <mat-option value="AC">{{'products.filter.TypeFilter.AC' | translate}}</mat-option>
        <mat-option value="HP">{{'products.filter.TypeFilter.HP' | translate}}</mat-option>
      </mat-select>
      <mat-hint>{{'products.filter.TypeFilter.Hint' | translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'products.filter.SystemOperatorFilter.Label' | translate}}</mat-label>
      <input matInput formControlName="ownerFilter" (keyup.enter)="applyFilter()">
      <mat-hint>{{'products.filter.SystemOperatorFilter.Hint' | translate}}</mat-hint>
    </mat-form-field>

    <button mat-icon-button (click)="applyFilter()" matTooltip="{{'products.filter.Title' | translate}}">
      <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button (click)="resetFilter()" matTooltip="{{'products.filter.Clear' | translate}}">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</form>


