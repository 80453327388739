<form class="flex flex-col mt-3" [formGroup]="form" *ngIf="form">
  <mat-form-field appearance="outline">
    <mat-label>{{'companyStreet' | translate}}</mat-label>
    <input type="text" matInput formControlName="street" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'companyPostalCode' | translate}}</mat-label>
    <input type="text" matInput formControlName="zip" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'companyCity' | translate}}</mat-label>
    <input type="text" matInput formControlName="city" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'companyCountry' | translate}}</mat-label>
    <input type="text" matInput formControlName="country" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'companyRegion' | translate}}</mat-label>
    <input type="text" matInput formControlName="state" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'companyPhone' | translate}}</mat-label>
    <input type="text" matInput formControlName="phoneNumber" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'companyWeb' | translate}}</mat-label>
    <input type="text" matInput formControlName="website" [readonly]="readonly">
  </mat-form-field>
</form>
