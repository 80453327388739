import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatChipSelectionChange} from "@angular/material/chips";
import {FormControl} from "@angular/forms";
import {DocumentTypeResultEntry} from "../../search/model/search-api";
import {SelectionChange} from "../../../component/filter/filter-chips/filter-chips.component";

@Component({
  selector: 'app-document-board-content-type-select',
  templateUrl: './document-board-content-type-select.component.html',
  styleUrl: './document-board-content-type-select.component.scss'
})
export class DocumentBoardContentTypeSelectComponent {


  formControl = new FormControl<string[]>([])
  types: DocumentTypeResultEntry[] = []
  availableTypes: DocumentTypeResultEntry[] = []
  @Output() selectionChanged = new EventEmitter<SelectionChange>()

  @Input()
  set relatedTypeEntries(data: DocumentTypeResultEntry[]) {
    this.types = data
    this.updateAvailableTypes()
  }

  @Input()
  set selection(data: string[]) {
    this.formControl.setValue(data)
  }

  handleChipSelectionChange(event: MatChipSelectionChange, type: DocumentTypeResultEntry) {
    if (!event.isUserInput) return
    let selected = event.selected
    if (selected) {
      this.handleSelectionChanged(type.type)
    } else {
      this.unselectType(type.type)
    }
  }

  handleSelectionChanged(value: string) {
    let selectedTypes = this.formControl.value
    if (selectedTypes == null) return
    let current = selectedTypes.length >= 0 ? selectedTypes[0] : undefined
    if (current === value) return
    let type = this.types.find(t => t.type === value)
    if (type) {
      this.selectType(type.type)
    }
  }

  selectType(type: string) {
    let selectedTypes = this.formControl.value
    if (selectedTypes == null) return
    let index = selectedTypes.indexOf(type)
    if (index < 0) {
      this.formControl.setValue([...selectedTypes, type]);
      this.notifySelectionChanged()
    }
  }

  unselectType(type: string) {
    let selectedTypes = this.formControl.value
    if (selectedTypes == null) return
    let index = selectedTypes.indexOf(type)
    if (index >= 0) {
      selectedTypes.splice(index, 1)
      this.formControl.setValue(selectedTypes)
      this.notifySelectionChanged()
    }
  }

  isSelected(type: string) {
    return this.formControl.value?.includes(type) || false
  }

  private updateAvailableTypes() {
    let selectedTypes = this.formControl.value
    if (selectedTypes == null) return
    let selectedTypeDefinitions = selectedTypes
    let newAvailableTypes: DocumentTypeResultEntry[] = []

    this.types.forEach(t => {
      let selected = selectedTypeDefinitions.indexOf(t.type) >= 0
      if (!selected) newAvailableTypes.push(t)
    })

    this.availableTypes = newAvailableTypes
  }

  private notifySelectionChanged() {
    let selectedTypes = this.formControl.value
    if (selectedTypes == null) return
    this.selectionChanged.emit({selectedTypes})
  }
}

