export class CartEntryChangeRequest {
  constructor(
    public code: string,
    public quantity: number,
  ) {
  }
}

export interface CartEntryChangeResponse {
  info: CartInfo,
  entry: CartEntry,
  quantity: number,
  quantityAdded: number,
}

export interface Cart {
  id: number,
  companyId: number,
  ownerId: number,
  timestamp: Date,
  info: CartInfo,
  entries: CartEntry[],
}

export interface CartInfo {
  totalItems: number,
  totalUnitCount: number,
  totalPrice: number,
  currency: string,
}

export interface CartEntry {
  id: number,
  position: number,
  code: string,
  description: string,
  quantity: number,
  price: number,
  totalPrice: number,
}

export class PriceRequest {
  constructor(
    public entries: PriceRequestEntry[],
  ) {
  }
}

export class PriceRequestEntry {
  constructor(
    public code: string,
    public quantity: number,
  ) {
  }
}

export interface PriceResponse {
  currency: string,
  entries: PriceResponseEntry[],
}

export interface PriceResponseEntry {
  code: string,
  price: number,
  totalPrice: number,
}

export interface Store {
  id: number,
  name: string,
}

export interface OrderResponse {
  success: boolean,
  message: string,
  redirectUrl: string,
}

export class ShareCartRequest {
  constructor(
    public name: string,
    public description: string
  ) {
  }
}

export interface SharedCart {
   id: string,
   name: string,
   description: string,
   totalItems: number,
   totalUnitCount: number,
   entries: SharedCartEntry[]
}

export interface SharedCartEntry {
   position: number,
   code: string,
   description: string,
   quantity: number
}
