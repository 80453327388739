<ng-container *ngIf="hasCommerceReadPermissions()">
  <a mat-fab extended color="primary" routerLink="cart">
    @if (service.reloading) {
      <mat-spinner matSuffix [diameter]="18" color="accent"></mat-spinner>
    } @else {
      <h3 *ngIf="service.cart">{{ service.cart.info.totalItems }}</h3>
    }
    <mat-icon>shopping_cart</mat-icon>
  </a>
</ng-container>
