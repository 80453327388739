import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../material/material.module";


@NgModule({
  declarations: [
    HierarchyComponent
  ],
  exports: [
    HierarchyComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    MaterialModule
  ]
})
export class HierarchyModule { }
