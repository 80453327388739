import { Component } from '@angular/core';
import { FilterService } from "../model/filter.service";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: 'app-generic-filter',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss']
})
export class GenericFilterComponent {

  alarmControl: UntypedFormControl

  constructor(public filterService: FilterService,) {
    this.alarmControl = new UntypedFormControl(this.filterService.activeFilter.value.lastAlarms.toString())
    this.alarmControl.valueChanges.subscribe(_ => this.filterService.setLastAlarmsFilter(this.alarmControl.value))
  }
}
