<h1 mat-dialog-title>{{ "cart.dialog.share.title" | translate }}
</h1>
<div mat-dialog-content class="!pt-2">
  <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
  @if (link) {
    <div class="flex flex-row justify-center items-center content-center gap-2">
      <mat-form-field class="grow shrink basis-auto" appearance="outline">
        <mat-label>{{ 'cart.dialog.share.link.label' | translate }}</mat-label>
        <input matInput [value]="link" readonly>
      </mat-form-field>
      <button mat-flat-button [cdkCopyToClipboard]="link" class="grow shrink basis-auto" [disabled]="link.length <= 0">
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  } @else {
    <form [formGroup]="fg">
      <div class="flex flex-col gap-3">
        <mat-form-field class="grow shrink basis-auto" appearance="outline">
          <mat-label> {{ 'cart.dialog.share.name' | translate }}</mat-label>
          <input type="text" matInput formControlName="name"/>
        </mat-form-field>

        <mat-form-field class="grow shrink basis-auto" appearance="outline">
          <mat-label>{{ 'cart.dialog.share.description' | translate }}</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </form>
  }
</div>
<mat-dialog-actions align="end">
  <div class="flex flex-row gap-2">
    @if (!link) {
    <button mat-button mat-stroked-button color="primary" (click)="shareCart()" class="grow shrink basis-auto" [disabled]="reloading">
      <mat-icon>move_to_inbox</mat-icon>
      {{ "cart.dialog.share.create" | translate }}
    </button>
    }
    <button mat-button mat-stroked-button color="warn" mat-dialog-close class="grow shrink basis-auto" [disabled]="reloading">
      <mat-icon>cancel</mat-icon>
      {{ "cancel" | translate }}
    </button>
  </div>
</mat-dialog-actions>
