@if (item) {
  <a routerLink="{{item.url}}" routerLinkActive="highlight" class="flex flex-col main-menu-item h-10 justify-center p-3 rounded-md">
    <div class="flex flex-row gap-3 items-center ">
      <mat-icon>{{ item.icon }}</mat-icon>
      <div class="flex flex-row gap-2 justify-between content-start flex-auto">
        <div>{{ item.text | translate }}</div>
        <div *ngIf="isBeta(item)"><span class="chip">{{ 'Beta' | translate }}</span></div>
      </div>
    </div>
  </a>
}

