import { Observable } from "rxjs";

export interface MainMenuActionGroup {
  name: string,
  actions: MainMenuAction[]
}


export class MainMenuAction {
  constructor(
    public url: string,
    public text: string,
    public icon: string,
    public roles: string[] = [],
    public status: Observable<MainMenuActionStatus> | undefined
  ) {
  }
}

export interface MainMenuActionStatus {
  text: string,
  color: string
}
