import { EChartsOption } from "echarts";

export class MeantimeChart {

  build(): EChartsOption {
    return {
      animation: false,
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'time',
        splitNumber: 15,
        splitLine: {
          show: true,
        },
        axisLabel: {
          hideOverlap: true
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
      },
      grid: {
        top: 40,
        bottom: 50
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
            brushStyle: {
              borderWidth: 1,
              color: 'rgba(120,140,180,0.3)',
              borderColor: 'rgba(120,140,180,0.8)',
            },
          },
        }
      }
    }
  }


}
