import { Component, Input } from '@angular/core';
import { Product } from "../model/product-api";
import { TableCardEntry } from "../../../component/card/model/card-api";
import { ProductService } from "../model/product.service";

@Component({
  selector: 'app-product-info-oil',
  templateUrl: './product-info-oil.component.html',
  styleUrls: ['./product-info-oil.component.scss']
})
export class ProductInfoOilComponent {

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setInfo(product)
  }

  title = 'oilInformation'
  data: TableCardEntry[] = []


  constructor(private productService: ProductService) {
  }

  private setInfo(p: Product) {
    this.productService.getCompositeAttributes(p.id).subscribe(compAttr => {
      let oilTypes = new Map(Object.entries(compAttr))
      if (oilTypes)
        for (const [key, value] of oilTypes.entries()) {
          this.pushData(key, value.join(', ') || '')
        }
    })
  }

  pushData(key: string, value: string | undefined) {
    this.data.push(
      new TableCardEntry(key, value || ""),
    )
  }
}
