<h1 mat-dialog-title>{{ "wishlist.dialog.create" | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="fg">
    <div class="flex flex-col gap-2 py-2">
      <mat-form-field class="dense-1">
        <mat-label>{{ 'wishlist.name' | translate }}</mat-label>
        <input formControlName="name" matInput>
      </mat-form-field>

      <mat-form-field class="dense-1">
        <mat-label>{{ 'wishlist.note' | translate }}</mat-label>
        <textarea formControlName="note" matInput placeholder=""></textarea>
      </mat-form-field>

    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button (click)="submit()" [disabled]="!fg.valid" color="primary" mat-stroked-button>
    <mat-icon>check</mat-icon>
    {{ "action.Submit" | translate }}
  </button>
  <button color="warn" mat-dialog-close mat-stroked-button>
    <mat-icon>cancel</mat-icon>
    {{ "cancel" | translate }}
  </button>
</mat-dialog-actions>

