import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SystemBoardComponent } from "./system-board/system-board.component";
import { SystemDetailsComponent } from "./system-details/system-details.component";

const routes: Routes = [
  {
    path: '',
    title: 'BDN System - Board',
    component: SystemBoardComponent
  },
  {
    path: ':id',
    title: 'BDN System - Details',
    component: SystemDetailsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemRoutingModule {
}
