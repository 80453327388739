import { Observable } from "rxjs";
import { MainMenuActionStatus } from "./main-menu-action";

export interface MainHeaderAction {
  icon: string
  text: string
  roles: string[]
}

export class MainHeaderActionLinkExternal implements MainHeaderAction {
  constructor(
    public icon: string,
    public text: string,
    public href: string,
    public target: string,
    public roles: string[] = [],
  ) {
  }
}

export class MainHeaderActionLinkInternal implements MainHeaderAction {
  constructor(
    public icon: string,
    public text: string,
    public routerLink: string,
    public roles: string[] = [],
  ) {
  }
}

export class MainHeaderActionButton implements MainHeaderAction {
  constructor(
    public icon: string,
    public text: string,
    public callback: (evt: MouseEvent) => void,
    public status: Observable<MainMenuActionStatus> | undefined,
    public roles: string[] = [],
  ) {
  }
}

export interface MainHeaderActionStatus {
  text: string,
  color: string
}
