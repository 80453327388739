@if (sparePart) {
  <div>
    <mat-card [ngClass]="{'m-2': this.id()}">
      <div class="flex flex-col gap-2  justify-center content-stretch">
        <div class="flex flex-row justify-center pt-3">
          <img src="https://oiservicesprod.blob.core.windows.net/images/large/{{sparePart.id}}.jpg"
               appDefaultImage defaultImage="assets/img/default-spare.png"
               style="max-height:436px; object-fit: contain;" alt="Spare Part Picture">
        </div>

        <mat-tab-group color="accent">
          <mat-tab label="{{'properties' | translate}}">
            <mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
            <div class="flex flex-col gap-2 py-2 px-4">
              @for (p of content; track p) {
                <div class="flex flex-row justify-between">
                  <div>{{ p.text | translate }}</div>
                  <div>{{ p.content }}</div>
                </div>
                <mat-divider></mat-divider>
              }
            </div>
          </mat-tab>
          <mat-tab label="{{'related-products' | translate}}">
            <mat-progress-bar *ngIf="productReloading" mode="indeterminate"></mat-progress-bar>
            <div class="flex flex-col gap-2  py-2 px-4">
              @for (p of products; track p) {
                <div class="flex flex-row justify-between items-center gap-4" (click)="showProduct(p)">
                  <div class="basis-1/4">{{ p.id }}</div>
                  <div class="flex-auto"></div>
                  <div class="flex flex-row gap-2">
                    <div class="product-badge">{{ p.technology }}</div>
                    <div class="product-badge">{{ p.series }}</div>
                  </div>
                </div>
                <mat-divider></mat-divider>
              }
            </div>
          </mat-tab>
        </mat-tab-group>

      </div>
    </mat-card>
  </div>
}
