<div class="flex flex-col gap-2">
  <div class="flex gap-2" [ngClass]="{'flex-row': flexRow, 'flex-wrap': flexWrap, 'flex-col': !flexRow}">

    <div class="flex flex-col gap-2 items">
      <mat-card>
        <div class="flex flex-row gap-2 px-2 py-1">
          <button mat-button (click)="backToCategories()">
            <mat-icon>arrow_back</mat-icon>
            {{'categories' | translate}}</button>
        </div>
      </mat-card>
      <app-spare-parts-category-items style="overflow-y: auto;"></app-spare-parts-category-items>
    </div>
    <app-spare-parts-category-drawing #drawing class="drawing"></app-spare-parts-category-drawing>

    <ng-container *ngIf="service.selectedSparePart">
      <app-spare-parts-category-details class="details"></app-spare-parts-category-details>
    </ng-container>
  </div>
</div>
