<div class="flex flex-row justify-stretch gap-6 flex-auto text-sm">
  <div class="flex flex-col basis-1/2 py-5">
    <div class="pb-5">
      <div class="font-bold">{{ 'pid.dates' | translate }}</div>
    </div>
    <div class="flex gap-3">
      <div class="basis-40 font-medium"> {{ 'pid.created' | translate }}</div>
      <div class="font-light">{{ pid.changeLog.created.timestamp | date: 'short' }}</div>
    </div>

    <div class="flex gap-3">
      <div class="basis-40 font-medium"> {{ 'pid.updated' | translate }}</div>
      <div class="font-light">{{ pid.changeLog.updated?.timestamp | date: 'short' }}</div>
    </div>
  </div>
  <div class="flex flex-col basis-1/2 py-5">
    <div class="pb-5">
      <div class="font-bold">{{ 'pid.insights' | translate }}</div>
    </div>

    <div class="flex gap-3">
      <div class="basis-40 font-medium "> {{ 'pid.standard' | translate }}</div>
      <div class="font-light">{{ pid.standard }}</div>
    </div>
    <div class="flex gap-3">
      <div class="basis-40 font-medium "> {{ 'pid.suggested' | translate }}</div>
      <div class="font-light">{{ pid.suggested }}</div>
    </div>
  </div>
</div>
