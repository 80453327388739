@if (linkExternal) {
  <a class="flex flex-col gap-2 items-center text-sm main-header-action"
     [href]="linkExternal.href"
     [target]="linkExternal.target"
  >
    @if (linkExternal.icon) {
      <mat-icon>{{ linkExternal.icon }}</mat-icon>
    }
    <span>{{ linkExternal.text | translate }}</span>
  </a>
}

@if (linkInternal) {
  <a class="flex flex-col gap-2 items-center text-sm main-header-action" [routerLink]="linkInternal.routerLink" routerLinkActive="highlight">
    @if (linkInternal.icon) {
      <mat-icon>{{ linkInternal.icon }}</mat-icon>
    }
    <span>{{ linkInternal.text | translate }}</span>
  </a>
}


@if (button) {
  <button class="flex flex-col gap-2 items-center text-sm main-header-action" (click)="button.callback($event)">
    @if (button.icon) {
      <mat-icon>{{ button.icon }}</mat-icon>
    }
    <span>{{ button.text | translate }}</span>
  </button>
}

