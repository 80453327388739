import { PropertyService } from "../../../../property/model/property.service";
import { TranslateService } from "@ngx-translate/core";
import { BaseLoader, LoaderCallback } from "./base-loader";
import { SeriesOption } from "echarts";
import { PropertyDouble, PropertySearchRequest } from "../../../../property/model/property-api";
import { OperationalGraphService } from "../../model/operational-graph.service";
import { Subject } from "rxjs";
import { YAXisOption } from "echarts/types/dist/shared";
import { SystemTimeService } from "../../../../system/system-time/system-time.service";
import { UnitOfMeasure } from "../../../../thing-template/model/thing-template-api";

export class PropertyLoader extends BaseLoader<PropertyDouble> {

  override readonly PAGE_SIZE = 50000
  uomTranslations: Map<string, string> = new Map

  constructor(
    private opGraphService: OperationalGraphService,
    private service: PropertyService,
    private translate: TranslateService,
    private systemTime: SystemTimeService,
  ) {
    super()
  }

  protected loadPage(id: number, from: string, to: string, page: number, data: PropertyDouble[], callback: Subject<LoaderCallback>): void {
    let selectedPropertyNames = [...new Set(this.opGraphService.selectedProperties.map(p => p.name))]
    let request = new PropertySearchRequest(from, to, selectedPropertyNames)
    this.service.getDoublePropertyEdges(id, request).subscribe(edges => {
      const merged = edges ? [...data,  ...edges.previous, ...edges.next] : data
      this.service.getDoubleProperties(id, request, page, this.PAGE_SIZE)
        .subscribe(d => this.handlePage(id, from, to, page, merged, callback, d))
    })
  }

  protected processData(data: PropertyDouble[], callback: Subject<LoaderCallback>): void {
    let primaryProperties: Map<string, PropertyDouble[]> = new Map()
    let secondaryProperties: Map<string, PropertyDouble[]> = new Map()

    const propertyUomMap: Map<string, string> = new Map(this.opGraphService.selectedProperties.map(p => [p.name, p.unitOfMeasure]))

    data.sort((a, b) => a.timestamp < b.timestamp ? -1 : 1).forEach(p => {
      let isPrimaryValue = this.isPrimaryValue(p, propertyUomMap)
      if (isPrimaryValue) {
        this.addValue(p, primaryProperties)
      } else {
        this.addValue(p, secondaryProperties)
      }
    })

    let primarySeries = this.createSeries(primaryProperties, propertyUomMap)
    let secondarySeries = this.createSeries(secondaryProperties, propertyUomMap)
    callback.next({ primary: primarySeries, secondary: secondarySeries})
  }

  private isPrimaryValue(p: PropertyDouble, propertyUomMap: Map<string, string>): Boolean {
    let uom = propertyUomMap.get(p.metadata.name)
    if (!uom) return false

    return this.opGraphService.secondGraphMeasures.indexOf(uom) < 0
  }

  private addValue(p: PropertyDouble, result: Map<string, PropertyDouble[]>) {
    let existing = result.get(p.metadata.name)
    if (existing) {
      existing.push(p)
    } else {
      result.set(p.metadata.name, [p])
    }
  }


  private createSeries(data: Map<string, PropertyDouble[]>, propertyUomMap: Map<string, string>): SeriesOption[] {
    let result: SeriesOption[] = []
    for (let [key, value] of data) {
      result.push(this.createPropertySeries(key, value, propertyUomMap))
    }
    return result
  }

  private createPropertySeries(name: string, data: PropertyDouble[], propertyUomMap: Map<string, string>): SeriesOption {
    let uom = propertyUomMap.get(name)
    let unit = this.getUoMTranslation(uom)
    let values = data.map(p => [this.systemTime.formatTimestamp(p.timestamp), p.value.toFixed(2)])
    return {
      name: this.translate.instant(this.opGraphService.trimPropertyName(name)),
      yAxisId: uom,
      animation: false,
      type: 'line',
      showSymbol: false,
      step: 'end',
      color: this.getDefaultColorForProp(name),
      data: values,
      tooltip: {
        valueFormatter: value => `${value} ${unit}`
      },
    }
  }

  private getUoMTranslation(uom: string | undefined): string {
    if (!uom) return ''
    const translation = this.uomTranslations.get(uom)
    if (!translation) return ''
    return translation
  }

  getDefaultColorForProp(propId: string): string {
    switch (propId) {
      case 'BDN.CondTemp': {
        return '#FF0000'
      }
      case 'BDN.EvapTemp': {
        return '#005FB8'
      }
      case 'BDN.OilDiscGasTemp': {
        return '#6D2C9E'
      }
      case 'BDN.Capacity': {
        return '#000000'
      }
      case 'BDN.PowerModuleTemp': {
        return '#BF8F00'
      }
      case 'BDN.ColdPlateTemp': {
        return '#FFD65B'
      }
      case 'BDN.MotorCurrent': {
        return '#C04700'
      }
      case 'BDN.MotorVoltage': {
        return '#00A63A'
      }
      case 'BDN.MotorPower': {
        return '#000000'
      }
      case 'BDN.MotorThermistor': {
        return '#868686'
      }

      default:
        return ''
    }
  }

  loadUomConfiguration(primary: boolean): YAXisOption[] {
    let uoms = this.opGraphService.selectedProperties.filter(p => {
      return primary ? !this.opGraphService.secondGraphMeasures.includes(p.unitOfMeasure) : this.opGraphService.secondGraphMeasures.includes(p.unitOfMeasure)
    }).map(p => p.unitOfMeasure)
    let distinct = [...new Set(uoms)]

    let weatherAxis = this.addWeatherAxis(primary, distinct)
    const data: YAXisOption[] = distinct.map((unit, index) => {
      const uom = this.uomTranslations.get(unit) ?? unit
      return {
        id: unit,
        type: 'value',
        name: uom,
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: 35,
        offset: index * 70,
        axisLine: {
          show: true
        },
        max: function (value) {
          return Math.ceil(value.max * 1.1)
        },
        position: (index == 0) ? "left" : "right",
        splitLine: {
          show: false
        }
      }
    })
    data.push(...weatherAxis)
    return data
  }

  private addWeatherAxis(primary: boolean, distinct: UnitOfMeasure[]): YAXisOption[] {
    let axisOptions: YAXisOption[] = []
    if (primary && !distinct.includes(UnitOfMeasure.UNIT_C) && this.opGraphService.weatherSeries.includes('temp')) {
      axisOptions.push({
        id: UnitOfMeasure.UNIT_C,
        type: 'value',
        name: this.translate.instant('C'),
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: 35,
        offset: (distinct.length + axisOptions.length) * 70,
        axisLine: {
          show: true
        },
        max: function (value) {
          return Math.ceil(value.max * 1.1)
        },
        position: (distinct.length > 1) ? "right": "left",
        splitLine: {
          show: false
        }
      })
    }
    if (primary && !distinct.includes(UnitOfMeasure.UNIT_HPA) && this.opGraphService.weatherSeries.includes('pressure')) {
      axisOptions.push({
        id: UnitOfMeasure.UNIT_HPA,
        type: 'value',
        name: this.translate.instant('hPa'),
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: 35,
        offset: (distinct.length + axisOptions.length) * 70,
        axisLine: {
          show: true
        },
        max: function (value) {
          return Math.ceil(value.max * 1.1)
        },
        position: (distinct.length > 1) ? "right": "left",
        splitLine: {
          show: false
        }
      })
    }
    if (!primary && !distinct.includes(UnitOfMeasure.UNIT_PCT) && this.opGraphService.weatherSeries.includes('humidity')) {
      axisOptions.push({
        id: UnitOfMeasure.UNIT_PCT,
        type: 'value',
        name: this.translate.instant('%'),
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: 35,
        offset: (distinct.length + axisOptions.length) * 70,
        axisLine: {
          show: true
        },
        max: function (value) {
          return Math.ceil(value.max * 1.1)
        },
        position: (distinct.length > 1) ? "right": "left",
        splitLine: {
          show: false
        }
      })
    }
    return axisOptions
  }
}
