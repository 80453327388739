import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import {BestInfo, DeviceUpdateRequest, DeviceUpdateResponse} from "./best-core-api";

@Injectable({
  providedIn: 'root'
})
export class BestCoreGatewayService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/best', logging, zone)
    this.retryCount = 1
  }
  getInfo(equipmentId: number, thingId: number): Observable<BestInfo> {
    return this.get('info/' +equipmentId + '/thing/' + thingId)
  }

  clearAlarms(equipmentId: number, thingId: number) {
    return this.put('clear/alarms/' + equipmentId + '/thing/' + thingId, {})
  }

  update(equipmentId: number, request: DeviceUpdateRequest): Observable<DeviceUpdateResponse> {
    return this.put('device/' + equipmentId + '/update', request)
  }
}
