export interface EventState {
  itemId: number,
  online: boolean,
  status: CompressorStatus,
  timestamp: string
}

export enum CompressorStatus {
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  FAULT = 'FAULT',
  OFFLINE = 'OFFLINE',
  UNKNOWN = 'UNKNOWN'
}
