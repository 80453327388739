import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import {
  defaultRegistration,
  RegisterEquipment,
  RegisterEquipmentRequest,
  RegisterSite,
  RegisterSiteRequest,
  RegisterSystem,
  RegisterSystemRequest,
  Registration
} from "./registration-api";
import { RegistrationService } from "./registration.service";
import { tap } from "rxjs/operators";
import { RegistrationSwitchService } from "./registration-switch.service";
import { HotToastService } from "@ngxpert/hot-toast";
import {ProcessService} from "../../process/model/process.service";
import {Process} from "../../process/model/process-api";

@Injectable({
  providedIn: 'root'
})
export class RegistrationProcessService {
  reloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  submittable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  registration: Registration = defaultRegistration

  constructor(
    private service: RegistrationService,
    private switchService: RegistrationSwitchService,
    private toastService: HotToastService,
    private processService: ProcessService
  ) {
  }

  reload(): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.getRegistration().pipe(
      tap(data => this.handleData(data))
    )
  }

  siteCreate(request: RegisterSiteRequest): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.siteCreate(request).pipe(
      tap(data => this.handleData(data))
    )
  }

  siteUpdate(siteId: string, request: RegisterSiteRequest): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.siteUpdate(siteId, request).pipe(
      tap(data => this.handleData(data))
    )
  }

  systemCreate(siteId: string, request: RegisterSystemRequest): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.systemCreate(request, siteId).pipe(
      tap(data => this.handleData(data))
    )
  }

  systemUpdate(systemId: string, request: RegisterSystemRequest): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.systemUpdate(systemId, request).pipe(
      tap(data => this.handleData(data))
    )
  }

  equipmentCreate(systemId: string, request: RegisterEquipmentRequest): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.equipmentCreate(request, systemId).pipe(
      tap(data => this.handleData(data))
    )
  }

  equipmentUpdate(equipmentId: string, request: RegisterEquipmentRequest): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.equipmentUpdate(equipmentId, request).pipe(
      tap(data => this.handleData(data))
    )
  }

  clear(): Observable<Registration> {
    return this.service.clear()
  }

  private handleData(data: Registration) {
    this.registration = data
    this.updateStatus()
    this.reloading.next(false)
  }

  private updateStatus() {
    if (this.registration.sites.length == 0) {
      this.submittable.next(false)
      return
    }
    for (let location of this.registration.sites) {
      if (location.systems.length == 0) {
        this.submittable.next(false)
        return
      }
      for (let system of location.systems) {
        if (system.equipment.length == 0) {
          this.submittable.next(false)
          return
        } else {
          this.submittable.next(true)
        }
      }
    }
  }


  siteRemove(site: RegisterSite): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.siteRemove(site.key).pipe(
      tap(data => this.handleData(data))
    )
  }

  systemRemove(system: RegisterSystem): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.systemRemove(system.key).pipe(
      tap(data => this.handleData(data))
    )
  }

  equipmentRemove(equipment: RegisterEquipment): Observable<Registration> {
    if (this.reloading.getValue()) return EMPTY
    this.reloading.next(true)
    return this.service.equipmentRemove(equipment.id).pipe(
      tap(data => this.handleData(data))
    )
  }

  submit() {
    return this.service.submit()
  }

  selectSite(siteId: number) {
    return this.service.selectSite(siteId)
      .pipe(
        tap((value) => this.switchSiteComponent(value.sites.at(-1)),)
      )
  }

  selectSystem(systemId: number) {
    return this.service.selectSystem(systemId)
      .pipe(
        tap((value) => this.switchSystemComponent(value.sites.at(-1)?.systems.at(-1)))
      )
  }

  switchSiteComponent(site: RegisterSite | undefined) {
    if (!site) {
      this.switchService.switchToBoard()
    } else {
      this.switchService.switchToCreateSystem(site.key)
    }
  }

  switchSystemComponent(system: RegisterSystem | undefined) {
    if (!system) {
      this.switchService.switchToBoard()
    } else {
      this.switchService.switchToCreateEquipment(system.key)
    }
  }

  hasRegistrationPermissions(): boolean {
    return this.service.hasRegistrationPermissions()
  }

  getProcess(processId: number): Observable<Process> {
    return this.processService.getById(processId)
  }
}
