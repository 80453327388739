@if (data.actions.length > 0) {
<div class="flex flex-col gap-2 justify-center content-center flex-auto">
  @for (action of data.actions; track action) {
  <app-main-menu-action
    [data]="action"
    [collapsed]="collapsed()"
  ></app-main-menu-action>
  }
  <mat-divider class="!mt-2" *ngIf="!last"></mat-divider>
</div>
}
