import {Component} from '@angular/core';
import {WishlistEntry} from "../../wishlist/model/wishlist-api";
import {CommerceCartService} from "../../../commerce/model/commerce-cart.service";
import {AuthService} from "../../../auth/auth.service";
import {WishlistService} from "../../wishlist/model/wishlist.service";
import {MatDialog} from "@angular/material/dialog";
import {COMMERCE_ADMIN, COMMERCE_READ} from "../../../auth/roles";
import {ProductConfigSelectResult} from "../model/product-config-api";
import {PriceRequestEntry, PriceResponse} from "../../../commerce/model/commerce-api";
import {ProductConfigSelectService} from "../model/product-config-select.service";

@Component({
  selector: 'app-product-config-select-result',
  templateUrl: './product-config-select-result.component.html',
  styleUrl: './product-config-select-result.component.scss'
})
export class ProductConfigSelectResultComponent {
  displayedColumns = ['id']
  prices: Record<string, number> = {}
  wishlistActive: WishlistEntry[] = []

  constructor(
    public service: ProductConfigSelectService,
    private commerceService: CommerceCartService,
    private authService: AuthService,
    private wishlistService: WishlistService,
    private dialog: MatDialog
  ) {
    service.search$.subscribe(s => {
      this.updatePrice(s)
      this.updateWishlistActive()
    })
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ) || this.authService.hasRole(COMMERCE_ADMIN)
  }

  private updatePrice(s: ProductConfigSelectResult) {
    if (!this.hasCommerceReadPermissions()) return

    let pids = s.suggestedEntries.slice()
    if (s.entries.length <= 10) {
      let missingPids = s.entries
      .filter(p => !pids.find(e => e.id === p.id))
      pids.push(...missingPids)
    }

    let entries = pids.map(p => new PriceRequestEntry(p.id, 1))
    this.commerceService.getPrices(entries).subscribe({
      next: value => this.handlePriceInfo(value)
    })
  }

  private handlePriceInfo(response: PriceResponse) {
    response.entries.forEach(p => {
      this.prices[p.code] = p.totalPrice
    })
  }

  private updateWishlistActive() {
    const references = this.service.result.entries.map(entry => entry.id)
    if (references.length) {
      this.wishlistService.checkWishlist(references).subscribe(data => {
        this.wishlistActive = data
      })
    }
  }
}
