<mat-card class="flex">

  <mat-card-header class="flex items-center content-center justify-between">
    <mat-card-title>
      <h5> {{ 'site.Contact' | translate }} </h5>
    </mat-card-title>
    <mat-card-actions *ngIf="service.hasSiteEditPermissions() && canWrite">
      <button mat-stroked-button (click)="editMode=true;" *ngIf="!editMode">
        <mat-icon>edit</mat-icon>
        {{ 'action.Edit' | translate }}
      </button>
      <div class="flex gap-1" *ngIf="editMode">
        <button mat-stroked-button (click)="save()" [disabled]="!form.dirty">
          <mat-icon>save</mat-icon>
          {{ 'action.Save' | translate }}
        </button>
        <button mat-stroked-button (click)="reset()">
          <mat-icon>cancel</mat-icon>
          {{ 'cancel' | translate }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card-header>

  <mat-card-content>
    <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>

    <form [formGroup]="form">
      <div class="flex flex-col gap-2">
        <mat-form-field class="basis-full" appearance="outline">
          <mat-label> {{ 'contactPersonTitle' | translate }}</mat-label>
          <input matInput formControlName="name" type="text" [readonly]="!editMode"/>
        </mat-form-field>

        <mat-form-field class="basis-full" appearance="outline">
          <mat-label> {{ 'contactPhone' | translate }}</mat-label>
          <input matInput formControlName="phone" type="text" [readonly]="!editMode"/>
        </mat-form-field>

        <mat-form-field class="basis-full" appearance="outline">
          <mat-label> {{ 'contactMail' | translate }}</mat-label>
          <input matInput formControlName="email" type="text" [readonly]="!editMode"/>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
