<ng-container *ngIf="service.selectedSparePart">
  <mat-card [style.max-height]="imageMaxHeight" [style.min-height]="imageMaxHeight"
            style="overflow-y: auto;">
    <img
      alt="Spare Part Picture"
      appDefaultImage
      mat-card-image
      src="https://oiservicesprod.blob.core.windows.net/images/large/{{service.selectedSparePart.id}}.jpg"
      style="max-width: 100%;">
    <mat-card-content>
      <table *ngIf="content.length > 0" [dataSource]="content" mat-table>
        <ng-container matColumnDef="text">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row;" mat-cell> {{ row.text | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" mat-cell> {{ row.content }}</td>
        </ng-container>
        <tr *matRowDef="let row; columns: displayedColumns;" class="dense-1" mat-row></tr>
      </table>

      <mat-divider></mat-divider>
      <div class="flex flex-row gap-3 items-center">
        <app-add-to-cart-button [code]="service.selectedSparePart.id"
                                mode="button"></app-add-to-cart-button>
        <app-product-config-wishlist [isStandalone]="true" [pid]="service.selectedSparePart"></app-product-config-wishlist>
        <!--        <button (click)="addToWishList()" [style.color]="isWishlistActive() ? '#50c422' : 'black'"-->
        <!--                mat-mini-fab-->
        <!--                matTooltip="{{'wishlist.dialog.add' | translate}}">-->
        <!--          <mat-icon>star</mat-icon>-->
        <!--        </button>-->
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
