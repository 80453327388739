import {Injectable} from '@angular/core';
import {distinctUntilChanged, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {FormBuilder} from "@angular/forms";
import {EquipmentService} from "../../model/equipment.service";
import {SystemService} from "../../../system/model/system.service";
import {EquipmentOperationReportRequest} from "./operational-report-api";
import {TranslateService} from "@ngx-translate/core";
import {UomTranslateMapping} from "../../operational-graph/model/uom-translate-mapping";
import {ThingTemplate} from "../../../thing-template/model/thing-template-api";
import {SystemTimeService} from "../../../system/system-time/system-time.service";
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class OperationalReportService {

  selectedTime: string = '7'
  range = this.fb.group({
    start: this.fb.control(DateTime.utc().minus({days: 7}).startOf('day').toISO()),
    end: this.fb.control(DateTime.utc().endOf('day').toISO())
  });
  equipmentId: number = 0
  secondGraphMeasures = ['UNIT_HZ', 'UNIT_PCT', 'NONE']
  template: ThingTemplate | undefined
  private loadProperty: string[] = ['BDN.Capacity']
  private meantimeProperties: string[] = ['BDN.CondTemp', 'BDN.Capacity', 'BDN.OilDiscGasTemp', 'BDN.EvapTemp']
  private changeSubject: Subject<boolean> = new Subject()
  changeEvent = this.changeSubject.pipe(debounceTime(500))
  private downloadSubject: Subject<void> = new Subject()
  downloadEvent = this.downloadSubject.pipe(debounceTime(500))
  weather: string = 'temp'

  constructor(
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private systemService: SystemService,
    private systemTime: SystemTimeService,
    private translate: TranslateService
  ) {

    this.range.get('end')?.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe(value => {
        if (value) this.notifyChanged()
      }
    )
  }

  filterOnPreset() {
    if (!this.selectedTime) return

    const now = DateTime.now()
    const amount = parseInt(this.selectedTime, 10)

    let start = amount ? now.minus({days: amount}).startOf('day') : now
    let end = now.endOf('day')

    this.range.setValue({
      start: start.toISO(),
      end: end.toISO()
    })
  }

  refresh() {
    this.notifyChanged(false)
  }

  download() {
    this.downloadSubject.next()
  }

  switchSystemTime(systemTime: boolean) {
    if (systemTime) {
      this.findSystemTimezone()
      return
    }
    this.systemTime.timezone = DateTime.now().zoneName
    this.refresh()
  }

  createReportRequest(): EquipmentOperationReportRequest {
    const rangeValue = this.range.value
    const from = rangeValue.start ? DateTime.fromISO(rangeValue.start).startOf('day').toISO() ?? '' : '';
    const to = rangeValue.end ? DateTime.fromISO(rangeValue.end).endOf('day').toISO() ?? '' : '';

    return new EquipmentOperationReportRequest(
      from,
      to,
      this.translate.currentLang,
      this.loadProperty,
      this.meantimeProperties,
      this.weather,
      this.systemTime.timezone
    )
  }

  calculateMin() {
    const timestamp = this.range.value.start
                      ? DateTime.fromISO(this.range.value.start).startOf('day').toISO()
                        ?? '' : '';
    return this.systemTime.formatTimestamp(timestamp)
  }

  calculateMax() {
    const timestamp = this.range.value.end
                      ? DateTime.fromISO(this.range.value.end).endOf('day').toISO()
                        ?? '' : '';
    return this.systemTime.formatTimestamp(timestamp)
  }

  getUoMTranslation(uom: string | undefined): string {
    if (!uom) return ''
    let uomIndex = Object.keys(UomTranslateMapping).indexOf(uom)
    let unit = Object.values(UomTranslateMapping)[uomIndex]
    if (!unit || unit == 'na') return ''
    return this.translate.instant(unit)
  }

  trimPropertyName(name: string) {
    const property = name.replace('BDN.', '')
    return property.charAt(0).toLowerCase() + property.slice(1)
  }

  translatePropertyName(name: string, isAbbreviated: boolean = false): string {
    if (!name) return ''
    let propertyName = this.trimPropertyName(name)
    if (!isAbbreviated) return this.translate.instant(propertyName)
    let short = propertyName + 'Short'
    let translatedShort = this.translate.instant(short)
    return translatedShort != short ? translatedShort : this.translate.instant(propertyName)
  }

  getPropertyDefinitions() {
    if (!this.template) return []
    return this.template.categories.flatMap(c => {
      return c.properties
    })
  }

  setMeantimeProperties(oldProp: string, newProp: string) {
    if (oldProp === newProp || !this.meantimeProperties.includes(oldProp) || this.meantimeProperties.includes(newProp)) return
    let index = this.meantimeProperties.indexOf(oldProp)
    this.meantimeProperties[index] = newProp
    this.notifyChanged()
  }

  setLoadProperty(property: string) {
    if (this.loadProperty.includes(property)) return

    this.loadProperty.pop()
    this.loadProperty.push(property)
  }

  getLoadProperty() {
    return this.loadProperty
  }

  private notifyChanged(reload: boolean = true) {
    this.changeSubject.next(reload)
  }

  private findSystemTimezone() {
    if (!this.equipmentId) return
    this.equipmentService.getPath(this.equipmentId).subscribe(path => {
      const systemId = path[0].children[0].resource.id
      this.systemService.getSystem(systemId).subscribe(system => {
        this.systemTime.timezone = system.timezone
        this.refresh()
      })
    })
  }
}
