<mat-card class="flex">

  <mat-card-header class="flex items-center content-center justify-between">
    <mat-card-title>
      <h5> {{ 'site.Address' | translate }} </h5>
    </mat-card-title>
    <mat-card-actions *ngIf="service.hasSiteEditPermissions() && canWrite">
      <button mat-stroked-button (click)="editMode=true" *ngIf="!editMode">
        <mat-icon>edit</mat-icon>
        {{ 'action.Edit' | translate }}
      </button>
      <div class="flex gap-1" *ngIf="editMode">
        <button mat-stroked-button [disabled]="!form.valid || !form.touched" (click)="save()">
          <mat-icon>save</mat-icon>
          {{ 'action.Save' | translate }}
        </button>
        <button mat-stroked-button (click)="reset()">
          <mat-icon>cancel</mat-icon>
          {{ 'cancel' | translate }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card-header>

  <mat-card-content>
    <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>

    <form [formGroup]="form">
      <div class="flex flex-col gap-2">
        <div class="flex flex-col md:flex-row justify-between">
          <mat-form-field class="basis-full md:basis-7/12" appearance="outline">
            <mat-label> {{ 'companyStreet' | translate }}</mat-label>
            <input matInput formControlName="streetLine1" type="text" [readonly]="!editMode"/>
          </mat-form-field>

          <mat-form-field class="basis-full md:basis-4/12" appearance="outline">
            <mat-label> {{ 'companyStreet' | translate }}</mat-label>
            <input matInput formControlName="streetLine2" type="text" [readonly]="!editMode"/>
          </mat-form-field>
        </div>

        <div class="flex flex-col md:flex-row justify-between">
          <mat-form-field class="basis-full md:basis-7/12" appearance="outline">
            <mat-label> {{ 'companyPostalCode' | translate }}</mat-label>
            <input matInput formControlName="postalCode" type="text" [readonly]="!editMode"/>
          </mat-form-field>

          <mat-form-field class="basis-full md:basis-4/12" appearance="outline">
            <mat-label> {{ 'companyCity' | translate }}</mat-label>
            <input matInput formControlName="city" type="text" [readonly]="!editMode"/>
          </mat-form-field>
        </div>

        <mat-form-field class="basis-full" appearance="outline">
          <mat-label> {{ 'companyCountry' | translate }}</mat-label>
          <mat-select formControlName="country">
            <mat-option *ngFor="let country of countries" [value]="country" (onSelectionChange)="selectCountry($event)" [disabled]="!editMode">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="basis-full" appearance="outline">
          <mat-label> {{ 'companyRegion' | translate }}</mat-label>
          <mat-select [disabled]="!form.get('country')?.value" formControlName="region">
            <mat-option *ngFor="let region of regions" [value]="region" (onSelectionChange)="selectRegion($event)" [disabled]="!editMode">
              {{ region }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="basis-full" appearance="outline">
          <mat-label> {{ 'site.AdditionalInfo' | translate }}</mat-label>
          <textarea matInput formControlName="additionalInformation" [readonly]="!editMode"></textarea>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
