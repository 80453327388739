import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { StripLeadingZerosPipe } from './util-pipes/strip-leading-zeros.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [StripLeadingZerosPipe],
  exports: [StripLeadingZerosPipe]
})
export class SharedModule {}
