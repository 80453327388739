export interface Company {
  id: number,
  externalId: string,
  name: string,
  type: CompanyType
}

export class CompanyChangeRequest {
  constructor(
    public externalId: string,
    public name: string,
    public type: string
  ) {
  }
}

export interface Customer {
  id: number,
  name: string,
  email: string
}

export interface CompanyProfile {
  id: number,
  street: string,
  zip: string,
  city: string,
  country: string,
  state: string,
  phoneNumber: string,
  website: string
}

export class CompanyProfileChangeRequest {
  constructor(
    public street: string,
    public zip: string,
    public city: string,
    public country: string,
    public state: string,
    public phoneNumber: string,
    public website: string
  ) {
  }

}

export interface CompanyContact {
  id: number,
  title: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string
}

export class CompanyContactChangeRequest {
  constructor(
    public title: string,
    public firstName: string,
    public lastName: string,
    public phoneNumber: string,
    public email: string
  ) {
  }
}

export class Requester {
  constructor(
    public externalId: string,
    public name: string,
    public email: string,
    public azureGuid: string,
  ) {
  }
}

export enum CompanyType {
  DEMO = 'DEMO',
  REGULAR = 'REGULAR'
}


export interface CompanyValidationResult {
  created: boolean,
  company: Company
}
