<mat-progress-bar *ngIf="service.reloading" mode="indeterminate"></mat-progress-bar>
<div class="flex flex-col gap-2 p-2">
  <mat-card class="flex-auto">
    <div class="flex flex-col gap-0 justify-center content-stretch">
      <app-product-config-filter></app-product-config-filter>
      <app-product-config-table [data]="service.data"></app-product-config-table>
      <mat-divider></mat-divider>
      <mat-paginator [length]="service.totalSize"
                     [pageSize]="service.pageSize"
                     [pageIndex]="service.pageIndex"
                     (page)="service.handlePaginatorEvent($event)"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     showFirstLastButtons
                     class="dense-1 py-3" *ngIf="service.totalSize > 0 && service.totalSize > service.pageSize">
      </mat-paginator>

    </div>
  </mat-card>
</div>
