<div *ngIf="data"
     class="flex flex-row gap-3 px-2 items-center hover:bg-stone-100 hover:cursor-pointer">
  <div style="flex: 0 1 45px;">
    <app-site-image [site]="data.site"></app-site-image>
  </div>

  <div class="flex-auto">
    {{ data.site.name }}
  </div>


  <app-alarm-info [alarms]="data.alarmState" class="md:basis-2/12 grow-0"></app-alarm-info>
  <div class="md:basis-1/12 grow-0 flex flex-col items-center me-3">
    <app-iot-status [eventState]="data.eventState"></app-iot-status>
  </div>
</div>
<mat-divider></mat-divider>
