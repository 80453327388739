<mat-toolbar>
  <div class="text-2xl font-bold">{{ 'cart.title' | translate }}</div>
  <div class="flex-auto"></div>

</mat-toolbar>


<mat-progress-bar *ngIf="service.reloading" mode="indeterminate"></mat-progress-bar>
@if (service.cart) {
  <div class="flex flex-col gap-2 p-4">
    <mat-card class="flex-auto basis-1/2">
      <app-cart-details-entry-list [data]="service.cart.entries"></app-cart-details-entry-list>
    </mat-card>

    <mat-card>
      <div class="flex flex-row gap-3 px-3 py-3 justify-start">
        <button mat-stroked-button (click)="deleteAll()" color="warn" [disabled]="service.cart.entries.length <= 0">
          {{ 'cart.clear' | translate }}
          <mat-icon>delete</mat-icon>
        </button>
        <div class="flex-auto"></div>
        <button mat-stroked-button (click)="share()" color="primary" [disabled]="service.cart.entries.length <= 0">
          {{ 'cart.share' | translate }}
          <mat-icon>share</mat-icon>
        </button>
        <app-file-download-button color="primary" text="cart.export" icon="download" [provider]="service.exportCart.bind(this)"
                                  [disabled]="service.cart.entries.length <= 0">
        </app-file-download-button>
        <button mat-stroked-button (click)="checkout()" color="accent" [disabled]="service.cart.entries.length <= 0">
          {{ 'cart.checkout' | translate }}
          <mat-icon>shopping_cart_checkout</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
}
