import { Component, Input } from '@angular/core';
import { EquipmentAlarmStats, EquipmentAlarmStatsEntry } from "../../model/operational-report-alarms-api";
import { AlarmType } from "../../../../site/model/site-api";
import { AlarmInfoDialogComponent } from "../../../../alarm/alarm-info-dialog/alarm-info-dialog.component";
import { AlarmInfo } from "../../../../alarm/model/alarm-api";
import { MatDialog } from "@angular/material/dialog";
import { OperationalReportService } from "../../model/operational-report.service";

@Component({
  selector: 'app-alarm-stats',
  templateUrl: './alarm-stats.component.html',
  styleUrl: './alarm-stats.component.scss'
})
export class AlarmStatsComponent {

  @Input()
  set statsData(data: EquipmentAlarmStats | undefined) {
    if (!data) return
    this.stats = data
  }

  stats: EquipmentAlarmStats | undefined
  displayedColumns = ['level', 'code', 'description', 'quantity', 'percentage']

  constructor(
    private service: OperationalReportService,
    private dialog: MatDialog
  ) { }

  defineStyle(info: EquipmentAlarmStatsEntry) {
    if (info.level === AlarmType.FAULT) {
      return { 'color': '#b00000' }
    } else if (info.level === AlarmType.CRITICAL) {
      return { 'color': '#e78c07' }
    }

    return { 'color': 'black' }
  }

  openAlarmsDialog(alarms: AlarmInfo[]) {
    if (!alarms.length) return
    const dialogRef = this.dialog.open(AlarmInfoDialogComponent, {
      width: '85%',
      data: alarms
    })
  }
}
