import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductBoardComponent } from "./product-board/product-board.component";
import { ProductDetailsComponent } from "./product-details/product-details.component";
import { AuthGuard } from "../../auth/auth.guard";
import { ProductDetailsTabRouteData } from "./product-details-tabs/product-details-tab-route-data";
import { ProductDetailsInfoComponent } from "./product-details-info/product-details-info.component";
import { DocumentProductDetailsComponent } from "../document/document-product-details/document-product-details.component";
import { SparePartProductDetailsComponent } from "../spare-parts/spare-part-product-details/spare-part-product-details.component";
import { ProductConfigProductDetailsComponent } from "../product-config/product-config-product-details/product-config-product-details.component";
import { BETA_ACCESS, PUBLIC_ACCESS } from "../../auth/roles";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    component: ProductBoardComponent
  },
  {
    path: 'details',
    canActivate: [AuthGuard],
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    component: ProductDetailsComponent,
    children: [
      {
        path: '',
        redirectTo: 'information',
        pathMatch: 'full'
      },
      {
        path: 'information',
        component: ProductDetailsInfoComponent,
        data: new ProductDetailsTabRouteData("Information", false, [])
      },
      {
        path: 'documents',
        component: DocumentProductDetailsComponent,
        data: new ProductDetailsTabRouteData("Documents", false, [])
      },
      {
        path: 'pid',
        component: ProductConfigProductDetailsComponent,
        data: new ProductDetailsTabRouteData("Product Config", false, [])
      },
      {
        path: 'spareparts',
        component: SparePartProductDetailsComponent,
        data: new ProductDetailsTabRouteData("Spare parts", false, [])
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {
}
