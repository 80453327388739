<mat-card>
  <div class="flex flex-row flex-wrap gap-2 p-2 justify-between">
    @if (entries.length) {
      @for (p of entries; track p.id) {
        <app-list-card-entry
          class="product-list-entry"
          (click)="showDetails(p)"
          imageUrl="https://oiservicesprod.blob.core.windows.net/images/products/{{p.matNrKMat}}.png"
          [title]="p.id"
          [subtitle]="p.seriesName"
        ></app-list-card-entry>
      }
    } @else {
      <h1 class="text-2xl align-middle m-auto p-4">{{ 'products.table.NoData' | translate }}</h1>
    }
  </div>

  <mat-paginator (page)="handlePaginatorEvent($event)"
                 *ngIf="totalSize > 0 && totalSize > pageSize"
                 [length]="totalSize"
                 [pageIndex]="pageIndex"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 [pageSize]="pageSize" class="dense-1 pb-3">
  </mat-paginator>
</mat-card>
