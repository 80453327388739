import { Component, input, Input } from '@angular/core';
import { MainMenuActionGroup } from '../model/main-menu-action';
import { HomeService } from '../model/home.service';

@Component({
  selector: 'app-main-menu-action-group',
  templateUrl: './main-menu-action-group.component.html',
  styleUrl: './main-menu-action-group.component.scss',
})
export class MainMenuActionGroupComponent {
  @Input() data!: MainMenuActionGroup;
  @Input() last: boolean = false;
  collapsed = input(false);

  constructor(public service: HomeService) {}
}
