<mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
<mat-card class="m-2">
  <div class="flex flex-col">
    <ng-container *ngFor="let site of data">
      <app-site-board-content-list-entry [data]="site" routerLink="/site/{{site.site.id}}"></app-site-board-content-list-entry>
    </ng-container>
  </div>
</mat-card>
<mat-paginator *ngIf="!(reloading)"
               [length]="totalSize"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               [pageSizeOptions]="[5, 10, 25, 100]"
               showFirstLastButtons
               (page)="handlePaginatorEvent($event)">
</mat-paginator>
