import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, Subscription } from "rxjs";
import { HotToastService } from "@ngxpert/hot-toast";
import { finalize } from "rxjs/operators";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { DocumentStorageService } from "../model/document-storage.service";
import { StorageDocument } from "../model/document-api";

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent {

  @Input() itemId: number | undefined
  @Input() submitUpload: Subject<any> | undefined
  @Output() event = new EventEmitter<boolean>()
  fileName = ''
  file: File | undefined

  uploadProgress: number | undefined
  uploadSub: Subscription | undefined
  uploading: boolean = false
  title: string = ''

  constructor(private service: DocumentStorageService, private toast: HotToastService) {
  }

  ngOnInit() {
    if (!this.submitUpload) return
    this.submitUpload.subscribe(e => {
      this.onUpload()
    });
  }

  ngOnDestroy() {
    this.submitUpload?.unsubscribe()
  }

  onFileSelected(event: any) {
    if (!this.itemId) return

    const file: File = event.target.files[0]
    if (!file) return

    this.file = file
    this.fileName = file.name
    this.uploading = true
  }

  onUpload() {
    const formData = new FormData()
    if (!this.file || !this.itemId) return
    formData.append("file", this.file, this.fileName)

    const upload$ = this.service.upload(this.itemId, formData).pipe(
      finalize(() => this.reset())
    )

    this.uploadSub = upload$.subscribe(
      {
        next: (evt) => this.handleUpdate(evt),
        error: (err) => this.handleFailed(err),
      })
  }

  private handleUpdate(event: HttpEvent<StorageDocument>) {
    if (event.type == HttpEventType.UploadProgress) {
      this.uploadProgress = Math.round(100 * (event.loaded / event.total!!))
    } else if (event.type == HttpEventType.Response) {
      this.handleCompleted(event.body!!)
    }
  }

  private handleCompleted(v: StorageDocument) {
    if(!this.itemId) return
    let request = {
      value: this.title
    }
    this.service.setTitle(this.itemId, v.id, request).subscribe(it => {
      this.toast.success("Successfully uploaded file '" + this.fileName + "' ")
      this.event.emit(true)
    })
  }

  private handleFailed(e: any) {
    this.toast.error("Failed to upload file '" + this.fileName + "' ")
    this.event.emit(false)
  }

  reset() {
    this.uploadProgress = undefined
    this.uploadSub = undefined
    this.uploading = false
  }
}
