import { Component } from '@angular/core';
import { SearchService } from "../model/search.service";

@Component({
  selector: 'app-search-board',
  templateUrl: './search-board.component.html',
  styleUrls: ['./search-board.component.scss']
})
export class SearchBoardComponent {

  constructor(public service: SearchService) {
  }


}
