<div class="flex flex-col justify-center p-3">
  <mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
  @if (pid) {

    <mat-card class="container mx-auto">
      <div class="flex flex-col justify-center items-stretch p-6 gap-6">
        <div class="flex flex-row p-3 border rounded border-gray-300 items-center justify-stretch gap-4">
          <button (click)="back()" mat-icon-button matTooltip="{{'back' | translate}}">
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
          <app-product-config-details-title [pid]="pid" class="flex-auto"></app-product-config-details-title>
        </div>
        <app-product-config-details-header [pid]="pid" class="p-3 border rounded border-gray-300"></app-product-config-details-header>
        <app-product-config-details-cstics [pid]="pid" class="p-3 border rounded border-gray-300"></app-product-config-details-cstics>
      </div>
    </mat-card>
  }
</div>
