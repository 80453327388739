import {Component} from '@angular/core';
import {System} from "../model/system-api";
import {SystemService} from "../model/system.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ResourceHierarchyNode} from "../../hierarchy/model/hierarchy-api";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {DeleteSystemDialogComponent} from "../delete-system-dialog/delete-system-dialog.component";
import {ChangeSystemDialogComponent} from "../change-system-dialog/change-system-dialog.component";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {AccessService} from "../../access/model/access.service";
import {DateTime} from "luxon";

@Component({
  selector: 'app-system-details',
  templateUrl: './system-details.component.html',
  styleUrls: ['./system-details.component.scss']
})
export class SystemDetailsComponent {

  system: System | undefined
  hierarchy: ResourceHierarchyNode | undefined
  grantToShare: boolean = false
  canWrite: boolean = false
  systemSubtitle = ''
  operatorSubtitle = ''
  isHandset$: Observable<boolean> = this.responsive.observe(['(max-width: 1000px)'])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(
    public service: SystemService,
    private translate: TranslateService,
    public shareService: AccessService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private responsive: BreakpointObserver
  ) {
  }

  ngOnInit() {
    const systemId = this.activatedRoute.snapshot.params.id
    this.service.getSystem(systemId).subscribe(data => {
      this.system = data
      this.setSubtitles(data);
    })
    this.service.getPath(systemId).subscribe(data => this.hierarchy = data)
    this.shareService.getItemAccess(systemId).subscribe(access => {
      if (!access) return
      this.grantToShare = access.grantToShare
      this.canWrite = access.canWrite
    })
  }

  openSystemOpReport() {
    if (!this.system) return
    window.location.href = environment.sapIotBaseUrl + '/systemreport/index.html#/?system='
      + this.system.ainResourceId + '&dateFrom=' +
      DateTime.utc().minus({days: 7}).startOf('day').toISO() +
      '&dateTo=' +
      DateTime.utc().endOf('day').toISO() +
      '&uomsystem=metric';
  }

  changeSystem() {
    if (!this.system) return
    let dialogRef = this.dialog.open(ChangeSystemDialogComponent, {
      width: '50%',
      data: this.system
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) window.location.reload()
    })
  }

  deleteSystem() {
    const dialogRef = this.dialog.open(DeleteSystemDialogComponent, {
      width: '300px',
      data: this.system
    })

    dialogRef.afterClosed().subscribe((shouldNavigate: boolean) => {
      if (shouldNavigate) this.navigateToParent()
    })
  }

  openShareDialog() {
    let siteId = this.findSite()?.id
    if (!siteId) return
    this.router.navigate(['access', 'site', siteId]).then()
  }

  private setSubtitles(data: System) {
    this.systemSubtitle = data.type + ' / ' + data.refrigerant
    this.service.getOwner(data.id).subscribe(c => {
      let op = this.translate.instant('owner')
      this.operatorSubtitle = op + ': ' + c.name
    })
  }

  private findSite() {
    let resource
    this.hierarchy?.children.forEach(c => {
      if (c.resource.type == 'SITE') resource = c.resource
    })
    return resource ? resource : this.hierarchy?.resource
  }

  private navigateToParent() {
    const site = this.findSite()
    const url = site ? 'site/' + site.id : 'site'
    this.router.navigate([url]).then()
  }
}
