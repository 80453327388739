import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Company } from "../model/company-api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from "../model/company.service";

@Component({
  selector: 'app-company-change-dialog',
  templateUrl: './company-change-dialog.component.html',
  styleUrls: ['./company-change-dialog.component.scss']
})
export class CompanyChangeDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Company,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CompanyChangeDialogComponent>,
    private service: CompanyService
  ) {
  }

  companyForm: FormGroup = this.fb.group({
    name: this.fb.control(this.data.name, [Validators.required]),
  })

  processing: boolean = false

  cancel() {
    this.dialogRef.close()
  }

  change() {
    if (!this.data.id) return

    this.processing = true
    const value = this.companyForm.value
    this.service.setName(this.data.id, value.name).subscribe((data) => {
      this.processing = false
      this.dialogRef.close(data)
    })
  }
}
