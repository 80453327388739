import {Component} from '@angular/core';
import {Subscription, switchMap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductConfigSelectService} from "../model/product-config-select.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-product-config-select',
  templateUrl: './product-config-select.component.html',
  styleUrl: './product-config-select.component.scss'
})
export class ProductConfigSelectComponent {
  subscriptions: Subscription[] = []

  constructor(
    public service: ProductConfigSelectService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.pipe(
      switchMap(params => {
        const productId = params ? params['product'] : ''
        return this.service.selectProduct(productId)
      }),
      takeUntilDestroyed()
    ).subscribe()
  }

  reset(): void {
    this.service.resetSelection()
  }

  navigateToPidComparison(): void {
    const configIds = this.service.result.entries.map(i => i.id)
    this.router.navigate(['portal', 'pid', 'compare'], {queryParams: {p: configIds}}).then()
  }
}
