import { Component, Input } from '@angular/core';
import { MeanSeries } from "../../../model/operational-report-activity-api";
import { ECharts, EChartsOption, SeriesOption } from "echarts";
import { OperationalReportService } from "../../../model/operational-report.service";
import { SystemTimeService } from "../../../../../system/system-time/system-time.service";
import { MeantimeChart } from "../meantime-graph/meantime-chart";
import { YAXisOption } from "echarts/types/dist/shared";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-weather-graph',
  templateUrl: './weather-graph.component.html',
  styleUrl: './weather-graph.component.scss'
})
export class WeatherGraphComponent {

  @Input()
  set data(data: MeanSeries | undefined) {
    if (!data) return
    this.series = data
    this.process(data)
  }

  chart: ECharts | undefined
  series: MeanSeries | undefined
  initialValue: EChartsOption | null
  dynamicData: EChartsOption | null
  weatherNames = ['temp', 'humidity', 'pressure']

  constructor(
    public service: OperationalReportService,
    private systemTime: SystemTimeService,
    private translate: TranslateService
  ) {
    this.initialValue = null
    this.dynamicData = null
    this.initialValue = new MeantimeChart().build()
    this.service.changeEvent.subscribe(reload => {
      if (reload) this.chart?.showLoading()
    })
  }

  process(series: MeanSeries) {
    let data = this.createSeries(series)
    this.dynamicData = {
      series: [data],
      color: '#3aaa35',
      xAxis: {
        min: () => this.service.calculateMin(),
        max: () => this.service.calculateMax()
      },
      yAxis: this.getUoMLabel()
    }
    this.chart?.hideLoading()
  }

  createSeries(series: MeanSeries): SeriesOption {
    const values = series.entries.map(p => [this.systemTime.formatTimestamp(p.timestamp), (p.value).toFixed(2)])
    return {
      name: this.translate.instant(this.getName(this.service.weather)),
      type: 'line',
      data: values,
    }
  }

  getUoMTranslation() {
    switch (this.service.weather) {
      case 'temp': return '°C'
      case 'humidity': return '%'
      case 'pressure': return 'hPa'
    }
    return ''
  }


  getUoMLabel(): YAXisOption {
    const label = this.getUoMTranslation()
    return {
      type: 'value',
      name: label,
      nameLocation: 'middle',
      nameRotate: 90,
      nameGap: 35,
      axisLine: {
        show: true
      },
      splitLine: {
        show: false
      }
    }
  }

  setChart(chart: ECharts) {
    this.chart = chart
  }

  refresh() {
    this.service.refresh()
  }

  getName(name: string) {
    switch(name) {
      case 'temp': return 'bdnTemp'
      case 'pressure': return 'airPressure'
    }
    return name
  }
}
