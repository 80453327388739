import { KeycloakOptions, KeycloakService } from "keycloak-angular";
import { environment } from "../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  let baseUrl = window.location.origin
  let reverseProxy = baseUrl.includes("dev.mybitzer.de")
    || baseUrl.includes("bdn-prod.northeurope.cloudapp.azure.com")
    || baseUrl.includes("bdn-dev.chinanorth3.cloudapp.chinacloudapi.cn")
    || baseUrl.includes("bdn-prod.chinanorth3.cloudapp.chinacloudapi.cn");
  let prefix = reverseProxy ? baseUrl + "/frontend-service" : baseUrl
  let redirectUri = prefix + '/assets/silent-check-sso.html'
  console.log("Redirect uri : " + redirectUri)
  let options: KeycloakOptions = {
    config: {
      url: environment.authUrl,
      realm: 'bdn',
      clientId: 'bdn-ui',
    },
    initOptions: {
      // onLoad: 'check-sso',
      checkLoginIframe: false,
      pkceMethod: 'S256',
    },
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
    bearerExcludedUrls: ['/assets', '/img'],
  };
  return () => keycloak.init(options)
    .then()
    .catch((error) => {
      console.error("Keyclok init failed.", error)
      alert("Login Error.")
    });
}
