import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HotToastService } from "@ngxpert/hot-toast";
import { System } from "../../system/model/system-api";
import { Site } from "../../site/model/site-api";
import { TemporalAccessService } from "../model/temporal-access.service";
import { TemporalAccessItemRequest, TemporalAccessItemResponse } from "../model/access-api";

@Component({
  selector: 'app-temporal-access-dialog',
  templateUrl: './temporal-access-dialog.component.html',
  styleUrl: './temporal-access-dialog.component.scss'
})
export class TemporalAccessDialogComponent {
  reloading: boolean = false
  response: TemporalAccessItemResponse | undefined

  fg = this.fb.group({
    period: ['ONE_DAY', Validators.required]
  })

  constructor(
    public dialogRef: MatDialogRef<TemporalAccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Site | System,
    private service: TemporalAccessService,
    private toast: HotToastService,
    private fb: FormBuilder
  ) {
  }

  createTemporalAccess() {
    if (this.reloading) return
    if (!this.fg.valid) return
    this.reloading = true
    let value = this.fg.value
    this.service.createTemporalAccessItem(new TemporalAccessItemRequest(this.data.id, value.period!!)).subscribe({
      next: response => this.handleResponse(response),
      error: err => this.handleError(err)
    })
  }

  private handleResponse(response: TemporalAccessItemResponse) {
    this.response = response
    this.reloading = false
  }

  private handleError(err: any) {
    this.toast.error("Something went wrong")
    this.reloading = false
  }
}
