<input class="file-input" type="file" accept="image/*" (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload flex flex-col gap-2 items-center">
  <div>
    {{fileName || "No file uploaded yet."}}
  </div>
  <button mat-fab extended color="primary" class="upload-btn" (click)="fileUpload.click()" [disabled]="uploading">
    <mat-icon>attach_file</mat-icon>
    {{'uploadPicture' | translate}}
  </button>

  <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploading">

  </mat-progress-bar>
</div>
