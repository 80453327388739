import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { EquipmentOperationalReport, EquipmentOperationReportRequest } from "./operational-report-api";
import { EquipmentLoadReport, EquipmentLoadReportRequest } from "./operational-report-load-api";

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/report', logging, zone)
  }

  getEquipmentReport(equipmentId: number, request: EquipmentOperationReportRequest): Observable<EquipmentOperationalReport> {
    return this.post(`${equipmentId}`, request)
  }

  getEquipmentLoadReport(equipmentId: number, request: EquipmentLoadReportRequest): Observable<EquipmentLoadReport> {
    return this.post('' + equipmentId + '/load', request)
  }
}
