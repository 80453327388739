export enum FilterType {
  Select = 'select',
  AutoSelect = 'autoselect'
}

export interface FilterValue {
  value: unknown;
  displayValue: string;
  displayValueSuffix?: string;
}

export interface Filter {
  clearable?: boolean;
  name: string;
  nameSuffix: string;
  type: FilterType;
  parentId?: string;
  selectedValue: FilterValue | undefined;
  options: FilterValue[];
  inline?: boolean;
}
