import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ResourceHierarchyNode, ResourceType} from "../model/hierarchy-api";

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnChanges {

  @Input() data: ResourceHierarchyNode | undefined

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'].currentValue) this.assignUrls()
  }

  private assignUrls() {
    if (!this.data) return
    this.data.url = this.createUrl('site', this.data.resource.id)

    if (!this.data.children.length) return
    const resourceType = this.data.children[0].resource.type === ResourceType.SITE ? 'site' : 'system'
    this.data.children[0].url = this.createUrl(resourceType, this.data.children[0].resource.id)

    if (!this.data.children[0].children.length) return
    this.data.children[0].children[0].url = this.createUrl('system', this.data.children[0].children[0].resource.id)
  }

  private createUrl(type: string, resourceId: number) {
    return `../../${type}/${resourceId}`
  }
}
