import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HomeService } from "../model/home.service";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent {

  @Input() isMobile: boolean = false
  @Output() toggleDrawer = new EventEmitter<any | null>()

  constructor(public service: HomeService) {
  }
}
