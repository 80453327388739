<app-header-toolbar *ngIf="!productDetailsRoute" [title]="product?.id">
  <app-header-toolbar-icon>

  </app-header-toolbar-icon>
  <app-header-toolbar-content>

  </app-header-toolbar-content>
  <app-header-toolbar-actions>

  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<div class="flex flex-col gap-2 p-2">
  <ng-container *ngIf="selectedCategory; else categoryView">
    <app-spare-parts-category [category]="selectedCategory" (backEvent)="handleCategoryBack()"></app-spare-parts-category>
  </ng-container>
  <ng-template #categoryView>
    <app-spare-parts-categories [categories]="categories" (select)="selectCategory($event)"></app-spare-parts-categories>
  </ng-template>
</div>
