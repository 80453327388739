<div class="flex flex-row gap-2 content-center items-center justify-center">
  <div class="flex flex-col gap-2 flex-auto">
    <div><b>{{ pid.id | stripLeadingZeros }}</b></div>
    <div class="text-sm">{{ pid.matNrKMat  | stripLeadingZeros }}</div>
  </div>
  <app-add-to-cart-button [code]="pid.id" mode="fab"></app-add-to-cart-button>
  <app-product-config-wishlist [isStandalone]="true" [pid]="{id: pid.id, description: pid.id}"></app-product-config-wishlist>
  <app-file-download-button *hasRole="roles" [provider]="getProvider(pid.id)" color="tertiary" icon="download"
                            mode="fab" text="cart.export"/>
  <app-product-config-compare-button [pid]="pid.id"/>
  <app-material-status [showText]="true" [status]="pid.status" class=" flex p-4 bg-gray-200 rounded-2xl"></app-material-status>
</div>
