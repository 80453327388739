import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SparePartsRoutingModule} from './spare-parts-routing.module';
import {SparePartsBoardComponent} from './spare-parts-board/spare-parts-board.component';
import {FilterModule} from "../../filter/filter.module";
import {HeaderToolbarModule} from "../../component/header-toolbar/header-toolbar.module";
import {SystemModule} from "../../system/system.module";
import {TranslateModule} from "@ngx-translate/core";
import {SearchModule} from "../../component/search/search.module";
import {
  SparePartsCategoriesComponent
} from './spare-parts-categories/spare-parts-categories.component';
import {
  SparePartProductDetailsComponent
} from './spare-part-product-details/spare-part-product-details.component';
import {MaterialModule} from "../../material/material.module";
import {
  SparePartsCategoryDrawingComponent
} from './spare-parts-category-drawing/spare-parts-category-drawing.component';
import {SparePartsCategoryComponent} from './spare-parts-category/spare-parts-category.component';
import {
  SparePartsCategoryItemsComponent
} from './spare-parts-category-items/spare-parts-category-items.component';
import {
  SparePartsCategoryDetailsComponent
} from './spare-parts-category-details/spare-parts-category-details.component';
import {ComponentModule} from "../../component/component.module";
import {
  SparePartsBoardContentComponent
} from './spare-parts-board-content/spare-parts-board-content.component';
import {CategoryModule} from "../category/category.module";
import {
  SparePartsBoardContentResultListComponent
} from './spare-parts-board-content-result-list/spare-parts-board-content-result-list.component';
import {
  SparePartsBoardContentDetailsComponent
} from './spare-parts-board-content-details/spare-parts-board-content-details.component';
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {CommerceModule} from "../../commerce/commerce.module";
import {ProductConfigModule} from "../product-config/product-config.module";


@NgModule({
  declarations: [
    SparePartsBoardComponent,
    SparePartsCategoriesComponent,
    SparePartProductDetailsComponent,
    SparePartsCategoryDrawingComponent,
    SparePartsCategoryComponent,
    SparePartsCategoryItemsComponent,
    SparePartsCategoryDetailsComponent,
    SparePartsBoardContentComponent,
    SparePartsBoardContentResultListComponent,
    SparePartsBoardContentDetailsComponent
  ],
  exports: [
    SparePartsCategoriesComponent,
    SparePartsCategoryComponent
  ],
  imports: [
    CommonModule,
    SparePartsRoutingModule,
    FilterModule,
    HeaderToolbarModule,
    MaterialModule,
    SystemModule,
    TranslateModule,
    SearchModule,
    ComponentModule,
    CategoryModule,
    MatGridList,
    MatGridTile,
    CommerceModule,
    ProductConfigModule
  ]
})
export class SparePartsModule {
}
