import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {FeedbackService} from "../model/feedback.service";
import {MatDialog} from "@angular/material/dialog";
import {FeedbackDialogComponent} from "../feedback-dialog/feedback-dialog.component";

@Component({
  selector: 'app-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrl: './feedback-button.component.scss'
})
export class FeedbackButtonComponent {
  @Input() title = ''
  enabled: boolean = false
  private url!: string

  constructor(private service: FeedbackService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit() {
    this.url = this.router.url
    this.enabled = this.service.showFeedback(this.url)
  }

  showDialog() {
    this.dialog.open(FeedbackDialogComponent, {
      data: {title: this.title}
    }).afterClosed().subscribe(() => this.enabled = false)
  }
}
