import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alarm-level',
  templateUrl: './alarm-level.component.html',
  styleUrls: ['./alarm-level.component.scss']
})
export class AlarmLevelComponent {

  @Input() level: string | undefined
  @Input() showLabel: boolean = true

}
