<mat-card>
  <mat-card-header class="flex items-center content-center justify-between">
    <div class="text-xl font-medium"> {{ 'products.table.Equipment' | translate }}</div>
    <div class="flex-auto"></div>
    <button mat-stroked-button (click)="onAddEquipment()" color="primary" *ngIf="registrationService.hasRegistrationPermissions()">
      <mat-icon>add_box</mat-icon>
      {{ 'addEquipment' | translate }}
    </button>
  </mat-card-header>
  <div class="flex flex-col p-2">
    <table mat-table [dataSource]="equipment">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'name' | translate }}</th>
        <td mat-cell *matCellDef="let equipment">
          <div class="flex flex-wrap gap-2 justify-content-left">
            <div style="margin-top: 2px">
              <img [src]="equipmentImage" class="equipment-image" alt="">
            </div>
            <div class="flex flex-col gap-1" style="line-height: normal !important;">
              <div>{{ equipment.equipment.name }}</div>
              <div class="small-text">SN: {{ equipment.equipment.serialNumber }}</div>
            </div>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> {{ 'type' | translate }}</th>
        <td mat-cell *matCellDef="let equipment">
          <div class="flex flex-col gap-1">
            <div>{{ equipment.equipment.productId }}</div>
            <div>{{ equipment.equipment.refrigerant }}</div>
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="lastAlarms">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'lastAlarms' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center">
          <app-alarm-info [alarms]="equipment.alarmState"></app-alarm-info>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'iotStatus' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center">
          <app-iot-status *ngIf="equipment.eventState" [eventState]="equipment.eventState"></app-iot-status>
          <app-time-in-fault [state]="equipment.eventState"></app-time-in-fault>
        </td>
      </ng-container>

      <ng-container matColumnDef="timeInFault">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'products.table.TimeInFault' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center">
          <app-time-in-fault [state]="equipment.eventState"></app-time-in-fault>
        </td>
      </ng-container>

      <ng-container matColumnDef="refrigerant" class="hide-on-mobile">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'refrigerant' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center"> {{ equipment.equipment.refrigerant }}</td>
      </ng-container>

      <ng-container matColumnDef="capacity">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'capacity' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center"> {{ getLastPropertiesValues(equipment.equipment.thingId, 0) }}</td>
      </ng-container>

      <ng-container matColumnDef="evapTemp">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'condTemp' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center"> {{ getLastPropertiesValues(equipment.equipment.thingId, 1) }}</td>
      </ng-container>

      <ng-container matColumnDef="condTemp">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'evapTemp' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center"> {{ getLastPropertiesValues(equipment.equipment.thingId, 2) }}</td>
      </ng-container>

      <ng-container matColumnDef="gasTemp">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'oilDiscGasTemp' | translate }}</th>
        <td mat-cell *matCellDef="let equipment" class="text-center"> {{ getLastPropertiesValues(equipment.equipment.thingId, 3) }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'actions' | translate }}</th>
        <td mat-cell *matCellDef="let equipment">
          <div class="flex flex-row gap-2 justify-center" *ngIf="systemService.hasOperationPermissions()">
            <button mat-mini-fab color="primary" (click)="openEquipmentAlarms($event, equipment.equipment)" matTooltip="{{'Alarms' | translate}}">
              <mat-icon>warning</mat-icon>
            </button>
            <button mat-mini-fab color="primary" (click)="openEquipmentReport($event, equipment.equipment)"
                    matTooltip="{{'operationreport.appDescription' | translate}}">
              <mat-icon>list_alt</mat-icon>
            </button>
            <button mat-mini-fab color="primary" (click)="openEquipmentGraph($event, equipment.equipment)"
                    matTooltip="{{'showInGraph' | translate}}">
              <mat-icon>insights</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
          [ngClass]="{ 'gray': even }"
          class="hover-effect"
          (click)="openEquipmentDetailsPage(row)">
      </tr>
    </table>
    <mat-paginator *ngIf="totalSize > 4"
                   [length]="totalSize"
                   [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   showFirstLastButtons
                   [pageSizeOptions]="[4,8,16,32]"
                   (page)="handlePaginatorEvent( $event)">
    </mat-paginator>
  </div>
</mat-card>
