import {
  Component,
  computed,
  contentChild,
  forwardRef,
  input,
  output,
  TemplateRef,
  viewChild
} from '@angular/core';
import {Filter, FilterType, FilterValue} from "../filter.const";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";
import {NgClass, NgTemplateOutlet} from "@angular/common";
import {MatSelectModule} from "@angular/material/select";
import {FilterOptionDirective} from "../filter-option.directive";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-filter',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterComponent),
      multi: true
    }
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    NgClass,
    NgTemplateOutlet,
    TranslateModule
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {
  filter = input.required<Filter>();
  filterSelected = output<any>();
  filterCleared = output<any>();
  control = input<FormControl | null>(null);
  selectedOption: any = null;
  defaultOptionTemplate = viewChild<TemplateRef<unknown>>('defaultOption');
  content = contentChild(FilterOptionDirective);
  optionTemplate = computed(() => {
    return this.content()?.templateRef || this.defaultOptionTemplate() || null;
  });
  protected readonly FilterType = FilterType;
  private internalFormControl = new FormControl();

  constructor(private readonly translateService: TranslateService) {
    toObservable(this.filter).pipe(takeUntilDestroyed()).subscribe((filter) => {
      if (filter.selectedValue) {
        this.formControlInstance.setValue(filter.selectedValue)
        this.selectedOption = filter.selectedValue
      }
    });
  }

  get formControlInstance(): FormControl {
    return this.control() || this.internalFormControl;
  }

  writeValue(value: any): void {
    this.formControlInstance.setValue(value, {emitEvent: false});
  }

  registerOnChange(fn: any): void {
    this.formControlInstance.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    // Optionally handle touched event, usually with some internal state like "isTouched"
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControlInstance.disable() : this.formControlInstance.enable();
  }

  clear(event: MouseEvent) {
    event.stopPropagation();
    this.selectedOption = false;

    this.filterCleared.emit(this.formControlInstance.value);
    this.formControlInstance.reset();
  }

  displayFn(option: FilterValue): string {
    if (option?.displayValue) {
      return this.translateService.instant(option?.displayValue);
    }
    return '';
  }

  onOptionSelected(value: any) {
    if (this.selectedOption === value) {
      return;
    }
    this.selectedOption = value;
    this.filterSelected.emit(value);
  }
}
