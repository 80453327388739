import {Injectable} from '@angular/core';
import {ProductConfigService} from "./product-config.service";
import {
  ProductConfigFilterResultEntry,
  ProductConfigIdInfo,
  ProductConfigSearchRequest,
  ProductConfigSearchResponse,
  ProductConfigSearchResultEntry
} from "./product-config-api";
import {PageEvent} from "@angular/material/paginator";
import {HotToastService} from "@ngxpert/hot-toast";
import {COMMERCE_ADMIN, COMMERCE_READ} from "../../../auth/roles";
import {AuthService} from "../../../auth/auth.service";
import {WishlistService} from "../../wishlist/model/wishlist.service";
import {WishlistEntry} from "../../wishlist/model/wishlist-api";
import {CommerceCartService} from "../../../commerce/model/commerce-cart.service";
import {
  PriceRequestEntry,
  PriceResponse,
  PriceResponseEntry
} from "../../../commerce/model/commerce-api";
import {Observable} from "rxjs";
import {Page} from "../../../utils/page";
import {FindResult} from "../../sales-order/model/sales-order-api";

@Injectable({
  providedIn: 'root'
})
export class ProductConfigBoardService {

  reloading: boolean = false
  pageSize: number = 20
  pageIndex: number = 0
  totalSize: number = -1

  data: ProductConfigSearchResultEntry[] = []
  request: ProductConfigSearchRequest = new ProductConfigSearchRequest([], ['PRODUCTION'], [], false, false, '', '')
  matNrKMats: FindResult[] = []
  status: ProductConfigFilterResultEntry[] = []
  type: ProductConfigFilterResultEntry[] = []

  whishlist: WishlistEntry[] = []
  prices: PriceResponseEntry[] = []
  currency: string = ""

  constructor(
    private service: ProductConfigService,
    private authService: AuthService,
    private commerceService: CommerceCartService,
    private wishlistService: WishlistService,
    private toast: HotToastService
  ) {
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ) || this.authService.hasRole(COMMERCE_ADMIN)
  }

  search(query: string): void {
    this.request.fullTextSearch = query
    this.applyFilter()
  }

  findMaterial(query: string): Observable<Page<FindResult>> {
    return this.service.findMaterial(query, 0, 20)
  }

  setMatNrKMatsFilter(values: string[]) {
    this.request.matNrKMats = values
    this.applyFilter()
  }

  setStatusFilter(values: string[]) {
    this.request.status = values
    this.applyFilter()
  }

  setTypeFilter(values: string[]) {
    this.request.type = values
    this.applyFilter()
  }

  setStandardFilter(value: boolean) {
    this.request.standard = value
    this.applyFilter()
  }

  setSuggestedFilter(value: boolean) {
    this.request.suggested = value
    this.applyFilter()
  }

  resetFilter() {
    this.request = new ProductConfigSearchRequest([], ['PRODUCTION'], [], false, false, '', '')
    this.applyFilter()
  }

  handlePaginatorEvent(event: PageEvent): void {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.load()
  }

  getWishlistEntry(productConfig: ProductConfigIdInfo): WishlistEntry | undefined {
    return this.whishlist.find(v => v.reference === productConfig.id)
  }

  getPriceEntry(productConfig: ProductConfigIdInfo): PriceResponseEntry | undefined {
    return this.prices.find(v => v.code === productConfig.id)
  }

  private applyFilter() {
    this.pageIndex = 0
    this.load()
  }

  private load() {
    if (this.reloading) return
    this.reloading = true

    this.service.search(this.request, this.pageIndex, this.pageSize).subscribe(
      {
        next: d => this.handleResponse(d),
        error: err => this.handleError(err)
      }
    )
  }

  private handleResponse(d: ProductConfigSearchResponse) {
    this.data = d.entries.content
    this.updateWishlist()
    this.updatePrices()
    this.status = d.status
    this.type = d.type

    this.pageSize = d.entries.pageable.size
    this.pageIndex = d.entries.pageable.number
    this.totalSize = d.entries.totalSize

    this.reloading = false
  }

  private handleError(err: any) {
    this.toast.error("Something went wrong")
    this.reloading = false
  }

  private updateWishlist() {
    let references = this.data.map(e => e.id);
    if (references.length > 0) {
      this.wishlistService.updateWishlistActive(references).subscribe({
        next: value => this.handleWishlistUpdate(value),
        error: err => this.handleError(err)
      })
    } else {
      this.whishlist = []
    }
  }

  private handleWishlistUpdate(value: WishlistEntry[]) {
    this.whishlist = value
  }

  private updatePrices() {
    let entries = this.data.map(p => new PriceRequestEntry(p.id, 1))
    if (entries.length > 0) {
      this.commerceService.getPrices(entries).subscribe({
        next: value => this.handlePriceUpdate(value),
        error: err => this.handleError(err)
      })
    } else {
      this.prices = []
    }
  }

  private handlePriceUpdate(value: PriceResponse) {
    this.prices = value.entries
    this.currency = value.currency
  }

}
