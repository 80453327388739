import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Feedback, FeedbackEntry, FeedbackRequest } from "./feedback-api";
import { Observable } from "rxjs";
import { Page } from "../../../utils/page";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService {
  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
  ) {
    super(http, 'api/logic/feedback', logging, zone)
    this.retryCount = 0
  }


  feedback(request: FeedbackRequest): Observable<any> {
    localStorage.setItem(request.url, '')
    return this.post('', request)
  }

  showFeedback(url: string) {
    return localStorage.getItem(url) == null
  }

  createPoints(size: number): number[] {
    return Array(size).fill(1).map((x, i) => i + 1)
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        p => {
          resolve({ lon: p.coords.longitude, lat: p.coords.latitude })
        },
        e => {
          resolve({ lon: 0, lat: 0 })
        })
    })
  }

  getAllFeedback(page: number, size: number): Observable<Page<Feedback>> {
    return this.getPaged('', page, size)
  }

  getEntry(id: number): Observable<FeedbackEntry> {
    return this.get(id + '')
  }

}
