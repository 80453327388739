import { Component } from '@angular/core';
import { OperationalReportService } from "../../model/operational-report.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-load-property-selector',
  templateUrl: './load-property-selector.component.html',
  styleUrl: './load-property-selector.component.scss'
})
export class LoadPropertySelectorComponent {

  CAPACITY = 'BDN.Capacity'
  FREQUENCY = 'BDN.MotorFrequency'

  constructor(
    private service: OperationalReportService,
    private dialogRef: MatDialogRef<LoadPropertySelectorComponent>
  ) { }

  selectProperty(selected: string) {
    this.service.setLoadProperty(selected)
    this.dialogRef.close(selected)
  }

  isSelected(property: string) {
    return this.service.getLoadProperty().includes(property)
  }
}
