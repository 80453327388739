import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { EquipmentService } from "../model/equipment.service";
import { Equipment } from "../model/equipment-api";
import { Product, SparePartCategory } from "../../portal/product/model/product-api";
import { ProductService } from "../../portal/product/model/product.service";

@Component({
  selector: 'app-equipment-details-spare-parts',
  templateUrl: './equipment-details-spare-parts.component.html',
  styleUrls: ['./equipment-details-spare-parts.component.scss']
})
export class EquipmentDetailsSparePartsComponent {

  reloading: boolean = false
  categories: SparePartCategory[] = []
  selectedCategory: SparePartCategory | undefined

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private equipmentService: EquipmentService
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const equipmentId = Number(params['id'])
      if (!equipmentId) return
      this.loadEquipment(equipmentId)
    })
  }

  private loadEquipment(id: number) {
    if (this.reloading) return
    this.reloading = true
    this.equipmentService.getEquipment(id).subscribe(e => this.handleEquipment(e))
  }

  private handleEquipment(e: Equipment) {
    this.productService.getProduct(e.productId).subscribe(p => this.handleProduct(p))
  }

  private handleProduct(p: Product) {
    this.categories = p.references.spareParts?.category ?? []
    this.reloading = false
  }


  selectCategory(category: SparePartCategory) {
    this.selectedCategory = category
  }

  handleCategoryBack() {
    this.selectedCategory = undefined
  }
}
