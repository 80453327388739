import { MainMenuAction, MainMenuActionGroup } from "./main-menu-action";
import { BETA_STATUS } from "./status";
import { MainHeaderAction, MainHeaderActionLinkExternal, MainHeaderActionLinkInternal } from "./main-header-action";
import {
  ACCOUNTING_ACCOUNT_ADMIN,
  ACCOUNTING_ACCOUNT_READ,
  ACCOUNTING_ADMIN,
  BETA_ACCESS,
  COMMERCE_ADMIN,
  COMMERCE_READ,
  COMPANY_ADMIN,
  COMPANY_READ,
  CUSTOMER_ADMIN,
  ENTITLEMENT_ADMIN,
  ENTITLEMENT_READ,
  ENTITLEMENT_WRITE,
  FEEDBACK_ADMIN,
  PROCESS_ADMIN,
  PROCESS_READ,
  SALES_ADMIN,
  SEARCH_ADMIN,
  SITE_ADMIN,
  SITE_READ,
  STOCKLEVEL_IMPORT
} from "../../auth/roles";

export const MENU_ACTIONS: MainMenuActionGroup[] = [
  {
    name: "starter",
    actions: [
      new MainMenuAction('portal/product', 'product', 'home', [], BETA_STATUS),
      new MainMenuAction('site', 'sap.iot.ain.manageequipments.equipmentListTitle', 'fact_check', [SITE_READ, SITE_ADMIN], undefined),
      new MainMenuAction('accounting/home', 'account.accounting', 'account_balance_wallet', [ACCOUNTING_ACCOUNT_ADMIN, ACCOUNTING_ACCOUNT_READ], undefined),
      new MainMenuAction('user', 'usermanagement.appTitle', 'manage_accounts', [ENTITLEMENT_READ, ENTITLEMENT_WRITE, ENTITLEMENT_ADMIN], undefined),
    ]
  },
  {
    name: "experts",
    actions: [
      new MainMenuAction('portal/pid', 'pids', 'bookmark_check', [BETA_ACCESS], BETA_STATUS),
      new MainMenuAction('portal/spareparts', 'spare_parts', 'construction', [BETA_ACCESS], BETA_STATUS),
      new MainMenuAction('portal/equipment', 'action.equipment', 'precision_manufacturing', [BETA_ACCESS], BETA_STATUS),
      new MainMenuAction('portal/document', 'documents', 'description', [], undefined),
    ]
  }, {
    name: "sales",
    actions: [
      new MainMenuAction('portal/order', 'action.order', 'grading', [BETA_ACCESS], BETA_STATUS),
      new MainMenuAction('portal/wishlist', 'wishlist.id', 'grade', [BETA_ACCESS], BETA_STATUS),
      new MainMenuAction('portal/sales', 'action.sales', 'list_alt', [BETA_ACCESS], BETA_STATUS),
    ]
  }, {
    name: "legacy",
    actions: [
      new MainMenuAction('process', 'action.process', 'manage_history', [PROCESS_READ, PROCESS_ADMIN], undefined),
      new MainMenuAction('company', 'menu.CompanyProfile', 'corporate_fare', [COMPANY_READ, COMPANY_ADMIN], undefined),
      new MainMenuAction('portal/series', 'action.series', 'devices_other', [], BETA_STATUS),
      new MainMenuAction('portal/exports', 'export.Title', 'download', [BETA_ACCESS], BETA_STATUS),
    ]
  }
]

export const HEADER_ACTIONS: MainHeaderAction[] = [
  new MainHeaderActionLinkInternal('travel_explore', 'action.finder', '/portal/worldwide', []),
  new MainHeaderActionLinkExternal('help', 'help', 'https://wiki.bdn-live.com/', '_blank', []),
  new MainHeaderActionLinkInternal('shopping_cart', 'action.cart', '/cart', [COMMERCE_READ, COMMERCE_ADMIN]),
  new MainHeaderActionLinkInternal('admin_panel_settings', 'backoffice.Title', '/backoffice', [SEARCH_ADMIN, FEEDBACK_ADMIN, SALES_ADMIN, COMPANY_ADMIN, CUSTOMER_ADMIN, ACCOUNTING_ADMIN, STOCKLEVEL_IMPORT])
]
