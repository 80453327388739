import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { SearchService } from "../../portal/search/model/search.service";
import { ProductSearchRequest } from "../../portal/search/model/search-api";

@Injectable({
  providedIn: 'root'
})
/** @deprecated get rid of that **/
export class KnowledgeService extends BaseService {
  private refrigerants: string[] = []
  subject: Subject<string[]> = new Subject()

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private searchService: SearchService
  ) {
    super(http, 'api/knowledge', logging, zone);
  }

  getData() {
    if (!this.refrigerants || this.refrigerants.length == 0) this.getRefrigerantData()
  }

  getTypes(input: string): Observable<string[]> {
    return this.getTypeData(input)
  }


  getSystemType() {
    return ['LT', 'MT', 'AC', 'HP']
  }

  getElectronicModules(): string[] {
    return [
      '',
      'CM-RC-01',
      'CM-SW-01',
      'CM-RC-02',
      'SE-i1',
      'LFC',
      'VARIPACK',
      'CSV',
      'ECOSTAR-LHV5E7E',
      'VARIPACK2',
      'ECOLITE.A2L',
    ]
  }

  getTypeBySerialNumber(serialNumber: string): Observable<any> {
    return this.get('product/' + serialNumber + '/type')
  }


  loadRefrigerants() {
    if (this.refrigerants.length) {
      return this.refrigerants
    }

    this.getRefrigerantData()
    return []
  }

  private getRefrigerantData() {
    this.get("refrigerant").subscribe(d => {
      if (d) {
        this.refrigerants = d as []
        this.subject.next(this.refrigerants)
      }
    })
  }


  private getTypeData(input: string): Observable<string[]> {
    let request = new ProductSearchRequest([], [], [], input, 'en')
    return this.searchService.searchProducts(request, 0, 10).pipe(
      map(value => value.body?.entries?.content.map(c => c.id) ?? [])
    )
  }

}
