<h5 class="m-3">{{ title | translate }}</h5>

<div class="flex flex-col gap-2 pb-2">
  @for (row of data; track row; let last = $last) {
    <div class="flex flex-row gap-2 justify-between px-3">
      <div>
        <p>{{ row.key | translate }}</p>
      </div>
      <div>
        <p>{{ row.value }}</p>
      </div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  }
</div>
