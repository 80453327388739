<mat-toolbar class="flex flex-row gap-3">
  <mat-button-toggle-group [(ngModel)]="selectedTime" (ngModelChange)="filterAlarmsOnPreset()" class="dense-1">
    <mat-button-toggle value="1">{{'1 ' + ('day' | translate)}}</mat-button-toggle>
    <mat-button-toggle value="7">{{'7 ' + ('days' | translate)}}</mat-button-toggle>
    <mat-button-toggle value="28">{{'28 ' + ('days' | translate)}}</mat-button-toggle>
  </mat-button-toggle-group>
  <div class="flex-auto flex flex-row">
    <mat-form-field appearance="outline" class="dense-1 date-range" subscriptSizing="dynamic">
      <mat-label>MM/DD/YYYY – MM/DD/YYYY</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<app-alarm-info-list [data]="data" (page)="handlePaginatorEvent($event)"></app-alarm-info-list>
