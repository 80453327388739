import { Component, Input } from '@angular/core';
import { PipeConnection, Product, WorkingFluids } from "../model/product-api";
import { TableCardEntry, TableCardGroup } from "../../../component/card/model/card-api";

@Component({
  selector: 'app-product-working-fluids',
  templateUrl: './product-working-fluids.component.html',
  styleUrl: './product-working-fluids.component.scss'
})
export class ProductWorkingFluidsComponent {

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setInfo(product)
  }

  title = 'workingFluids'
  data: TableCardGroup[] = []

  setInfo(product: Product) {
    this.addGeneral(product.attributes.workingFluids)
    this.addCharges(product.attributes.workingFluids)
  }

  private addGeneral(fluids: WorkingFluids | null) {
    if (!fluids) return

    let entries: TableCardEntry[] = []
    this.addData('volume', fluids.fluidVolume, entries)
    this.addData('fluids', fluids.workingFluids.join(', ') || '', entries)
    this.addData('flowWorkingFluids', fluids.flowWorkingFluids["Fan 50Hz"], entries)

    if (!entries.length) return
    this.data.push(new TableCardGroup('', entries))
  }

  private addCharges(fluids: WorkingFluids | null) {
    if (!fluids) return

    let entries: TableCardEntry[] = []
    const charges = new Map(Object.entries(fluids.workingFluidCharge))
    charges.forEach((value, key) => {
      this.addData(key, value, entries)
    })

    if (!entries.length) return
    this.data.push(new TableCardGroup('fluidCharges', entries))
  }


  private addData(key: string, value: string | undefined, entries: TableCardEntry[]) {
    if (!value) return
    entries.push(new TableCardEntry(key, value))
  }
}
