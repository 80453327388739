import {Component, Input} from '@angular/core';
import {System} from "../model/system-api";
import {ImageService} from "../../image/model/image.service";
import {MatDialog} from "@angular/material/dialog";
import {ImageUploadDialogComponent} from "../../image/image-upload-dialog/image-upload-dialog.component";
import { HotToastService } from "@ngxpert/hot-toast";
import {finalize} from "rxjs/operators";
import {Subscription} from "rxjs";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import {Image} from "../../image/model/image-api";

@Component({
  selector: 'app-system-details-image',
  templateUrl: './system-details-image.component.html',
  styleUrls: ['./system-details-image.component.scss']
})
export class SystemDetailsImageComponent {

  @Input()
  set data(data: System | undefined) {
    this.system = data
    this.updateData()
  }

  @Input()
  canWrite: boolean = false

  system: System | undefined
  image: any
  reloading: boolean = false

  fileName = ''

  uploadProgress: number | undefined
  uploadSub: Subscription | undefined
  uploading: boolean = false

  constructor(
    public service: ImageService,
    public dialog: MatDialog,
    private toast: HotToastService
  ) {
  }

  showUploadDialog() {
    if (!this.system) return
    const dialogRef = this.dialog.open(ImageUploadDialogComponent, {
      data: this.system.id,
    })

    dialogRef.afterClosed().subscribe(result => {
      this.updateData()
    });
  }

  onFileSelected(event: any) {
    const itemId = this.system?.id
    if (!itemId) return

    const file: File = event.target.files[0]
    if (!file) return

    this.fileName = file.name
    this.uploading = true

    const formData = new FormData()
    formData.append("file", file, this.fileName)

    const upload$ = this.service.upload(itemId, formData).pipe(
      finalize(() => this.reset())
    )

    this.uploadSub = upload$.subscribe(
      {
        next: (evt) => this.handleUpdate(evt),
        error: (err) => this.handleFailed(err),
      })
  }

  private handleUpdate(event: HttpEvent<Image>) {
    if (event.type == HttpEventType.UploadProgress) {
      this.uploadProgress = Math.round(100 * (event.loaded / event.total!!))
    } else if (event.type == HttpEventType.Response) {
      this.handleCompleted(event.body!!)
    }
  }

  private handleCompleted(v: Image) {
    this.toast.success("Successfully uploaded file '" + this.fileName + "' ")
    this.updateData()
  }

  private handleFailed(e: any) {
    this.toast.error("Failed to upload file '" + this.fileName + "' ")
  }

  reset() {
    this.uploadProgress = undefined
    this.uploadSub = undefined
    this.uploading = false
  }

  private updateData() {
    if (!this.system) return
    this.service.findByItemId(this.system.id).subscribe(d => {
        if (!d || !this.system) return
        this.service.downloadImage(this.system?.id, d.id).subscribe(stream => {
          const reader = new FileReader();
          reader.onload = (e) => this.image = e.target?.result;
          reader.readAsDataURL(new Blob([stream]))
        })
      }
    )
  }
}
