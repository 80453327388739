import { Component, Input } from '@angular/core';
import { AlarmInfo } from "../model/alarm-api";

@Component({
  selector: 'app-alarm-hints',
  templateUrl: './alarm-hints.component.html',
  styleUrls: ['./alarm-hints.component.scss']
})
export class AlarmHintsComponent {
  @Input() data: AlarmInfo | undefined
}
