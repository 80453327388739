import { Component, Input } from '@angular/core';
import { ModuleService } from "../../../module/model/module.service";
import { PropertyService } from "../../../property/model/property.service";
import { PropertyText } from "../../../property/model/property-api";
import { ElectronicModule } from "../../../module/model/module-api";
import { TableCardEntry } from "../../../component/card/model/card-api";
import { OperationalReportService } from "../model/operational-report.service";
import { EquipmentInfo } from "../model/operational-report-api";

@Component({
  selector: 'app-operational-report-equipment-info',
  templateUrl: './operational-report-equipment-info.component.html',
  styleUrl: './operational-report-equipment-info.component.scss'
})
export class OperationalReportEquipmentInfoComponent {

  @Input()
  set reportInfoData(data: EquipmentInfo | undefined) {
    this.info = data
    this.process()
  }

  info: EquipmentInfo | undefined
  firmwareVersion: string = ''
  title = 'CompInformation'
  data: TableCardEntry[] = []

  constructor(
    private service: OperationalReportService,
    private moduleService: ModuleService,
    private propertyService: PropertyService
  ) {
    service.changeEvent.subscribe(_ => {
      if (!this.data.length) this.process()
    })
  }

  process() {
    if (!this.info) return
    this.data = []
    const equipment = this.info.equipment
    this.moduleService.findByEquipmentId(equipment?.id, 0).subscribe(p => {
      this.handleModule(p.content)
    })
    this.propertyService.getLastThingTextProperties(equipment.thingId, ['BDN.FirmwareVersion']).subscribe(properties => {
      let values: Map<string, PropertyText> = new Map(Object.entries(properties))
      this.handleFirmware(values.get('BDN.FirmwareVersion'))
    })

    let entries: { [key: string]: string } = {
      "name": equipment.name,
      "type": equipment.productId,
      "serialNumber": equipment.serialNumber,
      "refrigerant": equipment.refrigerant,
      "system.title": this.info.system.name,
      "site.Location": this.info.site.name,
    }
    for (const key in entries) {
      this.pushData(key, entries[key])
    }
  }

  private handleModule(content: ElectronicModule[]) {
    if (!content || !content.length) return
    let eModule = content[0]

    let entries: { [key: string]: string } = {
      "SNElectronicModule": eModule?.serialNumber,
      "electronicModule": eModule?.type,
    }
    for (const key in entries) {
      this.pushData(key, entries[key])
    }
  }


  private handleFirmware(property: PropertyText | undefined) {
    let firmwareVersion = property?.value
    this.pushData("Firmware Version", firmwareVersion)
  }

  pushData(key: string, value: string | undefined) {
    this.data.push(
      new TableCardEntry(key, value || ""),
    )
  }
}
