import { Component, Input } from '@angular/core';
import { System } from "../model/system-api";
import { NoteService } from "../../note/model/note.service";
import { Note, NoteChangeRequest } from "../../note/model/note-api";

@Component({
  selector: 'app-system-details-notes',
  templateUrl: './system-details-notes.component.html',
  styleUrls: ['./system-details-notes.component.scss']
})
export class SystemDetailsNotesComponent {

  @Input()
  set data(data: System | undefined) {
    this.system = data
    if (this.system) {
      this.service.findByItemId(this.system.id).subscribe({
          next: (n) => {
            if(!n) {
              this.note = undefined
              this.text = ''
              return
            }
            this.note = n
            this.text = n.text
          },
          error: (e) => {
            this.note = undefined
            this.text = ''
          }
        }
      )
    }
  }

  @Input()
  canWrite: boolean = false

  system: System | undefined
  note: Note | undefined
  text: string = ''
  editMode: boolean = false

  constructor(public service: NoteService) {

  }

  ngOnInit() {

  }


  save() {
    if (!this.system) return
    let request = new NoteChangeRequest(this.text)
    if (this.note) {
      this.service.update(this.system.id, this.note.id, request).subscribe()
    } else {
      this.service.create(this.system.id, request).subscribe()
    }
    this.editMode = false
  }
}
