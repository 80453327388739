import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFilterComponent } from "./generic-filter/generic-filter.component";
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { GenericFilterMenuComponent } from './generic-filter-menu/generic-filter-menu.component';


@NgModule({
  declarations: [GenericFilterComponent, GenericFilterMenuComponent],
  exports: [GenericFilterComponent, GenericFilterMenuComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule
  ],

})
export class FilterModule {
}
