import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SparePartCategory } from "../../product/model/product-api";
import { SparePartsCategoryService } from "../model/spare-parts-category.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { distinctUntilChanged } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: 'app-spare-parts-category',
  templateUrl: './spare-parts-category.component.html',
  styleUrls: ['./spare-parts-category.component.scss']
})
export class SparePartsCategoryComponent {

  flexRow: boolean = true
  flexWrap: boolean = false


  @Input()
  set category(data: SparePartCategory | undefined) {
    this.service.selectCategory(data)
  }

  @Output() backEvent = new EventEmitter<Boolean>()

  constructor(
    public service: SparePartsCategoryService,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.XLarge, Breakpoints.Small])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  ngOnInit() {
    this.breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.flexRow = true
      this.flexWrap = false
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.flexRow = false
      this.flexWrap = false
    } else {
      this.flexRow = true
      this.flexWrap = true
    }
  }

  backToCategories() {
    this.backEvent.emit(true)
  }
}
