import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CartEntry, ShareCartRequest } from "../model/commerce-api";
import { CommerceService } from "../model/commerce.service";
import { FormBuilder, Validators } from "@angular/forms";
import { HotToastService } from "@ngxpert/hot-toast";

@Component({
  selector: 'app-cart-share-dialog',
  templateUrl: './cart-share-dialog.component.html',
  styleUrl: './cart-share-dialog.component.scss'
})
export class CartShareDialogComponent {

  reloading: boolean = false
  link: string | undefined

  fg = this.fb.group({
    name: ['', Validators.required],
    description: [''],
  })

  constructor(
    public dialogRef: MatDialogRef<CartShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CartEntry,
    private service: CommerceService,
    private toast: HotToastService,
    private fb: FormBuilder
  ) {
  }

  shareCart() {
    if (!this.fg.valid) return
    if (this.reloading) return
    this.reloading = true
    let value = this.fg.value
    let request = new ShareCartRequest(value.name ?? "", value.description ?? "")
    this.service.shareCart(request).subscribe({
      next: link => this.showLink(link),
      error: err => this.handleError(err)
    })
  }

  private showLink(link: string) {
    this.link = (link) ? link : ""
    this.reloading = false
  }

  private handleError(err: any) {
    this.toast.error("Failed to share cart")
    this.reloading = false
    this.dialogRef.close()
  }
}
