<mat-card *ngIf="!initialized || children.length > 0">
  <mat-card-header class="flex items-center content-center justify-between">
    <h5> {{'site.SubSites' | translate}} </h5>
    <div class="flex-auto"></div>
  </mat-card-header>
  <div class="flex flex-col p-2 gap-1">
    <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
    <ng-container *ngFor="let site of children">
      <app-site-details-site-list-entry [data]="site" routerLink="/site/{{site.site.id}}"></app-site-details-site-list-entry>
    </ng-container>
  </div>
  <mat-paginator *ngIf="children.length > 5"
                 [length]="totalSize"
                 [pageSize]="pageSize"
                 [pageIndex]="pageIndex"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 showFirstLastButtons
                 (page)="handlePaginatorEvent($event)">
  </mat-paginator>
</mat-card>
