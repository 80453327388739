<h2 mat-dialog-title>{{'registerCompany' | translate}}</h2>
<mat-dialog-content>
<mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
  <mat-stepper linear #stepper>
    <mat-step [stepControl]="companyForm">
      <ng-template matStepLabel>{{'demoCompanyTitle' | translate}}</ng-template>
      <app-company-change-form [readonly]=false [form]="companyForm"></app-company-change-form>
      <div class="flex gap-2 content-end">
        <button mat-flat-button color="primary" [disabled]="!companyForm.valid" matStepperNext>
          {{'next' | translate}}
        </button>
        <button mat-flat-button mat-dialog-close>
          {{'cancel' | translate}}
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="profileForm">
      <ng-template matStepLabel>{{'demoCompanyTitle' | translate}}</ng-template>
      <app-company-profile-form [readonly]=false [form]="profileForm"></app-company-profile-form>
      <div class="flex gap-2 content-end">
        <button mat-flat-button color="primary" [disabled]="!profileForm.valid" matStepperNext>
          {{'next' | translate}}
        </button>
        <button mat-flat-button mat-dialog-close>
          {{'cancel' | translate}}
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="contactForm">
      <ng-template matStepLabel>{{'contactPersonTitle' | translate}}</ng-template>
      <app-company-contact-form [readonly]=false [form]="contactForm"></app-company-contact-form>
      <div class="flex gap-2 content-end">
        <button mat-flat-button color="primary" [disabled]="!contactForm.valid" matStepperNext>
          {{'next' | translate}}
        </button>
        <button mat-flat-button matStepperPrevious>
          {{'back' | translate}}
        </button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{'sucessStepTitle' | translate}}</ng-template>
      <div class="my-3" style="font-size: 12px">{{'bdnWelcome' | translate}}</div>
      <div class="flex gap-2 content-end">
        <button mat-flat-button color="primary" (click)="submit()" [disabled]="reloading">
          {{'action.Finish' | translate}}
        </button>
        <button mat-flat-button matStepperPrevious>
          {{'back' | translate}}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>


