import { Component, Input } from '@angular/core';
import { ProductConfigIdInfo } from "../model/product-config-api";
import { COMMERCE_ADMIN, COMMERCE_READ } from "../../../auth/roles";
import { AuthService } from "../../../auth/auth.service";
import { WishlistAddEntry, WishlistAddEntryDialogComponent } from "../../wishlist/wishlist-add-entry-dialog/wishlist-add-entry-dialog.component";
import { WishlistService } from "../../wishlist/model/wishlist.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-product-config-details-header',
  templateUrl: './product-config-details-header.component.html',
  styleUrl: './product-config-details-header.component.scss'
})
export class ProductConfigDetailsHeaderComponent {
  @Input() pid!: ProductConfigIdInfo

  constructor(    private authService: AuthService,
                  private wishlistService: WishlistService,
                  private dialog: MatDialog,) {
  }



  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ) || this.authService.hasRole(COMMERCE_ADMIN)
  }

  // addToWishList(entry: ProductConfigIdInfo) {
  //   if (entry) {
  //     const ref = this.dialog.open(WishlistAddEntryDialogComponent, {
  //       width: '50%',
  //       data: {
  //         entry: new WishlistAddEntry(entry.id, entry.type),
  //         entries: this.entries.map(e => new WishlistAddEntry(e.id, e.type))
  //       }
  //     });
  //     ref.afterClosed().subscribe(_ => this.updateWishlistActive())
  //   }
  // }
  //
  // private updateWishlistActive() {
  //   const references = this.entries.map(entry => entry.id)
  //   if (references.length) this.wishlistService.checkWishlist(references).subscribe(data => {
  //     this.wishlistActive = data
  //   })
  // }
  //
  // isWishlistActive(entry: ProductConfigIdInfo) {
  //   if (!this.wishlistActive) return false
  //   const active = this.wishlistActive.find(e => parseInt(e.reference) == parseInt(entry.id))
  //   return active != undefined
  // }
}
