import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Page } from "../../utils/page";
import { SearchRequest, SearchResultEntry } from "./search-api";
import { PageEvent } from "@angular/material/paginator";
import { FilterService } from "../../filter/model/filter.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {


  query: string = ""
  refrigerantFilter: string = ""
  systemTypeFilter: string = ""
  ownerFilter: string = ""

  availableTypes: string[] = ['SITE', 'SYSTEM', 'EQUIPMENT']
  typeFilter: string[] = []

  reloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  pageIndex: number = 0
  pageSize: number = 20
  totalSize: number = -1

  result: SearchResultEntry[] = []
  private initialized: boolean = false

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private filterService: FilterService
  ) {
    super(http, 'api/gateway/search', logging, zone)

    this.filterService.activeFilter.subscribe(_ => this.runSearch(this.pageIndex, this.pageSize))
    this.initialized = true
  }

  private search(request: SearchRequest, page: number, size: number): Observable<Page<SearchResultEntry>> {
    return this.postPaged('', request, page, size)
  }


  private runSearch(page: number, size: number) {
    if (!this.initialized) return
    if (this.reloading.value) return console.error("Ignore change due to ongoing request")
    this.reloading.next(true)
    let filter = this.filterService.activeFilter.value
    let request = new SearchRequest(
      this.query,
      filter.onlineFilter,
      filter.alarmFilter,
      filter.lastAlarms,
      this.refrigerantFilter,
      this.systemTypeFilter,
      this.ownerFilter,
      this.typeFilter
    )
    this.search(request, page, size).subscribe(d => this.handleData(d))
  }

  private handleData(d: Page<SearchResultEntry>) {
    this.result = d.content
    this.pageIndex = d.pageable.number
    this.pageSize = d.pageable.size
    this.totalSize = d.totalSize
    this.reloading.next(false)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.runSearch(event.pageIndex, event.pageSize)
  }

  setFilter(query: string, refrigerant: string, systemType: string, owner: string, types: string[]) {
    this.query = query
    this.refrigerantFilter = refrigerant
    this.systemTypeFilter = systemType
    this.ownerFilter = owner
    this.typeFilter = types
    this.runSearch(this.pageIndex, this.pageSize)
  }
}
