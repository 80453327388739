import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {
  DocumentBoardContentComponent
} from "../document-board-content/document-board-content.component";
import {environment} from "../../../../environments/environment";
import {Meta} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {HomeService} from "../../../home/model/home.service";
import {
  MainHeaderActionButton,
  MainHeaderActionLinkExternal
} from "../../../home/model/main-header-action";

@Component({
  selector: 'app-document-board',
  templateUrl: './document-board.component.html',
  styleUrls: ['./document-board.component.scss']
})
export class DocumentBoardComponent {

  @ViewChild(DocumentBoardContentComponent) content: DocumentBoardContentComponent | undefined
  protected readonly environment = environment;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private home: HomeService,
    private meta: Meta
  ) {
  }

  ngOnInit() {
    this.meta.addTag({
      name: "description",
      content: "All important documentation on BITZER products: reciprocating compressors / screw compressors / scroll compressors / condensing units / heat exchangers and pressure vessels: With filter options by product category✓ Type of documentation✓ Or full-text search✓ ➠ Simply download as PDF "
    })
    this.home.searchVisible = true
    this.home.customHeaderActions = [
      new MainHeaderActionLinkExternal('article', 'refrigreport', 'https://www.bitzer.de/shared_media/html/a-500-501/', '_blank'),
      new MainHeaderActionButton('library_books', 'QUICK LINKS', evt => this.openQuickLinks(), undefined)
    ]
  }

  ngOnDestroy() {
    this.home.customHeaderActions = []
    this.home.searchVisible = false
  }

  openQuickLinks() {
    let lang = this.translate.currentLang
    if (lang === "de") {
      window.open('https://www.bitzer.de/shared_media/html/matrix/de-DE/matrix.html?P=/html/matrix/de-DE/&N=matrix.html', '_blank')
    } else {
      window.open('https://www.bitzer.de/shared_media/html/matrix/en-GB/matrix.html?P=/html/matrix/en-GB/&N=matrix.html', '_blank')
    }
  }

  // protected handleRequestChanged(request: DocumentSearchRequest) {
  //   let query = (request.fullTextSearch.length > 0) ? request.fullTextSearch : undefined
  //   const navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       q: query,
  //       t: (request.documentTypes.length) ? request.documentTypes : undefined,
  //       ca: (request.selectedApplicationId.length > 0) ? request.selectedApplicationId : undefined,
  //       ct: (request.selectedTechnologyId.length > 0) ? request.selectedTechnologyId : undefined,
  //       p: (request.productIds.length) ? request.productIds : undefined,
  //       country: (request.country) ? request.country : undefined
  //     },
  //     relativeTo: this.route
  //   }
  //   this.home.query = request.fullTextSearch
  // }
}
