<div class="flex flex-wrap justify-center gap-2">
  <ng-container *ngIf="!alarms.length">
    <div matTooltip="{{'products.alarms.None' | translate}}">
      <mat-icon class="done">done</mat-icon>
    </div>
  </ng-container>

  <ng-container *ngIf="faultAlarms">
    <div matTooltip="{{'alarmState_fault' | translate}}" class="flex flex-col items-center content-center">
      <mat-icon class="fault">warning</mat-icon>
      <div><span>{{ faultAlarms }}</span></div>
    </div>
  </ng-container>

  <ng-container *ngIf="criticalAlarms">
    <div matTooltip="{{'products.alarms.critical' | translate}}" class="flex flex-col items-center content-center">
      <mat-icon class="critical">warning</mat-icon>
      <div><span>{{ criticalAlarms }}</span></div>
    </div>
  </ng-container>

  <ng-container *ngIf="warningAlarms">
    <div matTooltip="{{'products.alarms.warning' | translate}}" class="flex flex-col items-center content-center">
      <mat-icon class="warn">warning</mat-icon>
      <div><span>{{ warningAlarms }}</span></div>
    </div>
  </ng-container>
</div>
