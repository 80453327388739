@if (mode === 'button') {
  <button mat-stroked-button (click)="startDownload()" color="{{color}}" [disabled]="disabled">
    @if (downloading) {
      <mat-spinner class="float-left right-2" matSuffix [diameter]="18" color="accent"></mat-spinner>
    } @else {
      <mat-icon>{{ icon }}</mat-icon>
    }
    {{ text | translate }}
  </button>
} @else {
  <button mat-mini-fab [matTooltip]="text | translate" [color]="color" (click)="startDownload()" [disabled]="disabled">
    @if (downloading) {
      <mat-icon>
        <mat-spinner diameter="18" mode="indeterminate"></mat-spinner>
      </mat-icon>
    } @else {
      <mat-icon>{{ icon }}</mat-icon>
    }
  </button>
}
