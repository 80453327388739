<mat-toolbar>
  <div class="flex gap-2 items-center w-full">
    <div>{{ 'pidComparison.results.title' | translate }}</div>

    <div class="flex items-center gap-2 ml-auto">
      @if (header.length == 0) {
        <button mat-stroked-button routerLink="../../product">
          <mat-icon>rule</mat-icon>
          {{ 'pidComparison.select.button' | translate }}
        </button>
      }

      <button mat-stroked-button (click)="toggleComparisonValues()" [disabled]="header.length < 2">
        <mat-icon>difference</mat-icon>
        @if (showOnlyComparisonDifferences) {
          {{ 'pidComparison.results.showAllValues' | translate }}
        } @else {
          {{ 'pidComparison.results.showDifferenceOnly' | translate }}
        }
      </button>

      <button mat-stroked-button (click)="clearComparison()" [disabled]="header.length < 2">
        <mat-icon>delete</mat-icon>
        {{ 'pidComparison.results.clear' | translate }}
      </button>
    </div>
  </div>
</mat-toolbar>

@if (loading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (header.length > 0) {
  <mat-card class="p-2 m-2">
    <table>
      <tr>
        <th></th>
        @for (el of header; track el) {
          <th>
            <div class="flex flex-1	pb-3">
              <div class="flex flex-col">
                <div class="mb-1 font-medium">{{ el.type }}</div>
                {{ el.id | stripLeadingZeros }}
              </div>

              <button class="ml-4" color="accent" mat-icon-button (click)="remove(el.id)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </th>
        }
      </tr>

      @for (row of rows; track row; let last = $last) {
        <tr *ngIf="(showOnlyComparisonDifferences && !row.similarValues) || !showOnlyComparisonDifferences">
          <td>
            <div class="font-medium">{{ row.defaultName }}</div>
          </td>

          @for (o of row.values; track o) {
            <td>
              <p class="column-item flex-1 p-3" [ngClass]="{'text-green-700 font-medium': !row.similarValues}">
                @if (o.defaultName.length <= 0) {
                  {{ o.key }}
                } @else {
                  {{ o.defaultName }}
                }
              </p>
            </td>
          }
        </tr>
      }
    </table>
  </mat-card>
} @else {
  <mat-card class="mat-mdc-card mdc-card m-2">
    <h3 class="p-3">{{ 'pidComparison.results.selectPidsForComparison' | translate }}</h3>
  </mat-card>
}
