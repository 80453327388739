import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { PUBLIC_ACCESS } from "./roles";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const requiredRoles = route.data.roles ?? []
    let token = (await this.keycloak.getToken())
    let loggedIn = !!(this.keycloak.isLoggedIn() || (token))

    let publicAccess = requiredRoles.some((role: string) => role === PUBLIC_ACCESS)
    if (publicAccess && !loggedIn) return true

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login()
    }

    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true
    }

    return requiredRoles.some((role) => this.roles.includes(role))
  }


}
