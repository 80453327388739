import {Component, computed, Input, signal, ViewChild} from '@angular/core';
import {CategorySelectComponent} from "../../category/category-select/category-select.component";
import {TranslateModule} from "@ngx-translate/core";
import {PageEvent} from "@angular/material/paginator";
import {AsyncPipe, CommonModule} from "@angular/common";
import {MaterialModule} from "../../../material/material.module";
import {CategoryModule} from "../../category/category.module";
import {
  ProductBoardContentResultListComponent
} from "../product-board-content-result-list/product-board-content-result-list.component";
import {FilterGroupComponent} from "../../../component/filter/filter-group/filter-group.component";
import {ProductFacadeService} from "../model/product-facade.service";
import {
  FilterGroupListComponent
} from "../../../component/filter/filter-group-list/filter-group-list.component";
import {ProductSearchResponse} from "../../search/model/search-api";
import {
  CategoryFilterListComponent,
  ContentCategoryType
} from "../../../component/category/category-filter-list/category-filter-list.component";

let DEFAULT_PAGE_SIZE = 25;

@Component({
  selector: 'app-product-board-content',
  templateUrl: './product-board-content.component.html',
  styleUrl: './product-board-content.component.scss',
  standalone: true,
  providers: [ProductFacadeService],
  imports: [CommonModule, MaterialModule, TranslateModule, CategoryModule, ProductBoardContentResultListComponent, FilterGroupComponent, FilterGroupListComponent, AsyncPipe, CategoryFilterListComponent]
})
export class ProductBoardContentComponent {

  @Input() categorySelectorVisible: boolean = true;
  @Input() loadOnStartup: boolean = true;

  searching = computed(() => this.productFacadeService.searching());
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageIndex: number = 0;
  totalSize: number = -1;
  response = signal<ProductSearchResponse | null>(null);
  @ViewChild(CategorySelectComponent) categorySelect: CategorySelectComponent | undefined;
  protected readonly contentType = ContentCategoryType.PRODUCTS;

  constructor(private productFacadeService: ProductFacadeService
  ) {

  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.load();
  }

  handleData(response: any | null) {
    if (!response) return false;
    this.response.set(response);
    this.pageIndex = response?.entries?.pageable.number || 0;
    this.totalSize = response.entries.totalSize;
    return true;
  }

  private load(request = {}) {
    if (this.searching()) return
    
    this.productFacadeService.updateProductResponse(this.pageIndex, this.pageSize, request)
    .subscribe()
  }
}
