<h2 mat-dialog-title class="text-center"> {{'addEquipment' | translate}} </h2>

@if (!isSystemView) {
  @for (system of operationalGraphService.systems; track system) {
    <app-operational-graph-equipment-list [systemData]="system"
                                          [selectedEquipmentData]="selectedEquipments"
                                          (equipmentSelect)="handleSelectedEquipment($event)">
    </app-operational-graph-equipment-list>
  }

  <div class="flex justify-center my-2">
    <button mat-stroked-button
            (click)="isSystemView=true">
      {{ 'addSystem' | translate }}
    </button>
  </div>

  <mat-dialog-actions align="end">
    <button mat-stroked-button
            (click)="select()">
      {{ 'ok' | translate }}
    </button>
    <button mat-stroked-button
            (click)="cancel()">
      {{ 'cancel' | translate }}
    </button>
  </mat-dialog-actions>
} @else {
  <app-operational-graph-add-system (selectSystems)="handleSelectedSystems($event)"
                                    [siteData]="siteId">
  </app-operational-graph-add-system>
}
