import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseService } from "../../utils/base-service";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { ParameterView } from "./parameter-api";

@Injectable({
  providedIn: 'root'
})
export class ParameterService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
) {
    super(http, 'api/gateway/bestcore/parameter', logging, zone)
  }

  getTranslatedParameterViews(thingId: number, lang: string): Observable<ParameterView[]> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.get('thing/' + thingId + '/views', params)
  }
}
