<div class="title m-3">{{ 'compressorStartKPIs' | translate }}</div>

<diV class="flex justify-between gap-3 m-3">
  <div> {{ 'totalNoCompStart' | translate }}: {{ kpi?.totalNumberOfStarts }}
    <span>starts</span>
  </div>

  <div> {{ 'meanNoStartsPerHour' | translate }}: {{ kpi?.meanNumberOfStartsPerInterval | number: '1.0-2' }}
    <span>{{ 'start' | translate }}/{{ 'hour' | translate }}</span>
  </div>

  <div> {{ 'maxNoStartsPerHour' | translate }}: {{ kpi?.maxNumberOfStartsPerInterval }}
    <span>{{ 'start' | translate }}/{{ 'hour' | translate }}</span>
  </div>
</diV>
