import { Component, Input } from '@angular/core';
import { Equipment } from "../model/equipment-api";
import { MatDialog } from "@angular/material/dialog";
import { ActivationDialogComponent } from "../../activation/activation-dialog/activation-dialog.component";
import { ProcessResponse } from "../../process/model/process-api";
import { ActivationConfigDialogComponent } from "../../activation/activation-config-dialog/activation-config-dialog.component";

@Component({
  selector: 'app-equipment-activation-menu',
  templateUrl: './equipment-activation-menu.component.html',
  styleUrls: ['./equipment-activation-menu.component.scss']
})
export class EquipmentActivationMenuComponent {
  @Input() data: Equipment | undefined


  constructor(
    private dialog: MatDialog
  ) { }

  activate() {
    if (!this.data) return
    const dialogRef = this.dialog.open(ActivationDialogComponent, {
      width: '50%',
      data: this.data
    })

    dialogRef.afterClosed().subscribe((request: ProcessResponse | null) => {
      if (!request) return
      window.location.reload()
    })
  }

  onOpenActivationConfig() {
    if (!this.data) return
    const dialogRef = this.dialog.open(ActivationConfigDialogComponent, {
      width: '50%',
      data: this.data
    })
  }
}
