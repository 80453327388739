@if (loading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  @if (systems.length) {
    <div class="mx-3">
      {{ 'selectSystem' | translate }}:
    </div>
    @for (s of systems; track s.system.id) {
      <mat-dialog-content class="dialog-container">
        <mat-checkbox (change)="onSystemSelect(s.system)"
                      [checked]="checkStatus(s.system)">
          {{ s.system.name }}
        </mat-checkbox>
      </mat-dialog-content>
    }
  } @else {
    <div class="flex justify-center m-3">
      {{ 'NoDataMessage' | translate }}
    </div>
  }

  <mat-paginator *ngIf="totalSize > 5"
                 [length]="totalSize"
                 [pageSize]="pageSize"
                 [pageIndex]="pageIndex"
                 showFirstLastButtons
                 [pageSizeOptions]="[5,10,50,100]"
                 (page)="handlePaginatorEvent($event)">
  </mat-paginator>
}

<mat-dialog-actions align="end">
  <button mat-stroked-button
          [disabled]="!selectedSystems.length"
          (click)="select()">
    {{ 'ok' | translate }}
  </button>
  <button mat-stroked-button
          (click)="cancel()">
    {{ 'cancel' | translate }}
  </button>
</mat-dialog-actions>
