<h5 class="m-3">{{ 'compressorAlarms' | translate }}</h5>

<div class="flex gap-1 justify-between">
  <app-last-alarms [alarmData]="alarmReport?.last" class="alarm-table"></app-last-alarms>

  <app-alarm-graph [graphData]="alarmReport?.graph" class="alarm-graph"></app-alarm-graph>
</div>

<app-alarm-stats [statsData]="alarmReport?.stats"></app-alarm-stats>

<app-alarm-distribution [distributionData]="alarmReport?.distribution"></app-alarm-distribution>
