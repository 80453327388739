<app-header-toolbar *ngIf="!productDetailsRoute" [title]="product?.id">
  <app-header-toolbar-icon>

  </app-header-toolbar-icon>
  <app-header-toolbar-content>

  </app-header-toolbar-content>
  <app-header-toolbar-actions>

  </app-header-toolbar-actions>
</app-header-toolbar>

@if (reloading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  <app-product-config-select></app-product-config-select>
}



