import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category, CategoryEntry } from "../model/category-api";
import { CategoryService } from "../model/category.service";
import { RelatedCategoryIdEntry } from "../../document/model/document-api";

@Component({
  selector: 'app-category-select-tree',
  templateUrl: './category-select-tree.component.html',
  styleUrl: './category-select-tree.component.scss'
})
export class CategorySelectTreeComponent {
  @Input() title = ""

  @Input() set root(data: Category | undefined) {
    if (this.main == data) return
    // console.log("[" + this.title + "] : set main category to " + data?.id + "|" + data?.title)
    this.main = data
    if (data && this.selectedCategories.length == 0) this.loadCategory(data.id)
  }

  @Input() set country(data: string | undefined) {
    this._country = data
    this.refreshCategory()
  }

  @Input() set selected(data: Category | undefined) {
    // console.log("[" + this.title + "] : set selected category to " + data?.id + "|" + data?.title)
    let index = this.selectedCategories.findIndex(c => c.id == data?.id)
    if (index < 0 && data) {
      this.selectCategory(data)
    }
  }

  setRelatedCategoryEntries(data: RelatedCategoryIdEntry[]) {
    this.relatedCategories = new Map(data.map(e => [e.categoryId, e.amount]))
    this.updateCategoryEntries()
  }

  main: Category | undefined
  categories: Category[] = []
  categoryEntries: CategoryEntry[] = []
  selectedCategories: Category[] = []
  reloading: boolean = false

  protected _country: string | undefined

  private relatedCategories: Map<number, number> = new Map()

  @Output() selectionChanged = new EventEmitter<CategoryTreeSelectionChange>()

  constructor(
    private service: CategoryService
  ) {
  }

  protected selectCategory(category: Category) {
    this.loadCategory(category.id)
  }

  protected unselectCategory(category: Category) {
    let index = this.selectedCategories.indexOf(category)
    if (index < 0) return
    let elementsToRemove = this.selectedCategories.length - index
    this.selectedCategories.splice(index, elementsToRemove)
    // console.log("[" + this.title + "] : unselect category " + category?.id + "|" + category?.title + " at position " + index + " with " + this.selectedCategories.length + " remaining categories")
    this.refreshCategory()
  }

  reset() {
    this.selectedCategories = []
    this.refreshCategory()
  }

  updateSelectedCategories() {
    this.selectedCategories.forEach(c => this.service.getCategory(c.id).subscribe(d => this.updateSelectedCategory(d)))
  }


  private loadCategory(categoryId: string) {
    if (!categoryId) return
    this.reloading = true

    // console.log("[" + this.title + "] : load category " + categoryId)
    this.service.getChildren(categoryId, this._country ?? '').subscribe(d => this.handleCategoryData(d))
    this.service.getPath(categoryId, this._country ?? '').subscribe(d => {
      this.selectedCategories = d
      this.notifySelectionChanged()
    })
  }

  private notifySelectionChanged() {
    let selectedCategory = this.selectedCategories.length > 0 ? this.selectedCategories[this.selectedCategories.length - 1] : null
    this.selectionChanged.emit(new CategoryTreeSelectionChange(selectedCategory))
  }

  private handleCategoryData(categories: Category[]) {
    this.categories = categories
    this.updateCategoryEntries()
    this.reloading = false
  }

  private updateCategoryEntries() {
    this.categoryEntries = this.categories.map(c => {
        let amount = this.relatedCategories.get(+c.id) ?? 0
        return new CategoryEntry(c, amount)
      }
    )
  }

  private refreshCategory() {
    let selectedCategoryId = this.selectedCategories.at(-1)?.id ?? this.main?.id ?? undefined
    // console.log("[" + this.title + "] : refresh category to " + selectedCategoryId)
    if (selectedCategoryId) {
      this.loadCategory(selectedCategoryId)
    } else if (this.main) {
      this.loadCategory(this.main.id)
    }
  }

  private updateSelectedCategory(d: Category) {
    let index = this.selectedCategories.findIndex(c => c.id === d.id)
    if (index < 0) return
    this.selectedCategories[index] = d
  }

  updateRequest(selectedCategoryId: string, country: string) {
    // console.log("[" + this.title + "] : update selected category to " + selectedCategoryId)
    this.country = country
    let current = (this.selectedCategories.length > 0) ? this.selectedCategories[this.selectedCategories.length - 1] : undefined
    let changed = selectedCategoryId !== current?.id
    if (!changed) return
    if (selectedCategoryId.length > 0) {
      this.loadCategory(selectedCategoryId)
    } else if (this.main) {
      this.loadCategory(this.main.id)
    }
  }
}

export class CategoryTreeSelectionChange {
  constructor(
    public selectedCategory: Category | null
  ) {
  }
}
