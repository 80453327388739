import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {AuthService} from "../../../auth/auth.service";
import {CommerceCartService} from "../../../commerce/model/commerce-cart.service";
import {WishlistService} from "../../wishlist/model/wishlist.service";
import {WishlistEntry} from "../../wishlist/model/wishlist-api";
import {PriceRequestEntry, PriceResponse} from "../../../commerce/model/commerce-api";
import {COMMERCE_READ} from "../../../auth/roles";
import {SparePartSearchResponse, SparePartSearchResultEntry} from "../../search/model/search-api";

@Component({
  selector: 'app-spare-parts-board-content-result-list',
  templateUrl: './spare-parts-board-content-result-list.component.html',
  styleUrl: './spare-parts-board-content-result-list.component.scss'
})
export class SparePartsBoardContentResultListComponent {

  @Input() selected: SparePartSearchResultEntry | undefined
  @Output() selectionChanged: EventEmitter<SparePartSearchResultEntry> = new EventEmitter<SparePartSearchResultEntry>()
  @Output() page = new EventEmitter<PageEvent>()
  entries: SparePartBoardResultListEntry[] = []
  pageSize: number = 25
  pageIndex: number = 0
  totalSize: number = -1
  wishlistActive: WishlistEntry[] = []

  constructor(
    private commerceService: CommerceCartService,
    private authService: AuthService,
    private wishlistService: WishlistService
  ) {
  }

  @Input()
  set response(response: SparePartSearchResponse | null) {
    this.entries = response ? response.entries.content.map(e =>
      new SparePartBoardResultListEntry(e.id, e.picture, e.description, undefined)
    ) : []
    this.pageSize = response ? response.entries.pageable.size : 25
    this.pageIndex = response ? response.entries.pageable.number : 0
    this.totalSize = response ? response.entries.totalSize : 0
    this.updatePrice()
    this.updateWishlistActive()
  }

  handlePaginatorEvent(event: PageEvent) {
    this.page.emit(event)
  }

  selectSparePart(entry: SparePartSearchResultEntry) {
    this.selectionChanged.emit(entry)
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ)
  }

  getEntryActive(entry: SparePartBoardResultListEntry) {
    return this.wishlistActive.find(e => parseInt(e.reference) == parseInt(entry.id))
  }

  private updatePrice() {
    if (!this.hasCommerceReadPermissions()) return
    let entries = this.entries.map(e => new PriceRequestEntry(e.id, 1))
    this.commerceService.getPrices(entries).subscribe({
      next: value => this.handlePriceInfo(value)
    })
  }

  private handlePriceInfo(response: PriceResponse) {
    response.entries.forEach(p => {
      let s = this.entries.find(s => +s.id == +p.code)
      if (s) s.customerPrice = String(p.price)
    })
  }

  private updateWishlistActive() {
    const references = this.entries.map(entry => entry.id.padStart(18, '0'))
    if (references.length) {
      this.wishlistService.updateWishlistActive(references).subscribe(data => {
        this.wishlistActive = data
      })
    }
  }
}


export class SparePartBoardResultListEntry {
  constructor(
    public id: string,
    public picture: string,
    public description: string,
    public customerPrice: string | undefined
  ) {
  }
}
