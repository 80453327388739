import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../../../utils/page";
import { Product, ProductInfo } from "./product-api";

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/knowledge/product', logging, zone)
    this.retryCount = 1
  }

  getProduct(productId: string): Observable<Product> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get('', params)
  }

  getProductForEquipment(productId: string, equipmentId: number): Observable<Product> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get('id/' + equipmentId, params)
  }

  getCompositeAttributes(productId: string): Observable<Map<string, string[]>> {
    let params = new HttpParams()
    params = params.append('productId', productId)
    return this.get('composite', params)
  }

  findBySparePart(sparePartId: string): Observable<ProductInfo[]> {
    return this.get('find/by/sparepart/' + sparePartId)
  }

  findByCategoryIds(categoryIds: number[], page: number, size: number): Observable<Page<Product>> {
    return this.postPaged('find/by/category', categoryIds, page, size)
  }
}
