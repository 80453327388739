<mat-card>
  <div class="flex flex-row flex-wrap justify-between p-2 gap-2">
    @for (s of entries; track s) {
      <app-list-card-entry
        class="series-list-entry"
        (click)="showDetails(s)"
        imageUrl="https://www.bitzer.de/shared_media/product_images/images_f_p/{{s.picture}}"
        [title]="s.title"
      ></app-list-card-entry>
    }
  </div>
</mat-card>

<mat-paginator (page)="handlePaginatorEvent($event)"
               *ngIf="totalSize > 0 && totalSize > pageSize"
               [length]="totalSize"
               [pageIndex]="pageIndex"
               [pageSizeOptions]="[5, 10, 25, 100]"
               [pageSize]="pageSize" class="dense-1 pb-3">
</mat-paginator>
