<app-search-result-entry [name]="equipment?.name" [eventState]="eventState" [alarmState]="alarmState" [link]="getRouterLink()" *ngIf="entry">
  <app-equipment-image [data]="equipment" ngProjectAs="icon"></app-equipment-image>
  <ng-container *ngFor="let h of hierarchy" ngProjectAs="hierarchy">
    <div class="flex flex-row gap-2">
      <app-search-result-entry-hierarchy [data]="h" *ngIf="hierarchy"></app-search-result-entry-hierarchy>
    </div>
  </ng-container>
  <ng-container ngProjectAs="details">
    <div class="flex flex-row gap-2">
      <div class="col-4">{{'serialNumber' | translate}}</div>
      <div>{{equipment?.serialNumber}}</div>
    </div>
    <div class="flex flex-row gap-2">
      <div class="col-4">{{'refrigerant' | translate}}</div>
      <div>{{equipment?.refrigerant}}</div>
    </div>

    <div class="flex flex-row gap-2">
      <div class="col-4">{{'type' | translate}}</div>
      <div>{{equipment?.productId}}</div>
    </div>
  </ng-container>
</app-search-result-entry>
