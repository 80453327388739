<div class="flex flex-col scroll-off">

  <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>

  <div class="basis-full text-center my-3">{{ 'action.Edit' | translate }}</div>
  <mat-divider class="mb-4"></mat-divider>

  <form [formGroup]="update" class="flex flex-col justify-center items-center activation-form">
    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'electronicModule' | translate }}</mat-label>
      <mat-select formControlName="electronicModule" (scroll)="onScroll($event)">
        <mat-option *ngFor="let module of modules | keyvalue" [value]="module.value">
          {{ module.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'iotProtocol' | translate }}</mat-label>
      <mat-select formControlName="protocol" (selectionChange)="onProtocolSelect()">
        <mat-option *ngFor="let protocol of protocols" [value]="protocol.value">
          {{protocol.name | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'macAddress' | translate }}</mat-label>
      <input type="text" matInput formControlName="macAddress">
    </mat-form-field>

    <button mat-flat-button (click)="advanced=true" *ngIf="!advanced && !modbus">
      <mat-icon>expand_more</mat-icon>
      {{'advanced' | translate}}
    </button>

    <mat-form-field appearance="outline" class="col-8" *ngIf="advanced || modbus">
      <mat-label>{{ 'deviceAddress' | translate }}</mat-label>
      <input type="text" matInput formControlName="deviceAddress">
    </mat-form-field>

  </form>

  <div class="flex flex-wrap justify-content-sm-end justify-center mb-3 gap-2">
    <button mat-flat-button color="primary" class="me-2" *ngIf="equipmentService.hasEquipmentEditPermissions()"
            (click)="edit()" [disabled]="update.invalid || reloading">
      {{ 'action.Update' | translate }}
    </button>
    <button mat-flat-button class="me-2" (click)="cancel()">
      {{ 'cancel' | translate }}
    </button>
  </div>
</div>
