<mat-card class="m-3">
  <mat-card-header class="flex justify-center mb-3">
    <mat-card-title> {{ 'properties' | translate }} </mat-card-title>
  </mat-card-header>

  <mat-checkbox (change)="selectProperty(CAPACITY)"
                [checked]="isSelected(CAPACITY)">
    {{ 'capacity' | translate }}
  </mat-checkbox>

  <mat-checkbox (change)="selectProperty(FREQUENCY)"
                [checked]="isSelected(FREQUENCY)">
    {{ 'motorFrequency' | translate }}
  </mat-checkbox>


  <div class="flex content-end m-3">
    <button mat-stroked-button
            mat-dialog-close
            color="primary">
      {{ 'cancel' | translate }}
    </button>
  </div>
</mat-card>
