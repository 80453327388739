import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { HotToastService } from "@ngxpert/hot-toast";
import { AccountInfo } from "../model/account-api";
import { LicensePolicy } from "../model/license-api";
import { BestInfo } from "../../equipment/model/best-core-api";
import { Equipment } from "../../equipment/model/equipment-api";
import { AccountService } from "../model/account.service";
import { LicenseService } from "../model/license.service";
import { BestCoreGatewayService } from "../../equipment/model/best-core-gateway.service";

@Component({
  selector: 'app-license-activation-dialog',
  templateUrl: './license-activation-dialog.component.html',
  styleUrls: ['./license-activation-dialog.component.scss']
})
export class LicenseActivationDialogComponent {

  account: AccountInfo | undefined
  policies: LicensePolicy[] = []
  selected: number | undefined
  bestInfo: BestInfo | undefined
  loading = false
  messageSent = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public equipment: Equipment,
    private dialogRef: MatDialogRef<LicenseActivationDialogComponent>,
    private accountService: AccountService,
    private licenseService: LicenseService,
    private bestService: BestCoreGatewayService,
    private toastService: HotToastService,
    private translate: TranslateService
  ) {
    accountService.getUserAccountInfo().subscribe(acc => {
      this.account = acc
    })
    licenseService.getLicensePolicies().subscribe(data => {
      this.policies = data
    })
    bestService.getInfo(equipment.id, equipment.thingId).subscribe(info => {
      this.bestInfo = info
    })
  }

  getPolicy() {
    if (!this.selected) return
    return this.policies.find(it => it.id == this.selected)
  }

  getDifference() {
    if (!this.account) return 0
    let charge = this.getPolicy()?.charge
    if (!charge || !this.selected) return this.account.account.amount
    return this.account.account?.amount - charge
  }

  onActivateLicense() {
    if (!this.equipment || !this.selected) return

    this.loading = true
    this.messageSent = true

    this.licenseService.upgradeLicense(this.equipment.id, this.selected).subscribe({
      next: value => this.upgradeSucceed().subscribe(_ => this.dialogRef.close(true)),
      error: err => this.upgradeFailed().subscribe(_ => this.dialogRef.close(true))
    }
    )
  }

  private upgradeSucceed() {
    this.messageSent = false
    return this.toastService.success(this.translate.instant('licenseUpgradeOk')).afterClosed
  }

  private upgradeFailed() {
    this.messageSent = false
    return this.toastService.error(this.translate.instant('licenseUpgradeFailure')).afterClosed
  }
}
