import { Component } from '@angular/core';
import { FilterService } from "../model/filter.service";

@Component({
  selector: 'app-generic-filter-menu',
  templateUrl: './generic-filter-menu.component.html',
  styleUrls: ['./generic-filter-menu.component.scss']
})
export class GenericFilterMenuComponent {

  constructor(public filterService: FilterService) {

  }
}
