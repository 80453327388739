import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-feedback-form',
  templateUrl: 'feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})

export class FeedbackFormComponent implements OnInit {
  @Input() formTitle = '';
  
  feedbackForm = createFormGroup();

  constructor() { }

  ngOnInit(): void { }
  
  submitForm(): void {
    console.log('FORM VALUES',  this.feedbackForm.getRawValue())
  }
}

function createFormGroup(): FormGroup {
  return new FormGroup(
    {
      feedbackText: new FormControl(''),
      givenPoint: new FormControl('')
    }
  );
}