import {Component, Input} from '@angular/core';
import {CompressorStatus, EventState} from "../../site/model/state-api";
import {capitalize} from "../../utils/string-utils";

@Component({
  selector: 'app-iot-status',
  templateUrl: './iot-status.component.html',
  styleUrls: ['./iot-status.component.scss']
})
export class IotStatusComponent {

  data: EventState | undefined
  style: string = 'status-color-offline'
  status: string = this.capitalize(CompressorStatus.OFFLINE)

  constructor() {
  }

  @Input()
  set eventState(value: EventState | undefined) {
    this.data = value
    this.updateStatus()
    this.updateStyle()
  }

  private updateStatus() {
    if (!this.data) return
    this.status = this.data.status === CompressorStatus.UNKNOWN || !this.data.online
                  ? this.capitalize(CompressorStatus.OFFLINE)
                  : this.capitalize(this.data.status)
  }

  private updateStyle() {
    if (!this.data) return

    if (!this.data.online) {
      this.style = 'status-color-offline'
      return
    }

    switch (this.data.status) {
      case CompressorStatus.RUNNING:
        this.style = 'status-color-running'
        break
      case CompressorStatus.FAULT:
        this.style = 'status-color-fault'
        break
      case CompressorStatus.UNKNOWN:
        this.style = 'status-color-running'
        break
      case CompressorStatus.STOPPED:
      default:
        this.style = 'status-color-stopped'
    }
  }

  private capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
}
