@if (state()?.filterGroups?.length) {
  <app-filter-group-list (filterChipSelectionChanged)="handleRequestChanged({documentTypes: $event.selectedTypes})"
                         (filterCleared)="onFilterCleared($event)"
                         (filterSelected)="onFilterSelected($event)"
                         (resetFilters)="onFiltersReset()"
                         [filterChipItems]="filterChipItems()"
                         [filterGroups]="state()!.filterGroups"
                         [filterListTitleSuffix]="filterListTitleSuffix" [filterListTitle]="'category.filter.title' | translate"
                         [showFilters]="showFilters()"
  />
}


