import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  Company,
  CompanyChangeRequest,
  CompanyContactChangeRequest,
  CompanyProfileChangeRequest,
  Requester
} from "../model/company-api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from "../model/company.service";
import { ProcessResponse, TransitionRequest } from "../../process/model/process-api";
import { ProcessService } from "../../process/model/process.service";
import { AuthService } from "../../auth/auth.service";
import {interval, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import { HotToastService } from "@ngxpert/hot-toast";

@Component({
  selector: 'app-register-company-dialog',
  templateUrl: './register-company-dialog.component.html',
  styleUrls: ['./register-company-dialog.component.scss']
})
export class RegisterCompanyDialogComponent {

  companyForm: FormGroup = this.fb.group({
    name: this.fb.control('', [Validators.required]),
  })
  profileForm: FormGroup = this.service.createProfileForm(this.fb)
  contactForm: FormGroup = this.service.createContactForm(this.fb)
  reloading: boolean = false
  private processSubscription: Subscription | undefined

  constructor(
    @Inject(MAT_DIALOG_DATA) public company: Company | undefined,
    private dialogRef: MatDialogRef<RegisterCompanyDialogComponent>,
    private fb: FormBuilder,
    private service: CompanyService,
    private processService: ProcessService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private toastService: HotToastService
  ) {
  }

  submit() {
    if (!this.companyForm.valid) return

    this.reloading = true
    let companyValue = this.companyForm.value
    let companyRequest = new CompanyChangeRequest(
      "",
      companyValue.name ?? "",
      "REGULAR"
    )

    let profileValue = this.profileForm.value
    let profileRequest = new CompanyProfileChangeRequest(
      profileValue.street ?? "",
      profileValue.zip ?? "",
      profileValue.city ?? "",
      profileValue.country ?? "",
      profileValue.state ?? "",
      profileValue.phoneNumber ?? "",
      profileValue.website ?? "",
    )

    let contactValue = this.contactForm.value
    let contactRequest = new CompanyContactChangeRequest(
      contactValue.title ?? "",
      contactValue.firstName ?? "",
      contactValue.lastName ?? "",
      contactValue.phoneNumber ?? "",
      contactValue.email ?? "",
    )

    let requester = new Requester(
      '',
      this.authService.getPrincipal()?.username || '',
      this.authService.getPrincipal()?.email || '',
      ''
    )

    let request = new TransitionRequest(
      companyRequest,
      profileRequest,
      contactRequest,
      requester
    )
    this.processService.transition(request).subscribe({
      next: value => this.checkStatus(value),
      error: err => this.handleError()
    })
  }

  ngOnDestroy() {
    this.stopPolling()
  }

  private checkStatus(response: ProcessResponse) {
    if (response.status.code === 0 || response.status.code === 300 || response.status.code === 200)
      this.processSubscription = interval(3000).subscribe(() => this.handleProcess(response.processId))
    else
      this.handleError()
  }

  private handleProcess(processId: number) {
    this.processService.getById(processId).subscribe({
      next: value => {
        if (value.status.code === 200) {
          const snackBarRef = this.snackbar.open(
            this.translate.instant('bdnOrgRegOk'),
            undefined,
            {
              duration: 5000
            }
          )
          snackBarRef.afterDismissed().subscribe(() => {
            this.authService.logout()
          })
        }
        this.stopPolling()
      },
      error: err => {
        this.stopPolling()
        this.handleError()
      }
    })
  }

  private stopPolling(): void {
    if (this.processSubscription) {
      this.processSubscription.unsubscribe();
      this.processSubscription = undefined
    }
  }

  private handleError() {
    this.toastService.error('Error occurred during company registration')
    this.dialogRef.close()
  }
}
