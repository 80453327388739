<div class="flex flex-col items-center justify-center" *ngIf="site">
  <h6><strong>{{site.site.name}}</strong></h6>

  <app-iot-status [eventState]="site.eventState"></app-iot-status>
  <app-alarm-info [alarms]="site.alarmState"></app-alarm-info>

  <div>
    <button mat-flat-button (click)="showDetails()">
      <mat-icon>info</mat-icon>
      {{'details' | translate}}
    </button>
  </div>

</div>
