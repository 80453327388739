import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { License, LicenseInfo, LicenseModel, LicensePolicy } from "./license-api";
import { map } from "rxjs/operators";
import { BaseService } from "../../utils/base-service";
import { Page } from "../../utils/page";
import { LoggingService } from "../../utils/logging/logging.service";
import { HotToastService } from "@ngxpert/hot-toast";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LicenseService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private toast: HotToastService,
    private translate: TranslateService
  ) {
    super(http, 'api/gateway/accounting/license', logging, zone);
    this.retryCount = 1
  }

  getAllLicenses(page: number, size: number = 20): Observable<Page<License>> {
    return this.getPaged<License>('', page, size)
  }

  getAllLicenseInfo(page: number, size: number = 20): Observable<Page<LicenseInfo>> {
    return this.getPaged<LicenseInfo>('info', page, size)
  }

  getFilteredLicenseInfo(page: number, size: number = 20, filter: string): Observable<Page<LicenseInfo>> {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged<LicenseInfo>('info/filter', page, size, params)
  }

  private modelCache: Map<number, LicenseModel> = new Map<number, LicenseModel>();

  getLicenseModel(licenseModelId: number): Observable<LicenseModel> {
    let hit = this.modelCache.get(licenseModelId)
    if (hit) {
      return of(hit)
    }

    let observable = this.get<LicenseModel>('model/' + licenseModelId)
    return observable.pipe(
      map(m => {
        this.modelCache.set(licenseModelId, m)
        return m
      })
    )
  }

  getLicensePolicies() {
    return this.get<LicensePolicy[]>('policy')
  }

  createLicense(equipmentId: number, policyId: number): Observable<License> {
    let url = '' + equipmentId + '/policy/' + policyId
    return this.post(url, {})
  }

  activateLicense(equipmentId: number): Observable<License> {
    let url = '' + equipmentId + '/activate'
    return this.put(url, {})
  }

  upgradeLicense(equipmentId: number, policyId: number): Observable<License> {
    return this.post('upgrade/' + equipmentId + '/policy/' + policyId, {})
  }

  findByEquipment(equipmentId: number): Observable<LicenseInfo> {
    let url = 'find/by/equipment/' + equipmentId
    return this.get(url)
  }

  findByEquipmentsAndCompany(equipmentIds: number[], companyId: number): Observable<LicenseInfo[]> {
    let url = 'find/by/equipments/and/company/' + companyId
    return this.post(url, equipmentIds)
  }

  hasBPlusLicense(license: LicenseInfo | undefined): boolean {
    if (!license || license.model.title != "B-Plus") {
      this.toast.warning(this.translate.instant('licenseWarning'))
      return false
    }
    return license.model.title == "B-Plus"
  }

}
