<app-header-toolbar [title]="company?.name">
  <app-header-toolbar-actions>
    <button mat-icon-button [matMenuTriggerFor]="manage">
      <mat-icon>more_vert</mat-icon>
    </button>
  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
<div class="flex flex-row flex-wrap px-3 gap-2 pt-2">
  <app-company-details-profile [data]="company" style="flex: 1 1 calc(50% - 0.5rem)"></app-company-details-profile>
  <app-company-details-contact [data]="company" style="flex: 1 1 calc(50% - 0.5rem)"></app-company-details-contact>
</div>

<mat-menu #manage="matMenu">
  <button mat-menu-item (click)="edit()">
    <mat-icon>edit</mat-icon>
    {{'site.Rename' | translate}}
  </button>
<!--  <button mat-menu-item>-->
<!--    <mat-icon>delete</mat-icon>-->
<!--    {{'action.Delete' | translate}}-->
<!--  </button>-->
</mat-menu>
