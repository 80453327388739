export class FeedbackRequest {
  constructor(
    public score: number,
    public comment: string,
    public url: string,
    public userAgent: string,
    public language: string,
    public latitude: number,
    public longitude: number
  ) {
  }
}
export interface Feedback {
  id: number,
  score: number,
  comment: string,
  url: string,
  language: string,
  created: string,
}

export interface FeedbackEntry {
  id: number,
  userId: string,
  score: number,
  comment: string,
  url: string,
  userAgent: string,
  language: string,
  latitude: number,
  longitude: number,
  created: string,
}
