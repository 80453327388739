<app-header-toolbar [title]="series?.title ?? seriesId" [showBack]="false">
  <app-header-toolbar-icon>
    <button mat-icon-button (click)="back()"
            matTooltip="{{'back' | translate}}"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </app-header-toolbar-icon>
  <app-header-toolbar-content>

  </app-header-toolbar-content>
</app-header-toolbar>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<div class="flex flex-col gap-2 p-2">
  <app-series-details-tabs [data]="series"></app-series-details-tabs>
</div>
