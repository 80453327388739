<div class="flex gap-2 flex-wrap">
  @for (p of entries; track p) {
    <mat-expansion-panel #mep="matExpansionPanel" class="mb-2 w-full" [hideToggle]=true [disabled]="true">
      <mat-expansion-panel-header>
        <mat-panel-title (click)="mep.expanded = !mep.expanded" [style.color]="'black'">
          {{ p.id | stripLeadingZeros }}
        </mat-panel-title>
        @if (this.hasCommerceReadPermissions()) {
          <div (click)="mep.expanded = !mep.expanded" class="grow-0 content-end px-5 me-5 text-s font-medium">
            {{ prices[p.id] | currency:'EUR' }}
          </div>
        }
        <app-add-to-cart-button class="mr-2 mt-0.5" [code]="p.id"></app-add-to-cart-button>
        <div class="border-gray-50 border-2">
          <button mat-mini-fab (click)="addToWishList(p)"
                  matTooltip="{{'wishlist.dialog.add' | translate}}"
                  [style.background-color]="'white'">
            <mat-icon [style.color]="isWishlistActive(p) ? '#50c422' : 'black'">star</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>

      <div class="flex flex-col gap-2">
        <div class="grid grid-cols-2 gap-4 w-full">
          @for (i of p.cstics; track i) {
            <mat-form-field appearance="outline" disabled>
              <mat-label>{{ i.defaultName }}</mat-label>

              <div class="flex justify-between">
                <input matInput value="{{i.value.defaultName}}" readonly>
                @if (isSelectionOfType(i, ConfigSelectionType.USER)) {
                  <mat-icon color="accent">check_box</mat-icon>
                } @else if (isSelectionOfType(i, ConfigSelectionType.STANDARD)) {
                  <mat-icon style="color: #b36d23">check_box</mat-icon>
                }
              </div>
            </mat-form-field>
          }
        </div>
        <div>
          <a mat-flat-button color="primary" target="_blank"
             href="portal/product/details/information?product={{p.type}}">
            <mat-icon>open_in_new</mat-icon>
            {{ 'productConfig.openInTab' | translate }}</a>
        </div>
      </div>

    </mat-expansion-panel>
  }
</div>
