import { Component, Input } from '@angular/core';
import { Equipment } from "../../model/equipment-api";
import { connect, ECElementEvent, ECharts, EChartsOption, SeriesOption } from "echarts";
import { OperationalGraphService } from "../model/operational-graph.service";
import { InitialValueBuilder } from "./initial-value-builder";
import { EventLoader } from "./data/event-loader";
import { EventService } from "../../../event/event.service";
import { AlarmService } from "../../../alarm/model/alarm.service";
import { PropertyService } from "../../../property/model/property.service";
import { TranslateService } from "@ngx-translate/core";
import { AlarmLoader } from "./data/alarm-loader";
import { PropertyLoader } from "./data/property-loader";
import { LoaderCallback } from "./data/base-loader";
import { AlarmInfo } from "../../../alarm/model/alarm-api";
import { AlarmInfoDialogComponent } from "../../../alarm/alarm-info-dialog/alarm-info-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SystemTimeService } from "../../../system/system-time/system-time.service";
import { EquipmentService } from "../../model/equipment.service";
import { WeatherService } from "../../../weather/model/weather.service";
import { WeatherTempLoader } from "./data/weather-temp-loader";
import { WeatherHumidityLoader } from "./data/weather-humidity-loader";
import { WeatherPressureLoader } from "./data/weather-pressure-loader";
import { TranslationService } from "../../../translation/translation.service";

@Component({
  selector: 'app-operational-graph-equipment-entry',
  templateUrl: './operational-graph-equipment-entry.component.html',
  styleUrl: './operational-graph-equipment-entry.component.scss'
})
export class OperationalGraphEquipmentEntryComponent {

  @Input('data')
  set setData(data: Equipment) {
    this.equipment = data
    this.loadEquipmentValues()
  }

  siteId: number | undefined
  equipment: Equipment | undefined


  primaryChart: ECharts | undefined
  secondaryChart: ECharts | undefined
  loading: boolean = false
  private loadingCounter = 0
  primarySeries: SeriesOption[] = []
  secondarySeries: SeriesOption[] = []

  primaryInitialValue: EChartsOption | null
  primaryDynamicData: EChartsOption = {}
  secondaryInitialValue: EChartsOption | null
  secondaryDynamicData: EChartsOption = {}

  private eventLoader: EventLoader
  private alarmLoader: AlarmLoader
  private propertyLoader: PropertyLoader
  private tempLoader: WeatherTempLoader
  private humidityLoader: WeatherHumidityLoader
  private pressureLoader:WeatherPressureLoader

  constructor(
    private service: OperationalGraphService,
    private eventService: EventService,
    private alarmService: AlarmService,
    private propertyService: PropertyService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private systemTime: SystemTimeService,
    private equipmentService: EquipmentService,
    private weatherService: WeatherService,
    private translationService: TranslationService
  ) {
    this.primaryInitialValue = null
    this.secondaryInitialValue = null
    this.eventLoader = new EventLoader(this.service, this.eventService, this.translate, this.systemTime)
    this.alarmLoader = new AlarmLoader(this.alarmService, this.translate, this.systemTime)
    this.propertyLoader = new PropertyLoader(this.service, this.propertyService, this.translate, this.systemTime)
    this.tempLoader = new WeatherTempLoader(this.weatherService, this.translate, this.systemTime)
    this.humidityLoader = new WeatherHumidityLoader(this.weatherService, this.translate, this.systemTime)
    this.pressureLoader = new WeatherPressureLoader(this.weatherService, this.translate, this.systemTime)


    this.service.changeEvent.subscribe(reload => this.loadEquipmentValues(reload))
  }

  private loadEquipmentValues(reload: boolean = true) {
    if (!this.equipment) return
    if (this.loading) return
    this.loading = true
    this.showLoading()
    if (reload) {
      this.primaryInitialValue = new InitialValueBuilder().buildPrimary(this.service.calculateMin(), this.service.calculateMax())
      this.secondaryInitialValue = new InitialValueBuilder().buildSecondary(this.service.calculateMin(), this.service.calculateMax())
    }
    this.primarySeries = []
    this.secondarySeries = []
    this.loadingCounter = 3

    let from = this.service.getRangeStart()
    let to = this.service.getRangeEnd()

    this.equipmentService.getPath(this.equipment.id).subscribe(data => {
      this.siteId = data[0].resource.id
      if (this.service.weatherSeries.includes('temp')) this.tempLoader.load(this.siteId, from, to).subscribe(d => this.handleCallback(d))
      if (this.service.weatherSeries.includes('humidity')) this.humidityLoader.load(this.siteId, from, to).subscribe(d => this.handleCallback(d))
      if (this.service.weatherSeries.includes('pressure')) this.pressureLoader.load(this.siteId, from, to).subscribe(d => this.handleCallback(d))
    })

    this.eventLoader.load(this.equipment.id, from, to).subscribe(d => this.handleCallback(d))
    this.alarmLoader.load(this.equipment.id, from, to).subscribe(d => this.handleCallback(d))
    this.translationService.getUOMTranslations(this.translate.currentLang).subscribe(translation => {
      this.propertyLoader.uomTranslations = new Map(Object.entries(translation.entries))
      if (!this.equipment) return
      this.propertyLoader.load(this.equipment.id, from, to).subscribe(d => this.handleCallback(d))
    })

    connect('op-graph-1')
  }

  private handleCallback(callback: LoaderCallback) {
    this.addPrimarySeries(callback.primary)
    this.addSecondarySeries(callback.secondary)
    this.loadingCounter--
    this.loading = this.loadingCounter > 0
    if(!this.loading) this.hideLoading()
  }

  private addPrimarySeries(data: SeriesOption[]) {
    this.primarySeries.push(...data)
    let uomConfig = this.propertyLoader.loadUomConfiguration(true)

    this.primaryDynamicData = {
      legend: {
        type: 'scroll',
        data:
          this.getTranslatedData(),
        top: 70,
      },
      series: this.primarySeries,
      yAxis: uomConfig,
      xAxis: {
        min: () => this.service.calculateMin(),
        max: () => this.service.calculateMax()
      }
    }
    this.setEchartsActions()
  }

  private addSecondarySeries(data: SeriesOption[]) {
    if (!data) return
    this.secondarySeries.push(...data)
    let uomConfig = this.propertyLoader.loadUomConfiguration(false)
    this.secondaryDynamicData = {
      legend: {
        type: 'scroll',
        data:
          this.getTranslatedData(),
        top: 0,
      },
      series: this.secondarySeries,
      yAxis: uomConfig,
      xAxis: {
        min: () => this.service.calculateMin(),
        max: () => this.service.calculateMax()
      }
    }
  }


  handlePrimaryChartClick(event: ECElementEvent) {
    if (!this.equipment || !event.value) return
    let timestamp = (event.value as any)[0] as string
    this.alarmLoader.getAlarmDetailsForTimestamp(this.equipment, timestamp, 0).subscribe(a => this.openAlarmDetailsDialog(a))
  }

  setPrimaryChart(chart: ECharts) {
    chart.group = 'op-graph-1'
    this.primaryChart = chart
    this.showLoading()
  }

  setSecondaryChart(chart: ECharts) {
    chart.group = 'op-graph-1'
    this.secondaryChart = chart
    this.showLoading()
  }

  setEchartsActions() {
    if (!this.primaryChart || !this.secondaryChart) return
    this.primaryChart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'dataZoomSelect',
      dataZoomSelectActive: true,
    })
    this.secondaryChart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'dataZoomSelect',
      dataZoomSelectActive: true,
    })
  }

  private openAlarmDetailsDialog(alarms: AlarmInfo[]) {

    if (!alarms.length) return
    const dialogRef = this.dialog.open(AlarmInfoDialogComponent, {
      width: '85%',
      data: alarms
    })
  }

  private showLoading() {
    this.primaryChart?.showLoading()
    this.secondaryChart?.showLoading()
  }

  private hideLoading() {
    this.primaryChart?.hideLoading()
    this.secondaryChart?.hideLoading()
  }

  private getTranslatedData() {
    const trimmedPropertyNames = this.service.selectedProperties.map(p => this.service.trimPropertyName(p.name))
    return trimmedPropertyNames.map(p => this.translate.instant(p))
  }
}
