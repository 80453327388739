export interface ElectronicModule {
  id: number,
  equipmentId: number,
  externalId: string,

  serialNumber: string,
  type: string
}

export class ElectronicModuleValidationRequest{
  constructor(
    public type: string,
    public serialNumber: string
  ) {
  }
}
