import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {BoardComponent} from "./board/board.component";
import {BoardListComponent} from './board-list/board-list.component';
import {MaterialModule} from "../material/material.module";
import {IotStatusComponent} from "./iot-status/iot-status.component";
import {TimeInFaultComponent} from "./time-in-fault/time-in-fault.component";
import {RouterLink} from "@angular/router";
import {DefaultImageDirective} from "./default-image.directive";
import {TableCardComponent} from "./card/table-card/table-card.component";
import {ImageCardComponent} from './card/image-card/image-card.component';
import {FeedbackFormComponent} from './feedback-form/feedback-form.component';
import {GroupedTableCardComponent} from "./card/grouped-table-card/grouped-table-card.component";
import {FileDownloadButtonComponent} from './file-download-button/file-download-button.component';
import {ListCardEntryComponent} from './card/list-card-entry/list-card-entry.component';
import {HasRoleDirective} from "../auth/has-role.directive";


@NgModule({
  declarations: [
    BoardComponent,
    BoardListComponent,
    IotStatusComponent,
    TimeInFaultComponent,
    DefaultImageDirective,
    TableCardComponent,
    GroupedTableCardComponent,
    ImageCardComponent,
    FeedbackFormComponent,
    FileDownloadButtonComponent,
    ListCardEntryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MaterialModule,
    RouterLink,
    ReactiveFormsModule,
    HasRoleDirective
  ],
  exports: [
    BoardComponent,
    BoardListComponent,
    IotStatusComponent,
    TimeInFaultComponent,
    DefaultImageDirective,
    TableCardComponent,
    GroupedTableCardComponent,
    ImageCardComponent,
    FeedbackFormComponent,
    FileDownloadButtonComponent,
    ListCardEntryComponent
  ]
})
export class ComponentModule {
}
