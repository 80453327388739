import { Component, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from "@angular/router";
import { SparePartsBoardContentComponent } from "../spare-parts-board-content/spare-parts-board-content.component";
import { HomeService } from "../../../home/model/home.service";
import { SparePartSearchRequest } from "../../search/model/search-api";

@Component({
  selector: 'app-spare-parts-board',
  templateUrl: './spare-parts-board.component.html',
  styleUrls: ['./spare-parts-board.component.scss']
})
export class SparePartsBoardComponent {


  private subscriptions: Subscription[] = []
  @ViewChild(SparePartsBoardContentComponent) content: SparePartsBoardContentComponent | undefined

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private home: HomeService,
  ) {
  }

  ngOnInit() {
    this.home.searchVisible = true
  }

  ngAfterViewInit() {
    let s2 = this.route
      .queryParamMap
      .subscribe(value => this.handleUrlParamsChanged(value))

    let s3 = this.home.search.subscribe(query => {
        if (this.content) this.content.search(query)
      }
    )

    this.subscriptions = [s2, s3]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []

    this.home.customHeaderActions = []
    this.home.searchVisible = false
  }


  protected handleRequestChanged(request: SparePartSearchRequest) {
    this.updateUrl()
  }


  private updateUrl() {
    let request = this.content?.request

    let query = (request) ? (request.fullTextSearch.length > 0) ? request.fullTextSearch : undefined : undefined
    const navigationExtras: NavigationExtras = {
      queryParams: {
        q: query
      },
      relativeTo: this.route
    }
    this.home.query = request?.fullTextSearch || ''
  }


  private handleUrlParamsChanged(value: ParamMap) {
    let query = value.get('q') ?? ""
    if (this.content) this.content.updateRequest(query)
  }
}
