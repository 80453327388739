import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { BaseService } from "../../utils/base-service";
import { Observable } from "rxjs";
import { SiteContact, SiteContactChangeRequest } from "./site-api";

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  )
  {
    super(http, 'api/contact', logging, zone)
  }

  create(siteId: number, request: SiteContactChangeRequest): Observable<SiteContact> {
    return this.post<SiteContact>('' + siteId, request)
  }

  update(contactId: number, request: SiteContactChangeRequest): Observable<SiteContact> {
    return this.put<SiteContact>('' + contactId, request)
  }
}
