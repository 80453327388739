export class SeriesDetailsTabRouteData {
  constructor(
    public label: string,
    public permissions: string[]
  ) {

  }


}
