import {Component, Input} from '@angular/core';
import {EventState} from "../../site/model/state-api";
import {DateTime, Duration} from "luxon";
import {toCustomHumanDuration} from "../../utils/duration-utils";

@Component({
  selector: 'app-time-in-fault',
  templateUrl: './time-in-fault.component.html',
  styleUrls: ['./time-in-fault.component.scss']
})
export class TimeInFaultComponent {

  eventState: EventState | undefined
  text: string = ''

  @Input('state')
  set state(state: EventState) {
    this.eventState = state
    this.updateText()
  }

  private _showUndefined: boolean = true

  @Input()
  set showUndefined(value: boolean) {
    this._showUndefined = value
    this.updateText()
  }

  private updateText() {
    let state = this.eventState;
    if (!state || !state.online) {
      this.text = this.getUndefined()
    } else {
      const timestamp = DateTime.fromISO(state.timestamp + 'Z', {zone: 'utc'});
      const current = DateTime.utc();
      const duration = current.diff(timestamp);
      this.text = duration.as('milliseconds') < 0 ? this.getUndefined() : toCustomHumanDuration(Duration.fromMillis(duration.as('milliseconds')), {
        maxUnits: 1,
        biggestUnit: 'years',
        smallestUnit: 'milliseconds'
      });

    }
  }

  private getUndefined() {
    return (this.showUndefined) ? 'N/A' : '';
  }
}
