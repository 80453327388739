import {Component, computed, inject} from '@angular/core';
import {ProductConfigService} from "../model/product-config.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-product-config-main',
  templateUrl: './product-config-main.component.html',
  styleUrl: './product-config-main.component.scss'
})
export class ProductConfigMainComponent {
  private readonly configService = inject(ProductConfigService)
  numberOfComparisons = computed(() => this.configService.comparisonPids().length);
  private readonly router = inject(Router)

  navigateToPidComparison(): void {
    this.router.navigate(['portal', 'pid', 'compare'], {queryParams: {p: this.configService.comparisonPids()}}).then()
  }
}
