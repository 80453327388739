import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { filter, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent {

  @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>()
  @Input() resetEvent: EventEmitter<boolean> | undefined
  @Input()
  set predefinedQuery(query: string) {
    this.inputText.setValue(query)
  }

  inputText = new FormControl('')

  @Input()
  searching: boolean = false

  private searchChangeSubject: Subject<string> = new Subject();

  ngOnInit(): void {
    this.searchChangeSubject.pipe(
      filter(Boolean),
      debounceTime(500),
    ).subscribe(text => this.search(text))
    this.resetEvent?.subscribe(it => {
      this.reset()
    })
  }

  onSearchChange(value: string) {
    this.searchChangeSubject.next(value)
  }

  search(value: string) {
    this.searchEvent.emit(value)
  }

  reset() {
    this.inputText.reset()
  }
}
