<mat-card>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" color="accent">
    <a *ngFor="let link of navLinks" mat-tab-link
       #rla="routerLinkActive"
       [active]="rla.isActive"
       [routerLink]="link.path"
       [queryParams]="{series: series?.id}"
       routerLinkActive>
      {{ link.label | translate }}
    </a>
  </nav>
  <mat-divider></mat-divider>
  <mat-tab-nav-panel #tabPanel class="pb-3">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</mat-card>
