import { Component } from '@angular/core';

@Component({
  selector: 'app-system-board',
  templateUrl: './system-board.component.html',
  styleUrls: ['./system-board.component.scss']
})
export class SystemBoardComponent {

}
