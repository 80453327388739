<mat-chip-listbox [formControl]="formControl" multiple>
  @for (type of types; track type) {
    <mat-chip-option (selectionChange)="handleChipSelectionChange($event, type)" [value]="type.type" [selected]="isSelected(type.type)">
      <div class="flex flex-row gap-4 justify-between items-center">
        <div style="overflow-wrap: anywhere;">{{ type.typeName || type.type  | translate }}</div>
        @if (type.amount) {
          <div class="amount">{{ type.amount }}</div>
        }
      </div>
    </mat-chip-option>
  }
</mat-chip-listbox>
