<mat-card>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" color="accent">
    <a *ngFor="let link of navLinks" mat-tab-link
       #rla="routerLinkActive"
       [active]="rla.isActive"
       [routerLink]="link.path"
       routerLinkActive>
      <div class="flex flex-row gap-2 justify-between items-center flex-auto">
        <div>{{link.label | translate}}</div>
        <div *ngIf="link.beta"><span class="chip">{{'Beta' | translate}}</span></div>
      </div>
     </a>

  </nav>
  <mat-divider></mat-divider>
  <mat-tab-nav-panel #tabPanel class="p-3">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</mat-card>
