<!--**
 * @(#)calendar.component.html Sept 07, 2023

 * @author Aakash Kumar
 *-->
<div class="calendar-container">
  <div class="first-view">
    <mat-calendar #firstCalendarView (monthSelected)="monthSelected($event)"
                  (selectedChange)="updateDateRangeSelection($event)"
                  [maxDate]="maxDate" [minDate]="minDate"
                  [selected]="selectedDates" [startAt]="firstCalendarViewData.startDate"
                  id="firstCalendarView"></mat-calendar>
  </div>
  <div class="second-view">
    <mat-calendar #secondCalendarView (monthSelected)=" secondViewMonthSelected($event)"
                  (selectedChange)="updateDateRangeSelection($event)"
                  [maxDate]="maxDate" [minDate]="secondCalendarViewData.minDate"
                  [selected]="selectedDates"
                  [startAt]="secondCalendarViewData.startDate"
                  id="secondCalendarView"></mat-calendar>
  </div>
</div>
