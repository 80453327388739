import {Component} from '@angular/core';
import {HomeService} from "../model/home.service";
import {distinctUntilChanged, filter, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-main-search',
  templateUrl: './main-search.component.html',
  styleUrl: './main-search.component.scss'
})
export class MainSearchComponent {

  private searchChangeSubject: Subject<string> = new Subject();

  constructor(public service: HomeService) {
  }

  ngOnInit() {
    this.searchChangeSubject.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      debounceTime(2000)
    ).subscribe(text => this.search(text))
  }

  onSearchChange(value: string) {
    this.searchChangeSubject.next(value)
  }

  search(query: string) {
    this.service.executeSearch(query)
  }

  reset() {
    this.service.executeSearch('')
  }
}
