import {Component, computed, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CategorySelectComponent} from "../../category/category-select/category-select.component";
import {TranslateService} from "@ngx-translate/core";
import {PageEvent} from "@angular/material/paginator";
import {SeriesSearchRequest, SeriesSearchResponse} from "../../search/model/search-api";
import {
  ContentCategoryType
} from "../../../component/category/category-filter-list/category-filter-list.component";
import {ProductFacadeService} from "../../product/model/product-facade.service";

let DEFAULT_PAGE_SIZE = 25;

@Component({
  selector: 'app-series-board-content',
  templateUrl: './series-board-content.component.html',
  styleUrl: './series-board-content.component.scss',
  providers: [ProductFacadeService]
})
export class SeriesBoardContentComponent {


  @Input() categorySelectorVisible: boolean = true

  @Output() reset = new EventEmitter<boolean>()
  @Output() requestChanged = new EventEmitter<SeriesSearchRequest>()

  searching = computed(() => this.facadeService.searching());

  pageSize: number = DEFAULT_PAGE_SIZE
  pageIndex: number = 0
  totalSize: number = -1

  response: SeriesSearchResponse | null = null

  request: SeriesSearchRequest = new SeriesSearchRequest([], '', this.translate.currentLang)
  @ViewChild(CategorySelectComponent) categorySelect: CategorySelectComponent | undefined
  protected readonly type = ContentCategoryType.SERIES;

  constructor(private facadeService: ProductFacadeService,
              private translate: TranslateService
  ) {
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.load()
  }

  search(query: string) {
    this.request.fullTextSearch = query
    this.pageIndex = 0
    this.handleRequestChanged()
  }

  handleData(response: any | null) {
    if (!response) return false
    this.response = response
    this.pageIndex = response.entries.pageable.number
    this.totalSize = response.entries.totalSize
    return true
  }

  private load(overwrite: boolean = false) {
    if (this.searching() && !overwrite) return

    this.facadeService.updateProductResponse(this.pageIndex, this.pageSize)
    .subscribe()
  }

  private handleRequestChanged() {
    this.pageIndex = 0
    this.requestChanged.emit(this.request)
    this.load()
  }

}
