import {Component, input} from '@angular/core';
import {
  WishlistAddEntry,
  WishlistAddEntryDialogComponent
} from "../../wishlist/wishlist-add-entry-dialog/wishlist-add-entry-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {WishlistService} from "../../wishlist/model/wishlist.service";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";
import {BehaviorSubject, combineLatest, Observable, of, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {WISHLIST_ROLES} from "./wishlist-config.model";

export interface ProductInfo {
  id: string;
  description: string;
}

export type Reference = { id?: string };

@Component({
  selector: 'app-product-config-wishlist',
  templateUrl: './product-config-wishlist.component.html',
  styleUrl: './product-config-wishlist.component.scss'
})
export class ProductConfigWishlistComponent {
  pid = input.required<ProductInfo>()
  isStandalone = input(false)

  readonly WISHLIST_ROLES = WISHLIST_ROLES
  isEntryActive = false;

  private pid$ = toObservable(this.pid)
  private updateActive$ = new BehaviorSubject<boolean>(false)

  constructor(
    private dialog: MatDialog,
    private wishlistService: WishlistService
  ) {
    const isEntryActive$ = this.pid$.pipe(
      switchMap((pid) => this.wishlistService.getEntryActive(pid, this.isStandalone())),
      takeUntilDestroyed());

    combineLatest([isEntryActive$, this.updateActive$]).pipe(
      switchMap(([isEntryActive, shouldUpdate]) => {
        if (shouldUpdate) {
          return this.checkWishlistActive()
        }

        return of(isEntryActive)
      })
    ).subscribe((isEntryActive) => {
      this.isEntryActive = isEntryActive
    })

  }

  addToWishList() {
    if (!this.pid()) return
    const ref = this.dialog.open(WishlistAddEntryDialogComponent, {
      width: '50%',
      data: {
        entry: new WishlistAddEntry(this.pid().id, this.pid().description),
        entries: [new WishlistAddEntry(this.pid().id.padStart(18, '0'), this.pid().id)]
      }
    });
    ref.afterClosed().subscribe(_ => this.updateWishlistActive())
  }

  private updateWishlistActive() {
    this.updateActive$.next(true);
  }

  private checkWishlist(references: string[]) {
    return this.wishlistService.checkWishlist(references);
  }

  private checkWishlistActive(): Observable<boolean> {
    return this.pid$.pipe(
      map(pid => [pid.id.padStart(18, '0')]),
      switchMap((pid: string[]) => this.checkWishlist(pid)),
      map(entries => this.wishlistService.hasMatchingEntry(entries, this.pid().id))
    );
  }

}
