import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyRoutingModule } from "./company-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../material/material.module";
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyDetailsProfileComponent } from './company-details-profile/company-details-profile.component';
import { CompanyDetailsContactComponent } from './company-details-contact/company-details-contact.component';
import { RegisterCompanyDialogComponent } from './register-company-dialog/register-company-dialog.component';
import { CompanyContactFormComponent } from './company-contact-form/company-contact-form.component';
import { CompanyProfileFormComponent } from './company-profile-form/company-profile-form.component';
import { CompanyChangeFormComponent } from './company-change-form/company-change-form.component';
import { HeaderToolbarModule } from "../component/header-toolbar/header-toolbar.module";
import { CompanyChangeDialogComponent } from './company-change-dialog/company-change-dialog.component';


@NgModule({
  declarations: [
    CompanyDetailsComponent,
    CompanyDetailsProfileComponent,
    CompanyDetailsContactComponent,
    RegisterCompanyDialogComponent,
    CompanyContactFormComponent,
    CompanyProfileFormComponent,
    CompanyChangeFormComponent,
    CompanyChangeDialogComponent,
  ],
  exports: [
    CompanyChangeFormComponent,
    CompanyProfileFormComponent,
    CompanyContactFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    CompanyRoutingModule,
    HeaderToolbarModule
  ]
})
export class CompanyModule {
}
