import {Injectable} from '@angular/core';
import {CategoryManagerService} from "./category-manager.service";
import {Categories, CategoryGroup, ExtendedCategory} from "./facade.model";

@Injectable({
  providedIn: 'root'
})
export class CategoryGroupsManagerService {

  constructor(private categoryManagerService: CategoryManagerService) {
  }

  updateGroupTree(data: Categories, applicationId: string, technologyId: string, existingCategories: Categories, selectedValue: any) {
    const currentData = this.categoryManagerService.getLatestGroups();
    let applicationGroups = currentData.application;
    let technologyGroups = currentData.technology;
    if (applicationId) {
      this.updateGroups(applicationId, applicationGroups, data.application, existingCategories.application, selectedValue);
      this.updateRelatedGroupTreeCategories(technologyGroups, data.technology)

    } else if (technologyId) {
      this.updateGroups(technologyId, technologyGroups, data.technology, existingCategories.technology, selectedValue);
      this.updateRelatedGroupTreeCategories(applicationGroups, data.application)
    } else {
      this.updateGroups(applicationId, applicationGroups, data.application, existingCategories.application, selectedValue);
      this.updateRelatedGroupTreeCategories(technologyGroups, data.technology)

      this.updateGroups(technologyId, technologyGroups, data.technology, existingCategories.technology, selectedValue);
      this.updateRelatedGroupTreeCategories(applicationGroups, data.application)
    }

    this.categoryManagerService.updateCategoryData(data, {
      application: applicationGroups,
      technology: technologyGroups
    });
  }

  private updateGroups(id: string, previousGroups: CategoryGroup[], nextGroups: ExtendedCategory[], currentCategories: ExtendedCategory[], selectedValue: any) {
    if (this.categoryInParentCategories(id, previousGroups)) {
      this.clearChildGroups(id, previousGroups);
      currentCategories = previousGroups.slice(-1)[0].categories;
    }

    if (this.categoryInCurrent(id, previousGroups) && !selectedValue) {
      this.clearChildGroups(id, previousGroups);
      this.updateLastGroupSelectedValue(previousGroups, selectedValue);

    }

    if (selectedValue) {
      this.updateLastGroupSelectedValue(previousGroups, selectedValue);
    }
    if (nextGroups.length && id) {
      previousGroups.push(this.getGroup(id, nextGroups, currentCategories, previousGroups));

    }
  }

  private updateRelatedGroupTreeCategories(relatedTree: CategoryGroup[], newCategories: ExtendedCategory[]) {
    if (relatedTree.length) {
      relatedTree[relatedTree.length - 1].categories = newCategories;
    }
  }

  private getGroup(id: string, categories: any[], currentCategories: ExtendedCategory[], previousGroups: CategoryGroup[]) {
    const name = currentCategories.find(cat => cat.id === id)?.title || previousGroups.flatMap(gr => gr.categories).find(c => c.id === id)?.title;
    return {
      name,
      categories,
      id
    };
  }

  private categoryInParentCategories(id: string, groups: CategoryGroup[]) {
    const idInChildCategories = groups.slice(0, -1).some(group => group.categories.some(cat => cat.id === id));
    const idInParent = groups.slice(-2, -1).some(gr => gr.id === id);
    return idInChildCategories || idInParent;
  }

  private categoryInCurrent(id: string, groups: CategoryGroup[]) {
    return !!id && groups[0]?.id === id;
  }

  private updateLastGroupSelectedValue(groups: CategoryGroup[], selectedValue: any) {
    const lastGroup = groups[groups.length - 1];
    lastGroup.selectedValue = selectedValue;
  }

  private clearChildGroups(id: string, groups: CategoryGroup[]) {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].categories.some(cat => cat.id === id) || groups[i].id === id) {
        groups.splice(i + 1);
        return;
      }
    }
  }
}
