export interface StatusEvent {
  "code": number,
  "timestamp": string,
  "metadata": {
    "categoryId": number,
    "definitionId": number,
    "text": string,
    "source": {
      "thingId": number,
      "thingTypeId": number,
      "origin": string,
      "text": string,
      "inputType": string
    }
  }
}

export class EventSearchRequest {
  constructor(
    public from: string,
    public to: string,
  ) {
  }
}
