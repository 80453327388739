import {AlarmLevelEntry} from "../../site/model/site-api";
import {EventState} from "../../site/model/state-api";

export interface System {
  id: number,
  name: string,
  externalId: string,
  ownerId: number,
  type: string,
  refrigerant: string,
  ainResourceId: string,
  timezone: string
}

export interface SystemInfo {
  system: System,
  eventState: EventState,
  alarmState: AlarmLevelEntry[]
}

export class SystemUpdateRequest {
  constructor(
    public name: string = "",
    public refrigerant: string = "",
    public timezone: string = "",
    public type: string = ""
  ) {
  }
}
