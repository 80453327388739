import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "../../auth/auth.guard";
import { SeriesBoardComponent } from "./series-board/series-board.component";
import { SeriesDetailsComponent } from "./series-details/series-details.component";
import { SeriesDetailsInfoComponent } from "./series-details-info/series-details-info.component";
import { SeriesDetailsDocumentsComponent } from "./series-details-documents/series-details-documents.component";
import { SeriesDetailsProductsComponent } from "./series-details-products/series-details-products.component";
import { SeriesDetailsTabRouteData } from "./series-details-tabs/series-details-tab-route-data";
import { BETA_ACCESS, PUBLIC_ACCESS } from "../../auth/roles";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    component: SeriesBoardComponent
  },
  {
    path: 'details',
    canActivate: [AuthGuard],
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    component: SeriesDetailsComponent,
    children: [
      {
        path: '',
        redirectTo: 'information',
        pathMatch: 'full'
      },
      {
        path: 'information',
        component: SeriesDetailsInfoComponent,
        data: new SeriesDetailsTabRouteData("Information", [])
      },
      {
        path: 'documents',
        component: SeriesDetailsDocumentsComponent,
        data: new SeriesDetailsTabRouteData("Documents", [])
      },
      {
        path: 'products',
        component: SeriesDetailsProductsComponent,
        data: new SeriesDetailsTabRouteData("Products", [])
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SeriesRoutingModule {
}
