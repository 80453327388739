<app-header-toolbar
  [title]="system?.name"
  [subtitleFirst]="systemSubtitle"
  [subtitleLast]="operatorSubtitle">
  <app-header-toolbar-icon>
    <app-system-image [data]="system"></app-system-image>
  </app-header-toolbar-icon>
  <app-header-toolbar-filter *ngIf="(isHandset$ | async) == false">
    <app-generic-filter></app-generic-filter>
  </app-header-toolbar-filter>
  <app-header-toolbar-actions>
    <div>
      <button mat-icon-button *ngIf="shareService.hasSharingEditPermissions() && grantToShare && !(isHandset$ | async)"
              matTooltip="{{'action.Share' | translate}}"
              (click)="openShareDialog()"
      >
        <mat-icon>share</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="manage" *ngIf="service.hasSystemEditPermissions()"
              matTooltip="{{'menu.ShowMenu' | translate}}"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-menu #manage="matMenu">
  <ng-container *ngIf="canWrite">
    <button mat-menu-item (click)="changeSystem()">
      <mat-icon>edit</mat-icon>
      {{'action.Edit' | translate}}
    </button>
    <button mat-menu-item (click)="deleteSystem()">
      <mat-icon>delete</mat-icon>
      {{'action.Delete' | translate}}
    </button>
  </ng-container>
  <ng-container *ngIf="isHandset$ | async">
    <mat-divider></mat-divider>
    <button mat-menu-item *ngIf="shareService.hasSharingEditPermissions() && grantToShare"
            (click)="openShareDialog()">
      <mat-icon>share</mat-icon>
      {{'action.Share' | translate}}
    </button>
    <mat-divider></mat-divider>
    <app-generic-filter-menu></app-generic-filter-menu>
  </ng-container>
</mat-menu>

<div class="flex flex-col px-3 gap-2 pt-2">
  <app-hierarchy [data]="hierarchy"></app-hierarchy>
  <app-system-details-equipment-list [data]="system"></app-system-details-equipment-list>

  <div class="flex flex-row flex-wrap gap-2 items-stretch">
    <app-system-details-image [data]="system" [canWrite]=canWrite style="flex: 1 1 300px"></app-system-details-image>
    <app-system-details-notes [data]="system" [canWrite]=canWrite
                              style="flex: 1 1 calc(100% - 350px)"></app-system-details-notes>
  </div>

  <app-system-details-documents [data]="system" [canWrite]=canWrite></app-system-details-documents>
</div>
