import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SiteService } from "../model/site.service";
import { Site } from "../model/site-api";
import { ResourceHierarchyNode } from "../../hierarchy/model/hierarchy-api";
import { MatDialog } from "@angular/material/dialog";
import { DeleteSiteDialogComponent } from "../delete-site-dialog/delete-site-dialog.component";
import { ChangeSiteDialogComponent } from "../change-site-dialog/change-site-dialog.component";
import { Observable } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { AccessService } from "../../access/model/access.service";
import { TemporalAccessDialogComponent } from "../../access/temporal-access-dialog/temporal-access-dialog.component";
import { AuthService } from "../../auth/auth.service";
import { BETA_ACCESS } from "../../auth/roles";

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent {

  reloading: boolean = false
  siteId: number = 0
  site: Site | undefined
  hierarchy: ResourceHierarchyNode | undefined
  grantToShare: boolean = false
  canWrite: boolean = false

  isHandset$: Observable<boolean> = this.responsive.observe(['(max-width: 1000px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public service: SiteService,
    public shareService: AccessService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private responsive: BreakpointObserver,
    private authService: AuthService
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.siteId = parseInt(params.get('id') || '')
      this.loadData()
    })
  }


  private loadData() {
    if (!this.siteId) return
    if (this.reloading) return
    this.reloading = true

    this.service.getSite(this.siteId).subscribe(d => this.handleData(d))
    this.service.getPath(this.siteId).subscribe(data => this.hierarchy = data)
    this.shareService.getItemAccess(this.siteId).subscribe(access => {
      if (!access) return
      this.grantToShare = access.grantToShare
      this.canWrite = access.canWrite
    })
  }

  private handleData(d: Site) {
    this.site = d
    this.reloading = false
  }


  changeSite() {
    const dialogRef = this.dialog.open(ChangeSiteDialogComponent, {
      data: this.site
    })

    dialogRef.afterClosed().subscribe((data?: Site) => this.loadData())
  }

  deleteSite() {
    const dialogRef = this.dialog.open(DeleteSiteDialogComponent, {
      width: '300px',
      data: this.site
    })

    dialogRef.afterClosed().subscribe((shouldNavigate: boolean) => {
      if (shouldNavigate) this.navigateToParent()
    })
  }

  openShareDialog() {
    if (!this.siteId) return
    this.router.navigate(['access', 'site', this.siteId]).then()
  }

  openTemporalAccessDialog() {
    if (!this.siteId) return
    this.dialog.open(TemporalAccessDialogComponent, {
      data: this.site
    })
  }

  private navigateToParent() {
    if (this.hierarchy?.children.length)
      this.router.navigate(['site/' + this.hierarchy?.resource.id]).then()
    else
      this.router.navigate(['site']).then()
  }

  hasBetaAccess() {
    return this.authService.hasRole(BETA_ACCESS)
  }
}
