<div class="flex flex-row flex-wrap gap-3 justify-center mt-3">
  <ng-container *ngFor="let category of categories">
    <div class="flex flex-col items-center" style="flex: 0 1 calc(33% - 0.5rem);">
      <mat-card class="category-card">
        <mat-card-header class="justify-center mb-3">
          <mat-card-title>
            {{ category.name.en }}
          </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content (click)="selectCategory(category)">
          <img mat-card-image [src]="'https://oiservicesprod.blob.core.windows.net/exploded/svg/' + getMedia(category.media)"
               alt="Spare Part Category exploded drawing">
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</div>
