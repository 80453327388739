import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { SearchRoutingModule } from './search-routing.module';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { SiteModule } from "../site/site.module";
import { SearchActionComponent } from './search-action/search-action.component';
import { SearchBoardComponent } from './search-board/search-board.component';
import { SearchResultListComponent } from './search-result-list/search-result-list.component';
import { SearchResultEntrySiteComponent } from './search-result-entry-site/search-result-entry-site.component';
import { SearchResultEntrySystemComponent } from './search-result-entry-system/search-result-entry-system.component';
import { SearchResultEntryEquipmentComponent } from './search-result-entry-equipment/search-result-entry-equipment.component';
import { SearchResultEntryHierarchyComponent } from './search-result-entry-hierarchy/search-result-entry-hierarchy.component';
import { SearchResultEntryComponent } from './search-result-entry/search-result-entry.component';
import { SystemModule } from "../system/system.module";
import { EquipmentModule } from "../equipment/equipment.module";
import { ReactiveFormsModule } from "@angular/forms";
import { AlarmModule } from "../alarm/alarm.module";
import { FilterModule } from "../filter/filter.module";
import { ComponentModule } from "../component/component.module";


@NgModule({
  declarations: [
    SearchActionComponent,
    SearchBoardComponent,
    SearchResultListComponent,
    SearchResultEntrySiteComponent,
    SearchResultEntrySystemComponent,
    SearchResultEntryEquipmentComponent,
    SearchResultEntryHierarchyComponent,
    SearchResultEntryComponent
  ],
  exports: [
    SearchActionComponent
  ],
  imports: [
    CommonModule,
    SearchRoutingModule,
    MatPaginatorModule,
    MaterialModule,
    TranslateModule,
    SiteModule,
    NgOptimizedImage,
    SystemModule,
    EquipmentModule,
    ReactiveFormsModule,
    AlarmModule,
    FilterModule,
    ComponentModule
  ]
})
export class SearchModule {
}
