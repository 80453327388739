import { MenuItem } from "./main-menu/menu-item";
import { AuthService } from "../../auth/auth.service";

export let MAIN_MENU_CONFIG: MenuItem[] = [
  // new MenuItem('portal', 'overview', 'home', [], [], true),
  // new MenuItem('site', 'sap.iot.ain.manageequipments.equipmentListTitle', 'fact_check', [], []),
  // new MenuItem('process', 'process.Title', 'manage_history', [AuthService.PROCESS_ADMIN], []),
  // new MenuItem('accounting', 'account.accounting', 'account_balance_wallet', [], [
  //   // new MenuItem('admin/account', 'account.Title', 'account_balance', [AuthService.ACCOUNTING_ACCOUNT_ADMIN], []),
  //   // new MenuItem('admin/charge', 'charge_entry.Title', 'assignment', [AuthService.ACCOUNTING_CHARGE_ADMIN], []),
  //   // new MenuItem('admin/license', 'license.title', 'class', [AuthService.ACCOUNTING_LICENSE_ADMIN], []),
  //   // new MenuItem('admin/voucher', 'voucher.Title', 'redeem', [AuthService.ACCOUNTING_VOUCHER_ADMIN], []),
  //   // new MenuItem('admin/permission', 'permission.Title', 'admin_panel_settings', [AuthService.ACCOUNTING_PERMISSION_ADMIN], []),
  // ]),
  // new MenuItem('user', 'usermanagement.appDescription', 'manage_accounts', [AuthService.ENTITLEMENT_READ, AuthService.ENTITLEMENT_WRITE, AuthService.ENTITLEMENT_ADMIN], []),
  // new MenuItem('company', 'menu.CompanyProfile', 'corporate_fare', [AuthService.COMPANY_READ, AuthService.COMPANY_WRITE, AuthService.COMPANY_ADMIN], []),
  // new MenuItem('export', 'export.Title', 'file_download', [AuthService.ADMIN, AuthService.DATA_EXPORTER], []),
  // new MenuItem('admin', 'admin.Title', 'admin_panel_settings', [AuthService.ADMIN], []),
  // new MenuItem('backoffice', 'backoffice.Title', 'admin_panel_settings', [AuthService.SEARCH_ADMIN], []),
];

export let EXT_MAIN_MENU_CONFIG: MenuItem[] = [
  // new MenuItem('/site?appState=lean#locationlist-display?sap-ui-app-id-hint=bitzer.locationlist', 'locations', 'pin_drop', [], []),
  // new MenuItem('/site?appState=lean#ainannouncements-display?sap-ui-app-id-hint=sap.iot.ain.manageannouncements', 'menu.Announcements', 'campaign', [], []),
  // new MenuItem('/site?appState=lean#usermanagement-display?sap-ui-app-id-hint=bitzer.usermanagement', 'usermanagement.appDescription', 'manage_accounts', [], []),
  // new MenuItem('/site?appState=lean#servicemanagement-display?sap-ui-app-id-hint=bitzer.servicemanagement', 'servicemanagement.appTitle', 'settings_applications', [], []),
  // new MenuItem('/site?appState=lean#licence-display?sap-ui-app-id-hint=bitzer.licence', 'transactions', 'account_balance_wallet', [], []),
  // new MenuItem('/site?appState=lean#inviteemanagement-display?sap-ui-app-id-hint=bitzer.inviteemanagement', 'menu.PowerUser', 'contact_emergency', [AuthService.ADMIN], []),
]
