import { Component, Input } from '@angular/core';
import { MenuItem } from "../menu-item";
import { BETA_ACCESS } from "../../../../auth/roles";

@Component({
  selector: 'app-main-menu-item',
  templateUrl: './main-menu-item.component.html',
  styleUrl: './main-menu-item.component.scss'
})
export class MainMenuItemComponent {

  @Input()
  set itemData(data: MenuItem) {
    this.item = data
  }

  item: MenuItem | undefined

  constructor() {
  }

  isBeta(item: MenuItem): boolean {
    return item.roles.find(v => v == BETA_ACCESS) != null
  }
}
