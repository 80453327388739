import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { SiteBoardComponent } from "./site-board/site-board.component";
import { SiteBoardContentListComponent } from "./site-board-content-list/site-board-content-list.component";
import { SiteBoardContentMapComponent } from "./site-board-content-map/site-board-content-map.component";
import { SiteDetailsComponent } from "./site-details/site-details.component";

const routes: Routes = [
  {
    path: '',
    component: SiteBoardComponent,
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', title: 'BDN Site - List', component: SiteBoardContentListComponent },
      { path: 'map', title: 'BDN Site - Map', component: SiteBoardContentMapComponent },
    ]
  },
  {
    path: ':id',
    component: SiteDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteRoutingModule {
}
