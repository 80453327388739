<app-operational-graph-header></app-operational-graph-header>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<div class="flex flex-col p-2">
  @for (e of service.equipments; track e) {
    <app-operational-graph-equipment-entry [data]="e"></app-operational-graph-equipment-entry>
  }
</div>

