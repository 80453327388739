<mat-card>
  <mat-progress-bar *ngIf="service.sparePartsReloading | async" mode="indeterminate"></mat-progress-bar>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="dense-1">
    <input matInput (keyup)="applyFilter($event)" #input [placeholder]="'filter' | translate"/>
  </mat-form-field>
  <table mat-table [dataSource]="data" *ngIf="service.spareParts.length > 0">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>{{'itemNo' | translate}}</th>
      <td mat-cell *matCellDef="let spare;"> {{spare.position}} </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'spareParts.id' | translate}}</th>
      <td mat-cell *matCellDef="let spare"> {{spare.id}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{'spareParts.description' | translate}}</th>
      <td mat-cell *matCellDef="let spare"> {{ spare.description }} </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover-effect dense-1" (click)="service.selectSparePart(row)"
        [id]="row.position"
        [ngClass]="{ 'selected': row === service.selectedSparePart }"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
</mat-card>
