import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../material/material.module";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { SiteRoutingModule } from "./site-routing.module";
import { SiteBoardComponent } from './site-board/site-board.component';
import { SiteMapPopUpComponent } from './site-map-pop-up/site-map-pop-up.component';
import { SiteDetailsComponent } from "./site-details/site-details.component";
import { SiteDetailsAddressComponent } from "./site-details-address/site-details-address.component";
import { SiteDetailsContactComponent } from "./site-details-contact/site-details-contact.component";
import { DeleteSiteDialogComponent } from './delete-site-dialog/delete-site-dialog.component';
import { ChangeSiteDialogComponent } from './change-site-dialog/change-site-dialog.component';
import { SiteBoardHeaderComponent } from './site-board-header/site-board-header.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlarmModule } from "../alarm/alarm.module";
import { SiteBoardContentMapComponent } from './site-board-content-map/site-board-content-map.component';
import { SiteBoardContentListComponent } from './site-board-content-list/site-board-content-list.component';
import { SiteBoardContentListEntryComponent } from './site-board-content-list-entry/site-board-content-list-entry.component';
import { SystemModule } from "../system/system.module";
import { SiteImageComponent } from './site-image/site-image.component';
import { HierarchyModule } from "../hierarchy/hierarchy.module";
import { SiteDetailsSiteListComponent } from './site-details-site-list/site-details-site-list.component';
import { SiteDetailsSystemListComponent } from './site-details-system-list/site-details-system-list.component';
import { SiteDetailsSystemListEntryComponent } from './site-details-system-list-entry/site-details-system-list-entry.component';
import { SiteDetailsSiteListEntryComponent } from './site-details-site-list-entry/site-details-site-list-entry.component';
import { SiteDetailsMapComponent } from './site-details-map/site-details-map.component';
import { FilterModule } from "../filter/filter.module";
import { ComponentModule } from "../component/component.module";
import { HeaderToolbarModule } from "../component/header-toolbar/header-toolbar.module";
import { SearchModule } from "../component/search/search.module";


@NgModule({
  declarations: [
    SiteBoardComponent,
    SiteBoardHeaderComponent,
    SiteBoardContentMapComponent,
    SiteBoardContentListComponent,

    SiteDetailsComponent,
    SiteDetailsSiteListComponent,
    SiteDetailsSiteListEntryComponent,
    SiteDetailsSystemListComponent,
    SiteDetailsSystemListEntryComponent,
    SiteDetailsMapComponent,
    SiteDetailsAddressComponent,
    SiteDetailsContactComponent,
    SiteImageComponent,

    SiteMapPopUpComponent,
    DeleteSiteDialogComponent,
    ChangeSiteDialogComponent,
    SiteBoardContentListEntryComponent,


  ],
  exports: [
    SiteImageComponent
  ],
  imports: [
    CommonModule,
    SiteRoutingModule,
    TranslateModule,
    FormsModule,
    MaterialModule,
    LeafletModule,
    ReactiveFormsModule,
    AlarmModule,
    HierarchyModule,
    SystemModule,
    FilterModule,
    ComponentModule,
    HeaderToolbarModule,
    SearchModule
  ]
})
export class SiteModule {
}
