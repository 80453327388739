import { Injectable } from '@angular/core';
import { SparePartService } from "./spare-part.service";
import { SparePartCategory } from "../../product/model/product-api";
import { CategorySparePart } from "./spare-part-api";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SparePartsCategoryService {

  selectedCategory: SparePartCategory | undefined
  spareParts: CategorySparePart[] = []
  selectedSparePart: CategorySparePart | undefined

  sparePartsReloading = new BehaviorSubject<boolean>(false)
  mediaReloading = new BehaviorSubject<boolean>(false)
  selectedSparePartChanged = new BehaviorSubject<string>('')

  media: string | undefined

  constructor(private service: SparePartService) {

  }


  selectCategory(category: SparePartCategory | undefined) {
    this.selectedCategory = category
    this.spareParts = []
    this.selectedSparePart = undefined
    this.media = undefined
    this.updateData()
  }

  private updateData() {
    this.loadSpareParts()
    this.loadMedia();
  }

  private loadMedia() {
    if (this.mediaReloading.value) return
    if (!this.selectedCategory) return
    let media = this.selectedCategory.media.find(url => url.includes(".svg"))
    if (!media) return
    this.mediaReloading.next(true)
    this.service.getMediaContent(media).subscribe(c => this.handleMediaContent(c))
  }

  private handleMediaContent(c: string) {
    this.media = c
    this.mediaReloading.next(false)
  }

  private loadSpareParts() {
    if (!this.selectedCategory) return
    if (this.sparePartsReloading.value) return
    this.sparePartsReloading.next(true)
    this.service.getCategorySpareParts(this.selectedCategory.id).subscribe(s => this.handleSpareParts(s))
  }

  private handleSpareParts(s: CategorySparePart[]) {
    this.spareParts = s
    this.sparePartsReloading.next(false)
  }


  selectPosition(position: number) {
    this.selectSparePart(this.spareParts.find(s => s.position == position))
  }

  selectSparePart(sparePart: CategorySparePart | undefined) {
    this.selectedSparePart = sparePart
    this.selectedSparePartChanged.next('')
  }


}
