<div echarts
     [options]="initialValue"
     [merge]="dynamicData"
     style="height: 180px"
     (chartInit)="setChart($event)"
></div>
<div class="flex flex-row gap-4 justify-between items-center">
  <b class="m-3">{{ getName(service.weather) | translate }}</b>
  <p class="m-3">{{ 'Average' | translate }}: {{ series?.avg?.toFixed(2) }} {{ getUoMTranslation() }}</p>
  <p class="m-3">{{ 'min' | translate }}: {{ series?.minimum?.toFixed(2) }} {{ getUoMTranslation() }}</p>
  <p class="m-3">{{ 'max' | translate }}: {{ series?.maximum?.toFixed(2) }} {{ getUoMTranslation() }}</p>
  <mat-form-field>
    <mat-label>{{ 'weather' | translate }}</mat-label>
    <mat-select [(ngModel)]="service.weather" (selectionChange)="refresh()">
      @for (weather of weatherNames; track weather) {
        <mat-option [value]="weather">{{ getName(weather) | translate }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
