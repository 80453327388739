import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CartEntry } from "../model/commerce-api";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CommerceCartService } from "../model/commerce-cart.service";
import { HotToastService } from "@ngxpert/hot-toast";
import { CartDeleteDialogComponent } from "../cart-delete-dialog/cart-delete-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-cart-details-entry-list',
  templateUrl: './cart-details-entry-list.component.html',
  styleUrl: './cart-details-entry-list.component.scss'
})
export class CartDetailsEntryListComponent {
  @Input() set data(data: CartEntry[]) {
    this.dataSource.data = data
    this.dataSource.sort = this.sort
  }

  displayedColumns: string[] = ['name', 'code', 'price', 'quantity', 'total', 'cmd']

  @Output() reloadEvent = new EventEmitter<void>()

  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<CartEntry> = new MatTableDataSource()

  constructor(
    public service: CommerceCartService,
    private toast: HotToastService,
    private dialog: MatDialog
  ) {
  }

  update(entry: CartEntry, event: any) {
    this.service.updateEntry(entry.id, entry.code, event.target.value).subscribe()
  }

  delete(entry: CartEntry) {
    const dialogRef = this.dialog.open(CartDeleteDialogComponent, {
      data: entry
    })
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) return
      this.service.deleteEntry(entry.id).subscribe()
    })
  }

  protected readonly parseInt = parseInt;
}
