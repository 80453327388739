import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from "../../../auth/auth.service";
import { MenuItem } from "./menu-item";
import { Principal } from "../../../auth/principal";
import { Router } from "@angular/router";
import { EXT_MAIN_MENU_CONFIG, MAIN_MENU_CONFIG } from "../main-menu-config";
import { environment } from "../../../../environments/environment";
import { BETA_ACCESS } from "../../../auth/roles";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  @Input()
  menuItem: MenuItem[] = MAIN_MENU_CONFIG
  externalMenuItems: MenuItem[] = EXT_MAIN_MENU_CONFIG
  baseurl = environment.sapIotBaseUrl + '/cp.portal'
  helpItem: MenuItem = new MenuItem('https://wiki.bdn-live.com/', 'help', 'help', [], [])

  constructor(
    public authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.extendChildrenUrl()
  }


  logout() {
    this.authService.logout()
  }


  login() {
    this.authService.login()
  }

  public getPrincipal(): Principal | null {
    return this.authService.getPrincipal()
  }


  isAccessible(item: MenuItem): boolean {
    let principal = this.authService.getPrincipal()
    if (!principal) {
      return item.anonymous ?? false
    }

    if (item.roles.length == 0) return true
    // @ts-ignore
    return item.roles.find(role => principal.roles.find(r => r == role) != null) != null
  }

  isActive(item: MenuItem) {
    return this.router.url.includes(item.url)
  }

  isBeta(item: MenuItem): boolean {
    return item.roles.find(v => v == BETA_ACCESS) != null
  }

  private extendChildrenUrl() {
    this.menuItem.forEach(item => {
      if (!item.children.length) return
      item.children.forEach(child => child.url = item.url + '/' + child.url)
    })
  }
}
