import {Component, input, output} from '@angular/core';
import {MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-floating-button',
  standalone: true,
  imports: [
    MatFabButton,
    MatIcon,
    TranslateModule
  ],
  templateUrl: './floating-button.component.html',
  styleUrl: './floating-button.component.scss'
})
export class FloatingButtonComponent {
  title = input('');
  icon = input('');

  buttonClick = output<void>();

  onClick() {
    this.buttonClick.emit();
  }
}
