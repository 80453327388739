import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SeriesInfo } from "../model/series-api";
import { SeriesService } from "../model/series.service";

@Component({
  selector: 'app-series-details',
  templateUrl: './series-details.component.html',
  styleUrl: './series-details.component.scss'
})
export class SeriesDetailsComponent {

  reloading: boolean = false
  series: SeriesInfo | undefined
  seriesId: string = ''

  constructor(
    private route: ActivatedRoute,
    private service: SeriesService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.handleUrlChanged(params))
  }

  private reload() {
    if (!this.seriesId) return
    if (this.reloading) return
    this.service.getSeries(this.seriesId).subscribe(d => this.handleData(d))
  }


  back() {
    this.router.navigate(['portal/series']).then()
  }

  private handleUrlChanged(params: Params) {
    this.seriesId = params.series ?? ''
    this.reload()
  }

  private handleData(data: SeriesInfo) {
    this.series = data
    this.reloading = false
  }
}
