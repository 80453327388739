import {Directive, Input, OnChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "./auth.service";

@Directive({
  selector: '[hasRole]',
  standalone: true
})
export class HasRoleDirective implements OnChanges {

  @Input() hasRole: string[] = [];  // Required roles
  @Input() matchAll: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {

  }

  ngOnChanges() {
    const hasRequiredRoles = this.matchAll ? this.hasRole.every(r => this.authService.hasRole(r)) : this.hasRole.some(r => this.authService.hasRole(r))
    if (hasRequiredRoles) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
