import {Component, Input, OnChanges} from '@angular/core';
import {ProductConfigIdInfo} from "../model/product-config-api";
import {HotToastService} from "@ngxpert/hot-toast";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductConfigService} from "../model/product-config.service";

@Component({
  selector: 'app-product-config-details',
  templateUrl: './product-config-details.component.html',
  styleUrl: './product-config-details.component.scss'
})
export class ProductConfigDetailsComponent implements OnChanges {

  @Input() id!: string
  pid: ProductConfigIdInfo | null = null
  reloading: boolean = false

  constructor(
    protected service: ProductConfigService,
    private toast: HotToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }

  back() {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute}).then()
  }

  ngOnChanges(): void {
    if (this.id) {
      this.reloading = true
      this.service.getProductConfigIdInfo(this.id).subscribe({
        next: value => this.handleSelectionChanged(value),
        error: err => this.handleError(err)
      })

    }
  }

  private handleSelectionChanged(d: ProductConfigIdInfo) {
    this.pid = d
    this.reloading = false
  }

  private handleError(err: any) {
    this.toast.error("Error while loading data", err)
    this.reloading = false
  }
}
