<h1 mat-dialog-title>{{ "access.temporal.dialog.title" | translate }} - {{ data.name }}</h1>
<div mat-dialog-content class="!pt-2">
  <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
  @if (response) {
    <div class="flex flex-row justify-center items-center content-center gap-2">
      <mat-form-field class="grow shrink basis-auto" appearance="outline">
        <mat-label>{{ 'access.dialog.share.link.label' | translate }}</mat-label>
        <input matInput [value]="response.url" readonly>
      </mat-form-field>
      <button mat-flat-button [cdkCopyToClipboard]="response.url" class="grow shrink basis-auto" [disabled]="response.url.length <= 0">
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  } @else {
    <form [formGroup]="fg">
      <div class="flex flex-col gap-3">
        <mat-form-field class="grow shrink basis-auto" appearance="outline">
          <mat-label> {{ 'access.temporal.dialog.period' | translate }}</mat-label>
          <mat-select formControlName="period">
            <mat-option value="ONE_DAY">1 {{ 'day' | translate }}</mat-option>
            <mat-option value="THREE_DAY">3  {{ 'days' | translate }}</mat-option>
            <mat-option value="SEVEN_DAY">7  {{ 'days' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  }
</div>
<mat-dialog-actions align="end">
  <div class="flex flex-row gap-2">

    <button mat-button mat-stroked-button color="primary" (click)="createTemporalAccess()" class="grow shrink basis-auto" [disabled]="reloading">
      <mat-icon>move_to_inbox</mat-icon>
      {{ "access.temporal.dialog.create" | translate }}
    </button>
    <button mat-button mat-stroked-button color="warn" mat-dialog-close class="grow shrink basis-auto" [disabled]="reloading">
      <mat-icon>cancel</mat-icon>
      {{ "cancel" | translate }}
    </button>
  </div>
</mat-dialog-actions>
