import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import {
  Cart,
  CartEntryChangeRequest,
  CartEntryChangeResponse,
  CartInfo,
  OrderResponse,
  PriceRequest,
  PriceResponse,
  ShareCartRequest,
  SharedCart,
  Store
} from "./commerce-api";

@Injectable({
  providedIn: 'root'
})
export class CommerceService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/portal/commerce', logging, zone)
    this.retryCount = 0
  }

  addToCart(request: CartEntryChangeRequest): Observable<CartEntryChangeResponse> {
    return this.post('cart/entry', request)
  }

  getCart(): Observable<Cart> {
    return this.get('cart')
  }

  updateEntry(entryId: number, request: CartEntryChangeRequest): Observable<CartEntryChangeResponse> {
    return this.put(`cart/entry/${ entryId }`, request)
  }

  deleteEntry(entryId: number): Observable<CartInfo> {
    return this.delete(`cart/entry/${ entryId }`)
  }

  deleteAllEntries(): Observable<CartInfo> {
    return this.delete(`cart/entry`)
  }

  getPrices(request: PriceRequest): Observable<PriceResponse> {
    return this.post('price', request)
  }

  getStores(): Observable<Store[]> {
    return this.get('store')
  }

  placeOrder(storeId: number): Observable<OrderResponse> {
    return this.post(`store/${ storeId }/order`, {})
  }

  exportCart(): Observable<any> {
    return this.download('cart/export')
  }

  shareCart(request: ShareCartRequest): Observable<string> {
    return this.postText('cart/share', request)
  }

  getSharedCart(id: string): Observable<SharedCart> {
    return this.get('cart/share/' + id)
  }

  importSharedCartIntoCart(sharedCartId: string) {
    return this.post(`cart/share/${sharedCartId}/import`, {})
  }

  exportSharedCart(sharedCartId: string) {
    return this.download(`cart/share/${sharedCartId}/export`)
  }
}
