import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { DocumentUploadDialogComponent } from './document-upload-dialog/document-upload-dialog.component';
import { FormsModule } from "@angular/forms";


@NgModule({
  declarations: [
    DocumentUploadComponent,
    DocumentUploadDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
  ]
})
export class DocumentStorageModule { }
