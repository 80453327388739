import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Equipment} from "../../model/equipment-api";
import {EquipmentService} from "../../model/equipment.service";
import {ResourceHierarchyNode} from "../../../hierarchy/model/hierarchy-api";
import {System} from "../../../system/model/system-api";
import {Subscription} from "rxjs";
import {OperationalGraphService} from "../model/operational-graph.service";
import {SystemService} from "../../../system/model/system.service";

@Component({
  selector: 'app-operational-graph-add-equipment-dialog',
  templateUrl: './operational-graph-add-equipment-dialog.component.html',
  styleUrl: './operational-graph-add-equipment-dialog.component.scss'
})
export class OperationalGraphAddEquipmentDialogComponent implements OnInit, OnDestroy {

  selectedEquipments: Equipment[] = []
  siteId: number = 0
  isSystemView: boolean = false
  subscription: Subscription | undefined

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Equipment,
    private dialogRef: MatDialogRef<OperationalGraphAddEquipmentDialogComponent>,
    private equipmentService: EquipmentService,
    private systemService: SystemService,
    public operationalGraphService: OperationalGraphService
  ) { }

  ngOnInit() {
    this.loadEquipmentPath()
  }

  ngOnDestroy() {
    if (!this.subscription) return
    this.subscription.unsubscribe()
    this.subscription = undefined
  }

  select() {
    this.dialogRef.close(this.selectedEquipments)
  }

  cancel() {
    this.dialogRef.close()
  }

  handleSelectedEquipment(result: Equipment) {
    if (this.selectedEquipments.find(e => e.id === result.id) !== undefined) {
      const idx = this.selectedEquipments.indexOf(result)
      this.selectedEquipments.splice(idx, 1)
      return
    }

    this.selectedEquipments.push(result)
  }

  handleSelectedSystems(result: System[]) {
    this.isSystemView = false
    if (!result.length) return
    this.operationalGraphService.systems = [...result]
  }

  private loadEquipmentPath() {
    this.subscription = this.equipmentService.getPath(this.data.id).subscribe(data => this.handlePathData(data))
  }

  private handlePathData(data: ResourceHierarchyNode[]) {
    this.siteId = data[0].resource.id
    this.selectedEquipments = [...this.operationalGraphService.equipments]
    const systemId = data[0].children[0].resource.id
    if (this.operationalGraphService.systems.length) return
    this.systemService.getSystem(systemId).subscribe(data => this.operationalGraphService.systems.push(data))
  }
}
