import { Component } from '@angular/core';
import { HomeService } from "../model/home.service";

@Component({
  selector: 'app-main-header-actions',
  templateUrl: './main-header-actions.component.html',
  styleUrl: './main-header-actions.component.scss'
})
export class MainHeaderActionsComponent {



  constructor(public service: HomeService) {
  }

}
