<div class="flex flex-col lg:flex-row gap-3 p-2">
  <div class="flex flex-col gap-2 justify-start basis-1/2 h-100">
    <mat-progress-bar *ngIf="searching" mode="indeterminate"></mat-progress-bar>
    <app-spare-parts-board-content-result-list (page)="handlePageEvent($event)" (selectionChanged)="handleSelectionChanged($event)" [response]="response" [selected]="selected"
                                               class="h-100"/>
  </div>

  <div class="flex flex-col gap-2 flex-auto justify-start flex-auto">
    <app-spare-parts-board-content-details></app-spare-parts-board-content-details>
  </div>
</div>
