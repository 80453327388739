import {BaseLoader, LoaderCallback} from "./base-loader";
import {AlarmInfo} from "../../../../alarm/model/alarm-api";
import {AlarmService} from "../../../../alarm/model/alarm.service";
import {TranslateService} from "@ngx-translate/core";
import {Equipment} from "../../../model/equipment-api";
import {SeriesOption} from "echarts";
import {AlarmType} from "../../../../site/model/site-api";
import {Subject} from "rxjs";
import {map} from "rxjs/operators";
import {SystemTimeService} from "../../../../system/system-time/system-time.service";
import {DateTime} from "luxon";

export class AlarmLoader extends BaseLoader<AlarmInfo> {

  constructor(
    private service: AlarmService,
    private translate: TranslateService,
    private systemTime: SystemTimeService
  ) {
    super();
  }

  loadPage(id: number, from: string, to: string, page: number, data: AlarmInfo[], callback: Subject<LoaderCallback>) {
    this.service.search(id, from, to, this.translate.currentLang, page, this.PAGE_SIZE)
    .subscribe(d => this.handlePage(id, from, to, page, data, callback, d))
  }

  processData(data: AlarmInfo[], callback: Subject<LoaderCallback>) {
    const alarmSeries = data.map((alarm, index) => this.createAlarmSeries(alarm, index))
    callback.next({primary: alarmSeries, secondary: []})
  }

  getAlarmDetailsForTimestamp(equipment: Equipment, timestamp: string, page: number) {
    let from = DateTime.fromISO(timestamp).startOf('day').toISO() || '';
    let to = DateTime.fromISO(timestamp).endOf('day').toISO() || '';
    let min = DateTime.fromISO(timestamp).minus({minutes: 1});
    let max = DateTime.fromISO(timestamp).plus({minutes: 1});

    return this.service.search(equipment.id, from, to, this.translate.currentLang, page, this.PAGE_SIZE)
    .pipe(map(data => data.content.filter((a) => {
      const timestamp = DateTime.fromISO(this.systemTime.formatTimestamp(a.alarm.timestamp));
      return timestamp >= min && timestamp <= max;
    })));
  }

  private createAlarmSeries(alarm: AlarmInfo, index: number): SeriesOption {
    const timestamp = this.systemTime.formatTimestamp(alarm.alarm.timestamp)
    return {
      name: alarm.alarm.metadata.source.text + index,
      type: 'scatter',
      symbol: 'triangle',
      symbolSize: 18,
      symbolOffset: [0, -15],
      itemStyle: {
        color: this.setAlarmDataColor(alarm),
        borderColor: 'black',
        borderWidth: 1
      },
      data: [[timestamp, 250]],
      tooltip: {
        trigger: 'item',
        formatter: this.formatTooltipContent(alarm)
      }
    }
  }

  private setAlarmDataColor(alarm: AlarmInfo): string {
    switch (alarm.alarm.metadata.level) {
      case AlarmType.FAULT:
        return 'red'
      case AlarmType.CRITICAL:
        return 'orange'
      case AlarmType.WARNING:
        return 'yellow'
      default:
        return 'red'
    }
  }

  private formatTooltipContent(alarmInfo: AlarmInfo) {
    return `${alarmInfo.alarm.metadata.level}: ${alarmInfo.description}`
  }
}
