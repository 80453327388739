import { Component } from '@angular/core';
import { Product } from "../../product/model/product-api";
import { ProductService } from "../../product/model/product.service";
import { ProductDetailsService } from "../../product/model/product-details.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-config-product-details',
  templateUrl: './product-config-product-details.component.html',
  styleUrls: ['./product-config-product-details.component.scss']
})
export class ProductConfigProductDetailsComponent {
  reloading: boolean = false
  product: Product | undefined
  productId: string = ''
  productDetailsRoute = false

  constructor(
    private productService: ProductService,
    private productDetailsService: ProductDetailsService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.productId = params.product ?? ''
      this.productDetailsRoute = this.productDetailsService.isProductDetailsRoute()
      this.reload()
    })
  }

  private reload() {
    if (!this.productId) return
    if (this.reloading) return
    this.reloading = true
    if (this.productDetailsRoute) {
      this.productDetailsService.getProduct(this.productId)?.subscribe(p => this.handleProduct(p))
    } else {
      this.productService.getProduct(this.productId).subscribe(p => this.handleProduct(p))
    }
  }

  private handleProduct(p: Product) {
    this.product = p
    this.reloading = false
  }
}
