import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Site } from "../model/site-api";
import { FormControl, Validators } from "@angular/forms";
import { SiteService } from "../model/site.service";

@Component({
  selector: 'app-change-site-dialog',
  templateUrl: './change-site-dialog.component.html',
  styleUrls: ['./change-site-dialog.component.scss']
})
export class ChangeSiteDialogComponent {

  nameFormControl = new FormControl('', [Validators.required])

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Site,
    private dialogRef: MatDialogRef<ChangeSiteDialogComponent>,
    private service: SiteService
  ) {
    this.nameFormControl.setValue(data.name)
  }

  cancel() {
    this.dialogRef.close()
  }

  change() {
    if (!this.data.id) return

    const value = this.nameFormControl.value || ''
    this.service.setName(this.data.id, value).subscribe((data) => {
      this.dialogRef.close(data)
    })
  }
}
