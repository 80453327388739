import { Component, Input } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ProductConfigSearchResultEntry } from "../model/product-config-api";
import { ProductConfigBoardService } from "../model/product-config-board.service";

@Component({
  selector: 'app-product-config-table',
  templateUrl: './product-config-table.component.html',
  styleUrl: './product-config-table.component.scss',
})
export class ProductConfigTableComponent {
  @Input()
  set data(data: ProductConfigSearchResultEntry[]) {
    this.dataSource.data = data
    this.references = data
  }

  columnsToDisplay = ['id', 'materialNumber', 'materialName', 'type', 'status', 'cmd']
  dataSource: MatTableDataSource<ProductConfigSearchResultEntry> = new MatTableDataSource()
  references: ProductConfigSearchResultEntry[] = []

  constructor(public service: ProductConfigBoardService, private router: Router) {
  }

  showDetails(entry: ProductConfigSearchResultEntry) {
    this.router.navigate(['portal', 'pid', entry.id]).then()
  }
}
