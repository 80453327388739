<mat-card class="min-h-full">
  <div class="flex justify-center bg-gray-100 p-3 mb-4">
    <div class="text-xl font-medium">{{ title | translate }}</div>
  </div>
  <div class="flex flex-col gap-2 pb-2">
    @for (row of data; track row; let last = $last) {
        @if (row.name.length) {
          <div class="text-lg font-medium px-3">{{ row.name | translate }}</div>
        }
        @for (entry of row.entries; track entry) {
          <div class="flex flex-row gap-2 justify-between px-3">
              <div>{{ entry.key | translate }}</div>
              <div class="text-wrap">{{ entry.value }}</div>
            </div>
        }
        <mat-divider *ngIf="!last"></mat-divider>
    }
  </div>
</mat-card>
