import { Component } from '@angular/core';
import { EquipmentInfo } from "../model/equipment-api";
import { ActivatedRoute } from "@angular/router";
import { EquipmentService } from "../model/equipment.service";

@Component({
  selector: 'app-equipment-details-document',
  templateUrl: './equipment-details-document.component.html',
  styleUrls: ['./equipment-details-document.component.scss']
})
export class EquipmentDetailsDocumentComponent {

  equipment: EquipmentInfo = {} as EquipmentInfo

  constructor(
    public service: EquipmentService,
    private activatedRoute: ActivatedRoute
  ) {

    this.activatedRoute.paramMap.subscribe(paramsMap => {
      const equipmentId = Number(paramsMap.get('id'))
      if (!equipmentId) return
      this.service.getInfo(equipmentId).subscribe(equipment => {
        this.equipment = equipment
      })
    })
  }

}
