import { Component, Input } from '@angular/core';
import { Site } from "../model/site-api";

@Component({
  selector: 'app-site-image',
  templateUrl: './site-image.component.html',
  styleUrls: ['./site-image.component.scss']
})
export class SiteImageComponent {
  @Input() site: Site | undefined
}
