import {Component} from '@angular/core';
import {Product} from "../model/product-api";
import {ActivatedRoute} from "@angular/router";
import {ProductDetailsService} from "../model/product-details.service";
import {Location} from '@angular/common';
import {EMPTY, switchMap} from "rxjs";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent {

  reloading: boolean = false
  product: Product | undefined
  productId: string = ''

  bitzerSoftwareImage = 'assets/img/bitzerSoftware.png'
  epartsImage = 'assets/img/ePartsSoftware.png'
  myBitzerShopImage = 'assets/img/myBitzerShop.png'

  constructor(
    private route: ActivatedRoute,
    private productDetailsService: ProductDetailsService,
    private _location: Location
  ) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(
      switchMap(params => {
        this.productId = params.product ?? ''
        return this.productDetailsService.setProduct(this.productId) || EMPTY;
      })
    ).subscribe(p => {
      this.product = p
      this.reload()
    })
  }

  openBitzerSoftware() {
    if (!this.productId) return
    window.open('https://www.bitzer.de/websoftware/calculate.aspx?typ=' + this.productId, '_blank')
  }

  openEPartsSoftware() {
    if (!this.productId) return
    window.open('https://eparts.bitzer.de/customer.php?a=index&catalog=BITZER&show=partname&m=spc&value=' + this.productId + '&fallback=root&first=1', '_blank')
  }

  openWebShop() {
    if (!this.productId) return
    window.open('https://www.mybitzershop.com/zbitzerstorefront/bitzer/spareparts/' + this.productId, '_blank')
  }

  back() {
    this._location.back()
  }

  private reload() {
    if (!this.productId) return
    if (this.reloading) return
  }
}
