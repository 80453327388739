import { Component, Input } from '@angular/core';
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";
import { OrderStatus } from "../../sales-order/sales-order-status/sales-order-status.component";

@Component({
  selector: 'app-material-status',
  templateUrl: './material-status.component.html',
  styleUrl: './material-status.component.scss',
  standalone: true,
  imports: [
    MatIcon,
    TranslateModule,
    NgClass
  ],
})
export class MaterialStatusComponent {
  @Input() showText: boolean = false;
  @Input() showCode: boolean = false;
  @Input() status: string = '';
  @Input() showBackground: boolean = false;

  protected readonly StatusIconMap = StatusIconMap;

  get statusInfo() {
    return this.StatusIconMap[this.status] || {
      color: 'error',
      icon: 'help_outline',
      text: 'UNDEFINED',
      code: 'XX'
    };
  }
}


export const StatusIconMap: Record<string, { color: string; icon: string, text: string, code: string }> = {
  ['DEVELOPMENT']: {color: 'success', icon: 'in_progress', text: 'DEVELOPMENT', code: '10'},
  ['PROTOTYPE']: {color: 'success', icon: 'in_progress', text: 'PROTOTYPE', code: '20'},
  ['PRODUCTION']: {color: 'success', icon: 'in_progress', text: 'PRODUCTION', code: '50'},
  ['SPARE_PART']: {color: 'success', icon: 'in_progress', text: 'SPARE_PART', code: '60'},

  ['PHASED_OUT_PART']: {color: 'success', icon: 'in_progress', text: 'PHASED_OUT_PART', code: '70'},
  ['INACTIVE']: {color: 'warning', icon: 'in_progress', text: 'INACTIVE', code: '80'},
  ['SPECIAL_VERSION']: {color: 'warning', icon: 'in_progress', text: 'SPECIAL_VERSION', code: '94'},
  ['UNKNOWN']: {color: 'warning', icon: 'in_progress', text: 'UNKNOWN', code: 'XX'}
};
