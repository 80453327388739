<div class="flex flex-row gap-3 px-2 items-center entry" *ngIf="data">
  <div style="flex: 0 1 45px;">
    <app-site-image [site]="data.site"></app-site-image>
  </div>

  <div class="flex-auto">
    {{data.site.name}}
  </div>


  <app-alarm-info [alarms]="data.alarmState" class="col-2 grow-0"></app-alarm-info>
  <div class="col-1 grow-0 flex flex-col items-center pe-2">
    <app-iot-status [eventState]="data.eventState"></app-iot-status>
  </div>
</div>
