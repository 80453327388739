import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public itemId: number,
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>
  ) {
  }

  handleUploadFinished() {
    this.dialogRef.close()
  }
}
