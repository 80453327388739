import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CartEntry } from "../model/commerce-api";

@Component({
  selector: 'app-cart-delete-dialog',
  templateUrl: './cart-delete-dialog.component.html',
  styleUrl: './cart-delete-dialog.component.scss'
})
export class CartDeleteDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CartEntry
  ) {}

}
