import { Component, Input } from '@angular/core';
import { Observable } from "rxjs";
import { NavigationExtras, Router } from "@angular/router";
import { map, shareReplay } from "rxjs/operators";
import { AuthService } from "../../auth/auth.service";
import { CompanyService } from "../../company/model/company.service";
import { Company, CompanyType } from "../../company/model/company-api";
import { MatDialog } from "@angular/material/dialog";
import { RegisterCompanyDialogComponent } from "../../company/register-company-dialog/register-company-dialog.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { REGISTRATION_CREATE } from "../../auth/roles";

@Component({
  selector: 'app-site-board-header',
  templateUrl: './site-board-header.component.html',
  styleUrls: ['./site-board-header.component.scss']
})
export class SiteBoardHeaderComponent {

  @Input() showSwitchButton: boolean = false
  company: Company | undefined
  showRegisterCompany: boolean = false

  isHandset$: Observable<boolean> = this.responsive.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private authService: AuthService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private responsive: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.getCompany()
  }

  search(query: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        query: query,
        type: 'SITE'
      }
    }
    this.router.navigate(['/search'], navigationExtras).then()
  }

  isRegistrationButtonVisible(): boolean {
    return this.authService.hasRole(REGISTRATION_CREATE)
  }

  register() {
    const currentUrl = this.router.url
    localStorage.setItem('previousLocation', currentUrl)
    this.router.navigate(['registration']).then()
  }

  showRegisterCompanyDialog() {
    if (!this.company) return
    this.dialog.open(RegisterCompanyDialogComponent, {
      width: '50%',
      data: this.company
    })
  }

  private getCompany() {
    this.companyService.getOwnCompany().subscribe(data => {
      this.company = data
      this.showRegisterCompany = data.type === CompanyType.DEMO
    })
  }
}
