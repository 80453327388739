import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SystemRoutingModule } from './system-routing.module';
import { SystemBoardComponent } from './system-board/system-board.component';
import { SystemDetailsComponent } from './system-details/system-details.component';
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material/material.module";
import { SystemDetailsEquipmentListComponent } from './system-details-equipment-list/system-details-equipment-list.component';
import { SystemDetailsNotesComponent } from './system-details-notes/system-details-notes.component';
import { SystemDetailsImageComponent } from './system-details-image/system-details-image.component';
import { SystemDetailsDocumentsComponent } from './system-details-documents/system-details-documents.component';
import { SystemImageComponent } from './system-image/system-image.component';
import { HierarchyModule } from "../hierarchy/hierarchy.module";
import { ImageModule } from "../image/image.module";
import { DocumentStorageModule } from "../document-storage/document-storage.module";
import { CompanyModule } from "../company/company.module";
import { AlarmModule } from "../alarm/alarm.module";
import { ComponentModule } from "../component/component.module";
import { FilterModule } from "../filter/filter.module";
import { HeaderToolbarModule } from "../component/header-toolbar/header-toolbar.module";
import { DeleteSystemDialogComponent } from './delete-system-dialog/delete-system-dialog.component';
import { ChangeSystemDialogComponent } from './change-system-dialog/change-system-dialog.component';


@NgModule({
  declarations: [
    SystemBoardComponent,
    SystemDetailsComponent,
    SystemDetailsEquipmentListComponent,
    SystemDetailsNotesComponent,
    SystemDetailsImageComponent,
    SystemDetailsDocumentsComponent,
    SystemImageComponent,
    DeleteSystemDialogComponent,
    ChangeSystemDialogComponent
  ],
  exports: [
    SystemImageComponent
  ],
  imports: [
    CommonModule,
    SystemRoutingModule,
    TranslateModule,
    FormsModule,
    MaterialModule,
    HierarchyModule,
    ImageModule,
    DocumentStorageModule,
    CompanyModule,
    AlarmModule,
    ComponentModule,
    ReactiveFormsModule,
    FilterModule,
    HeaderToolbarModule,
  ]
})
export class SystemModule {
}
