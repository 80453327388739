import { Component, Input } from '@angular/core';
import { Site } from "../model/site-api";
import { SystemInfo } from "../../system/model/system-api";
import { PageEvent } from "@angular/material/paginator";
import { FilterService } from "../../filter/model/filter.service";
import { interval, Subscription } from "rxjs";
import { Page } from "../../utils/page";
import { SystemService } from "../../system/model/system.service";
import { Router } from "@angular/router";
import { RegistrationSwitchService } from "../../registration/model/registration-switch.service";
import { RegistrationProcessService } from "../../registration/model/registration-process.service";
import { HotToastService } from "@ngxpert/hot-toast";

@Component({
  selector: 'app-site-details-system-list',
  templateUrl: './site-details-system-list.component.html',
  styleUrls: ['./site-details-system-list.component.scss']
})
export class SiteDetailsSystemListComponent {
  @Input()
  set data(data: Site | undefined) {
    this.site = data
    this.updateData()
  }

  site: Site | undefined
  reloading: boolean = false
  pageIndex: number = 0
  pageSize: number = 20
  totalSize: number = -1
  systems: SystemInfo[] = []

  private filterSubscription: Subscription | undefined
  private reloadSubscription: Subscription | undefined

  constructor(
    private service: SystemService,
    private filterService: FilterService,
    public registrationService: RegistrationProcessService,
    private switchService: RegistrationSwitchService,
    private toastService: HotToastService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.filterSubscription = this.filterService.activeFilter.subscribe(_ => {
      if (this.filterService.activated) this.load(0, this.pageSize)
    })

    this.reloadSubscription = interval(180000).subscribe(_ => this.load(this.pageIndex, this.pageSize))
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe()
      this.filterSubscription = undefined
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe()
      this.reloadSubscription = undefined
    }
  }

  private updateData() {
    this.load(0, this.pageSize)
  }

  private load(page: number, size: number) {
    if (!this.site) return
    if (this.reloading) return
    this.reloading = true
    if (this.filterService.activated) {
      let filter = this.filterService.activeFilter.value
      this.service.getFilteredSystems(this.site.id, filter, page, size).subscribe(d => this.handleData(d))
    } else {
      this.service.getSystems(this.site.id, page, size).subscribe(d => this.handleData(d))
    }
  }

  private handleData(p: Page<SystemInfo>) {
    this.systems = p.content
    this.pageIndex = p.pageable.number
    this.pageSize = p.pageable.size
    this.totalSize = p.totalSize
    this.reloading = false
  }

  handlePaginatorEvent(event: PageEvent) {
    this.load(event.pageIndex, event.pageSize)
  }


  onAddSystem() {
    if (!this.site) return
    const currentUrl = this.router.url
    localStorage.setItem('previousLocation', currentUrl)
    this.registrationService.selectSite(this.site.id).subscribe({
      next: (value) => this.router.navigate(['registration']).then(),
      error: err => this.toastService.error("Error on site creation")
    })
  }
}
