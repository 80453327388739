@if (service.isLoggedIn()) {
  <button class="flex justify-center items-center flex-auto mx-2" [matMenuTriggerFor]="menu">
    <div class="flex justify-center items-center rounded-lg font-bold text-xl highlight text-white w-12 h-12">
      {{ service.initials }}
    </div>
  </button>
} @else {
  <button class="flex flex-col gap-2 items-center text-sm main-header-action" (click)="login()">
    <mat-icon>login</mat-icon>
    <span>{{ 'menu.Login' | translate }}</span>
  </button>
}


<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="profile">
    <mat-icon>person</mat-icon>
    <span>{{ 'profile' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>toggle_off</mat-icon>
    <span>{{ 'menu.Logout' | translate }}</span>
  </button>
</mat-menu>
