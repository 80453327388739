import {FilterGroup} from "../../../component/filter/filter-group/filter-group.component";
import {Category} from "../../category/model/category-api";

export interface ProductState {
  productResponse?: SearchResponse;
  pageSize?: number;
  pageIndex?: number;
  totalSize?: number;
  filterGroups: FilterGroup[][];
}

export interface SearchResponse {
  categories: any[];

  [key: string]: any;
}

export interface Categories {
  application: ExtendedCategory[];
  technology: ExtendedCategory[];
  productResponse: SearchResponse | null;
}

export interface CategoryGroups {
  application: CategoryGroup[],
  technology: CategoryGroup[]
}

export interface ExtendedCategory extends Category {
  amount?: number;
  category: Category;
}

export interface CategoryGroup {
  categories: ExtendedCategory[];
  name: string | undefined;
  id: string | undefined;
  selectedValue?: any;
}

export interface ProductSearchParams {
  categoryIds?: string[];
  technologyIds?: string[];
  seriesIds?: string[];
  fullTextSearch?: string;
}

export interface UrlParams {
  query?: string;
  ca?: string;
  ct?: string;
}

export interface DocumentSearchParams {
  documentTypes?: string[];
  productIds?: string[];
}

export interface SearchParams extends ProductSearchParams, UrlParams, DocumentSearchParams {
  applicationId: string;
  technologyId: string;
  selectedTechnologyId?: string;
  selectedApplicationId?: string;
  groupName?: string;
  parentGroupName?: string;
  categoryId?: string;
  selectedValue?: unknown;
  selectedIds?: string[];
  country?: string;
}

export const defaultSearchParams: SearchParams = {
  applicationId: '',
  technologyId: '',
  query: '',
  ca: '',
  ct: '',
  productIds: [],
  selectedTechnologyId: '',
  selectedApplicationId: '',
  groupName: '',
  parentGroupName: '',
  categoryId: '',
  selectedValue: undefined,
  selectedIds: [],
  country: ''
};
