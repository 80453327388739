import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ResourceHierarchyNode, ResourceType } from "../../hierarchy/model/hierarchy-api";
import { EquipmentInfo } from "../model/equipment-api";
import { EquipmentService } from "../model/equipment.service";
import { LicenseService } from "../../accounting/model/license.service";
import { LicenseInfo } from "../../accounting/model/license-api";
import { MatDialog } from "@angular/material/dialog";
import { DeleteEquipmentDialogComponent } from "../delete-equipment-dialog/delete-equipment-dialog.component";
import { ChangeEquipmentDialogComponent } from "../change-equipment-dialog/change-equipment-dialog.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { CompanyService } from "../../company/model/company.service";
import { CompanyType } from "../../company/model/company-api";
import { LicenseActivationDialogComponent } from "../../accounting/license-activation-dialog/license-activation-dialog.component";
import { AccessService } from "../../access/model/access.service";

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent {


  equipment: EquipmentInfo | undefined
  hierarchy: ResourceHierarchyNode[] = []
  license: LicenseInfo | undefined
  grantToShare: boolean = false
  canWrite: boolean = false
  bitzerSoftwareImage = 'assets/img/bitzerSoftware.png'
  epartsImage = 'assets/img/ePartsSoftware.png'
  myBitzerShopImage = 'assets/img/myBitzerShop.png'
  isDemoCompany: boolean = false

  isHandset$: Observable<boolean> = this.responsive.observe(['(max-width: 1000px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public service: EquipmentService,
    private activatedRoute: ActivatedRoute,
    public shareService: AccessService,
    public licenseService: LicenseService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private router: Router,
    private responsive: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    const equipmentId = this.activatedRoute.snapshot.params.id
    this.service.getInfo(equipmentId).subscribe(data => this.equipment = data)
    this.service.getPath(equipmentId).subscribe(data => this.hierarchy = data)
    this.companyService.getOwnCompany().subscribe(data => this.isDemoCompany = data.type === CompanyType.DEMO)
    this.shareService.getEquipmentAccess(equipmentId).subscribe(access => {
      if (!access) return
      this.grantToShare = access.grantToShare
      this.canWrite = access.canWrite
    })

    this.licenseService.findByEquipment(equipmentId).subscribe(info => {
      if (!info) return
      this.license = info
    })

  }

  openBitzerSoftware() {
    if (!this.equipment) return
    window.open('https://www.bitzer.de/websoftware/calculate.aspx?typ=' + this.equipment.equipment.productId, '_blank')
  }

  openEPartsSoftware() {
    if (!this.equipment) return
    window.open('https://eparts.bitzer.de/customer.php?a=index&catalog=BITZER&show=partname&m=spc&value=' + this.equipment.equipment.productId + '&fallback=root&first=1', '_blank')
  }

  openWebShop() {
    if (!this.equipment) return
    window.open('https://www.mybitzershop.com/zbitzerstorefront/bitzer/spareparts/' + this.equipment.equipment.productId, '_blank')
  }

  openEquipmentReport() {
    if (!this.equipment) return
    if (this.service.hasExemptPermissions() || this.licenseService.hasBPlusLicense(this.license)) {
      this.router.navigate(['equipment', this.equipment.equipment.id, 'operational', 'report']).then()
    }
  }

  openEquipmentGraph() {
    if (!this.equipment) return
    if (this.service.hasExemptPermissions() || this.licenseService.hasBPlusLicense(this.license)) {
      this.router.navigate(['equipment', this.equipment.equipment.id, 'operational', 'graph']).then()
    }
  }

  onOpenLicenseActivation() {
    if (!this.equipment) return
    const dialogRef = this.dialog.open(LicenseActivationDialogComponent, {
      width: '50%',
      data: this.equipment.equipment,
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(_ => {
      window.location.reload()
    })
  }

  changeEquipment() {
    const dialogRef = this.dialog.open(ChangeEquipmentDialogComponent, {
      width: '300px',
      data: this.equipment?.equipment
    })

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) window.location.reload()
    })
  }

  deleteEquipment() {
    const dialogRef = this.dialog.open(DeleteEquipmentDialogComponent, {
      width: '300px',
      data: this.equipment?.equipment
    })

    dialogRef.afterClosed().subscribe((shouldNavigate: boolean) => {
      if (shouldNavigate) this.navigateToParent()
    })
  }

  openShareDialog() {
    let siteId = this.findResource(ResourceType.SITE)?.id
    if (!siteId) return
    this.router.navigate(['access', 'site', siteId]).then()
  }

  private findResource(resourceType: ResourceType) {
    if (!this.hierarchy.length) return
    let hier = this.hierarchy[0]
    let resource
    hier?.children.forEach(c => {
      if (c.resource.type === resourceType) resource = c.resource
    })
    return resource ? resource : hier?.resource
  }

  private navigateToParent() {
    const resource = this.findResource(ResourceType.SYSTEM)
    if (resource)
      this.router.navigate(['system/' + resource.id]).then()
    else
      this.router.navigate(['site']).then()
  }
}
