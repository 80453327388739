<table [dataSource]="dataSource" mat-table>
  <ng-container matColumnDef="id">
    <th *matHeaderCellDef mat-header-cell>{{ 'pid.id' | translate }}</th>
    <td *matCellDef="let productConfig;" mat-cell> {{ productConfig.id | stripLeadingZeros }}</td>
  </ng-container>

  <ng-container matColumnDef="materialNumber">
    <th *matHeaderCellDef mat-header-cell>{{ 'pid.materialNumber' | translate }}</th>
    <td *matCellDef="let productConfig;" mat-cell> {{ productConfig.matNrKMat | stripLeadingZeros }}</td>
  </ng-container>

  <ng-container matColumnDef="materialName">
    <th *matHeaderCellDef mat-header-cell>{{ 'pid.materialName' | translate }}</th>
    <td *matCellDef="let productConfig;" mat-cell> {{ productConfig.name | stripLeadingZeros }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th *matHeaderCellDef mat-header-cell>{{ 'pid.type' | translate }}</th>
    <td *matCellDef="let productConfig;" mat-cell>
      <a [queryParams]="{ product: productConfig.type }" [routerLink]="['/portal/product/details/information']"
         class="bolder underline cursor-pointer flex align-middle" target="_blank">
        {{ productConfig.type }}
      </a>
  </ng-container>

  <ng-container matColumnDef="status">
    <th *matHeaderCellDef mat-header-cell>{{ 'material.status' | translate }}</th>
    <td *matCellDef="let productConfig;" mat-cell>
      <div class="flex flex-row gap-2">
        <app-material-status [showText]="true" [status]="productConfig.status" class="bg-gray-200 rounded px-2.5 py-1.5"></app-material-status>
        <div *ngIf="productConfig.standard" class="bg-gray-200 rounded px-2.5 py-1.5">{{ 'pid.standard' | translate }}</div>
        <div *ngIf="productConfig.suggested" class="bg-gray-200 rounded px-2.5 py-1.5">{{ 'pid.suggested' | translate }}</div>
      </div>
    </td>
  </ng-container>


  <ng-container matColumnDef="cmd">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let productConfig" mat-cell>
      <div class="flex flex-row gap-3 justify-end">

        <app-product-config-cart [currency]="service.currency" [data]="service.getPriceEntry(productConfig)" [pid]="productConfig"></app-product-config-cart>
        <app-product-config-wishlist [pid]="{id: productConfig.id, description: productConfig.id}"></app-product-config-wishlist>
        <app-product-config-compare-button [pid]="productConfig.id"/>
        <button (click)="showDetails(productConfig)" color="primary" mat-mini-fab>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>


  <tr *matHeaderRowDef="columnsToDisplay" class="background-primary" mat-header-row></tr>
  <tr *matRowDef="let element; columns: columnsToDisplay;" mat-row>
  </tr>
</table>
