import { Injectable, NgZone } from '@angular/core';
import { ActiveAlarmInfoEntry, AlarmInfo, AlarmSearchRequest } from "./alarm-api";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { BaseService } from "../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";

@Injectable({
  providedIn: 'root'
})
export class AlarmService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/alarm', logging, zone)
  }

  search(equipmentId: number, start: string, end: string, lang: string, page: number, size: number): Observable<Page<AlarmInfo>> {
    const request: AlarmSearchRequest = {
      from: start,
      to: end
    }
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.postPaged('search/' + equipmentId + '/info', request, page, size, params)
  }


  getActiveAlarms(equipmentId: number, page: number, size: number, lang: string): Observable<Page<AlarmInfo>> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.getPaged(equipmentId + '/active', page, size, params)
  }


  getActiveAlarmInfo(equipmentId: number): Observable<ActiveAlarmInfoEntry[]> {
    return this.get(equipmentId + '/active/info')
  }
}
