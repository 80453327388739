import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Document } from "./document-api";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private translateService: TranslateService,
  ) {
    super(http, 'api/knowledge/document', logging, zone)
    this.retryCount = 0
  }

  getDocumentsByIds(ids: string[]): Observable<HttpResponse<Document[]>> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.postResponse('', ids, params)
  }
}
