import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stripLeadingZeros'
})
export class StripLeadingZerosPipe implements PipeTransform {
  transform(value: string): string {
    return value?.replace(/^0+/, '');
  }
}
