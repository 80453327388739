import { Component } from '@angular/core';

@Component({
  selector: 'app-site-board',
  templateUrl: './site-board.component.html',
  styleUrls: ['./site-board.component.scss']
})
export class SiteBoardComponent {

}
