<div class="feedback-form-wrapper">
  <h3 class="form-title" *ngIf="formTitle">{{formTitle}}</h3>

  <form [formGroup]="feedbackForm" class="feedback-form">
    <div class="form-body">
      <div class="rating-values">
        <label for="point-1" class="rating-radio">
          <input type="radio"  id="point-1" value="1" formControlName="givenPoint">
          <span class="radio-text">1</span>
          <span class="checkmark"></span>
        </label>
  
        <label for="point-2" class="rating-radio">
          <input type="radio"  id="point-2" value="2" formControlName="givenPoint">
          <span class="radio-text">2</span>
          <span class="checkmark"></span>
        </label>
      </div>
      
      <mat-form-field>
        <mat-label>Textarea</mat-label>
        <textarea matInput formControlName="feedbackText"></textarea>
      </mat-form-field>
    </div>

    <button mat-flat-button color="primary" (click)="submitForm()">Submit</button>
  </form>
</div>