<mat-form-field class="dense-1 flex-auto font-medium rounded bg-white" appearance="outline" subscriptSizing="dynamic">
  <input matInput type="string" #searchText [value]="service.query" (keydown.enter)="search(searchText.value)" (keyup)="onSearchChange(searchText.value)">
  @if (service.query.length <= 0) {
    <button mat-icon-button matSuffix (click)="search(searchText.value)">
      <mat-icon>search</mat-icon>
    </button>
  } @else {
    <button mat-icon-button matSuffix (click)="reset()">
      <mat-icon>search_off</mat-icon>
    </button>
  }
</mat-form-field>
