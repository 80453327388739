import {Injectable, signal} from '@angular/core';
import {BehaviorSubject, forkJoin, of} from "rxjs";
import {Categories, CategoryGroups, ExtendedCategory} from "./facade.model";
import {map, tap} from "rxjs/operators";
import {CategoryService} from "../../category/model/category.service";
import {Category} from "../../category/model/category-api";

@Injectable({
  providedIn: 'root'
})
export class CategoryManagerService {
  private readonly categoriesSubject = new BehaviorSubject<Categories>({
    application: [],
    technology: [],
    productResponse: null
  });
  private readonly categoryGroupsSubject = new BehaviorSubject<CategoryGroups>({
    application: [],
    technology: []
  });
  private readonly APPLICATION = 'Application';
  private readonly TECHNOLOGY = 'Technology';
  private previouslySelectedCategoryParent = signal<string>('')


  constructor(private categoryService: CategoryService
  ) {
  }

  setCategoryByType(payload: any) {
    const currentCategory = payload.categoryId;
    if ([payload.parentGroupName, payload.groupName].includes(this.APPLICATION)) {
      payload.applicationId = currentCategory;
      payload.technologyId = '';
    } else {
      payload.technologyId = currentCategory;
      payload.applicationId = '';
    }

    payload.selectedIds = this.setSelectedIds(payload.selectedIds, currentCategory, payload.parentGroupName);
    this.previouslySelectedCategoryParent.set(payload.parentGroupName);
  }

  loadRootCategories(applicationId: string | undefined, technologyId: string | undefined, country: string, productResponse: any) {
    return forkJoin({
      application: this.loadRootCategoryData(applicationId, country, productResponse),
      technology: this.loadRootCategoryData(technologyId, country, productResponse)
    }).pipe(map(data => ({...data, productResponse})), tap(data => {
      const groups = {
        application: [{
          name: this.APPLICATION,
          id: "",
          categories: data.application
        }]
        ,
        technology: [{
          name: this.TECHNOLOGY,
          id: "",
          categories: data.technology
        }]
      };
      this.updateCategoryData(data, groups)
    }));
  }

  loadCategories(applicationId: string | undefined, technologyId: string | undefined, country: string | undefined) {
    const lastCategories = this.getLatestCategories()
    const categoryRequests$ = [
      applicationId ? this.loadCategory(applicationId, country) : of(lastCategories.application),
      technologyId ? this.loadCategory(technologyId, country) : of(lastCategories.technology)
    ];

    return forkJoin(categoryRequests$);
  }

  mapExistingCategories(existingCategories: Category[], productResponse: {
    categories: any[]
  } | null): ExtendedCategory[] {
    if (productResponse) {
      const productCategories = productResponse.categories;

      return existingCategories.map(existingCategory => {
        const matchedCategory = productCategories.find(productCategory => productCategory.categoryId.toString() === existingCategory.id);

        return {
          ...existingCategory,
          amount: matchedCategory?.amount,
          category: existingCategory
        };
      });

    }
    return [];
  }

  getLatestCategories() {
    return this.categoriesSubject.value;
  }

  getLatestGroups() {
    return this.categoryGroupsSubject.value
  }

  updateCategoryData(data: Categories, groups: CategoryGroups) {
    const previousCategories = this.categoriesSubject.value;
    data.application = data.application.length ? data.application : previousCategories.application;
    data.technology = data.technology.length ? data.technology : previousCategories.technology;

    this.categoriesSubject.next(data);
    this.categoryGroupsSubject.next(groups);
  }

  getRootCategories() {
    return this.categoryService.getRootCategories();
  }

  isRootCategory(categoryId: any) {
    return [this.categoryGroupsSubject.value.application[0].id, this.categoryGroupsSubject.value.technology[0].id].includes(categoryId)
  }

  private loadCategory(categoryId: string | undefined, country = '') {
    if (!categoryId) {
      return of([]);
    }

    return this.categoryService.getChildren(categoryId, country);
  }

  private loadRootCategoryData(categoryId: string | undefined, country = '', productResponse: any | null) {
    if (!categoryId) {
      return of([] as ExtendedCategory[]);
    }

    return this.loadCategory(categoryId, country).pipe(map((currentCategories) => this.mapExistingCategories(currentCategories, productResponse)));
  }

  private setSelectedIds(selectedIds: string[], currentCategory: any, currentParentGroup: string) {
    if (currentParentGroup === this.APPLICATION && selectedIds.length === 2) {
      return [currentCategory, selectedIds[1]];
    } else if (currentParentGroup === this.TECHNOLOGY && selectedIds.length === 2) {
      return [selectedIds[0], currentCategory];
    }

    if (currentParentGroup === this.previouslySelectedCategoryParent()) {
      return [currentCategory]
    }

    if (currentParentGroup === this.APPLICATION) {
      return [currentCategory, selectedIds[0]];
    }

    return [selectedIds[0], currentCategory];
  }
}
