import {Component, inject, Input} from '@angular/core';
import {ProductConfigIdInfo} from "../model/product-config-api";
import {PERMISSION_EXPORT_ADMIN, PERMISSION_EXPORT_USER} from "../../export/model/export.service";
import {ProductConfigService} from "../model/product-config.service";

@Component({
  selector: 'app-product-config-details-title',
  templateUrl: './product-config-details-title.component.html',
  styleUrl: './product-config-details-title.component.scss'
})
export class ProductConfigDetailsTitleComponent {
  roles = [PERMISSION_EXPORT_ADMIN, PERMISSION_EXPORT_USER];
  service = inject(ProductConfigService)

  @Input() pid!: ProductConfigIdInfo;

  getProvider(id: string) {
    return () => this.service.exportPidData(id)
  }

}
