<ng-template
  [ngTemplateOutlet]="nodeTemplateRef"
  [ngTemplateOutletContext]="{ $implicit: data }">
</ng-template>
<ng-template #nodeTemplateRef let-node>
  <ng-container *ngIf="node.children.length">
    <div class="node">
      <span>{{ node.resource.name }} // </span>
    </div>
    <ng-template ngFor [ngForOf]="node.children" [ngForTemplate]="nodeTemplateRef"></ng-template>
  </ng-container>

</ng-template>
