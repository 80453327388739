<div class="px-2 pt-2">
  <app-category-filter-list (responseChanged)="handleData($event)" [categorySelectorVisible]="categorySelectorVisible" [categoryType]="type"/>
</div>
<div class="flex flex-col lg:flex-row gap-2 p-2">

  <div class="flex flex-col gap-2 flex-auto justify-start">
    <mat-progress-bar *ngIf="searching()" mode="indeterminate"></mat-progress-bar>
    <app-series-board-content-result-list (page)="handlePageEvent($event)" [response]="response">

    </app-series-board-content-result-list>
  </div>
</div>
