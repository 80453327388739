<mat-toolbar>Menu</mat-toolbar>
<div class="flex flex-col gap-4 justify-center content-center p-3 flex-fill">
  <div class="flex flex-col gap-3">
    @for (item of menuItem.filter(isAccessible.bind(this)); track item) {
      <app-main-menu-item [itemData]="item"></app-main-menu-item>

      @if (isActive(item) && item.children.length) {
        @for (child of item.children.filter(isAccessible.bind(this)); track child) {
          <app-main-menu-item [itemData]="child"></app-main-menu-item>
        }
      }

    }
    @if (externalMenuItems.length > 0) {
      <mat-divider></mat-divider>
      @for (item of externalMenuItems; track item) {
        <div class="flex flex-row gap-3 items-center ">
          <mat-icon>{{ item.icon }}</mat-icon>
          <div class="flex flex-row gap-2 justify-between content-start flex-auto">
            <div>{{ item.text | translate }}</div>
            <div *ngIf="isBeta(item)"><span class="chip">{{ 'Beta' | translate }}</span></div>
          </div>
        </div>
      }
    }
    <mat-divider></mat-divider>

    <a href="{{helpItem.url}}" routerLinkActive="highlight" target="_blank" class="flex flex-col main-menu-item h-10 justify-center p-3 rounded-md">
      <div class="flex flex-row gap-3 items-center">
        <mat-icon>{{ helpItem.icon }}</mat-icon>
        <div>{{ helpItem.text | translate }}</div>
      </div>
    </a>

    <a routerLink="info" routerLinkActive="highlight" class="flex flex-col main-menu-item h-10 justify-center p-3 rounded-md">
      <div class="flex flex-row gap-3 items-center">
        <mat-icon>info</mat-icon>
        <div>{{ 'bdnInfo' | translate }}</div>
      </div>
    </a>
    <div class="flex-auto"></div>
    @if (authService.isLoggedIn()) {
      <a (click)="logout()" class="flex flex-col main-menu-item h-10 justify-center p-3 rounded-md">
        <div class="flex flex-row gap-3 items-center ">
          <mat-icon>toggle_off</mat-icon>
          <span class="flex-auto">{{ 'menu.Logout' | translate }}</span>
        </div>
      </a>
    } @else {
      <a (click)="login()" class="flex flex-col main-menu-item h-10 justify-center p-3 rounded-md">
        <div class="flex flex-row gap-3 items-center ">
          <mat-icon>toggle_off</mat-icon>
          <span class="flex-auto">{{ 'menu.Login' | translate }}</span>
        </div>
      </a>
    }
  </div>
</div>
