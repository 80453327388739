import { Component, Input } from '@angular/core';
import { SystemInfo } from "../../system/model/system-api";

@Component({
  selector: 'app-site-details-system-list-entry',
  templateUrl: './site-details-system-list-entry.component.html',
  styleUrls: ['./site-details-system-list-entry.component.scss']
})
export class SiteDetailsSystemListEntryComponent {
  @Input() data: SystemInfo | undefined
}
