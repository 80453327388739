<div class="flex flex-col gap-3 p-2" (click)="navigate()" >
  <div class="flex flex-row content-center items-center gap-2">
    <!-- icon-->
    <div style="flex: 0 1 calc(10%);">
      <ng-content select="icon"></ng-content>
    </div>

    <!-- hierarchy -->
    <div class="col-5 flex flex-col gap-1">
      <div>{{name}}</div>
      <div class="flex flex-row gap-2">
        <ng-content select="hierarchy"></ng-content>
      </div>
    </div>

    <!-- details -->
    <div class="flex flex-col gap-2 grow col-2 info">
      <ng-content select="details"></ng-content>
    </div>

    <!-- status -->
    <div class="col-2 flex flex-row content-center items-center">
      <div class="col-4">
        <app-iot-status [eventState]="eventState" *ngIf="eventState"></app-iot-status>
      </div>
      <div class="col-4">
        <app-time-in-fault [state]="eventState" [showUndefined]="false" *ngIf="eventState"></app-time-in-fault>
      </div>
      <div class="col-4">
        <app-alarm-info [alarms]="alarmState" *ngIf="alarmState.length"></app-alarm-info>
      </div>
    </div>

    <!-- nav -->
    <div style="flex: 0 1 auto;" class="ps-4">
      <a mat-icon-button [routerLink]="link" *ngIf="link">
        <mat-icon>read_more</mat-icon>
      </a>
    </div>
  </div>

</div>
<mat-divider></mat-divider>
