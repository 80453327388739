import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "../auth/auth.guard";
import { CartDetailsComponent } from "./cart-details/cart-details.component";
import { COMMERCE_ADMIN, COMMERCE_READ } from "../auth/roles";
import { SharedCartInfoComponent } from "./shared-cart-info/shared-cart-info.component";

const routes: Routes = [
  {
    path: '',
    component: CartDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [COMMERCE_READ, COMMERCE_ADMIN] },
  },
  {
    path: 'shared/:id',
    component: SharedCartInfoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommerceRoutingModule {
}
