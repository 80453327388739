import { Component } from '@angular/core';
import { HomeService } from "../model/home.service";

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrl: './main-footer.component.scss'
})
export class MainFooterComponent {

  constructor(private service: HomeService) {
  }

  openCookieSettings() {
    this.service.openCookieSettings()
  }
}
