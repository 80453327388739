<div *ngIf="data && data.activated">
  <button mat-mini-fab style="background-color: white; color: black;"
          class="flex-auto" (click)="onOpenActivationConfig()"
          matTooltip="{{'iotconfig' | translate}}">
    <mat-icon>cell_wifi</mat-icon></button>
</div>
<ng-container *ngIf="data && !data.activated">
  <button mat-flat-button class="flex-auto" (click)="activate()">
    <mat-icon>link</mat-icon>
    {{"activate" | translate}}</button>
</ng-container>
