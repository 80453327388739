import { Component, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-company-profile-form',
  templateUrl: './company-profile-form.component.html',
  styleUrls: ['./company-profile-form.component.scss']
})
export class CompanyProfileFormComponent {

  @Input() readonly: boolean = false
  @Input() form: FormGroup | undefined
}
