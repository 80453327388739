import { Component, Input } from '@angular/core';
import { EquipmentAlarmsReport } from "../model/operational-report-alarms-api";

@Component({
  selector: 'app-operational-report-equipment-alarms',
  templateUrl: './operational-report-equipment-alarms.component.html',
  styleUrl: './operational-report-equipment-alarms.component.scss'
})
export class OperationalReportEquipmentAlarmsComponent {

  @Input()
  set alarmReportData(data: EquipmentAlarmsReport | undefined) {
    this.alarmReport = data
  }

  alarmReport: EquipmentAlarmsReport | undefined

  constructor() { }
}
