import { Component } from '@angular/core';
import { SearchService } from "../model/search.service";

@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss']
})
export class SearchResultListComponent {
  constructor(public service: SearchService) {
  }
}
