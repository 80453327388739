import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { CompanyDetailsComponent } from "./company-details/company-details.component";

const routes: Routes = [
  { path: '', redirectTo: 'details', pathMatch: 'full' },
  { path: 'details', component: CompanyDetailsComponent }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule {
}
