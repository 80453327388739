<div class="dialog-width">
  <h1 mat-dialog-title class="mb-4"> {{'site.Dialog.Change.Title' | translate}} </h1>
  <form>
    <mat-form-field class="basis-full" appearance="outline">
      <mat-label> {{'site.Dialog.Change.Label' | translate}} </mat-label>
      <input type="text" matInput [formControl]="nameFormControl" />
      <mat-error *ngIf="nameFormControl.hasError('required')">
        {{'site.Dialog.Change.ErrorMessage' | translate}}
      </mat-error>
    </mat-form-field>
  </form>
  <div mat-dialog-actions class="float-end p-3">
    <button mat-raised-button color="primary" (click)="change()" [disabled]="!nameFormControl.valid">
      {{'action.Save' | translate}}
    </button>
    <button mat-stroked-button (click)="cancel()"> {{'cancel' | translate}}  </button>
  </div>
</div>
