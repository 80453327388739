<app-search-result-entry [name]="system?.name" [eventState]="eventState" [alarmState]="alarmState" [link]="getRouterLink()" *ngIf="entry">
  <app-system-image [data]="system" ngProjectAs="icon"></app-system-image>
  <app-search-result-entry-hierarchy [data]="hierarchy" *ngIf="hierarchy" ngProjectAs="hierarchy"></app-search-result-entry-hierarchy>
  <ng-container ngProjectAs="details">
    <div class="flex flex-row gap-2">
      <div class="col-4">{{'type' | translate}}</div>
      <div>{{system?.type}}</div>
    </div>
    <div class="flex flex-row gap-2">
      <div class="col-4">{{'refrigerant' | translate}}</div>
      <div>{{system?.refrigerant}}</div>
    </div>
  </ng-container>
</app-search-result-entry>
