import {Injectable, signal} from '@angular/core';
import {Category} from "../../category/model/category-api";
import {HotToastService} from "@ngxpert/hot-toast";
import {Observable, of, Subject, switchMap} from "rxjs";
import {catchError, finalize, map, tap} from "rxjs/operators";
import {toSignal} from "@angular/core/rxjs-interop";
import {TranslateService} from "@ngx-translate/core";
import {FilterService} from "../../../component/filter/filter.service";
import {SearchService} from "../../search/model/search.service";
import {DocumentSearchRequest, ProductSearchRequest} from "../../search/model/search-api";
import {
  ContentCategoryType
} from "../../../component/category/category-filter-list/category-filter-list.component";
import {defaultSearchParams, ProductState, SearchParams, SearchResponse} from "./facade.model";
import {CategoryManagerService} from "./category-manager.service";
import {CategoryGroupsManagerService} from "./category-groups-manager.service";

@Injectable({
  providedIn: 'root'
})
export class ProductFacadeService {
  readonly totalSize = -1;
  readonly searching = signal(false);
  private readonly DEFAULT_PAGE_SIZE = 25;
  private readonly DEFAULT_PAGE_INDEX = 0;
  private readonly contentType = signal<ContentCategoryType>(ContentCategoryType.NONE)
  private readonly filterPayload = signal<SearchParams>({
    applicationId: "",
    technologyId: "",
    selectedValue: undefined,
    selectedIds: [],
    country: "",
    seriesIds: []
  })
  private pageSize = this.DEFAULT_PAGE_SIZE;
  private pageIndex = this.DEFAULT_PAGE_INDEX;
  private readonly productStateSubject = new Subject<ProductState>();
  readonly productState = toSignal<ProductState>(this.productStateSubject.asObservable());

  constructor(
    private categoryManagerService: CategoryManagerService,
    private categoryGroupsManagerService: CategoryGroupsManagerService,
    private searchService: SearchService,
    private translate: TranslateService,
    private toast: HotToastService,
    private filterService: FilterService
  ) {
  }

  loadRootCategories(type?: ContentCategoryType, request = {}) {
    if (type) {
      this.contentType.set(type)
    }

    this.updatePayload(request);

    request = this.filterPayload()

    return this.categoryManagerService.getRootCategories().pipe(
      switchMap((value) => {
        const {application, technology} = value;

        return this.handleRootData({
          applicationId: application?.id,
          technologyId: technology?.id,
          request
        });
      })
    );
  }

  clearState({value}: any) {
    value.categoryId = value.applicationId ? value.selectedApplicationId : value.selectedTechnologyId;

    if (this.categoryManagerService.isRootCategory(value.categoryId)) {
      return this.loadRootCategories(undefined, defaultSearchParams);

    }
    value.selectedValue = null;
    return this.updateState(value);
  }

  updateState(payload: SearchParams, pageIndex: number = this.DEFAULT_PAGE_INDEX, pageSize: number = this.DEFAULT_PAGE_SIZE) {
    this.categoryManagerService.setCategoryByType(payload);
    this.updatePayload(payload);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    return this.handleDataUpdate(this.filterPayload());
  }

  updateProductResponse(pageIndex: number, pageSize: number, request?: any) {
    let payload = this.filterPayload();
    if (request && Object.keys(request).length) {
      payload = {
        ...payload,
        ...request
      }
    }
    return this.updateState(payload, pageIndex, pageSize)
  }

  private handleRootData({applicationId, technologyId, request}: {
    applicationId: string | undefined,
    technologyId: string | undefined,
    request: any | undefined,
  }, country = '') {

    return this.getEntities(request).pipe(
      switchMap((productResponse) => {
        return this.categoryManagerService.loadRootCategories(applicationId, technologyId, country, productResponse)
      }),
      map(({productResponse}) => this.mapToProductState(productResponse)),
      tap((state) => this.productStateSubject.next(state)),
      catchError((err) => this.handleRootDataError(err))
    );
  }

  private handleDataUpdate(payload: SearchParams) {
    const lastCategories = this.categoryManagerService.getLatestCategories()
    const {applicationId, technologyId, country, selectedValue, selectedIds} = payload;

    return this.categoryManagerService.loadCategories(applicationId, technologyId, country)
    .pipe(
      switchMap((categoryData) => this.getProductDataUpdate(categoryData, {
        ...payload,
        categoryIds: selectedIds
      })),
      map(data => {
        this.categoryGroupsManagerService.updateGroupTree(data, applicationId, technologyId, lastCategories, selectedValue);

        return data.productResponse;
      }),
      map((productResponse) => this.mapToProductState(productResponse)),
      tap((state) => this.productStateSubject.next(state)),
      catchError((err) => this.handleRootDataError(err))
    );
  }

  private getProductDataUpdate(categoryData: Category[][], params: SearchParams = {
    applicationId: '',
    technologyId: '',
    selectedValue: '',
    selectedIds: []
  }) {
    return this.getEntities(params).pipe(
      map(response => ({
        application: this.categoryManagerService.mapExistingCategories(categoryData[0], response),
        technology: this.categoryManagerService.mapExistingCategories(categoryData[1], response),
        productResponse: response
      })));
  }

  private getEntities(params: SearchParams) {
    const request = this.createSearchRequest(params);
    let response;
    switch (this.contentType()) {
      case ContentCategoryType.DOCUMENTS:
        response = this.searchService.searchDocuments(request as DocumentSearchRequest, this.pageIndex, this.pageSize);
        break;
      case ContentCategoryType.SERIES:
        response = this.searchService.searchSeries(request, this.pageIndex, this.pageSize).pipe(map(resp => resp.body))
        break;
      default:
        response = this.searchService.searchProducts(request as ProductSearchRequest, this.pageIndex, this.pageSize).pipe(map(resp => resp.body));
        break;

    }
    this.searching.set(true);
    return (response as Observable<SearchResponse | null>).pipe(
      finalize(() => {
        this.searching.set(false)
      })
    );
  }

  private handleRootDataError(err: any) {
    this.toast.error("Something went wrong", err);

    return of({
      productResponse: undefined,
      pageIndex: this.DEFAULT_PAGE_INDEX,
      pageSize: this.DEFAULT_PAGE_SIZE,
      filterGroups: [],
      totalSize: 0
    } as ProductState);
  }

  private createSearchRequest(params: SearchParams) {
    let {
      categoryIds = [],
      technologyIds = [],
      seriesIds = [],
      fullTextSearch = '',
      country = ''
    } = params;
    categoryIds = categoryIds.filter(id => !!id);
    const lang = this.translate.currentLang;

    if (this.contentType() === ContentCategoryType.DOCUMENTS) {
      let {documentTypes = [], productIds = []} = params;
      return new DocumentSearchRequest(categoryIds, documentTypes, fullTextSearch, lang, country, productIds, seriesIds);
    }
    return new ProductSearchRequest(categoryIds, technologyIds, seriesIds, fullTextSearch, lang);
  }

  private mapToProductState(productResponse: SearchResponse | null): ProductState {
    const filterGroups = this.filterService.toFilterGroups(this.categoryManagerService.getLatestGroups(), productResponse);

    return {
      filterGroups,
      productResponse
    } as ProductState;
  }

  private updatePayload(data: any) {
    const current = this.filterPayload();
    this.filterPayload.set({
      ...current,
      ...data
    })
  }
}
