<div class="flex flex-row gap-2">
  <div style="flex: 0 1 32px;">
    <ng-container [ngSwitch]="level">
      <mat-icon class="warn" *ngSwitchCase="'WARNING'">warning</mat-icon>
      <mat-icon class="critical" *ngSwitchCase="'CRITICAL'">warning</mat-icon>
      <mat-icon class="fault" *ngSwitchCase="'FAULT'">warning</mat-icon>
      <mat-icon class="default" *ngSwitchDefault>warning</mat-icon>
    </ng-container>
  </div>
  <span *ngIf="showLabel">{{level}}</span>
</div>
