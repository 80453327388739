<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> {{'Date' | translate}}</th>
    <td mat-cell *matCellDef="let info">{{info.alarm.timestamp | date: 'medium'}}</td>
  </ng-container>

  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef> {{'level' | translate}}</th>
    <td mat-cell *matCellDef="let info">
      <app-alarm-level [level]="info.alarm.metadata.level"></app-alarm-level>
    </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef> {{'code' | translate}}</th>
    <td mat-cell *matCellDef="let info">{{info.alarm.metadata.code}}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef> {{'description' | translate}}</th>
    <td mat-cell *matCellDef="let info">{{info.description}}</td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header"></tr>
  <tr mat-row *matRowDef="let info; columns: displayedColumns;" class="hover-effect" (click)="openHintsDialog(info)"></tr>

</table>
<mat-divider></mat-divider>
<mat-paginator
  [length]="totalSize"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  showFirstLastButtons
  (page)="handlePaginatorEvent($event)">
</mat-paginator>
