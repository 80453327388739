<div echarts
     [options]="initialValue"
     [merge]="dynamicData"
     style="height: 180px"
     (chartInit)="setChart($event)"
></div>
<div class="flex flex-row gap-4 justify-between items-center">
  <b class="m-3">{{ getPropertyName() }}</b>
  <p class="m-3">{{ 'Average' | translate }}: {{ series?.series?.avg?.toFixed(2) }} {{ getUoMTranslation() }}</p>
  <p class="m-3">{{ 'min' | translate }}: {{ series?.series?.minimum?.toFixed(2) }} {{ getUoMTranslation() }}</p>
  <p class="m-3">{{ 'max' | translate }}: {{ series?.series?.maximum?.toFixed(2) }} {{ getUoMTranslation() }}</p>
  <button mat-flat-button (click)="openPropertySelectionDialog()"
          matTooltip="{{'properties' | translate}}">
    ...
  </button>
</div>
