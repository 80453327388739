import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Category, RootCategories } from "../model/category-api";
import { CategoryService } from "../model/category.service";
import { RelatedCategoryIdEntry } from "../../document/model/document-api";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { CategorySelectTreeComponent, CategoryTreeSelectionChange } from "../category-select-tree/category-select-tree.component";
import { HotToastService } from "@ngxpert/hot-toast";

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrl: './category-select.component.scss'
})
export class CategorySelectComponent {

  @Input()
  set relatedCategoryEntries(data: RelatedCategoryIdEntry[]) {
    if (this.application) this.application.setRelatedCategoryEntries(data)
    if (this.technology) this.technology.setRelatedCategoryEntries(data)
  }

  applicationRoot: Category | undefined
  technologyRoot: Category | undefined
  country: string | undefined

  private current: CategorySelectionChange = new CategorySelectionChange('', '')

  @Output() selectionChanged = new EventEmitter<CategorySelectionChange>()

  private subscriptions: Subscription[] = []

  @ViewChild('application', { static: false }) application: CategorySelectTreeComponent | undefined
  @ViewChild('technology', { static: false }) technology: CategorySelectTreeComponent | undefined


  constructor(
    private service: CategoryService,
    private translate: TranslateService,
    private toast: HotToastService
  ) {

  }

  ngOnInit() {
    let s1 = this.translate.onLangChange.subscribe(_ => {
      this.updateSelectedCategories()
      this.loadRootCategories()
    })
    this.loadRootCategories()
    this.subscriptions = [s1]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }

  private updateSelectedCategories() {
    if (this.application) this.application.updateSelectedCategories()
    if (this.technology) this.technology.updateSelectedCategories()
  }

  reset() {
    if (this.application) this.application.reset()
    if (this.technology) this.technology.reset()
  }

  updateRequest(request: CategorySelectRequest, country: string = '') {
    // console.log("updateRequest to " + JSON.stringify(request))
    this.current.selectedApplicationId = request.selectedApplicationId
    this.current.selectedTechnologyId = request.selectedTechnologyId

    if (this.application) this.application.updateRequest(request.selectedApplicationId, country)
    if (this.technology) this.technology.updateRequest(request.selectedTechnologyId, country)

    this.country = country
  }


  private loadRootCategories() {
    this.service.getRootCategories().subscribe({
      next: value => this.handleRootDataChange(value),
      error: err => this.handleRootDataError(err)
    })
  }


  private handleRootDataChange(value: RootCategories) {
    this.applicationRoot = value.application
    this.technologyRoot = value.technology
  }

  private handleRootDataError(err: any) {
    this.toast.error("Something went wrong", err)
  }


  handleApplicationSelectionChanged(event: CategoryTreeSelectionChange) {
    if (this.current.selectedApplicationId == event.selectedCategory?.id ?? '') return
    this.current.selectedApplicationId = event.selectedCategory?.id ?? ''
    this.selectionChanged.emit(this.current)
  }


  handleTechnologySelectionChanged(event: CategoryTreeSelectionChange) {
    if (this.current.selectedTechnologyId == event.selectedCategory?.id ?? '') return
    this.current.selectedTechnologyId = event.selectedCategory?.id ?? ''
    this.selectionChanged.emit(this.current)
  }

}

export class CategorySelectionChange {
  constructor(
    public selectedApplicationId: string = '',
    public selectedTechnologyId: string = ''
  ) {
  }

  getSelectedCategoryIds(): string[] {
    return [this.selectedApplicationId, this.selectedTechnologyId].filter(item => item.length > 0)
  }
}

export interface CategorySelectRequest {
  selectedApplicationId: string
  selectedTechnologyId: string
}
