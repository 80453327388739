<div class="flex flex-row gap-3 px-2 items-center entry" *ngIf="data">
  <div style="flex: 0 1 45px;">
    <app-system-image [data]="data.system"></app-system-image>
  </div>

  <div class="flex-auto">
    {{data.system.name}}
  </div>

  <div class="md:basis-1/12 grow-0">{{data.system.refrigerant}}</div>
  <app-alarm-info [alarms]="data.alarmState" class="md:basis-2/12 grow-0"></app-alarm-info>
  <div class="md:basis-1/12 grow-0 flex flex-col items-center pe-2">
    <app-iot-status [eventState]="data.eventState"></app-iot-status>
  </div>
</div>
