<h5 class="m-3">{{ 'compressorLoad' | translate }}</h5>

<div class="flex justify-between">
  <div class="title m-3">{{ (isCapacity ? 'capacity' : 'motorFrequency') | translate }}</div>
  <button mat-mini-fab
          class="m-3"
          color="primary"
          (click)="openSelectorDialog()">
    <mat-icon>more_horiz</mat-icon>
  </button>
</div>

<app-operational-bar-chart [entryData]="entry"></app-operational-bar-chart>
