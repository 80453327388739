import { Component, Input } from '@angular/core';
import { EquipmentStatusReport } from "../model/operational-report-api";

@Component({
  selector: 'app-operational-report-equipment-status',
  templateUrl: './operational-report-equipment-status.component.html',
  styleUrl: './operational-report-equipment-status.component.scss'
})
export class OperationalReportEquipmentStatusComponent {

  @Input()
  set statusData(data: EquipmentStatusReport | undefined) {
    this.status = data
    this.reset()
    this.initStatus()
  }

  status: EquipmentStatusReport | undefined
  isCritical: boolean = false
  isFault: boolean = false

  constructor() { }

  private initStatus() {
    if (!this.status) return

    if (this.status.status === 'ERROR') {
      this.isFault = true
      return
    }
    if (this.status.status === 'WARNING') {
      this.isCritical = true
      return
    }
  }

  private reset() {
    this.isCritical = false
    this.isFault = false
  }
}
