import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { GeoLocationChangeRequest, GeoPosition, Site, SiteAddress, SiteAddressChangeRequest, SiteContact, SiteInfo, SiteMapEntry } from "./site-api";
import { ResourceHierarchyNode } from "../../hierarchy/model/hierarchy-api";
import { Filter } from "../../filter/model/filter.service";
import { AuthService } from "../../auth/auth.service";
import { SITE_ADMIN, SITE_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class SiteService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/site', logging, zone)
  }

  setName(siteId: number, value: string): Observable<Site> {
    return this.put<Site>(siteId + '/name', { value })
  }

  getSites(page: number, size: number): Observable<Page<SiteInfo>> {
    return this.getPaged('', page, size)
  }

  getSiteMapEntries(page: number, size: number): Observable<Page<SiteMapEntry>> {
    return this.getPaged('map', page, size)
  }

  getFilteredSites(filter: Filter, page: number, size: number): Observable<Page<SiteInfo>> {
    return this.postPaged('filter', filter, page, size)
  }

  getFilteredSiteMapEntries(filter: Filter, page: number, size: number): Observable<Page<SiteMapEntry>> {
    return this.postPaged('map/filter', filter, page, size)
  }

  getChildSites(siteId: number, page: number, size: number): Observable<Page<SiteInfo>> {
    return this.getPaged(siteId + '/children', page, size)
  }

  getFilteredChildSites(siteId: number, filter: Filter, page: number, size: number): Observable<Page<SiteInfo>> {
    return this.postPaged(siteId + '/children/filter', filter, page, size)
  }

  getSite(siteId: number): Observable<Site> {
    return this.get('' + siteId)
  }

  getAddress(siteId: number): Observable<SiteAddress> {
    return this.get(siteId + '/address')
  }

  updateAddress(siteId: number, request: SiteAddressChangeRequest): Observable<SiteAddress> {
    return this.put(siteId + '/address', request)
  }

  getContact(siteId: number, page: number, size: number): Observable<Page<SiteContact>> {
    return this.getPaged(siteId + '/contact', page, size)
  }

  getPath(siteId: number): Observable<ResourceHierarchyNode> {
    return this.get(siteId + '/path')
  }

  getGeoPosition(siteId: number): Observable<GeoPosition> {
    return this.get(siteId + '/geo')
  }


  updateGeoPosition(siteId: number, request: GeoLocationChangeRequest): Observable<GeoPosition> {
    return this.put<GeoPosition>(siteId + '/geo', request)
  }


  getInfo(siteId: number): Observable<SiteInfo> {
    return this.get(siteId + '/info')
  }

  getInfos(siteIds: number[]): Observable<SiteInfo[]> {
    return this.post('/info', siteIds)
  }

  deleteSite(siteId: number): Observable<Site> {
    return this.delete('' + siteId)
  }

  hasSiteEditPermissions(): boolean {
    return this.authService.hasRole(SITE_WRITE) || this.authService.hasRole(SITE_ADMIN)
  }
}
