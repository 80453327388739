import { Component, OnDestroy } from '@angular/core';
import { SVG, Svg, Text } from "@svgdotjs/svg.js";
import { SparePartsCategoryService } from "../model/spare-parts-category.service";
import { Subscription } from 'rxjs';
import * as d3 from 'd3'
import {ZoomBehavior} from "d3";


@Component({
  selector: 'app-spare-parts-category-drawing',
  templateUrl: './spare-parts-category-drawing.component.html',
  styleUrls: ['./spare-parts-category-drawing.component.scss']
})
export class SparePartsCategoryDrawingComponent implements OnDestroy {


  draw: Svg | undefined
  imageMaxHeight: string = ''
  protected imageIdStyle = 'font-family: ArialNarrow-Bold';
  protected imageIdHighlightedStyle = 'font-weight: bolder; fill: #3aaa35; font-family: ArialNarrow-Bold';

  protected subscriptions = new Subscription()

  constructor(private service: SparePartsCategoryService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.service.mediaReloading.subscribe(() => this.drawSvg()))
    this.subscriptions.add(this.service.selectedSparePartChanged.subscribe(() => this.updateSelection()))
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }


  private drawSvg() {
    let content = this.service.media
    if (!content) return

    this.draw = SVG().addTo('#zExplodedDrawing')
    this.draw.svg(content)
    this.draw.viewbox(this.draw.findOne('svg')?.attr('viewBox'))
    this.selectedImageHeight(this.draw.findOne('svg')?.attr('height'))
    this.draw.addClass('svg-image')
    this.draw.attr('width', '100%')
    this.draw.attr('height', '100%')
    this.draw.attr('preserveAspectRatio', 'xMidYMid meet')
    this.attachClickHandler()

    if (this.draw.findOne('svg')?.attr('height').includes('mm')) {
      this.scaleImage('height')
      this.scaleImage('width')
    }

    const svg= d3.select<SVGSVGElement, any>('#zExplodedDrawing')

    const zoom: ZoomBehavior<SVGSVGElement, any> = d3.zoom<SVGSVGElement, any>().on('zoom', (event) => {
      svg.select('svg').attr('transform', event.transform)
    })

    svg.call(zoom)
  }

  private scaleImage(attr: string) {
    if (!this.draw) return
    const currentAttr: string = this.draw.findOne('svg')?.attr(attr)

    const mmIdx = currentAttr.indexOf('mm')
    const attrValue = Number(currentAttr.slice(0, mmIdx))

    this.draw.findOne('svg')?.attr(attr, attrValue * 100 + 'px')
  }

  private selectedImageHeight(height: string): void {
    this.imageMaxHeight = height
  }

  private attachClickHandler(): void {
    if (!this.draw) return
    this.draw.find('text')
      .forEach(e =>
        e.click((el: any) => this.imageIdClicked(el))
      )
  }

  private imageIdClicked(element: any): void {
    let selectedPosition = parseInt(element.target.textContent)
    this.service.selectPosition(selectedPosition)
  }

  private updateSelection() {
    if (!this.draw) return

    let selected = this.service.selectedSparePart
    this.draw.find('text')
      .forEach(e => {
          let text = (e as Text).text()
          if (text === selected?.position?.toString()) {
            e.node.setAttribute('style', this.imageIdHighlightedStyle)
          } else {
            e.node.setAttribute('style', this.imageIdStyle)
          }
        }
      )

  }
}
