<h2 mat-dialog-title>{{ 'details' | translate }}</h2>

<mat-dialog-content class="p-3 flex flex-col">
  @if (entry && !reloading) {
    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.userId' | translate }}</strong>
      <span>{{ entry.userId }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.score' | translate }}</strong>
      <span>{{ entry.score }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.comment' | translate }}</strong>
      <span>{{ entry.comment }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.url' | translate }}</strong>
      <span>{{ entry.url }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.userAgent' | translate }}</strong>
      <span class="text-wrap break-words">{{ entry.userAgent }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.language' | translate }}</strong>
      <span>{{ entry.language }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.geopos' | translate }}</strong>
      <span>{{ entry.latitude }} {{ entry.longitude }}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row justify-between p-2 gap-4">
      <strong>{{ 'feedback.created' | translate }}</strong>
      <span>{{ entry.created | date: 'medium' }}</span>
    </div>
    <mat-divider></mat-divider>
  } @else {
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'action.close' | translate }}</button>
</mat-dialog-actions>
