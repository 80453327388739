export interface ThingTemplate {
  id: number,
  key: string,
  name: string,
  categories: PropertyCategory[],
  tags: string[]
}

export interface PropertyCategory {
  id: number,
  key: string,
  name: string,
  properties: PropertyDefinition[]
}

export interface PropertyDefinition {
  id: number,
  name: string,
  hasHistory: boolean,
  type: string,
  unitOfMeasure: UnitOfMeasure
}

export enum UnitOfMeasure{
  UNIT_C = 'UNIT_C',

  UNIT_HH = 'UNIT_HH',

  UNIT_SS = 'UNIT_SS',

  UNIT_BAR = 'UNIT_BAR',

  UNIT_KW = 'UNIT_KW',

  UNIT_HZ = 'UNIT_HZ',

  UNIT_V = 'UNIT_V',

  UNIT_W = 'UNIT_W',

  UNIT_H1 = 'UNIT_H1',

  UNIT_MA = 'UNIT_MA',

  UNIT_MM = 'UNIT_MM',

  UNIT_D1 = 'UNIT_D1',

  UNIT_YY = 'UNIT_YY',

  UNIT_BAR_ABS = 'UNIT_BAR_ABS',

  UNIT_BPS = 'UNIT_BPS',

  UNIT_PCT = 'UNIT_PCT',

  UNIT_MS = 'UNIT_MS',

  UNIT_DD = 'UNIT_DD',

  UNIT_OHM = 'UNIT_OHM',

  UNIT_MI = 'UNIT_MI',

  UNIT_SEC_10PCT = 'UNIT_SEC_10PCT',

  UNIT_A = 'UNIT_A',

  UNIT_K = 'UNIT_K',

  UNIT_HPA = 'UNIT_HPA',

  NONE = 'NONE',
}
