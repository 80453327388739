import {Component, computed, inject, input} from '@angular/core';
import {MatMiniFabButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {ProductConfigService} from "../model/product-config.service";

@Component({
  selector: 'app-product-config-compare-button',
  standalone: true,
  imports: [
    MatMiniFabButton,
    MatIconModule,
    MatTooltip,
    TranslateModule
  ],
  templateUrl: './product-config-compare-button.component.html',
  styleUrl: './product-config-compare-button.component.scss'
})
export class ProductConfigCompareButtonComponent {
  pid = input.required<string>()
  private service = inject(ProductConfigService)
  isAddedToCompare = computed(() => this.service.comparisonPids().includes(this.pid()));

  toggleComparison() {
    if (this.isAddedToCompare()) {
      this.service.removeFromComparison(this.pid())
    } else {
      this.service.addToComparison(this.pid());
    }
  }
}
