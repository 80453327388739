import { Component } from '@angular/core';
import { Product, SparePartCategory } from "../../product/model/product-api";
import { ProductService } from "../../product/model/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductDetailsService } from "../../product/model/product-details.service";

@Component({
  selector: 'app-spare-part-product-details',
  templateUrl: './spare-part-product-details.component.html',
  styleUrls: ['./spare-part-product-details.component.scss']
})
export class SparePartProductDetailsComponent {

  reloading: boolean = false
  product: Product | undefined
  categories: SparePartCategory[] = []
  selectedCategory: SparePartCategory | undefined

  productId: string = ''
  categoryId: number = -1
  productDetailsRoute = false

  constructor(
    private productService: ProductService,
    private productDetailsService: ProductDetailsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.productId = params.product ?? ''
      this.categoryId = parseInt(params.category ?? '')
      this.productDetailsRoute = this.productDetailsService.isProductDetailsRoute()
      this.reload()
    })
  }

  private reload() {
    if (!this.productId) return
    if (this.reloading) return
    this.reloading = true
    if(this.productDetailsRoute) {
      this.productDetailsService.getProduct(this.productId)?.subscribe(p => this.handleProduct(p))
    } else {
      this.productService.getProduct(this.productId).subscribe(
        {
          next: p => this.handleProduct(p),
          error: err => {
            this.product = undefined
            this.reloading = false
          }
        })
    }
  }

  private handleProduct(p: Product) {
    this.product = p
    this.categories = p.references.spareParts?.category ?? []
    this.selectedCategory = this.categories.find(c => c.id == this.categoryId)
    this.reloading = false
  }


  selectCategory(category: SparePartCategory) {
    this.router.navigate([], {
      queryParams: { category: category.id },
      queryParamsHandling: "merge",
      replaceUrl: true
    }).then()
  }


  handleCategoryBack() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { "category": null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    }).then()
  }
}
