import {Injectable} from '@angular/core';
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class SystemTimeService {

  timezone: string = DateTime.local().zoneName;

  constructor() {
  }

  formatTimestamp(timestamp: string): string {
    const dt = DateTime.fromISO(timestamp, {zone: 'utc'}).setZone(this.timezone);

    return dt.toFormat("yyyy-MM-dd'T'HH:mm:ss");
  }
}
