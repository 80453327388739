import { Component, Input } from '@angular/core';
import { SiteInfo } from "../model/site-api";
import { Router } from "@angular/router";

@Component({
  selector: 'app-site-map-pop-up',
  templateUrl: './site-map-pop-up.component.html',
  styleUrls: ['./site-map-pop-up.component.scss']
})
export class SiteMapPopUpComponent {

  @Input() site: SiteInfo | undefined

  constructor(private router: Router) {
  }

  showDetails() {
    if (!this.site) return
    this.router.navigate(['/site', this.site.site.id]).then()
  }
}
