import { Component, Input } from '@angular/core';
import { System } from "../model/system-api";
import { Location } from "@angular/common";

@Component({
  selector: 'app-system-image',
  templateUrl: './system-image.component.html',
  styleUrls: ['./system-image.component.scss']
})
export class SystemImageComponent {
  @Input()
  set data(data: System | undefined) {
    if (data) {
      this.image = this.getSystemImage(data.type)
    }
  }

  image: string = ''

  constructor(private location: Location) {
  }

  private getSystemImage(type: string) {
    if (!type) return this.location.prepareExternalUrl('assets/img/picto_system__ref.png')
    switch (type) {
      case 'HP':
        return this.location.prepareExternalUrl('assets/img/picto_system_hp.png')
      case 'AC':
        return this.location.prepareExternalUrl('assets/img/picto_system_ac.png')
      default:
        return this.location.prepareExternalUrl('assets/img/picto_system__ref.png')
    }
  }
}
