import { Component, Input } from '@angular/core';
import { EquipmentStartsKpis } from "../../model/operational-report-starts-api";

@Component({
  selector: 'app-starts-kpi',
  templateUrl: './starts-kpi.component.html',
  styleUrl: './starts-kpi.component.scss'
})
export class StartsKpiComponent {

  @Input()
  set kpiData(data: EquipmentStartsKpis | undefined) {
    this.kpi = data
  }

  kpi: EquipmentStartsKpis | undefined

  constructor() { }
}
