import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ProductRoutingModule } from './product-routing.module';
import { ProductBoardComponent } from './product-board/product-board.component';
import { HeaderToolbarModule } from "../../component/header-toolbar/header-toolbar.module";
import { SearchModule } from "../../component/search/search.module";
import { MaterialModule } from "../../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ComponentModule } from "../../component/component.module";
import { ProductDetailsTabsComponent } from './product-details-tabs/product-details-tabs.component';
import { ProductDetailsInfoComponent } from './product-details-info/product-details-info.component';
import { ProductInfoImageComponent } from './product-image/product-info-image.component';
import { ProductInfoCompComponent } from './product-info-comp/product-info-comp.component';
import { ProductInfoMotorComponent } from './product-info-motor/product-info-motor.component';
import { ProductInfoOilComponent } from './product-info-oil/product-info-oil.component';
import { ProductBoardContentComponent } from './product-board-content/product-board-content.component';
import { DocumentModule } from "../document/document.module";
import { CategoryModule } from "../category/category.module";
import { SeriesModule } from "../series/series.module";
import { ProductTechnicalInfoComponent } from './product-technical-info/product-technical-info.component';
import { ProductPipeConnectionsComponent } from './product-pipe-connections/product-pipe-connections.component';
import { ProductWorkingFluidsComponent } from './product-working-fluids/product-working-fluids.component';


@NgModule({
  declarations: [
    ProductBoardComponent,
    ProductDetailsComponent,
    ProductDetailsTabsComponent,
    ProductDetailsInfoComponent,
    ProductInfoImageComponent,
    ProductInfoCompComponent,
    ProductInfoMotorComponent,
    ProductInfoOilComponent,
    ProductTechnicalInfoComponent,
    ProductPipeConnectionsComponent,
    ProductWorkingFluidsComponent,
  ],
  exports: [
    ProductInfoImageComponent,
    ProductInfoCompComponent,
    ProductInfoMotorComponent,
    ProductInfoOilComponent,
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    HeaderToolbarModule,
    SearchModule,
    MaterialModule,
    TranslateModule,
    NgOptimizedImage,
    ComponentModule,
    DocumentModule,
    CategoryModule,
    SeriesModule,
    ProductBoardContentComponent
  ]
})
export class ProductModule {
}
