<mat-toolbar class="!px-2">
  <div class="flex gap-4 align-middle justify-between w-full">
    <div class="width-medium flex gap-4 align-middle items-center w-full">
      <h1 class="font-medium text-2xl">
        {{ "pid.title" | translate }}&nbsp;

        <span class="opacity-60"
        >({{ service.data.length }} of {{ service.totalSize }})</span>

      </h1>


      <div class="flex gap-2">
        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-label>{{ 'material.status' | translate }}</mat-label>
          <mat-select multiple (selectionChange)="handleStatusSelectionChanged($event)" [value]="service.request.status">
            @for (s of availableStatus; track s) {
              <mat-option [value]="s">
                {{ 'material.statuses.' + s | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-label>{{ 'pid.materialNumber' | translate }}</mat-label>
          <mat-select multiple (selectionChange)="handleMatNrKMatSelectionChanged($event)" [value]="service.request.matNrKMats">
            @for (e of service.matNrKMats; track e) {
              <mat-option [value]="e.id">
                {{ e.name }} ({{ e.id | stripLeadingZeros }})
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-label>{{ 'pid.type' | translate }}</mat-label>
          <mat-select multiple (selectionChange)="handleTypeSelectionChanged($event)" [value]="service.request.type">
            @for (e of service.type; track e) {
              <mat-option [value]="e.value">
                {{ e.value }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-button-toggle (change)="service.setStandardFilter(!service.request.standard)"
                           [checked]="service.request.standard">
          {{ 'pid.standard' | translate }}
        </mat-button-toggle>

        <mat-button-toggle (change)="service.setSuggestedFilter(!service.request.suggested)"
                           [checked]="service.request.suggested">
          {{ 'pid.suggested' | translate }}
        </mat-button-toggle>

      </div>
    </div>
    <div>
      <button
        (click)="resetFilter()"
        class="mr-5"
        color="primary"
        mat-mini-fab
        matTooltip="Reset filters"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
