import { Component } from '@angular/core';
import { CommerceService } from "../model/commerce.service";
import { OrderResponse, Store } from "../model/commerce-api";
import { MatDialogRef } from "@angular/material/dialog";
import { HotToastService } from "@ngxpert/hot-toast";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-cart-checkout-dialog',
  templateUrl: './cart-checkout-dialog.component.html',
  styleUrl: './cart-checkout-dialog.component.scss'
})
export class CartCheckoutDialogComponent {

  reloading: boolean = false
  stores: CheckoutStore[] = []
  checkingOut: boolean = false

  constructor(
    private dialogRef: MatDialogRef<CartCheckoutDialogComponent>,
    private service: CommerceService,
    private toast: HotToastService,
    private translate: TranslateService,
  ) {
    this.reloading = true
    service.getStores().subscribe({
      next: stores => this.handleStores(stores),
      error: err => this.handleError(err)
    })
  }

  private handleStores(stores: Store[]) {
    this.stores = stores.map(s => new CheckoutStore(s.id, s.name, false))
    this.reloading = false
  }

  checkout(store: CheckoutStore) {
    if (!store) return
    store.selectedForCheckout = true
    this.checkingOut = true
    const newTab = window.open('', '_blank')
    this.service.placeOrder(store.id).subscribe({
      next: response => this.handleOrderResponse(response, store, newTab),
      error: err => {
        this.handleError(err)
        if (newTab) newTab.close()
        store.selectedForCheckout = false
        this.checkingOut = false
      }
    })
  }

  private handleOrderResponse(response: OrderResponse, store: CheckoutStore, newTab: Window | null) {
    if (!response.success) {
      this.toast.error(response.message)
    } else {
      if (newTab) {
        newTab.location.href = response.redirectUrl
      }
    }
    store.selectedForCheckout = false
    this.checkingOut = false
    this.dialogRef.close()
  }

  private handleError(err: any) {
    this.toast.error("Something went wrong")
    this.dialogRef.close()
  }
}

class CheckoutStore {
  constructor(
    public id: number,
    public name: string,
    public selectedForCheckout: boolean,
  ) {
  }
}
