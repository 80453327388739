<mat-toolbar>
  <button mat-icon-button
          class="menu-button"
          type="button"
          aria-label="Toggle sidenav"
          (click)="toggleDrawer.emit()"
          matTooltip="{{'menu.ShowMenu' | translate}}" *ngIf="isMobile"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <div class="flex flex-row gap-4 flex-auto items-center">
    <app-main-search class="basis-1/4 flex flex-col flex-grow-0" *ngIf="service.searchVisible"></app-main-search>
    <div class="flex-auto"></div>
    <app-main-header-actions class="basis-1/4"></app-main-header-actions>
    <app-main-header-account class="flex flex-col content-stretch"></app-main-header-account>
  </div>
</mat-toolbar>
