<app-header-toolbar [showBack]="false" [title]="productId">
  <app-header-toolbar-icon>
    <button (click)="back()" mat-icon-button
            matTooltip="{{'back' | translate}}"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </app-header-toolbar-icon>
  <app-header-toolbar-content>

  </app-header-toolbar-content>
  <app-header-toolbar-actions>
    <button [matMenuTriggerFor]="bookmarkMenu" mat-icon-button
            matTooltip="{{'toolsServices' | translate}}">
      <mat-icon>bookmarks</mat-icon>
    </button>
  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<div class="flex flex-col gap-2 p-2">
  @if (!product) {
    <h1 class="text-2xl align-middle m-auto p-4">{{ 'products.table.NoData' | translate }}</h1>
  } @else {
    <app-product-details-tabs [data]="product"></app-product-details-tabs>
  }
</div>


<mat-menu #bookmarkMenu="matMenu">
  <button (click)="openBitzerSoftware()" mat-menu-item>
    <img [src]="bitzerSoftwareImage" alt="Menu Image for the BITZER SOFTWARE" class="menu-image">
  </button>
  <button (click)="openEPartsSoftware()" mat-menu-item>
    <img [src]="epartsImage" alt="Menu Image for EParts" class="menu-image">
  </button>
  <button (click)="openWebShop()" mat-menu-item>
    <img [src]="myBitzerShopImage" alt="Menu Image for the MyBITZER Shop" class="menu-image">
  </button>
</mat-menu>
