import { Directive } from "@angular/core";


@Directive({
  selector: 'app-header-toolbar-actions'
})
export class HeaderToolbarActions {
}

@Directive({
  selector: 'app-header-toolbar-content'
})
export class HeaderToolbarContent {
}

@Directive({
  selector: 'app-header-toolbar-icon'
})
export class HeaderToolbarIcon {
}

@Directive({
  selector: 'app-header-toolbar-filter'
})
export class HeaderToolbarFilter {
}
