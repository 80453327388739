import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-card-entry',
  templateUrl: './list-card-entry.component.html',
  styleUrl: './list-card-entry.component.scss'
})
export class ListCardEntryComponent {

  @Input()
  imageUrl: string = ''
  @Input()
  title: string = ''
  @Input()
  subtitle: string = ''
}
