import { Component } from '@angular/core';
import { CompanyService } from "../model/company.service";
import { Company } from "../model/company-api";
import { MatDialog } from "@angular/material/dialog";
import { CompanyChangeDialogComponent } from "../company-change-dialog/company-change-dialog.component";
import { Site } from "../../site/model/site-api";

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent {


  company: Company | undefined
  reloading: boolean = false

  constructor(private service: CompanyService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.reload()
  }

  reload() {
    if (this.reloading) return
    this.reloading = true
    this.service.getOwnCompany().subscribe(data => this.handleData(data))
  }

  private handleData(data: Company) {
    this.company = data
    this.reloading = false
  }

  edit() {
    if (!this.company) return
    const dialogRef = this.dialog.open(CompanyChangeDialogComponent, {
      width: '85%',
      data: this.company
    })
    dialogRef.afterClosed().subscribe((data?: Site) => this.reload())
  }
}
