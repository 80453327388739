import {Injectable, NgZone} from '@angular/core';
import {BaseService} from "../../../utils/base-service";
import {HttpClient} from "@angular/common/http";
import {LoggingService} from "../../../utils/logging/logging.service";
import {Observable} from 'rxjs';

export const PERMISSION_EXPORT_ADMIN = "bdn.export.admin"
export const PERMISSION_EXPORT_USER = "bdn.export.user"

@Injectable({
  providedIn: 'root'
})
export class ExportService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/portal/export', logging, zone)
    this.retryCount = 0
  }

  exportProductExcel(): Observable<any> {
    return this.download('product')
  }

  exportPidExcel(): Observable<any> {
    return this.download('pid')
  }

  exportSinglePidExcel(id: string): Observable<any> {
    return this.download('pid/' + id)
  }

  exportPidProductsExcel(): Observable<Blob> {
    return this.download('pid/products')
  }

  exportSparePartExcel(): Observable<any> {
    return this.download('sparepart')
  }
}
