import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Equipment } from "../model/equipment-api";
import { ActivatedRoute, Routes } from "@angular/router";
import { NavLink } from "./nav-link";
import { EquipmentTabRouteData } from "./equipment-tab-route-data";
import { AuthService } from "../../auth/auth.service";
import { BETA_ACCESS } from "../../auth/roles";

@Component({
  selector: 'app-equipment-details-tabs',
  templateUrl: './equipment-details-tabs.component.html',
  styleUrls: ['./equipment-details-tabs.component.scss']
})
export class EquipmentDetailsTabsComponent {
  @Input()
  set data(data: Equipment | undefined) {
    this.equipment = data
    if (this.equipment) {
      this.updateNavigation()
    }
  }


  equipment: Equipment | undefined
  isViewInitialized = false
  navLinks: NavLink[] = []

  constructor(private route: ActivatedRoute, private changeDetector: ChangeDetectorRef, private authService: AuthService) {
  }


  ngOnInit() {
    this.updateNavigation()
  }

  ngAfterViewInit() {
    this.isViewInitialized = true
    this.changeDetector.detectChanges()
  }

  private updateNavigation() {
    let config = this.route.routeConfig
    let children = config?.children
    this.navLinks = children ? this.buildNavItems(children) : []
  }


  private buildNavItems(routes: Routes): NavLink[] {
    return (routes)
      .filter(route => route.data && this.isVisible(route.data as EquipmentTabRouteData))
      .map(({ path = '', data }) => ({
        path: path,
        label: data?.label ?? '',
        icon: data?.icon,
        beta: data?.permissions?.some((p: string) => p === BETA_ACCESS) ?? false
      }));
  }

  private isVisible(data: EquipmentTabRouteData): boolean {
    return this.isActivated(data) && this.hasPermission(data)
  }

  private isActivated(data: EquipmentTabRouteData): boolean {
    return data.requiresActivation ? this.equipment?.activated ?? false : true;
  }

  private hasPermission(data: EquipmentTabRouteData): boolean {
    if (!data.permissions.length) return true
    let hasAccess = false
    data.permissions.forEach(permission => {
      if (this.authService.hasRole(permission)) hasAccess = true
    })
    return hasAccess
  }
}
