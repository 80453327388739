<div class="title m-3">{{ 'alarmStatistics' | translate }}</div>

<table mat-table [dataSource]="stats?.entries || []">

  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef> {{'level' | translate}} </th>
    <td mat-cell *matCellDef="let entry">
      <app-alarm-level [level]="entry.level"></app-alarm-level>
    </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef> {{'code' | translate}} </th>
    <td mat-cell *matCellDef="let entry">{{ entry.code }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef> {{'description' | translate}} </th>
    <td mat-cell *matCellDef="let entry">{{ entry.description }}</td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef> {{'spareParts.quantity' | translate}} </th>
    <td mat-cell *matCellDef="let entry">{{ entry.amount }}</td>
  </ng-container>

  <ng-container matColumnDef="percentage">
    <th mat-header-cell *matHeaderCellDef>%</th>
    <td mat-cell *matCellDef="let entry">{{ entry.percentage.toFixed(2) }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header"></tr>
  <tr mat-row *matRowDef="let entry; columns: displayedColumns;"
      [ngStyle]="defineStyle(entry)"
      (click)="openAlarmsDialog(entry.alarms)"
      class="hover-effect">
  </tr>

</table>
