<div class="flex flex-col gap-2">
  <div class="grid grid-cols-4 gap-4 w-full">
    @for (i of pid.cstics.sort(sorted()); track i) {
      <mat-form-field appearance="outline" disabled>
        <mat-label>{{ i.defaultName }}</mat-label>

        <div class="flex justify-between">
          <input matInput value="{{i.value.defaultName.length > 0 ? i.value.defaultName : i.value.key}}" readonly>
        </div>
      </mat-form-field>
    }
  </div>
</div>
