<mat-card style="height: 100%;">
  <mat-card-header class="flex items-center content-center justify-between">
    <mat-card-title>
      <h5> {{'site.Location' | translate}} </h5>
    </mat-card-title>
    <mat-card-actions *ngIf="service.hasSiteEditPermissions() && canWrite">
      <button mat-stroked-button (click)="edit()" *ngIf="!editMode" >
        <mat-icon>edit</mat-icon>
        {{'action.Edit' | translate}} </button>
      <div class="flex gap-1" *ngIf="editMode">
        <button mat-stroked-button [disabled]="!changed" (click)="save()">
          <mat-icon>save</mat-icon>
          {{'action.Save' | translate}} </button>
        <button mat-stroked-button (click)="reset()">
          <mat-icon>cancel</mat-icon>
          {{'cancel' | translate}} </button>
      </div>
    </mat-card-actions>
  </mat-card-header>
  <form [formGroup]="mapForm" *ngIf="editMode">
    <div class="flex flex-col flex-sm-row gap-2 px-2 mb-2">
      <mat-form-field appearance="outline" class="grow shrink" subscriptSizing="dynamic">
        <mat-label> {{'latitude' | translate}} </mat-label>
        <input matInput type="text" formControlName="latitude" (change)="handleInputChange()"/>
      </mat-form-field>
      <mat-form-field appearance="outline" class="grow shrink" subscriptSizing="dynamic">
        <mat-label> {{'longitude' | translate}} </mat-label>
        <input matInput type="text" formControlName="longitude" (change)="handleInputChange()"/>
      </mat-form-field>
    </div>
  </form>
  <div #mapContainer class="flex flex-col map-container">
    <div id="map"></div>
  </div>
</mat-card>
