import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductConfigBoardComponent} from "./product-config-board/product-config-board.component";
import {
  ProductConfigProductDetailsComponent
} from "./product-config-product-details/product-config-product-details.component";
import {AuthGuard} from "../../auth/auth.guard";
import {
  ProductConfigCompareComponent
} from "./product-config-compare/product-config-compare.component";
import {BETA_ACCESS} from "../../auth/roles";
import {
  ProductConfigDetailsComponent
} from "./product-config-details/product-config-details.component";
import {ProductConfigMainComponent} from "./product-config-main/product-config-main.component";

const routes: Routes = [
  {
    path: '',
    component: ProductConfigMainComponent,
    children: [
      {path: '', component: ProductConfigBoardComponent},
      {path: 'details', component: ProductConfigProductDetailsComponent},
      {path: 'compare', component: ProductConfigCompareComponent},
      {path: ':id', component: ProductConfigDetailsComponent}
    ],
    canActivate: [AuthGuard],
    data: {roles: [BETA_ACCESS]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductConfigRoutingModule {
}
