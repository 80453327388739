<app-search-action></app-search-action>
<mat-progress-bar *ngIf="service.reloading | async" mode="indeterminate"></mat-progress-bar>
<div class="flex flex-col">
  <app-search-result-list></app-search-result-list>

  <mat-paginator *ngIf="!(service.reloading | async)"
                 [length]="service.totalSize"
                 [pageSize]="service.pageSize"
                 [pageIndex]="service.pageIndex"
                 [pageSizeOptions]="[5, 10, 20, 50, 100]"
                 showFirstLastButtons
                 (page)="service.handlePaginatorEvent($event)">
  </mat-paginator>
</div>
