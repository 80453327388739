import { Component } from '@angular/core';
import { Product } from "../model/product-api";
import { ActivatedRoute } from "@angular/router";
import { ProductDetailsService } from "../model/product-details.service";

@Component({
  selector: 'app-product-details-info',
  templateUrl: './product-details-info.component.html',
  styleUrls: ['./product-details-info.component.scss']
})
export class ProductDetailsInfoComponent {

  reloading: boolean = false
  product: Product | undefined
  productId: string = ''

  constructor(
    private productDetailsService: ProductDetailsService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.productId = params.product ?? ''
      this.reload()
    })
  }

  private reload() {
    if (!this.productId) return
    if (this.reloading) return
    this.reloading = true
    this.productDetailsService.getProduct(this.productId)?.subscribe(p => this.handleProduct(p))
  }

  private handleProduct(p: Product) {
    this.product = p
    if (p && p.attributes.general) {
      p.attributes.general.maxOperatingCurrent = new Map(Object.entries(p.attributes.general.maxOperatingCurrent))
      p.attributes.general.displacement = new Map(Object.entries(p.attributes.general.displacement))
      p.attributes.general.oilType = new Map(Object.entries(p.attributes.general.oilType))
    }
    this.reloading = false
  }
}
