import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Translation } from "./translation-api";

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/knowledge/translation', logging, zone)
    this.retryCount = 1
  }

  getUOMTranslations(lang: string): Observable<Translation> {
    const params = new HttpParams().append('lang', lang)
    return this.get('type/uom', params)
  }
}
