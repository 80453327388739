import { Series } from "../../series/model/series-api";

export interface Category {
  id: string,
  infoText: string,
  mediaTitle: string,
  notFor: string,
  onlyFor: string,
  pictogram: string,
  picture: string,
  series: Series[],
  title: string
}


export class CategoryEntry {
  constructor(
    public category: Category,
    public amount: number
  ) {
  }

}

export class CategorySearchRequest {
  constructor(
    public categoryIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }
}

export interface RootCategories {
  application: Category | undefined,
  technology: Category | undefined,
}
