<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
<div class="container-fluid flex flex-row flex-wrap gap-3 pt-3">
  <app-product-info-image *ngIf="product" class="equ-card" [product]="product"></app-product-info-image>
  <app-product-technical-info *ngIf="!product?.attributes?.general" class="equ-card" [product]="product"></app-product-technical-info>
  <app-product-pipe-connections *ngIf="!product?.attributes?.general" class="equ-card" [product]="product"></app-product-pipe-connections>
  <app-product-working-fluids *ngIf="!product?.attributes?.general" class="equ-card" [product]="product"></app-product-working-fluids>
  <app-product-info-comp *ngIf="product?.attributes?.general" class="equ-card" [product]="product"></app-product-info-comp>
  <app-product-info-motor *ngIf="product?.attributes?.general" class="equ-card" [product]="product"></app-product-info-motor>
  <app-product-info-oil *ngIf="product" class="equ-card" [product]="product"></app-product-info-oil>
</div>
