import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { SparePartsCategoryService } from "../model/spare-parts-category.service";
import {MatRow, MatTableDataSource} from "@angular/material/table";
import { Subscription } from "rxjs";
import {CategorySparePart} from "../model/spare-part-api";

@Component({
  selector: 'app-spare-parts-category-items',
  templateUrl: './spare-parts-category-items.component.html',
  styleUrls: ['./spare-parts-category-items.component.scss']
})
export class SparePartsCategoryItemsComponent {

  @Input() imageMaxHeight: string = ''
  @ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

  displayedColumns = ['position', 'description', 'id']
  data: MatTableDataSource<CategorySparePart> = new MatTableDataSource()
  subscriptions = new Subscription()

  constructor(public service: SparePartsCategoryService) {
    this.service.sparePartsReloading.subscribe((value) => {
      if (value) return
      this.loadData()
    })
  }

  ngOnInit(): void {
    this.subscriptions.add(this.service.selectedSparePartChanged.subscribe(() => this.scrollToSparePart()))
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  applyFilter(event: Event) {
    const value = (event.target as HTMLInputElement).value.trim().toLowerCase()
    if (!this.service.spareParts.length) return
    this.data.data = this.service.spareParts.filter(spare => {
      return spare.id.includes(value) || spare.position.toString().includes(value) || spare.description.includes(value)
    })
  }

  private loadData() {
    this.data = new MatTableDataSource(this.service.spareParts)
  }

  private scrollToSparePart() {
    let sp = this.service.selectedSparePart
    if (!sp) return

    let elem = this.rows.find(r => r.nativeElement.id == sp!.position.toString())
    if(elem) elem.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
  }
}
