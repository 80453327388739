import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WishlistService } from "../model/wishlist.service";
import { WishlistChangeRequest } from "../model/wishlist-api";
import { AuthService } from "../../../auth/auth.service";

@Component({
  selector: 'app-wishlist-create-dialog',
  templateUrl: './wishlist-create-dialog.component.html',
  styleUrl: './wishlist-create-dialog.component.scss'
})
export class WishlistCreateDialogComponent {
  fg: FormGroup
  ownerId = this.authService.getPrincipal()?.id

  constructor(
    public dialogRef: MatDialogRef<WishlistCreateDialogComponent>,
    private service: WishlistService,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    this.fg = fb.group({
      name: ['', Validators.required],
      note: [''],
    });
  }

  submit() {
    if (!this.fg.valid) return
    if (!this.ownerId) return
    let value = this.fg.value
    let request = new WishlistChangeRequest(value.name, this.ownerId, value.note)
    this.service.createWishlist(request).subscribe({
      next: v => this.dialogRef.close(v),
      error: err => this.dialogRef.close(null)
    })

  }
}
