import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { System, SystemInfo, SystemUpdateRequest } from "./system-api";
import { ResourceHierarchyNode } from "../../hierarchy/model/hierarchy-api";
import { Filter } from "../../filter/model/filter.service";
import { Location } from "@angular/common";
import { AuthService } from "../../auth/auth.service";
import { Company } from "../../company/model/company-api";
import { SYSTEM_ADMIN, SYSTEM_OPERATION, SYSTEM_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class SystemService extends BaseService {
  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private location: Location,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/system', logging, zone)
  }

  getSystems(siteId: number, page: number, size: number): Observable<Page<SystemInfo>> {
    return this.getPaged('site/' + siteId, page, size)
  }

  getFilteredSystems(siteId: number, filter: Filter, page: number, size: number): Observable<Page<SystemInfo>> {
    return this.postPaged('site/' + siteId + '/filter', filter, page, size)
  }

  getSystem(systemId: number): Observable<System> {
    return this.get<System>('' + systemId)
  }

  getOwner(systemId: number): Observable<Company> {
    return this.get('' + systemId + "/owner")
  }

  getPath(systemId: number): Observable<ResourceHierarchyNode> {
    return this.get(systemId + '/path')
  }

  getInfo(systemId: number): Observable<SystemInfo> {
    return this.get(systemId + '/info')
  }

  getInfos(systemIds: number[]): Observable<SystemInfo[]> {
    return this.post('/info', systemIds)
  }

  getSystemImage(type: string) {
    if (!type) return this.location.prepareExternalUrl('assets/img/picto_system__ref.png')
    switch (type) {
      case 'HP':
        return this.location.prepareExternalUrl('assets/img/picto_system_hp.png')
      case 'AC':
        return this.location.prepareExternalUrl('assets/img/picto_system_ac.png')
      default:
        return this.location.prepareExternalUrl('assets/img/picto_system__ref.png')
    }
  }

  hasSystemEditPermissions(): boolean {
    return this.authService.hasRole(SYSTEM_WRITE) || this.authService.hasRole(SYSTEM_ADMIN)
  }

  hasOperationPermissions(): boolean {
    return this.authService.hasRole(SYSTEM_OPERATION)
  }

  update(systemId: number, request: SystemUpdateRequest): Observable<System> {
    return this.put('' + systemId, request)
  }

  deleteSystem(systemId: number): Observable<System> {
    return this.delete('' + systemId)
  }
}
