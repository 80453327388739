<div class="flex flex-col sm:flex-row items-center content-center">
  <div class="flex flex-row gap-2" style="margin-right: 5px">
    <button mat-mini-fab style="background-color: white; color: black;"
            matTooltip="{{'products.toolbar.ToggleAlarmFilter' | translate}}"
            (click)="filterService.setAlarmFilter()">
      <mat-icon [ngClass]="{ 'selected-filter': filterService.activeFilter.value.alarmFilter }">warning</mat-icon>
    </button>

    <button mat-mini-fab style="background-color: white; color: black;"
            matTooltip="{{'products.toolbar.ToggleOnlineFilter' | translate}}"
            (click)="filterService.setOnlineFilter()">
      <mat-icon [ngClass]="{ 'selected-filter': filterService.activeFilter.value.onlineFilter }">cell_tower</mat-icon>
    </button>
  </div>

  <div class="label flex justify-center items-center lg-screen">
    {{'lastAlarms' | translate}}:
    <mat-button-toggle-group class="ms-1" [formControl]="alarmControl">
      <mat-button-toggle value="1">{{'today' | translate}}</mat-button-toggle>
      <mat-button-toggle value="7">7 {{'days' | translate}}</mat-button-toggle>
      <mat-button-toggle value="28">28 {{'days' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
