import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Routes } from "@angular/router";
import { AuthService } from "../../../auth/auth.service";
import { ProductDetailsTabRouteData } from "./product-details-tab-route-data";
import { NavLink } from "./nav-link";
import { Product } from "../model/product-api";

@Component({
  selector: 'app-product-details-tabs',
  templateUrl: './product-details-tabs.component.html',
  styleUrls: ['./product-details-tabs.component.scss']
})
export class ProductDetailsTabsComponent {
  @Input()
  set data(product: Product | undefined) {
    this.product = product
    if (this.product) {
      this.updateNavigation()
    }
  }

  product: Product | undefined
  isViewInitialized = false
  navLinks: NavLink[] = []

  constructor(private route: ActivatedRoute, private changeDetector: ChangeDetectorRef, private authService: AuthService) {
  }


  ngOnInit() {
    this.updateNavigation()
  }

  ngAfterViewInit() {
    this.isViewInitialized = true
    this.changeDetector.detectChanges()
  }

  private updateNavigation() {
    let config = this.route.routeConfig
    let children = config?.children
    this.navLinks = children ? this.buildNavItems(children) : []
  }


  private buildNavItems(routes: Routes): NavLink[] {
    return (routes)
      .filter(route => route.data && this.isVisible(route.data as ProductDetailsTabRouteData))
      .map(({ path = '', data }) => ({
        path: path,
        label: data?.label ?? '',
        icon: data?.icon
      }));
  }

  private isVisible(data: ProductDetailsTabRouteData): boolean {
    if(!this.containsData(data.label)) return false
    return this.hasPermission(data)
  }

  private hasPermission(data: ProductDetailsTabRouteData): boolean {
    if(!data.permissions.length) return true
    let hasAccess = false
    data.permissions.forEach(permission => {
      if(this.authService.hasRole(permission)) hasAccess = true
    })
    return hasAccess
  }

  private containsData(label: string): boolean {
    switch(label) {
      case "Information": {
        if(!this.product) return false
        break;
      }
      case "Documents": {
        if(!this.product?.references.document) return false
        break;
      }
      case "Spare parts": {
        if(!this.product?.references.spareParts) return false
        break;
      }
      case "Product Config": {
        if(!this.product?.references.pids?.pids.length) return false
        break;
      }
    }
    return true
  }
}
