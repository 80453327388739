<mat-card>
  <div class="p-2 px-3 flex flex-row gap-2">
    <ng-template
      [ngTemplateOutlet]="nodeTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: data }" *ngIf="data">
    </ng-template>
  </div>
</mat-card>
<ng-template #nodeTemplateRef let-node>
  <ng-container *ngIf="node.children.length">
    <div>
      <span class="node" [routerLink]="node.url">{{ node.resource.name }} // </span>
    </div>
    <ng-template ngFor [ngForOf]="node.children" [ngForTemplate]="nodeTemplateRef"></ng-template>
  </ng-container>

</ng-template>
