import { Component, input, Input } from '@angular/core';
import {
  MainMenuAction,
  MainMenuActionStatus,
} from '../model/main-menu-action';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-menu-action',
  templateUrl: './main-menu-action.component.html',
  styleUrl: './main-menu-action.component.scss',
})
export class MainMenuActionComponent {
  @Input() data!: MainMenuAction;
  collapsed = input(false);
  status: MainMenuActionStatus | undefined;
  private subscription: Subscription | undefined;

  ngOnChanges() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.status = undefined;
    }
    if (this.data.status) {
      this.subscription = this.data.status.subscribe((d) =>
        this.handleStatusChanged(d)
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.status = undefined;
    }
  }

  private handleStatusChanged(d: MainMenuActionStatus) {
    this.status = d;
  }
}
