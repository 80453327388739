import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationalReportComponent } from "./operational-report/operational-report.component";
import { OperationalReportHeaderComponent } from "./operational-report-header/operational-report-header.component";
import {
  OperationalReportEquipmentActivityComponent
} from "./operational-report-equipment-activity/operational-report-equipment-activity.component";
import {
  OperationalReportEquipmentAlarmsComponent
} from "./operational-report-equipment-alarms/operational-report-equipment-alarms.component";
import {
  OperationalReportEquipmentLoadComponent
} from "./operational-report-equipment-load/operational-report-equipment-load.component";
import {
  OperationalReportEquipmentStartsComponent
} from "./operational-report-equipment-starts/operational-report-equipment-starts.component";
import {
  OperationalReportEquipmentStatusComponent
} from "./operational-report-equipment-status/operational-report-equipment-status.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxEchartsDirective } from "ngx-echarts";
import {
  OperationalReportEquipmentInfoComponent
} from "./operational-report-equipment-info/operational-report-equipment-info.component";
import { ComponentModule } from "../../component/component.module";
import {
  LastAlarmsComponent
} from "./operational-report-equipment-alarms/last-alarms/last-alarms.component";
import {
  ActivityRunningComponent
} from './operational-report-equipment-activity/activity-running/activity-running.component';
import { AlarmModule } from "../../alarm/alarm.module";
import {
  AlarmStatsComponent
} from "./operational-report-equipment-alarms/alarm-stats/alarm-stats.component";
import {
  ActivityRuntimeComponent
} from './operational-report-equipment-activity/activity-runtime/activity-runtime.component';
import {
  ActivityMeantimeComponent
} from "./operational-report-equipment-activity/activity-meantime/activity-meantime.component";
import {
  MeantimeGraphComponent
} from "./operational-report-equipment-activity/activity-meantime/meantime-graph/meantime-graph.component";
import {
  MeantimeDialogComponent
} from "./operational-report-equipment-activity/activity-meantime/meantime-dialog/meantime-dialog.component";
import { OperationalEnvelopeComponent } from './operational-envelope/operational-envelope.component';
import {
  ActivityHeatmapComponent
} from "./operational-report-equipment-activity/activity-heatmap/activity-heatmap.component";
import {
  AlarmDistributionComponent
} from "./operational-report-equipment-alarms/alarm-distribution/alarm-distribution.component";
import {
  DistributionEntryComponent
} from "./operational-report-equipment-alarms/alarm-distribution/distribution-entry/distribution-entry.component";
import {
  AlarmGraphComponent
} from "./operational-report-equipment-alarms/alarm-graph/alarm-graph.component";
import { OperationalBarChartComponent } from "./operational-bar-chart/operational-bar-chart.component";
import {
  StartsDistributionComponent
} from "./operational-report-equipment-starts/starts-distribution/starts-distribution.component";
import { StartsKpiComponent } from "./operational-report-equipment-starts/starts-kpi/starts-kpi.component";
import { StartsGraphComponent } from "./operational-report-equipment-starts/starts-graph/starts-graph.component";
import {
  LoadPropertySelectorComponent
} from "./operational-report-equipment-load/load-property-selector/load-property-selector.component";
import { WeatherGraphComponent } from './operational-report-equipment-activity/activity-meantime/weather-graph/weather-graph.component';


@NgModule({
  declarations: [
    OperationalReportComponent,
    OperationalReportHeaderComponent,
    OperationalReportEquipmentActivityComponent,
    OperationalReportEquipmentAlarmsComponent,
    OperationalReportEquipmentLoadComponent,
    OperationalReportEquipmentStartsComponent,
    OperationalReportEquipmentStatusComponent,
    OperationalReportEquipmentInfoComponent,
    LastAlarmsComponent,
    AlarmStatsComponent,
    AlarmDistributionComponent,
    AlarmGraphComponent,
    OperationalEnvelopeComponent,
    OperationalBarChartComponent,
    ActivityRunningComponent,
    ActivityRuntimeComponent,
    ActivityMeantimeComponent,
    ActivityHeatmapComponent,
    MeantimeGraphComponent,
    MeantimeDialogComponent,
    DistributionEntryComponent,
    StartsDistributionComponent,
    StartsKpiComponent,
    StartsGraphComponent,
    LoadPropertySelectorComponent,
    WeatherGraphComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxEchartsDirective,
        ComponentModule,
        AlarmModule,
    ]
})
export class OperationalReportModule {
}
