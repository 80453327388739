<div class="flex flex-col scroll-off">
  <div mat-dialog-title class="basis-full text-center my-3">{{ 'wishlist.dialog.add' | translate }}</div>
  <mat-divider></mat-divider>
  <div class="basis-full text-center my-3">{{ data.entry.description }}</div>
  <div class="basis-full text-center">{{ 'part' | translate }} {{ data.entry.id }}</div>
  <mat-divider></mat-divider>
  <mat-dialog-content>

    <div class="flex flex-col gap-2 pb-2">
      <button mat-mini-fab style="background-color: white; color: black;" (click)="create()"
                                                  matTooltip="{{'wishlist.dialog.create' | translate}}">
      <mat-icon>add</mat-icon>
    </button>
      @for (row of wishlists; track row; let last = $last) {
        <div class="flex flex-row gap-2 justify-between items-center px-3">
          <div>
            <p>{{ row.name | translate }}</p>
          </div>
          <div>
            @if (isSelected(row)) {
              <button mat-stroked-button style="color: red" (click)="removeFromWishlist(row)">{{ 'remove' | translate }}
                <mat-icon>remove</mat-icon>
              </button>
            } @else {
              <button mat-stroked-button style="color: #3aaa35" (click)="addToWishlist(row)">{{ 'add' | translate }}
                <mat-icon>add</mat-icon>
              </button>
            }
          </div>
        </div>
        <mat-divider *ngIf="!last"></mat-divider>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-center items-center content-end">
    <button mat-stroked-button mat-dialog-close>{{ 'close' | translate }}</button>
  </mat-dialog-actions>
</div>
