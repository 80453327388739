import { Injectable } from '@angular/core';
import { Cart, CartEntryChangeRequest, CartEntryChangeResponse, CartInfo, PriceRequest, PriceRequestEntry, PriceResponse } from "./commerce-api";
import { CommerceService } from "./commerce.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CommerceCartService {

  reloading: boolean = false
  cart: Cart | undefined

  constructor(private service: CommerceService) {
  }

  reload() {
    if (this.reloading) return
    this.reloading = true

    this.service.getCart().subscribe(d => this.handleData(d))
  }

  private handleData(d: Cart) {
    this.cart = d
    this.reloading = false
  }

  addToCart(code: string, quantity: number): Observable<CartEntryChangeResponse> {
    let request = new CartEntryChangeRequest(code, quantity)
    return this.service.addToCart(request).pipe(
      tap(value => this.reload())
    )
  }

  getPrices(priceEntries: PriceRequestEntry[]): Observable<PriceResponse> {
    let request = new PriceRequest(priceEntries)
    return this.service.getPrices(request)
  }

  updateEntry(entryId: number, code: string, quantity: number) {
    let request = new CartEntryChangeRequest(code, quantity)
    return this.service.updateEntry(entryId, request).pipe(
      tap(_ => this.reload())
    )
  }

  deleteEntry(entryId: number): Observable<CartInfo> {
    return this.service.deleteEntry(entryId).pipe(
      tap(_ => this.reload())
    )
  }

  deleteAllEntries(): Observable<CartInfo> {
    return this.service.deleteAllEntries().pipe(
      tap(_ => this.reload())
    )
  }

  exportCart(): Observable<any> {
    return this.service.exportCart()
  }

  exportSharedCart(sharedCartId: string): Observable<any> {
    return this.service.exportSharedCart(sharedCartId)
  }
}
