<mat-card>
  <div class="flex flex-col gap-2 justify-center content-stretch">
    <mat-list class="flex-auto pt-0">
      @for (entry of entries; track entry) {
        <mat-list-item class="spare-part-item" [ngClass]="{ 'selected': entry.id === selected?.id }">
          <div class="flex flex-row items-center gap-3">
            <img src="https://oiservicesprod.blob.core.windows.net/images/thumbs/{{entry.id}}.jpg"
                 appDefaultImage defaultImage="assets/img/default-spare.png"
                 mat-card-image style="max-width: 50px" alt="Spare Part Thumbnail Picture">
            <div class="flex flex-col gap-1 flex-auto content-stretch">
              <div class="flex-auto spare-part-description">{{ entry.description }}</div>
              <div class="col-2 spare-part-id">{{ entry.id }}</div>
            </div>
            <div class="flex-auto"></div>
            @if (this.hasCommerceReadPermissions()) {
              <div class="grow-0 content-end px-5 me-5 text-s font-medium">
                {{ entry.customerPrice | currency:'EUR' }}
              </div>
            }

            <app-product-config-wishlist [pid]="entry"></app-product-config-wishlist>
            <app-add-to-cart-button [code]="entry.id"></app-add-to-cart-button>
            <button mat-mini-fab (click)="selectSparePart(entry)">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      }
    </mat-list>

    <mat-paginator (page)="handlePaginatorEvent($event)"
                   *ngIf="totalSize > 0 && totalSize > pageSize"
                   [length]="totalSize"
                   [pageIndex]="pageIndex"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   [pageSize]="pageSize" class="dense-1 pb-3">
    </mat-paginator>

  </div>
</mat-card>


