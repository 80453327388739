export class ProductDetailsTabRouteData {
  constructor(
    public label: string,
    public requiresActivation: boolean,
    public permissions: string[]
  ) {

  }


}
