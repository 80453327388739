<mat-card style="height: 100%">
  <mat-card-header>
    <mat-card-title>{{'notes' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field appearance="outline" class="form-full-width mt-2">
      <textarea matInput class="input-area" [(ngModel)]="text" [readonly]="!editMode" [ngClass]="{'default-cursor': !editMode}"></textarea>
    </mat-form-field>

  </mat-card-content>
  <mat-card-actions *ngIf="service.hasEditPermissions() && canWrite">
    <button mat-flat-button *ngIf="!editMode" (click)="editMode=!editMode">
      <mat-icon>edit</mat-icon>
      {{'action.Edit' | translate}}
    </button>

    <ng-container *ngIf="editMode">
      <button mat-flat-button (click)="save()">
        <mat-icon>save</mat-icon>
        {{'action.Save' | translate}}
      </button>

      <button mat-flat-button (click)="editMode=!editMode">
        {{'cancel' | translate}}
      </button>
    </ng-container>
  </mat-card-actions>
</mat-card>
