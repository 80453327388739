import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-shared-cart-import-dialog',
  templateUrl: './shared-cart-import-dialog.component.html',
  styleUrl: './shared-cart-import-dialog.component.scss'
})
export class SharedCartImportDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<SharedCartImportDialogComponent>
  ) {
  }


  accept() {
    this.dialogRef.close(true)
  }
}
