export class TableCardEntry {
  constructor(public key: string, public value: string = '') {
  }
}

export class TableCardGroup {
  constructor(
    public name: string,
    public entries: TableCardEntry[]
  ) {
  }
}
