import {Component, computed, input} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-status-chip',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './status-chip.component.html',
  styleUrl: './status-chip.component.scss'
})
export class StatusChipComponent {
  count = input(0);
  label = input('');
  color = input('');
  text = input('');

  isGrayedOut = computed(() => this.count() === 0)
}
