<div *ngIf="data && data.activated">
  <button mat-menu-item class="flex-auto" (click)="onOpenActivationConfig()">
    <mat-icon>info</mat-icon>
    {{"iotconfig" | translate}}</button>
</div>
<ng-container *ngIf="data && !data.activated">
  <button mat-menu-item class="flex-auto" (click)="activate()">
    <mat-icon>link</mat-icon>
    {{"activate" | translate}}</button>
</ng-container>
