export interface Note {
  id: number,
  text: string,
  timestamp: string
}

export class NoteChangeRequest {
  constructor(
    public text: string
  ) {
  }
}
