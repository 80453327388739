import { Component, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-company-change-form',
  templateUrl: './company-change-form.component.html',
  styleUrls: ['./company-change-form.component.scss']
})
export class CompanyChangeFormComponent {

  @Input() readonly: boolean = false
  @Input() form: FormGroup | undefined
}
