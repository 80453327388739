import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EquipmentBoardComponent} from "./equipment-board/equipment-board.component";
import {EquipmentDetailsComponent} from "./equipment-details/equipment-details.component";
import {
  EquipmentDetailsStatusComponent
} from "./equipment-details-status/equipment-details-status.component";
import {
  EquipmentDetailsAlarmsComponent
} from "./equipment-details-alarms/equipment-details-alarms.component";
import {
  EquipmentDetailsModuleSettingsComponent
} from "./equipment-details-module-settings/equipment-details-module-settings.component";
import {
  EquipmentDetailsInformationComponent
} from "./equipment-details-information/equipment-details-information.component";
import {
  EquipmentDetailsDocumentComponent
} from "./equipment-details-document/equipment-details-document.component";
import {EquipmentTabRouteData} from "./equipment-details-tabs/equipment-tab-route-data";
import {
  EquipmentDetailsSparePartsComponent
} from "./equipment-details-spare-parts/equipment-details-spare-parts.component";
import {
  OperationalGraphComponent
} from "./operational-graph/operational-graph/operational-graph.component";
import {
  OperationalReportComponent
} from "./operational-report/operational-report/operational-report.component";
import {EQUIPMENT_MAINTAIN} from "../auth/roles";

const routes: Routes = [
  {
    path: '',
    component: EquipmentBoardComponent
  },
  {
    path: ':id',
    component: EquipmentDetailsComponent,
    children: [
      {
        path: '',
        redirectTo: 'status',
        pathMatch: 'full'
      },
      {
        path: 'status',
        title: 'BDN Equipment Status',
        component: EquipmentDetailsStatusComponent,
        data: new EquipmentTabRouteData("status", false, [])
      },
      {
        path: 'alarms',
        title: 'BDN Equipment Alarm History',
        component: EquipmentDetailsAlarmsComponent,
        data: new EquipmentTabRouteData("alarms", true, [])
      },
      {
        path: 'module',
        title: 'BDN Equipment Module Info',
        component: EquipmentDetailsModuleSettingsComponent,
        data: new EquipmentTabRouteData("Module Settings", true, [EQUIPMENT_MAINTAIN])
      },
      {
        path: 'information',
        title: 'BDN Equipment Information',
        component: EquipmentDetailsInformationComponent,
        data: new EquipmentTabRouteData("Information", false, [])
      },
      {
        path: 'documents',
        title: 'BDN Equipment Documents',
        component: EquipmentDetailsDocumentComponent,
        data: new EquipmentTabRouteData("Documents", false, [])
      },
      {
        path: 'spareparts',
        title: 'BDN Equipment Spare Parts',
        component: EquipmentDetailsSparePartsComponent,
        data: new EquipmentTabRouteData("Spare parts", false, [])
      },
      {
        path: 'operational/graph',
        title: 'BDN Equipment Operational Graph',
        component: OperationalGraphComponent,
        data: new EquipmentTabRouteData("iotChart", true, [])
      },
      {
        path: 'operational/report',
        title: 'BDN Equipment Operational Report',
        component: OperationalReportComponent,
        data: new EquipmentTabRouteData("title", true, [])
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EquipmentRoutingModule {
}
