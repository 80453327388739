import { Component, Input, output, signal } from '@angular/core';
import { HomeService } from '../model/home.service';
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
})
export class MainMenuComponent {
  @Input() set isMobile(value: boolean) {
    if (value) this.setCollapsed(true)
  }

  collapsed = signal(false);
  onCollapsed = output<boolean>();

  isHandset$: Observable<boolean> = this.breakpoint.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(public service: HomeService, private breakpoint: BreakpointObserver) {
  }

  ngOnInit() {
    this.isHandset$.subscribe(h => {
      if (h) this.setCollapsed(true)
    })
  }


  toggleCollapsed() {
    this.setCollapsed(!this.collapsed())
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed.set(collapsed)
    this.onCollapsed.emit(this.collapsed())
  }
}
