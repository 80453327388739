<div class="px-2 pt-2">
  <app-category-filter-list (responseChanged)="handleData($event)" [categorySelectorVisible]="categorySelectorVisible" [categoryType]="contentType"/>
</div>
<div class="flex flex-col lg:flex-row gap-4 p-2">

  <div class="flex flex-col gap-2 flex-auto justify-start">
    <mat-progress-bar *ngIf="searching()" mode="indeterminate"></mat-progress-bar>
    <ng-container *ngIf="response()">
      <app-product-board-content-result-list (page)="handlePageEvent($event)"
                                             [response]="response()">
      </app-product-board-content-result-list>
    </ng-container>


  </div>
</div>
