import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MaterialModule } from "../../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { MainMenuItemComponent } from "./main-menu/main-menu-item/main-menu-item.component";


@NgModule({
  declarations: [
    MainMenuComponent,
    MainMenuItemComponent
  ],
  exports: [
    MainMenuComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    RouterModule
  ]
})
export class MainMenuModule { }
