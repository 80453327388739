import { Component, Input } from '@angular/core';
import { MainHeaderAction, MainHeaderActionButton, MainHeaderActionLinkExternal, MainHeaderActionLinkInternal } from "../model/main-header-action";

@Component({
  selector: 'app-main-header-action',
  templateUrl: './main-header-action.component.html',
  styleUrl: './main-header-action.component.scss'
})
export class MainHeaderActionComponent {

  @Input() set value(value: MainHeaderAction) {

    this.linkInternal = undefined
    this.linkExternal = undefined
    this.button = undefined

    if (value instanceof MainHeaderActionLinkExternal) {
      this.linkExternal = value
    } else if (value instanceof MainHeaderActionLinkInternal) {
      this.linkInternal = value
    } else if (value instanceof MainHeaderActionButton) {
      this.button = value
    }

  }

  linkExternal: MainHeaderActionLinkExternal | undefined
  linkInternal: MainHeaderActionLinkInternal | undefined
  button: MainHeaderActionButton | undefined

}
