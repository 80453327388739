import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Note, NoteChangeRequest } from "./note-api";
import { Observable } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { NOTE_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class NoteService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/note', logging, zone)
    this.retryCount = 1
  }

  create(itemId: number, request: NoteChangeRequest) {
    return this.post('' + itemId, request)
  }

  findByItemId(itemId: number): Observable<Note> {
    return this.get('by/item/' + itemId)
  }

  update(itemId: number, id: number, request: NoteChangeRequest) {
    return this.put('system/' + itemId + '/id/' + id, request)
  }

  deleteNote(itemId: number, id: number) {
    return this.delete('system/' + itemId + '/id/' + id)
  }

  hasEditPermissions(): boolean {
    return this.authService.hasRole(NOTE_WRITE)
  }
}
