import { Component, Input } from '@angular/core';
import { Site, SiteContact, SiteContactChangeRequest } from "../model/site-api";
import { FormBuilder, Validators } from "@angular/forms";
import { ContactService } from "../model/contact.service";
import { SiteService } from "../model/site.service";

@Component({
  selector: 'app-site-details-contact',
  templateUrl: './site-details-contact.component.html',
  styleUrls: ['./site-details-contact.component.scss']
})
export class SiteDetailsContactComponent {

  @Input()
  set data(data: Site | undefined) {
    this.site = data
    this.updateData()
  }

  @Input()
  canWrite = false

  site: Site | undefined
  reloading: boolean = false
  contact: SiteContact | undefined

  editMode: boolean = false
  form = this.fb.group({
    name: this.fb.control('', [Validators.required]),
    phone: this.fb.control('', []),
    email: this.fb.control('', [Validators.required, Validators.email]),
  })

  constructor(
    public service: SiteService,
    private contactService: ContactService,
    private fb: FormBuilder
  ) {
  }


  private updateData() {
    if (!this.site) return
    if (this.reloading) return
    this.reloading = true
    this.service.getContact(this.site.id, 0, 1).subscribe(data => this.handleData(data.content[0]))
  }

  private handleData(data: SiteContact) {
    this.contact = data
    this.setFormData()
    this.reloading = false
  }


  private setFormData() {
    if (!this.contact) {
      this.form.reset()
      return
    }

    this.form.get('name')?.setValue(this.contact.name)
    this.form.get('phone')?.setValue(this.contact.phoneNumber)
    this.form.get('email')?.setValue(this.contact.email)
  }

  save() {
    if (!this.site) return
    if (!this.form.valid) return

    const request: SiteContactChangeRequest = {
      name: this.form.get('name')?.value || '',
      phoneNumber: this.form.get('phone')?.value || '',
      email: this.form.get('email')?.value || '',
      primary: true
    }
    this.editMode = false
    this.reloading = true
    if (this.contact) {
      this.contactService.update(this.contact.id, request).subscribe(d => this.handleData(d))
    } else {
      this.contactService.create(this.site.id, request).subscribe(d => this.handleData(d))
    }
  }

  reset() {
    this.editMode = false
    this.setFormData()
  }
}
