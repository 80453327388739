import { Component } from '@angular/core';
import { PageEvent } from "@angular/material/paginator";
import { interval, Subscription } from "rxjs";
import { FilterService } from "../../filter/model/filter.service";
import { SiteInfo } from "../model/site-api";
import { Page } from "../../utils/page";
import { SiteService } from "../model/site.service";
import { HotToastService } from "@ngxpert/hot-toast";

@Component({
  selector: 'app-site-board-content-list',
  templateUrl: './site-board-content-list.component.html',
  styleUrls: ['./site-board-content-list.component.scss']
})
export class SiteBoardContentListComponent {

  reloading: boolean = false
  pageIndex: number = 0
  pageSize: number = 20
  totalSize: number = -1
  data: SiteInfo[] = []

  private filterSubscription: Subscription | undefined
  private reloadSubscription: Subscription | undefined


  constructor(private filterService: FilterService,
              public service: SiteService,
              private hotToast: HotToastService
  ) {

  }

  handlePaginatorEvent(event: PageEvent) {
    this.load(event.pageIndex, event.pageSize)
  }

  ngOnInit() {
    this.load(0, this.pageSize)
    this.filterSubscription = this.filterService.activeFilter.subscribe(value => {
      if (this.filterService.activated) this.load(0, this.pageSize)
    })
    this.reloadSubscription = interval(180000).subscribe(_ => this.load(this.pageIndex, this.pageSize))

  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe()
      this.filterSubscription = undefined
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe()
      this.reloadSubscription = undefined
    }
  }

  load(page: number, size: number) {
    if (this.reloading) return
    this.reloading = true
    if (this.filterService.activated) {
      let filter = this.filterService.activeFilter.value
      this.service.getFilteredSites(filter, page, size).subscribe({
        next: d => this.handleData(d),
        error: err => this.handleError(err)
      })
    } else {
      this.service.getSites(page, size).subscribe({
        next: d => this.handleData(d),
        error: err => this.handleError(err)
      })
    }
  }

  private handleData(data: Page<SiteInfo>) {
    this.data = data.content

    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.totalSize = data.totalSize
    this.reloading = false
  }

  private handleError(err: any) {
    this.hotToast.error("Failed to load data",)
  }
}
