import {inject, Injectable} from '@angular/core';
import {FilterType} from "./filter.const";
import {FilterGroup} from "./filter-group/filter-group.component";
import {
  CategoryGroup,
  ExtendedCategory,
  SearchResponse
} from "../../portal/product/model/facade.model";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  translate = inject(TranslateService);

  toFilterGroups(rootGroups: {
    application: CategoryGroup[],
    technology: CategoryGroup[]
  }, response: SearchResponse | null): FilterGroup[][] {
    if (!rootGroups) return [] as FilterGroup[][];
    const selectedApplicationId = this.getCurrentGroupId(rootGroups.application)
    const selectedTechnologyId = this.getCurrentGroupId(rootGroups.technology)
    return Object.values(rootGroups).map((root) => {
      return root.map((group: CategoryGroup) => {
        const filter = {
          clearable: true,
          name: this.translate.instant('category.filter.list.groupName', {groupName: group?.name}),
          nameSuffix: response?.entries.totalSize.toString(),
          type: FilterType.AutoSelect,
          selectedValue: group.selectedValue,
          options: group?.categories.filter(c => !!c.amount).map((categoryEntry: ExtendedCategory) => ({
            value: {
              groupName: group.name,
              parentGroupName: root[0].name,
              categoryId: categoryEntry.category.id,
              selectedApplicationId,
              selectedTechnologyId,
              selectedIds: [selectedApplicationId, selectedTechnologyId].filter(item => item?.length ?? 0 > 0)
            },
            displayValue: categoryEntry.category.title,
            displayValueSuffix: categoryEntry.amount
          }))
        };

        return {
          groupName: group?.name,
          groupId: group?.id,
          filters: [filter]
        } as FilterGroup;

      });
    });
  }

  private getCurrentGroupId(group: CategoryGroup[]): string {
    if (!group.length) {
      return '';
    }
    return group[group.length - 1].selectedValue?.value?.categoryId
      || group[group.length - 1].id;
  }
}
