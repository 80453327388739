import { Injectable, NgZone } from '@angular/core';
import { Observable } from "rxjs";
import { Equipment, EquipmentInfo, EquipmentUpdateRequest } from "./equipment-api";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { BaseService } from "../../utils/base-service";
import { ResourceHierarchyNode } from "../../hierarchy/model/hierarchy-api";
import { Page } from "../../utils/page";
import { Filter } from "../../filter/model/filter.service";
import { AuthService } from "../../auth/auth.service";
import { EQUIPMENT_ADMIN, EQUIPMENT_EXEMPT, EQUIPMENT_MAINTAIN, EQUIPMENT_OPERATE, EQUIPMENT_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/equipment', logging, zone)
  }

  getInfo(equipmentId: number): Observable<EquipmentInfo> {
    return this.get(equipmentId + '/info')
  }

  getEquipment(equipmentId: number): Observable<Equipment> {
    return this.get('' + equipmentId)
  }

  getPath(equipmentId: number): Observable<ResourceHierarchyNode[]> {
    return this.get(equipmentId + '/path')
  }

  getSystemEquipments(systemId: number, page: number, size: number): Observable<Page<EquipmentInfo>> {
    return this.getPaged('system/' + systemId, page, size)
  }

  getFilteredSystemEquipments(systemId: number, filter: Filter, page: number, size: number): Observable<Page<EquipmentInfo>> {
    return this.postPaged('system/' + systemId, filter, page, size)
  }

  hasEquipmentEditPermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_WRITE) || this.authService.hasRole(EQUIPMENT_ADMIN)
  }

  hasOperatePermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_OPERATE)
  }

  hasExemptPermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_EXEMPT)
  }

  hasMaintenancePermissions(): boolean {
    return this.authService.hasRole(EQUIPMENT_MAINTAIN)
  }

  deleteEquipment(equipmentId: number): Observable<Equipment> {
    return this.delete('' + equipmentId)
  }

  changeEquipment(equipmentId: number, request: EquipmentUpdateRequest): Observable<Equipment> {
    return this.put('' + equipmentId, request)
  }
}
