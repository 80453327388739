<div class="px-2 pt-2">
  <app-category-filter-list
    (responseChanged)="handleData($event)"
    [categorySelectorVisible]="categorySelectorVisible"
    [categoryType]="contentType"
    [filterChipItems]="filterChipItems()"
    [productId]="productId"/>
</div>

<div class="flex flex-col lg:flex-row gap-2 p-2">
  <div class="flex flex-col gap-2 flex-auto justify-start">
    <mat-progress-bar *ngIf="searching()" mode="indeterminate"></mat-progress-bar>
    <mat-card>
      <mat-card-content class="p-0">
        <app-document-board-content-result-list (page)="handlePageEvent($event)" [response]="response">

        </app-document-board-content-result-list>
      </mat-card-content>
    </mat-card>

  </div>
</div>

<app-feedback-button title="feedback.document"></app-feedback-button>
