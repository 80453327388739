<div class="flex flex-col gap-4 min-w-96">
  <app-category-select-tree #application title="Application" [root]="applicationRoot"
                            (selectionChanged)="handleApplicationSelectionChanged($event)"
  >
  </app-category-select-tree>
  <app-category-select-tree #technology title="Technology" [root]="technologyRoot"
                            (selectionChanged)="handleTechnologySelectionChanged($event)"
  >
  </app-category-select-tree>
</div>
