import { Component, Input } from '@angular/core';
import { EquipmentOperationalReport } from "../model/operational-report-api";

@Component({
  selector: 'app-operational-report-equipment-activity',
  templateUrl: './operational-report-equipment-activity.component.html',
  styleUrl: './operational-report-equipment-activity.component.scss'
})
export class OperationalReportEquipmentActivityComponent {

  @Input()
  set reportData(data: EquipmentOperationalReport | undefined) {
    this.report = data
  }

  report: EquipmentOperationalReport | undefined

  constructor() { }
}
