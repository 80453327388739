import { Component, Input } from '@angular/core';
import { EquipmentPropertyHeatMapReport, HeatmapEntry } from "../../model/operational-report-activity-api";

@Component({
  selector: 'app-activity-heatmap',
  templateUrl: './activity-heatmap.component.html',
  styleUrl: './activity-heatmap.component.scss'
})
export class ActivityHeatmapComponent {

  @Input()
  set data(data: EquipmentPropertyHeatMapReport | undefined) {
    if (!data) return
    this.report = data
    this.process(data)
  }

  report: EquipmentPropertyHeatMapReport | undefined
  heatmap: HeatmapEntry[] = []


  private process(report: EquipmentPropertyHeatMapReport) {
    this.heatmap = report.entries.map((it) => new HeatmapEntry(it.x.start, it.y.start, parseFloat((it.value / 60).toFixed(2))))
  }

}

