import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../utils/page";
import { EventSearchRequest, StatusEvent } from "./event-api";

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/event', logging, zone)
    this.retryCount = 0
  }

  findEvents(equipmentId: number, request: EventSearchRequest, page: number, size: number): Observable<Page<StatusEvent>> {
    return this.putPaged(equipmentId + '/find', request, page, size)
  }

  findLastBefore(equipmentId: number, categoryId: number, timestamp: string): Observable<StatusEvent> {
    let params = new HttpParams()
    params = params.append('timestamp', timestamp)
    return this.get(`/${equipmentId}/find/category/${categoryId}/last/before`, params)
  }
}
