import { AlarmLevelEntry } from "../../site/model/site-api";
import { EventState } from "../../site/model/state-api";

export interface Equipment {
  id: number,
  name: string,
  externalId: string,
  ainResourceId: string,
  sapModelId: string,
  sapThingId: string,
  thingId: number,
  serialNumber: string,
  productId: string,
  timeInFault: string,
  refrigerant: string,
  activated: boolean,
  activatedOn: string,
  registeredOn: string
}

export interface EquipmentInfo {
  equipment: Equipment,
  eventState: EventState,
  alarmState: AlarmLevelEntry[],
}

export class EquipmentUpdateRequest {
  constructor(
    public name: string = "",
    public refrigerant: string = "",
    public type: string = "",
    public serialNumber: string = "",
    public electronicModuleType: string = "",
    public electronicModuleSerialNumber: string = ""
  ) {
  }
}
