<form class="flex flex-col mt-3" [formGroup]="form" *ngIf="form">
  <mat-form-field appearance="outline">
    <mat-label>{{'contactTitle' | translate}}</mat-label>
    <input type="text" matInput formControlName="title" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'contactFirstName' | translate}}</mat-label>
    <input type="text" matInput formControlName="firstName" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'contactLastName' | translate}}</mat-label>
    <input type="text" matInput formControlName="lastName" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'contactPhone' | translate}}</mat-label>
    <input type="text" matInput formControlName="phoneNumber" [readonly]="readonly">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{'contactMail' | translate}}</mat-label>
    <input type="text" matInput formControlName="email" [readonly]="readonly">
  </mat-form-field>
</form>
