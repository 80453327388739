<mat-card style="height: 100%">
  <mat-card-header>
    <mat-card-title>{{'systemImage' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="flex flex-col items-center">
    <img [src]="image" alt="" class="image mt-2"/>
    <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
  </mat-card-content>
  <mat-card-actions *ngIf="service.hasUploadPermissions()  && canWrite">
    <input class="file-input" type="file" accept="image/*" (change)="onFileSelected($event)" #fileUpload>
    <button mat-flat-button class="upload" (click)="fileUpload.click()" [disabled]="uploading">
      <mat-icon>upload</mat-icon>
      {{'uploadPicture' | translate}}
    </button>
  </mat-card-actions>
</mat-card>
