<app-header-toolbar *ngIf="equipment"
                    [title]="equipment.equipment.name"
                    [subtitleFirst]="equipment.equipment.productId"
                    [subtitleLast]="'SN: ' + equipment.equipment.serialNumber"
>
  <app-header-toolbar-icon>
    <app-equipment-image [data]="equipment.equipment"></app-equipment-image>
  </app-header-toolbar-icon>
  <app-header-toolbar-content *ngIf="!(isHandset$ | async)">

    <div class="flex-auto flex flex-col">
      <div class="flex flex-row gap-3 justify-center" *ngIf="equipment">

        <button mat-stroked-button class="upgrade" (click)="onOpenLicenseActivation()"
                *ngIf="equipment.equipment.activated && !license?.license?.active">
          {{'licenseUpgrade' | translate}}
        </button>

        <ng-container *ngIf="service.hasOperatePermissions() && equipment.equipment.activated">
          <button mat-mini-fab style="background-color: white; color: black;"
                  (click)="openEquipmentReport()"
                  matTooltip="{{'operationreport.appTitle' | translate}}">
            <mat-icon>list_alt</mat-icon>
          </button>
          <button mat-mini-fab style="background-color: white; color: black;"
                  (click)="openEquipmentGraph()"
                  matTooltip="{{'iotChart' | translate}}">
            <mat-icon>insights</mat-icon>
          </button>
        </ng-container>
        <app-equipment-activation-status [data]="equipment.equipment" class="basis-1/4 flex flex-col"
                                         *ngIf="service.hasEquipmentEditPermissions() && !isDemoCompany"></app-equipment-activation-status>
      </div>
    </div>
  </app-header-toolbar-content>
  <app-header-toolbar-actions>
    <div class="grow-0">
      <button mat-icon-button [matMenuTriggerFor]="bookmarkMenu" *ngIf="!(isHandset$ | async)"
              matTooltip="{{'toolsServices' | translate}}"
        >
        <mat-icon>bookmarks</mat-icon>
      </button>
      <button mat-icon-button
              *ngIf="shareService.hasSharingEditPermissions() && grantToShare && !(isHandset$ | async)"
              matTooltip="{{'action.Share' | translate}}"
              (click)="openShareDialog()"
      >
        <mat-icon>share</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="manage" *ngIf="service.hasEquipmentEditPermissions()"
              matTooltip="{{'menu.ShowMenu' | translate}}"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-menu #manage="matMenu">
  <ng-container *ngIf="canWrite">
    <button mat-menu-item (click)="changeEquipment()">
      <mat-icon>edit</mat-icon>
      {{'action.Edit' | translate}}
    </button>
    <button mat-menu-item (click)="deleteEquipment()">
      <mat-icon>delete</mat-icon>
      {{'action.Delete' | translate}}
    </button>
  </ng-container>
  <ng-container *ngIf="(isHandset$ | async)">
    <ng-container *ngIf="equipment">
      <button mat-menu-item class="upgrade" (click)="onOpenLicenseActivation()" *ngIf="equipment.equipment.activated && !license?.license?.active">
        <mat-icon>upgrade</mat-icon>
        {{'licenseUpgrade' | translate}}
      </button>

      <ng-container *ngIf="service.hasOperatePermissions() && equipment.equipment.activated">
        <button mat-menu-item (click)="openEquipmentReport()" class="basis-1/4">
          <mat-icon>list_alt</mat-icon>
          {{'operationreport.appTitle' | translate}}
        </button>
        <button mat-menu-item (click)="openEquipmentGraph()" class="basis-1/4">
          <mat-icon>insights</mat-icon>
          {{'iotChart' | translate}}
        </button>
      </ng-container>
      <app-equipment-activation-menu [data]="equipment.equipment" *ngIf="service.hasEquipmentEditPermissions()"></app-equipment-activation-menu>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="(isHandset$ | async)">
    <mat-divider></mat-divider>
    <button mat-menu-item *ngIf="shareService.hasSharingEditPermissions() && grantToShare"
            class="basis-1/4" (click)="openShareDialog()">
      <mat-icon>share</mat-icon>
      {{'action.Share' | translate}}
    </button>
  </ng-container>
</mat-menu>

<mat-menu #bookmarkMenu="matMenu">
  <button mat-menu-item (click)="openBitzerSoftware()">
    <img [src]="bitzerSoftwareImage" class="menu-image" alt="">
  </button>
  <button mat-menu-item (click)="openEPartsSoftware()">
    <img [src]="epartsImage" class="menu-image" alt="">
  </button>
  <button mat-menu-item (click)="openWebShop()">
    <img [src]="myBitzerShopImage" class="menu-image" alt="">
  </button>
</mat-menu>

<div class="flex flex-col gap-2 px-4 py-2">
  <app-hierarchy [data]="hierarchy[0]"></app-hierarchy>
  <app-equipment-details-tabs [data]="equipment?.equipment"></app-equipment-details-tabs>
</div>
