import {CategorySelectRequest} from "../../category/category-select/category-select.component";

export interface Document {
  id: string,
  code: string,
  version: number,
  latest: boolean,
  lang: string,
  fileType: string,
  technology: string,
  type: string,
  sapDocumentNr: string,
  title: string,
  online: boolean,
  url: string,
  internal: boolean,
  translation: string,
  hasVideo: boolean,
}

export class DocumentSearchRequest implements CategorySelectRequest {
  constructor(
    public categoryIds: string[],
    public documentTypes: string[],
    public fullTextSearch: string,
    public lang: string,
    public country: string,
    public productIds: string[],
    public seriesIds: string[]
  ) {}

  selectedApplicationId: string = '';
  selectedTechnologyId: string = '';
}

export interface RelatedCategoryIdEntry {
  categoryId: number,
  amount: number
}

