import { Component, Input } from '@angular/core';
import { TableCardEntry } from "../../component/card/model/card-api";
import { Equipment, EquipmentInfo } from "../model/equipment-api";
import { ElectronicModule } from "../../module/model/module-api";
import { PropertyText } from "../../property/model/property-api";
import { ModuleService } from "../../module/model/module.service";
import { PropertyService } from "../../property/model/property.service";

@Component({
  selector: 'app-equipment-details-information-main',
  templateUrl: './equipment-details-information-main.component.html',
  styleUrls: ['./equipment-details-information-main.component.scss']
})
export class EquipmentDetailsInformationMainComponent {

  @Input()
  set equipment(equipment: EquipmentInfo | undefined) {
    if (equipment) this.load(equipment.equipment)
  }

  eModule: ElectronicModule | undefined
  firmwareVersion: string = ''
  title = 'mainSetup'
  data: TableCardEntry[] = []


  constructor(
    private moduleService: ModuleService,
    private propertyService: PropertyService) {
  }

  load(equipment: Equipment) {
    this.moduleService.findByEquipmentId(equipment.id, 0).subscribe(p => {
      this.handleModule(p.content)
    })
    this.propertyService.getLastThingTextProperties(equipment.thingId, ['BDN.FirmwareVersion']).subscribe(properties => {
      let values: Map<string, PropertyText> = new Map(Object.entries(properties))
      this.handleFirmware(values.get('BDN.FirmwareVersion'))
    })

    let entries: { [key: string]: string } = {
      "refrigerant": equipment.refrigerant,
      "compressorName": equipment.name,
    }
    for (const key in entries) {
      this.pushData(key, entries[key])
    }
  }

  private handleModule(content: ElectronicModule[]) {
    if (!content || !content.length) return
    let eModule = content[0]

    let entries: { [key: string]: string } = {
      "SNElectronicModule": eModule?.serialNumber,
      "electronicModule": eModule?.type,
    }
    for (const key in entries) {
      this.pushData(key, entries[key])
    }
  }


  private handleFirmware(property: PropertyText | undefined) {
    let firmwareVersion = property?.value
    this.pushData("Firmware Version", firmwareVersion
    )
  }

  pushData(key: string, value: string | undefined) {
    this.data.push(
      new TableCardEntry(key, value || ""),
    )
  }
}
