<ng-container *ngIf="data">
  <div class="description ms-3 mt-4"> {{data.description}} </div>
  <div class="ms-3 mt-2"> {{data.detailedDescription}} </div>

  <div class="flex flex-col">
    <div class="my-3 ms-3"><strong>{{ 'possibleCauses' | translate }}</strong></div>

    <mat-accordion [multi]="true">
      <mat-expansion-panel *ngFor="let hint of data.hints">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{hint.text}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let step of hint.steps">
          <div class="hint-step flex gap-2 items-center mb-3">
            <mat-icon>play_arrow</mat-icon>
            <span>{{step}}</span>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</ng-container>
