import { Component } from '@angular/core';
import { OperationalReportService } from "../model/operational-report.service";

@Component({
  selector: 'app-operational-report-header',
  templateUrl: './operational-report-header.component.html',
  styleUrl: './operational-report-header.component.scss'
})
export class OperationalReportHeaderComponent {

  systemTime: boolean = false

  constructor(
    public service: OperationalReportService
  ) {
  }

  refresh() {
    this.service.refresh()
  }

  download() {
    this.service.download()
  }

  toggleSystemTime() {
    this.systemTime = !this.systemTime
    this.service.switchSystemTime(this.systemTime)
  }
}
