import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Subject} from "rxjs";
import {
  ConfigItemEntry,
  ConfigItemSelectEntry,
  ConfigSelectionType,
  CsticValueInfo,
  ProductConfigSelectRequest,
  ProductConfigSelectResult
} from "./product-config-api";
import {ProductConfigService} from "./product-config.service";
import {finalize, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProductConfigSelectService {
  reloading$ = new BehaviorSubject<boolean>(false)
  selectedPidChanged$ = new BehaviorSubject<ProductConfigSelectResult | null>(null)

  searchSubject = new Subject<ProductConfigSelectResult>()
  search$ = this.searchSubject.asObservable()

  result: ProductConfigSelectResult = {
    entries: [],
    items: [],
    request: new ProductConfigSelectRequest('', []),
    suggestedEntries: []
  }
  request: ProductConfigSelectRequest | undefined

  constructor(
    private service: ProductConfigService
  ) {
  }

  selectProduct(productId: string) {
    if (this.reloading$.value) return EMPTY
    this.reloading$.next(true)
    return this.service.selectStandard(productId)
    .pipe(
      tap((d) => this.handleData(d)),
      finalize(() => {
        this.reloading$.next(false)
      }));
  }

  resetSelection() {
    if (!this.request) return
    this.request.entries = []
    this.updateRequest()
  }

  selectConfigItem(c: ConfigItemEntry, o: CsticValueInfo) {
    if (!this.request) return
    const itemIndex = this.request.entries.findIndex(e => e.key == c.key)
    let entry = new ConfigItemSelectEntry(c.key, o.key, ConfigSelectionType.USER)
    if (itemIndex >= 0) {
      this.request.entries[itemIndex] = entry
    } else {
      this.request.entries.push(entry)
    }
    this.updateRequest()
  }

  unselectConfigItem(c: ConfigItemEntry) {
    if (!this.request) return
    const item = this.request.entries.find(e => e.key == c.key)
    if (!item) return
    item.selectionType = ConfigSelectionType.NO_SELECTION
    this.updateRequest()
  }

  private updateRequest() {
    if (!this.request) return
    if (this.reloading$.value) return
    this.reloading$.next(true)
    this.service.select(this.request).pipe(
      tap((d) => this.handleData(d)),
      finalize(() => {
        this.reloading$.next(false)
      })).subscribe();
  }

  private handleData(d: ProductConfigSelectResult): ProductConfigSelectResult {
    if (d) {
      this.searchSubject.next(d)
      this.result = d
      this.request = d.request
      this.selectedPidChanged$.next(d)
    }

    return d
  }

}
