<div class="flex flex-col scroll-off">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="basis-full text-center my-3">{{ 'iotStatus' | translate }}</div>
  <mat-divider class="mb-4"></mat-divider>

  <form [formGroup]="info" class="flex flex-col justify-center items-center activation-form">
    <div>{{ 'gateway.Info' | translate }}</div>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'onlineStatus' | translate }}</mat-label>
      <input type="text" matInput formControlName="gatewayStatus" [readonly]="true">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'macAddress' | translate }}</mat-label>
      <input type="text" matInput formControlName="gatewayMAC" [readonly]="true">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'firmwareVersion' | translate }}</mat-label>
      <input type="text" matInput formControlName="firmwareVersion" [readonly]="true">
    </mat-form-field>
  </form>

  <mat-divider class="mb-4"></mat-divider>

  <form [formGroup]="device" class="flex flex-col justify-center items-center activation-form">
    <div>{{ 'device.Info' | translate }}</div>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'device.Status' | translate }}</mat-label>
      <input type="text" matInput formControlName="status" [readonly]="true">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'iotProtocol' | translate }}</mat-label>
      <input type="text" matInput formControlName="protocol" [readonly]="true">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'deviceAddress' | translate }}</mat-label>
      <input type="text" matInput formControlName="address" [readonly]="true">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'device.Errors' | translate }}</mat-label>
      <input type="text" matInput formControlName="errors" [readonly]="true">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-8">
      <mat-label>{{ 'type' | translate }}</mat-label>
      <input type="text" matInput formControlName="type" [readonly]="true">
    </mat-form-field>
  </form>

  <div class="flex content-end m-3">
    <button mat-flat-button (click)="openEditDialog()" *ngIf="equipmentService.hasEquipmentEditPermissions() && canWrite">
      {{ 'action.Edit' | translate }}
    </button>
    <button mat-flat-button mat-dialog-close>
      {{ 'back' | translate }}
    </button>
  </div>
</div>
