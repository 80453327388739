<mat-card>
  <mat-card-header>
    <mat-card-title>{{'Documents' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="documents">

      <ng-container matColumnDef="title">
        <th class="name-column" mat-header-cell *matHeaderCellDef> {{'table.title' | translate}} </th>
        <td class="name-column" mat-cell *matCellDef="let document"><strong>{{document.title}}</strong></td>
      </ng-container>

      <ng-container matColumnDef="filename">
        <th class="name-column" mat-header-cell *matHeaderCellDef> {{'name' | translate}} </th>
        <td class="name-column" mat-cell *matCellDef="let document"><strong>{{document.filename}}</strong></td>
      </ng-container>

      <ng-container matColumnDef="extension">
        <th class="name-column" mat-header-cell *matHeaderCellDef> {{'type' | translate}} </th>
        <td class="name-column" mat-cell *matCellDef="let document"><strong>{{document.mediaType}}</strong></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="md:basis-2/12" mat-header-cell *matHeaderCellDef> <span class="float-end me-4">{{'actions' | translate}}</span> </th>
        <td class="md:basis-2/12" mat-cell *matCellDef="let document">
          <button mat-icon-button (click)="downloadDocument(document)" matTooltip="{{'download' | translate}}" class="float-end">
            <mat-icon>download</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteDocument(document)" matTooltip="{{'action.Delete' | translate}}" class="float-end" *ngIf="service.hasEditPermissions()">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="documentColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: documentColumns;" class="hover-effect"></tr>
    </table>
    <mat-paginator *ngIf="totalSize > 5"
                   [length]="totalSize"
                   [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   showFirstLastButtons
                   (page)="handlePaginatorEvent($event)">
    </mat-paginator>

  </mat-card-content>
  <mat-card-actions *ngIf="service.hasEditPermissions() && canWrite">
    <button mat-flat-button (click)="showUploadDialog()">
      <mat-icon>upload</mat-icon>
      {{'Upload' | translate}}
    </button>
  </mat-card-actions>
  <mat-card>

  </mat-card>
</mat-card>
