import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GeoLocationChangeRequest, GeoPosition, Site, SiteInfo } from "../model/site-api";
import * as L from "leaflet";
import { LatLng, latLng } from "leaflet";
import { SiteService } from "../model/site.service";
import { CompressorStatus } from "../model/state-api";
import {BreakpointObserver} from "@angular/cdk/layout";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-site-details-map',
  templateUrl: './site-details-map.component.html',
  styleUrls: ['./site-details-map.component.scss']
})
export class SiteDetailsMapComponent {

  @Input()
  set data(data: Site | undefined) {
    this.site = data
    this.updateData()
  }

  @Input()
  canWrite = false

  site: Site | undefined
  info: SiteInfo | undefined
  geoLocation: GeoPosition | undefined
  reloading: boolean = false

  private map: L.Map | undefined
  private markers: L.LayerGroup | undefined
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef<HTMLDivElement>;
  private statusColor: Map<CompressorStatus, string> = new Map(
    [
      [CompressorStatus.FAULT, '#D22525'],
      [CompressorStatus.RUNNING, '#1FBE1F'],
      [CompressorStatus.STOPPED, '#000000'],
    ]
  )

  editMode: boolean = false
  changed: LatLng | undefined = undefined

  mapForm = this.fb.group({
    latitude: this.fb.control(''),
    longitude: this.fb.control('')
  })

  constructor(
    public service: SiteService,
    private responsive: BreakpointObserver,
    private fb: FormBuilder,
  ) {
  }

  ngAfterViewInit() {
    this.initMap()
    this.responsive.observe('(min-width: 992px)').subscribe(result => {
      this.mapContainer.nativeElement.style.height = result.matches ? '100%' : '500px'
    })
  }

  private updateData() {
    if (!this.site) return
    if (this.reloading) return
    this.reloading = true
    this.service.getInfo(this.site.id).subscribe(d => this.handleSiteInfo(d))
    this.service.getGeoPosition(this.site.id).subscribe(d => this.handleGeoLocation(d))
  }


  private handleSiteInfo(d: SiteInfo) {
    this.info = d
    this.initMap()
  }

  private handleGeoLocation(d: GeoPosition) {
    this.geoLocation = d
    this.reset()
    this.initMap()
  }


  private initMap() {
    if (!this.info) return
    if (!this.geoLocation) return

    let defaultLocation = latLng(48.783333, 9.183333)
    let location = this.geoLocation.location ? latLng(this.geoLocation.location.coordinates[1], this.geoLocation.location.coordinates[0]) : defaultLocation
    this.handleFormData(this.geoLocation.location?.coordinates[1], this.geoLocation.location?.coordinates[0])

    if (this.map) {
      this.map.panTo(location)
    } else {
      this.map = L.map('map', {
        center: location,
        zoom: 6
      })
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map)
    }

    if (this.markers) {
      this.markers.clearLayers()
    } else {
      this.markers = L.layerGroup().addTo(this.map)
    }


    this.addMarker()
    this.reloading = false
    if (this.editMode) this.addMapClickEvent()
  }


  private addMarker() {
    if (!this.map) return
    if (!this.info) return
    if (!this.geoLocation) return
    if (!this.geoLocation.location) return

    const color = this.info.eventState.online || this.info.eventState.status === CompressorStatus.UNKNOWN ? '#6C6C6C' : this.statusColor.get(this.info.eventState.status)
    const icon = this.setMarkerIcon(color)

    L.marker(latLng(this.geoLocation.location.coordinates[1], this.geoLocation.location.coordinates[0]), { icon: icon })
      .addTo(this.markers!)
  }


  private setMarkerIcon(color?: string) {
    const markerHtmlStyles = `
    background-color: ${ color || '#008AFF' };
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    cursor: pointer;
    border: 1px solid #FFFFFF`

    return L.divIcon({
      iconSize: [10, 10],
      iconAnchor: [0, 0],
      html: `<span style="${ markerHtmlStyles }" />`
    })
  }

  private addMapClickEvent() {
    if (!this.map) return
    this.map.on('click', (e) => {
      this.setGeoPosition(e.latlng)
    })
  }

  private setGeoPosition(latLng: L.LatLng) {
    if (!this.markers) return
    const icon = this.setMarkerIcon()

    this.markers.clearLayers()
    this.addMarker()
    L.marker(latLng, { icon: icon }).addTo(this.markers)
    this.changed = latLng
    this.handleFormData(latLng.lat, latLng.lng)
  }

  save() {
    if (!this.changed) return
    if (!this.site) return

    const request: GeoLocationChangeRequest = {
      location:
        {
          type: 'Point',
          coordinates: [this.changed.lng, this.changed.lat]
        }
    }
    this.service.updateGeoPosition(this.site.id, request).subscribe(d => this.handleGeoLocation(d))
  }

  reset() {
    this.editMode = false
    this.changed = undefined
    this.markers?.clearLayers()
    this.addMarker()
  }

  edit() {
    if (this.editMode) return
    this.editMode = true
    this.changed = undefined
    this.addMapClickEvent()
  }

  handleInputChange() {
    const lat = this.mapForm.get('latitude')?.value
    const lng = this.mapForm.get('longitude')?.value

    if (!lat || !lng) return

    const coordinates = latLng(Number(lat), Number(lng))
    this.setGeoPosition(coordinates)
  }

  private handleFormData(lat: number | undefined, lng: number | undefined) {
    this.mapForm.get('latitude')?.setValue( lat ? lat.toString() : '')
    this.mapForm.get('longitude')?.setValue(lng ? lng.toString() : '')
  }
}
