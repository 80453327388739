<h5 class="m-3">{{ 'compressorStatus' | translate }}</h5>

<div class="m-3">
  <div class="flex justify-between gap-3">
    <mat-icon [ngClass]="{ 'fault': isFault }">warning</mat-icon>
    <mat-icon [ngClass]="{ 'critical': isCritical }">warning</mat-icon>
    <mat-icon [ngClass]="{ 'ok': !isFault && !isCritical }">check</mat-icon>
  </div>

  @if (isFault) {
    <div>{{ 'reportStatusFault' | translate }}</div>
  } @else if (isCritical) {
    <div>{{ 'reportStatusCritical' | translate }}</div>
  } @else {
    <div>{{ 'reportStatusOk' | translate }}</div>
  }
</div>
