import { Component } from '@angular/core';

@Component({
  selector: 'app-clear-alarm-confirmation-dialog',
  templateUrl: './clear-alarm-confirmation-dialog.component.html',
  styleUrls: ['./clear-alarm-confirmation-dialog.component.scss']
})
export class ClearAlarmConfirmationDialogComponent {

}
