import { Component, Input } from '@angular/core';
import { PriceResponseEntry } from "../../../commerce/model/commerce-api";
import { ProductConfigIdInfo } from "../model/product-config-api";

@Component({
  selector: 'app-product-config-cart',
  templateUrl: './product-config-cart.component.html',
  styleUrl: './product-config-cart.component.scss'
})
export class ProductConfigCartComponent {
  @Input() pid!: ProductConfigIdInfo
  @Input() data!: PriceResponseEntry | undefined;
  @Input() currency!: string;

}
