import { Component, Input } from '@angular/core';
import { StorageDocument } from "../../document-storage/model/document-api";
import { System } from "../model/system-api";
import { DocumentStorageService } from "../../document-storage/model/document-storage.service";
import { Page } from "../../utils/page";
import { PageEvent } from "@angular/material/paginator";
import { saveAs } from "file-saver";
import { HotToastService } from "@ngxpert/hot-toast";
import { MatDialog } from "@angular/material/dialog";
import { DocumentUploadDialogComponent } from "../../document-storage/document-upload-dialog/document-upload-dialog.component";

@Component({
  selector: 'app-system-details-documents',
  templateUrl: './system-details-documents.component.html',
  styleUrls: ['./system-details-documents.component.scss']
})
export class SystemDetailsDocumentsComponent {

  @Input()
  set data(data: System | undefined) {
    this.system = data
    this.updateData()
  }

  @Input()
  canWrite: boolean = false

  system: System | undefined
  documentColumns: string[] = ['title', 'filename', 'extension', 'actions']
  documents: StorageDocument[] = []

  reloading: boolean = false
  pageIndex: number = 0
  pageSize: number = 5
  totalSize: number = -1

  file: File | undefined

  constructor(
    public service: DocumentStorageService,
    private toast: HotToastService,
    public dialog: MatDialog
  ) {
  }

  private updateData() {
    this.loadPage(this.pageIndex, this.pageSize)
  }

  private loadPage(page: number, size: number) {
    if (!this.system) return
    if (this.reloading) return
    this.reloading = true
    this.service.findByItemId(this.system.id, page, size).subscribe(p => this.handleData(p))
  }

  private handleData(p: Page<StorageDocument>) {
    this.documents = p.content
    this.pageIndex = p.pageable.number
    this.pageSize = p.pageable.size
    this.totalSize = p.totalSize
    this.reloading = false
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loadPage(event.pageIndex, event.pageSize)
  }

  downloadDocument(document: StorageDocument) {
    if (!this.system) return
    this.service.downloadDocument(this.system.id, document.id).subscribe(blob => {
      if (!blob) return
      const data = new Blob([blob], {
        type: document.mediaType
      });
      saveAs(data, document.filename)
    })
  }

  onChange(event: any) {
    this.file = event.target.files[0]
  }

  showUploadDialog() {
    if (!this.system) return
    const dialogRef = this.dialog.open(DocumentUploadDialogComponent, {
      data: this.system.id,
    })

    dialogRef.afterClosed().subscribe(result => {
      this.updateData()
    });
  }

  deleteDocument(document: StorageDocument) {
    if (!this.system) return
    this.service.deleteDocument(document.id, this.system.id).subscribe(d => {
      this.updateData()
    })
  }
}
