<div class="container-fluid flex flex-row flex-wrap gap-3 pt-3">
  <mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
  <ng-container *ngFor="let group of parameterGroups">
    <mat-card class="parameter-card">
      <mat-card-header class="card-header">
        <mat-card-title>{{group.name}}</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngFor="let parameter of group.parameter">
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{parameter.name}}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{parameter.value}} {{parameter.uom}}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
