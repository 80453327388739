<mat-list class="flex-auto pt-0">
  @for (entry of entries; track entry) {
    <mat-list-item class="document-item" *ngIf="!(isHandset$ | async)">
      <div class="flex flex-row items-center gap-2">
        <div class="flex flex-col gap-1 flex-auto content-stretch align-middle">
          <div class="flex flex-row align-middle gap-2 items-center">
            <mat-icon
              svgIcon="{{entry.fileType}}">
            </mat-icon>
            <div class="basis-6/12 app-link" (click)="showDocument(entry)">
              @if (entry.title.length > 70) {
                <b [matTooltip]="entry.title">{{ entry.title | truncate: 70 }}</b>
              } @else {
                <b>{{ entry.title }}</b>
              }
            </div>

            <div
              class="basis-2/12">{{
                (Array.isArray(entry.lang)) ? entry.lang.join(" / ").toUpperCase() : entry.lang
              }}
            </div>
            <div class="basis-2/12">{{ entry.code }}</div>
            <div class="basis-1/12">V {{ entry.version }}</div>
            <div class="basis-2/12 flex justify-end">
              <div class="document-type">{{ translateType(entry.type) }}</div>
            </div>
            <button mat-icon-button aria-label="open" (click)="showDocument(entry)">
              <mat-icon [svgIcon]="getIcon(entry.fileType)"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-list-item>
    <mat-card *ngIf="(isHandset$ | async)">
      <mat-card-content (click)="showDocument(entry)">
        <div class="flex flex-row items-center gap-2">
          <mat-icon
            svgIcon="{{entry.fileType}}">
          </mat-icon>

          <div class="flex flex-col gap-1 flex-auto content-stretch">
            <div class="docu-link">
              @if (entry.title.length > 40) {
                <b [matTooltip]="entry.title">{{ entry.title | truncate: 40 }}</b>
              } @else {
                <b>{{ entry.title }}</b>
              }
            </div>
            <div>{{
                (Array.isArray(entry.lang)) ? entry.lang.join(" / ").toUpperCase() : entry.lang
              }}
            </div>
            <div>{{ entry.code }}</div>
            <div>V {{ entry.version }}</div>
            <div class="document-type">{{ translateType(entry.type) }}</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
  }
</mat-list>

<mat-paginator (page)="handlePaginatorEvent($event)"
               *ngIf="totalSize > 0 && totalSize > pageSize"
               [length]="totalSize"
               [pageIndex]="pageIndex"
               [pageSizeOptions]="[5, 10, 25, 100]"
               [pageSize]="pageSize" class="dense-1 pb-3">
</mat-paginator>
