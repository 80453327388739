import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient, HttpEvent, HttpResponse } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { DocumentTitleRequest, StorageDocument } from "./document-api";
import { AuthService } from "../../auth/auth.service";
import { DOCUMENT_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class DocumentStorageService extends BaseService {


  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/document', logging, zone)
    this.retryCount = 1
  }

  setTitle(itemId: number, id: number, request: DocumentTitleRequest): Observable<HttpResponse<StorageDocument>> {
    return this.patchResponse(itemId + '/title/' + id, request)
  }

  upload(itemId: number, file: FormData): Observable<HttpEvent<StorageDocument>> {
    const url = this.createUrl('by/item/' + itemId)
    return this.http.post<StorageDocument>(url, file, {
      reportProgress: true,
      observe: 'events'
    })
  }

  downloadDocument(itemId: number, id: number) {
    const path = itemId + '/download/' + id
    return this.download(path)
  }

  findByItemId(itemId: number, page: number, size: number): Observable<Page<StorageDocument>> {
    return this.getPaged('by/item/' + itemId, page, size)
  }

  deleteDocument(id: number, itemId: number) {
    return this.delete(id + '/system/' + itemId)
  }

  hasEditPermissions(): boolean {
    return this.authService.hasRole(DOCUMENT_WRITE)
  }

}
