import { Component, Input, OnInit } from '@angular/core';
import { AlarmLevelEntry, AlarmType } from "../../site/model/site-api";

@Component({
  selector: 'app-alarm-info',
  templateUrl: './alarm-info.component.html',
  styleUrls: ['./alarm-info.component.scss']
})
export class AlarmInfoComponent implements OnInit {

  @Input() alarms: AlarmLevelEntry[] = []

  criticalAlarms: number = 0
  faultAlarms: number = 0
  warningAlarms: number = 0

  ngOnInit() {
    this.updateValues();
  }


  updateValues() {
    this.criticalAlarms = this.getAlarmCount(AlarmType.CRITICAL)
    this.warningAlarms = this.getAlarmCount(AlarmType.WARNING)
    this.faultAlarms = this.getAlarmCount(AlarmType.FAULT)
  }

  private getAlarmCount(type: AlarmType) {
    if (!this.alarms.length) return 0

    let alarms = this.alarms.filter(alarm => alarm.level === type)
    return alarms.reduce((sum, alarm) => sum + alarm.amount, 0)
  }
}
