@if (statusInfo) {
  <div class="flex flex-row gap-1 items-center" [ngClass]="{
        'justify-start bg-gray-200 rounded px-2.5 py-1.5 w-[196px]': showBackground,
        'justify-center w-full': !showBackground
     }">
    <!--    <mat-icon-->
    <!--      class="material-icon"-->
    <!--      [ngClass]="'material-icon-' + statusInfo.color"-->
    <!--      svgIcon="{{statusInfo.icon}}">-->
    <!--    </mat-icon>-->
    @if (showText) {
      {{ 'material.statuses.' + statusInfo.text | translate }}
    }
    @if (showCode) {
      {{ statusInfo.code }}
    }
  </div>
} @else {
  <span>{{ 'material.statuses.UNDEFINED' | translate }}</span>
  <!--  <mat-icon class="material-icon material-icon-error" fontIcon="help_outline"></mat-icon>-->
}
