<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>
<div class="p-4 flex flex-col gap-3">
  @if (data) {
    <mat-card>
      <div class="flex flex-col gap-2 py-3 px-4">
        <div class="text-lg font-bold">{{ data.name }}</div>
        <div *ngIf="data.description.length > 0">{{ data.description }}</div>
      </div>
    </mat-card>
  }

  <mat-card>
    <div class="flex flex-col gap-2">
      <table mat-table [dataSource]="entries">

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>{{ 'part' | translate }}</th>
          <td mat-cell *matCellDef="let entry">{{ parseInt(entry.code) }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex flex-row gap-3 justify-start items-center">
              {{ 'description' | translate }}
            </div>
          </th>
          <td mat-cell *matCellDef="let entry">{{ entry.description }}</td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>{{ 'spareParts.quantity' | translate }}</th>
          <td mat-cell *matCellDef="let entry">{{ parseInt(entry.quantity) }}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header"></tr>
        <tr mat-row *matRowDef="let info; columns: displayedColumns;" class="hover-effect"></tr>
      </table>
      <mat-divider></mat-divider>
      <div class="flex flex-row gap-2 py-3 px-4">
        @if (data && data.id) {
          <app-file-download-button color="primary" text="cart.export" icon="download"
                                    [provider]="cartService.exportSharedCart.bind(this, data.id)">

          </app-file-download-button>
        }
        <button mat-flat-button color="primary" (click)="importCart()">
          <mat-icon>upload</mat-icon>
          Import
        </button>
      </div>
    </div>
  </mat-card>
</div>
