import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ReportService } from "../model/report.service";
import { OperationalReportService } from "../model/operational-report.service";
import { forkJoin, Subscription, switchMap } from "rxjs";
import { EquipmentOperationalReport } from "../model/operational-report-api";
import { ThingTemplateService } from "../../../thing-template/model/thing-template.service";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-operational-report',
  templateUrl: './operational-report.component.html',
  styleUrl: './operational-report.component.scss'
})
export class OperationalReportComponent implements OnDestroy {

  report: EquipmentOperationalReport | undefined
  reloading: boolean = false
  subscriptions: Subscription[] = []

  constructor(
    private service: ReportService,
    public operationalReportService: OperationalReportService,
    private thingTemplateService: ThingTemplateService,
    private route: ActivatedRoute,
  ) {
    this.load()
  }

  ngOnDestroy() {
    if (!this.subscriptions.length) return
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }

  private load() {
    this.loadParams()
    const changeSubscription = this.operationalReportService.changeEvent.subscribe(result => {
      this.loadReport(this.operationalReportService.equipmentId)
    })
    const downloadSubscription = this.operationalReportService.downloadEvent.subscribe(result => {
      this.downloadReport()
    })
    this.subscriptions.push(changeSubscription, downloadSubscription)
  }

  private loadReport(equipmentId: number) {
    const request = this.operationalReportService.createReportRequest()
    this.reloading = true

    forkJoin([
      this.service.getEquipmentReport(equipmentId, request),
      this.thingTemplateService.getEquipmentTemplate(equipmentId)
    ])
      .pipe(
        switchMap(([report, template]) => {
          if (!report) {
            this.reloading = false
            return []
          }
          this.report = report
          this.reloading = false
          this.operationalReportService.template = template
          return []
        })
      )
      .subscribe()
  }

  private loadParams() {
    const paramSubscription = this.route.params.subscribe((params) => {
      this.reloading = true
      this.operationalReportService.equipmentId = Number(params['id'])
      if (!this.operationalReportService.equipmentId) return
      this.loadReport(this.operationalReportService.equipmentId)
    })
    this.subscriptions.push(paramSubscription)
  }

  private downloadReport() {
    if (!this.report) return
    const blob = JSON.stringify(this.report)
    const data = new Blob([blob], {
      type: 'application/json'
    })
    this.report.info.equipment.id
    saveAs(data, 'operational-report-' + this.report.info.equipment.id + '.json')
  }
}
