<mat-card *ngIf="enabled">
  @if (title) {
    <div class="flex flex-row gap-2 justify-between items-center flex-auto p-2 bg-stone-200">
      <div class="font-medium text-2xl">
        {{ title | translate }}
      </div>
    </div>
  }
  <form [formGroup]="fg">
    <div class="flex flex-col p-3 gap-3">

      <div class="flex flex-row gap-2 justify-center">
        @for (p of points; track p) {
          <label for="point-{{p}}" class="rating-radio">
            <input type="radio" id="point-{{p}}" value="{{p}}" formControlName="score" name="score">
            <span class="radio-text">{{ p }}</span>
            <span class="checkmark"></span>
          </label>
        }
      </div>

      @if (commentVisible) {
        <mat-form-field>
          <mat-label>Textarea</mat-label>
          <textarea matInput formControlName="comment"></textarea>
        </mat-form-field>
      }

      <button mat-flat-button color="primary" (click)="save()">{{ 'feedback.submit' | translate }}</button>
    </div>
  </form>
</mat-card>
