import { Injectable } from '@angular/core';
import LogRocket from "logrocket";
import Usercentrics, { BaseService } from '@usercentrics/cmp-browser-sdk';
import { Principal } from "../auth/principal";
import { environment } from "../../environments/environment";
import { ConsentCookie, ConsentEventSubType, ConsentEventType, ConsentServiceName } from "./consent.api";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  private UC = new Usercentrics('UZKpRkXQt')
  private logRocketConsent = false
  private logRocketInitialized = false
  private principal: Principal | null = null
  private requiresAppReload = false

  constructor(private cookieService: CookieService) {

  }

  init() {
    this.UC.init().then(_ => { this.initUC() })
    window.addEventListener(ConsentEventType.UC_UI_CMP_EVENT, this.handleUCEvent.bind(this));
  }

  private handleUCEvent(e: any) {
    switch (e.detail.type) {
      case ConsentEventSubType.SAVE:
      case ConsentEventSubType.ACCEPT_ALL:
        this.initUC()
        break
    }
  }

  private initUC() {
    let logRocket = this.getService(ConsentServiceName.LOG_ROCKET)
    if (logRocket) {
      this.updateLogRocket(logRocket.consent.status)
    }

    let google = this.getService(ConsentServiceName.GOOGLE_ANALYTICS)
    if (google) {
      this.updateGoogleAnalytics(google)
    }

    if(this.requiresAppReload) window.location.reload()
  }
  handlePrincipalChanged(principal: Principal | null) {
    this.principal = principal
    this.updateLogRocket(this.logRocketConsent)
  }

  openCookieSettings() {
    (window as any).UC_UI.showSecondLayer()
  }

  private updateGoogleAnalytics(service: BaseService) {
    let googleConsent = service.consent.status;
    this.cookieService.set(ConsentCookie.GOOGLE_ANALYTICS, String(googleConsent));
    updateGoogleAnalyticsGlobalVariable(googleConsent)
  }

  private updateLogRocket(consent: boolean) {
    if (!environment.logrocket) return
    if (!this.logRocketConsent && !consent) return

    if (this.logRocketConsent && consent) {
      this.handleLogRocketPrincipal()
    } else if (!this.logRocketConsent && consent) {
      this.enableLogRocket()
      this.handleLogRocketPrincipal()
    } else {
      this.disableLogRocket()
    }

    this.logRocketConsent = consent
  }

  private enableLogRocket() {
    if (!this.logRocketInitialized) {
      LogRocket.init(environment.logrocketAppID)
      this.logRocketInitialized = true
    }
  }

  private disableLogRocket() {
    if (this.logRocketInitialized) {
      this.logRocketInitialized = false
      this.requiresAppReload = true
    }
  }

  private handleLogRocketPrincipal() {
    if (this.principal) {
      LogRocket.identify(environment.logrocketAppID, {
        name: this.principal.username,
        email: this.principal.email,
      })
    }
  }

  private getService(name: ConsentServiceName): BaseService | undefined {
    let uc_ui = (window as any).UC_UI.getServicesBaseInfo()
    return uc_ui?.find((service: BaseService) => service.name == name)
  }
}

export function initialConsent() {
  let cookieService = new CookieService(document, "browser")

  let googleConsent = cookieService.get(ConsentCookie.GOOGLE_ANALYTICS) == "true";
  updateGoogleAnalyticsGlobalVariable(googleConsent)
}

function updateGoogleAnalyticsGlobalVariable(consent: boolean) {
  (window as any)[`ga-disable-${environment.googleAnalyticsId}`] = !consent
}
