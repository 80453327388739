import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  activeFilter: BehaviorSubject<Filter> = new BehaviorSubject<Filter>({
    alarmFilter: false,
    lastAlarms: 1,
    onlineFilter: false,
    refrigerationFilter: '',
    typeFilter: '',
    companyOwnerName: '',
  })

  activated: boolean = false
  siteFilterActive: boolean = false
  filterPanelActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  setAlarmFilter() {
    this.activeFilter.value.alarmFilter = !this.activeFilter.value.alarmFilter
    this.activated = true
    this.activeFilter.next(this.activeFilter.value)
  }

  setOnlineFilter() {
    this.activeFilter.value.onlineFilter = !this.activeFilter.value.onlineFilter
    this.activated = true
    this.activeFilter.next(this.activeFilter.value)
  }

  setLastAlarmsFilter(value: number) {
    this.activeFilter.value.lastAlarms = value
    this.activated = true
    this.activeFilter.next(this.activeFilter.value)
  }

  setSiteFilter(refrig: string, type: string, owner: string) {
    this.activeFilter.value.refrigerationFilter = refrig
    this.activeFilter.value.typeFilter = type
    this.activeFilter.value.companyOwnerName = owner
    this.activated = true
    if((refrig + type + owner).length == 0) {
      this.siteFilterActive = false
    } else {
      this.siteFilterActive = true
    }
    this.activeFilter.next(this.activeFilter.value)
  }

  toggleFilterDisplay() {
    this.toggle(this.filterPanelActive)
  }

  private toggle(subject: BehaviorSubject<boolean>) {
    subject.next(!subject.getValue())
  }
}

export interface Filter {
  alarmFilter: boolean,
  lastAlarms: number,
  onlineFilter: boolean,
  refrigerationFilter: string,
  typeFilter: string,
  companyOwnerName: string,
}
