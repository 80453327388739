@for(d of data; track $index) {
  <div class="flex flex-row gap-1 justify-center">
    <div style="flex: 0 1 32px;">
      <ng-container [ngSwitch]="d.level">
        <mat-icon class="warn" *ngSwitchCase="'WARNING'">warning</mat-icon>
        <mat-icon class="critical" *ngSwitchCase="'CRITICAL'">warning</mat-icon>
        <mat-icon class="fault" *ngSwitchCase="'FAULT'">warning</mat-icon>
        <mat-icon class="default" *ngSwitchDefault>warning</mat-icon>
      </ng-container>
    </div>
    <span class="flex flex-row gap-1 link"
          (click)="openAlarmsDialog(d.code)">
      <span> {{ d.code }} </span>
      <span> ({{ d.amount }}) </span>
    </span>
  </div>
}
