<!--**
 * @(#)ng-date-picker.component.html Sept 05, 2023

 * @author Aakash Kumar
 *-->
<div #trigger cdkOverlayOrigin class="date-picker-main">
  <mat-form-field (click)="toggleDateOptionSelectionList($event)"
                  [class]="{'display-hidden':isShowStaticDefaultOptions}"
                  appearance="outline"
                  class="w-full dense-1"
                  subscriptSizing="dynamic">
    <mat-label (click)="toggleDateOptionSelectionList($event)">{{ inputLabel }}</mat-label>
    <input #dateInput class="cursor-pointer" id="date-input-field" matInput readonly="readonly">
    <mat-icon (click)="clearSelection($event)" *ngIf="!!dateInput.value" class="cursor-pointer pe-0"
              matSuffix matTooltip="Clear">clear
    </mat-icon>
    <mat-icon class="cursor-pointer" matSuffix>date_range</mat-icon>
  </mat-form-field>

  @if (dateDropDownOptions && dateDropDownOptions.length && isShowStaticDefaultOptions) {
    <mat-action-list class="w-full custom-ckd-container range-input" [id]="staticOptionId">
      @for (option of dateDropDownOptions; track option.optionKey) {
        @if (option.isVisible) {
          <mat-list-item [activated]="option.isSelected"
                         (click)="updateSelection(option, dateInput)">{{ option.optionLabel }}
          </mat-list-item>
        }
      }
    </mat-action-list>
  }

  <ng-template
    (overlayOutsideClick)="!isShowStaticDefaultOptions && toggleDateOptionSelectionList()"
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOffsetX]="listCdkConnectedOverlayOffsetX"
    [cdkConnectedOverlayOffsetY]="listCdkConnectedOverlayOffsetY"
    [cdkConnectedOverlayOpen]="isDateOptionList"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPush]="cdkConnectedOverlayPush"
    cdkConnectedOverlay>

    @if (dateDropDownOptions && dateDropDownOptions.length && !isShowStaticDefaultOptions) {
      <mat-action-list [id]="dynamicOptionId" class="w-full custom-ckd-container range-input">
        @for (option of dateDropDownOptions; track option.optionKey) {
          @if (option.isVisible) {
            <mat-list-item [activated]="option.isSelected"
                           (click)="updateSelection(option, dateInput)">{{ option.optionLabel }}
            </mat-list-item>
          }
        }
      </mat-action-list>
    }
  </ng-template>

  <ng-template (overlayOutsideClick)="toggleCustomDateRangeView()"
               [cdkConnectedOverlayHasBackdrop]="false"
               [cdkConnectedOverlayOffsetX]="cdkConnectedOverlayOffsetX"
               [cdkConnectedOverlayOffsetY]="cdkConnectedOverlayOffsetY"
               [cdkConnectedOverlayOpen]="isCustomRange"
               [cdkConnectedOverlayOrigin]="trigger"
               [cdkConnectedOverlayPositions]="cdkConnectedOverlayPositions"
               [cdkConnectedOverlayPush]="cdkConnectedOverlayPush"
               cdkConnectedOverlay>
    <div [class]="{'without-default-opt':hideDefaultOptions}"
         class="custom-ckd-container custom-calendar-container">
      <div class="row-1">
        <div *ngIf="!hideDefaultOptions" class="pt-custom br-right column-1">
          <mat-action-list>
            @for (option of dateDropDownOptions; track option.optionKey) {
              @if (option.isVisible) {
                <mat-list-item (click)="updateSelection(option, dateInput)"
                               [activated]="option.isSelected">{{ option.optionLabel }}
                </mat-list-item>
              }
            }
          </mat-action-list>
        </div>
        <div class="mt-2 column-2">
          <app-calendar #calendar [maxDate]="maxDate" [minDate]="minDate"
                        [selectedDates]="selectedDates"></app-calendar>
        </div>
      </div>
      <div class="row-2 br-top">
        <div class="text-end my-2 w-full">
          <div class="footer-content">
            <span id="range-label-text">
              @if (calendar.selectedDates?.start) {
                {{ 'order.times.selectedPeriod' | translate }}:
              }
              {{ calendar?.selectedDates?.start | date: 'MMM d, y' }}
              @if (calendar?.selectedDates?.end) {
                <span> - {{
                    calendar.selectedDates?.end | date: 'MMM d, y'
                  }} </span>
              }
            </span>
            <div class="d-inline buttons">
              <button (click)="isCustomRange=false;" class="p-3 mx-2" mat-button mat-raised-button>
                Cancel
              </button>
              <button (click)="updateCustomRange(dateInput,calendar.selectedDates);"
                      [class.disabled]="!(calendar?.selectedDates?.start && calendar?.selectedDates?.end)"
                      class="ms-2 p-3" color="primary"
                      mat-button
                      mat-raised-button>&nbsp;Apply&nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
