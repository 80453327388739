<h1 mat-dialog-title>{{ "checkout" | translate }}</h1>
@for (entry of stores; track entry; let last = $last) {
  <div class="flex flex-row items-center gap-2 m-4">
    <div class="flex flex-col gap-1 flex-auto content-stretch">
      <div class="flex flex-row gap-2">
        <div class="basis-1/4 col-lg-2">{{ entry.name }}</div>
      </div>
    </div>
    <div class="col-2 flex flex-row content-end">
      <div>
        <button mat-mini-fab (click)="checkout(entry)" [disabled]="checkingOut">
          @if (entry.selectedForCheckout) {
            <mat-icon>
              <mat-spinner diameter="18" mode="indeterminate"></mat-spinner>
            </mat-icon>
          } @else {
            <mat-icon>shopping_cart_checkout</mat-icon>
          }
        </button>
      </div>
    </div>
  </div>

  @if (!last) {
    <mat-divider></mat-divider>
  }
}

