import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {AlarmInfo} from "../model/alarm-api";
import {Page} from "../../utils/page";
import {AlarmHintsDialogComponent} from "../alarm-hints-dialog/alarm-hints-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PageEvent} from "@angular/material/paginator";
import {DateTime} from 'luxon';

@Component({
  selector: 'app-alarm-info-list',
  templateUrl: './alarm-info-list.component.html',
  styleUrls: ['./alarm-info-list.component.scss']
})
export class AlarmInfoListComponent {

  @Output()
  page: EventEmitter<PageEvent> = new EventEmitter()
  displayedColumns = ['date', 'level', 'code', 'description']
  dataSource: MatTableDataSource<AlarmInfo> = new MatTableDataSource()
  pageSize = 10
  pageIndex: number = 0
  totalSize: number = -1

  constructor(private dialog: MatDialog) {
  }

  @Input()
  set data(data: Page<AlarmInfo> | undefined) {
    if (data) {
      this.dataSource.data = data.content
      this.pageIndex = data.pageable.number
      this.pageSize = data.pageable.size
      this.totalSize = data.totalSize
      this.convertToUserTime()
    }
  }

  openHintsDialog(alarm: AlarmInfo) {
    const dialogRef = this.dialog.open(AlarmHintsDialogComponent, {
      width: '85%',
      data: alarm
    })
  }

  handlePaginatorEvent($event: PageEvent) {
    this.page.emit($event)
  }

  private convertToUserTime() {
    this.dataSource.data.forEach(alarmInfo => {
      const toDate = DateTime.fromFormat(alarmInfo.alarm.timestamp, "yyyy-MM-dd'T'HH:mm:ss", {zone: 'utc'}).toJSDate();
      alarmInfo.alarm.timestamp = DateTime.fromJSDate(toDate).toFormat("yyyy-MM-dd'T'HH:mm:ss");
    })
  }
}
