import { Component, Input } from '@angular/core';
import { Site, SiteInfo } from "../model/site-api";
import { SiteService } from "../model/site.service";
import { Page } from "../../utils/page";
import { FilterService } from "../../filter/model/filter.service";
import { PageEvent } from "@angular/material/paginator";
import { interval, Subscription } from "rxjs";

@Component({
  selector: 'app-site-details-site-list',
  templateUrl: './site-details-site-list.component.html',
  styleUrls: ['./site-details-site-list.component.scss']
})
export class SiteDetailsSiteListComponent {
  @Input()
  set data(data: Site | undefined) {
    this.site = data
    this.updateData()
  }

  site: Site | undefined
  reloading: boolean = false
  initialized: boolean = false
  pageIndex: number = 0
  pageSize: number = 20
  totalSize: number = -1
  children: SiteInfo[] = []

  private filterSubscription: Subscription | undefined
  private reloadSubscription: Subscription | undefined

  constructor(private service: SiteService, public filterService: FilterService) {

  }

  ngOnInit() {
    this.filterSubscription = this.filterService.activeFilter.subscribe(value => {
      if (this.filterService.activated) this.load(0, this.pageSize)
    })
    this.reloadSubscription = interval(180000).subscribe(_ => this.load(this.pageIndex, this.pageSize))
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe()
      this.filterSubscription = undefined
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe()
      this.reloadSubscription = undefined
    }
  }

  private updateData() {
    this.load(0, this.pageSize)
  }

  private load(page: number, size: number) {
    if (!this.site) return
    if (this.reloading) return
    this.reloading = true
    if (this.filterService.activated) {
      let filter = this.filterService.activeFilter.value
      this.service.getFilteredChildSites(this.site.id, filter, page, size).subscribe(d => this.handleData(d))
    } else {
      this.service.getChildSites(this.site.id, page, size).subscribe(d => this.handleData(d))
    }
  }


  private handleData(p: Page<SiteInfo>) {
    this.children = p.content
    this.pageIndex = p.pageable.number
    this.pageSize = p.pageable.size
    this.totalSize = p.totalSize
    this.reloading = false
    this.initialized = true
  }

  handlePaginatorEvent(event: PageEvent) {
    this.load(event.pageIndex, event.pageSize)
  }
}
