<mat-card>
  <mat-card-header class="flex items-center content-center justify-between">
    <mat-card-title>
      <h5> {{'site.Systems' | translate}} </h5>
    </mat-card-title>
    <div class="flex-auto"></div>
    <button mat-stroked-button (click)="onAddSystem()" *ngIf="registrationService.hasRegistrationPermissions()">
      <mat-icon>add_box</mat-icon>
      {{'addSystem' | translate}}
    </button>
  </mat-card-header>
  <div class="flex flex-col p-2 gap-1">
    <mat-progress-bar mode="indeterminate" *ngIf="reloading"></mat-progress-bar>
    <ng-container *ngFor="let system of systems">
      <app-site-details-system-list-entry [data]="system" routerLink="/system/{{system.system.id}}"></app-site-details-system-list-entry>
    </ng-container>
  </div>
  <mat-paginator *ngIf="systems.length > 5"
                 [length]="totalSize"
                 [pageSize]="pageSize"
                 [pageIndex]="pageIndex"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 showFirstLastButtons
                 (page)="handlePaginatorEvent($event)">
  </mat-paginator>
</mat-card>
