import { Injectable } from '@angular/core';
import { ConsentService } from "../../consent/consent.service";
import { MainHeaderAction } from "./main-header-action";
import { MainMenuAction, MainMenuActionGroup, MainMenuActionStatus } from "./main-menu-action";
import packageJson from "../../../../package.json";
import { Principal } from "../../auth/principal";
import { HEADER_ACTIONS, MENU_ACTIONS } from "./config";
import { Observable, of, Subject } from "rxjs";
import { MenuService } from "./menu.service";
import { Menu, MenuAction, MenuActionGroup, MenuActionStatus } from "./menu-api";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public version: string = packageJson.version;
  menuActions: MainMenuActionGroup[] = []
  headerActions: MainHeaderAction[] = []
  customHeaderActions: MainHeaderAction[] = []

  private searchSubject = new Subject<string>()
  search = this.searchSubject.asObservable()
  query: string = ''
  searchVisible: boolean = false


  constructor(
    private consentService: ConsentService,
    private menuService: MenuService
  ) {

  }

  openCookieSettings() {
    this.consentService.openCookieSettings()
  }

  handlePrincipalChanged(principal: Principal | null) {
    this.menuService.getMenu().subscribe({
      next: value => this.handleMenuData(value),
      error: err => this.handleFailed(principal, err)
    })
    this.headerActions = HEADER_ACTIONS.filter(a => this.isHeaderActionAccessible(principal, a))
  }

  private handleMenuData(value: Menu) {
    this.menuActions = value.groups.map(v => this.convertMenuActionGroup(v))
  }

  private convertMenuActionGroup(v: MenuActionGroup): MainMenuActionGroup {
    return {
      name: v.name,
      actions: v.actions.map(a => this.convertMenuAction(a))
    }
  }

  private convertMenuAction(a: MenuAction): MainMenuAction {
    return {
      text: a.name,
      icon: a.icon,
      url: a.url,
      roles: a.roles,
      status: this.convertMenuActionStatus(a.status)
    }
  }

  private convertMenuActionStatus(s: MenuActionStatus | null): Observable<MainMenuActionStatus> | undefined {
    if (!s) return undefined
    let value: MainMenuActionStatus = { text: s.text, color: s.color }
    return of(value)
  }

  private handleFailed(principal: Principal | null, err: any) {
    this.menuActions = MENU_ACTIONS.map(g => this.updateGroupAccessibility(principal, g))
  }

  private updateGroupAccessibility(principal: Principal | null, g: MainMenuActionGroup): MainMenuActionGroup {
    let actions = g.actions.filter(a => this.isMenuActionAccessible(principal, a))
    return { name: g.name, actions }
  }

  private isMenuActionAccessible(principal: Principal | null, a: MainMenuAction): boolean {
    if (a.roles.length == 0) return true
    if (!principal) return false
    return a.roles.find(role => principal.roles.find(r => r == role) != null) != null
  }

  private isHeaderActionAccessible(principal: Principal | null, a: MainHeaderAction): boolean {
    if (a.roles.length == 0) return true
    if (!principal) return false
    return a.roles.find(role => principal.roles.find(r => r == role) != null) != null
  }

  executeSearch(query: string) {
    this.query = query
    this.searchSubject.next(query)
  }


}
