export interface SearchResultEntry {
  resourceId: number,
  externalId: string,
  name: string,
  type: string,
}

export class SearchRequest {
  constructor(
    public query: string,
    public onlineFilter: boolean,
    public alarmFilter: boolean,
    public lastAlarms: number,
    public refrigerantFilter: string,
    public systemTypeFilter: string,
    public companyOwnerName: string,
    public typeFilter: string[],
  ) {
  }
}

