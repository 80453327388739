import { Component } from '@angular/core';
import { Subscription } from "rxjs";
import { HomeService } from "../../../home/model/home.service";
import { ProductConfigBoardService } from "../model/product-config-board.service";

@Component({
  selector: 'app-product-config-board',
  templateUrl: './product-config-board.component.html',
  styleUrls: ['./product-config-board.component.scss']
})
export class ProductConfigBoardComponent {

  private subscriptions: Subscription[] = []

  constructor(
    public service: ProductConfigBoardService,
    private home: HomeService,
  ) {
  }

  ngOnInit(): void {
    this.service.search('')
    this.home.searchVisible = true
  }

  ngAfterViewInit() {
    let s1 = this.home.search.subscribe(query => {
        this.service.search(query)
      }
    )

    this.subscriptions = [s1]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.home.searchVisible = false
  }


}
