<div class="flex flex-col scroll-off">

  <div class="basis-full text-center my-3">{{ 'licenseUpgrade' | translate }}</div>
  <mat-stepper>
    <mat-step>
      <div *ngIf="account && policies.length" class="basis-full text-center my-3">
        <mat-form-field appearance="outline" class="dense-1" subscriptSizing="dynamic">
          <mat-label>{{"servicePackage" | translate}}</mat-label>
          <mat-select [(value)]="selected" required>
            <mat-option *ngFor="let item of policies" [value]="item.id">
              {{item.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div style="margin-top: 10px">
        <p>{{'cost' | translate}}:  {{ getPolicy()?.charge }}</p>
        <p>{{'available' | translate}}:  {{account.account.amount}}</p>
        </div>
      </div>
      <div>
        <button mat-button matStepperNext>{{'next' | translate}}</button>
        <button mat-button matDialogClose>{{'cancel' | translate}}</button>
      </div>
    </mat-step>

    <mat-step>
      <div class="basis-full text-center my-3">
        <p>{{'product' | translate}}:  {{equipment.name}}</p>
        <p>{{'serialNumber' | translate}}:  {{equipment.serialNumber}}</p>
        <p>{{'electronicModuleType' | translate}}:  {{bestInfo?.device?.type}}</p>
        <p>{{'electronicModuleSN' | translate}}:  {{bestInfo?.device?.serialNumber}}</p>
        <mat-divider></mat-divider>
        <p>{{'servicePackage' | translate}}:  {{getPolicy()?.title}}</p>
        <p>{{'balanceAfter' | translate}}:  {{getDifference()}}</p>
        <div style="display: flex; align-items: center; justify-content: center;" *ngIf="loading">
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <div>
        <button mat-button matStepperPrevious>{{'back' | translate}}</button>
        <button mat-button [disabled]="!selected || getDifference() < 0 || messageSent" (click)="onActivateLicense()">{{'licenseUpgrade' | translate}}</button>
        <button mat-button matDialogClose>{{'cancel' | translate}}</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
