import { Component, Inject, OnInit } from '@angular/core';
import { ProtocolEntry } from "../model/activation-api";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Equipment } from "../../equipment/model/equipment-api";
import { ModuleService } from "../../module/model/module.service";
import { HotToastService } from "@ngxpert/hot-toast";
import { Page } from "../../utils/page";
import { ElectronicModule } from "../../module/model/module-api";
import { BestCoreGatewayService } from "../../equipment/model/best-core-gateway.service";
import {BestInfo, DeviceUpdateRequest, DeviceUpdateResponse} from "../../equipment/model/best-core-api";
import { EquipmentService } from "../../equipment/model/equipment.service";

@Component({
  selector: 'app-activation-edit-dialog',
  templateUrl: './activation-edit-dialog.component.html',
  styleUrls: ['./activation-edit-dialog.component.scss']
})
export class ActivationEditDialogComponent implements OnInit {

  modules: Map<number, string> = new Map()
  protocols: ProtocolEntry[] = []
  private modbusEntry = new ProtocolEntry("protocol_Modbus", "MODBUS")
  private leprotEntry = new ProtocolEntry("protocol_LeProt", "LEPROT")

  reloading: boolean = false
  advanced: boolean = false
  modbus: boolean = false
  pageIndex: number = 0
  pageSize: number = 10
  totalSize: number = -1

  update = this.fb.group({
    electronicModule: this.fb.control('', [Validators.required]),
    deviceAddress: this.fb.control('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    macAddress: this.fb.control('', [Validators.required, Validators.pattern(/^([0-9A-Fa-f]{2}){5}([0-9A-Fa-f]{2})$/)]),
    protocol: this.fb.control('', [Validators.required]),
  })


  constructor(
    @Inject(MAT_DIALOG_DATA) public equipment: Equipment,
    private moduleService: ModuleService,
    private toastService: HotToastService,
    private service: BestCoreGatewayService,
    public equipmentService: EquipmentService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ActivationEditDialogComponent>
  ) {
  }

  ngOnInit() {
    this.moduleService.findByEquipmentId(this.equipment.id, 0, this.pageSize).subscribe(data => this.handleData(data))

    this.service.getInfo(this.equipment.id, this.equipment.thingId).subscribe(info => {
      this.handleDeviceData(info)
    })
  }

  onProtocolSelect() {
    this.modbus = this.update.get('protocol')?.value === 'MODBUS'
    if(this.modbus){
      this.update.controls.deviceAddress.addValidators(Validators.required)
    } else {
      this.update.controls.deviceAddress.removeValidators(Validators.required)
    }
    this.update.controls.deviceAddress.updateValueAndValidity()

    let leprot = this.update.get('protocol')?.value === 'LEPROT'
    if(leprot){
      this.update.get('deviceAddress')?.setValue(null)
    }
  }

  cancel() {
    this.dialogRef.close(null)
  }

  edit() {
    this.reloading = true
    let moduleId: number = 0
    this.modules.forEach((val, key) => {
      if (val === this.update.get('electronicModule')?.value) moduleId = key
    })
    const request = new DeviceUpdateRequest(
      this.equipment.thingId,
      this.update.get('protocol')?.value || '',
      this.update.get('macAddress')?.value || '',
      parseInt(this.update.get('deviceAddress')?.value || '0', 10),
      moduleId,
      null
    )
    this.service.update(this.equipment.id, request).subscribe(it => { this.handleResponse(it) })
  }

  private handleResponse(it: DeviceUpdateResponse) {
    if(!it) {
      this.handleError("Unable to update IoT configuration")
      return
    }

    if (it.subscriptionResponse && !it.subscriptionResponse.Description.Text) {
      this.handleError("Unable to update configuration, check if the device with same modbus address is already assigned to this gateway")
      return;
    }

    this.toastService.info("IoT configuration updated successfully")
    this.dialogRef.close()
  }

  onScroll(event: any) {
    const element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      this.moduleService.findByEquipmentId(this.equipment.id, ++this.pageIndex, this.pageSize).subscribe(data => this.handleData(data))
    }
  }

  private handleData(data: Page<ElectronicModule>) {
    if (!data) return
    data.content.forEach(module => {
      this.modules.set(module.id, module.type + ' SN: ' + module.serialNumber)
    })
    if (this.modules.size === 1) {
      this.update.get('electronicModule')?.setValue(this.modules.values().next().value)
    }
    this.pageSize = data.pageable.size
    this.pageIndex = data.pageable.number
    this.totalSize = data.totalSize
  }

  private handleDeviceData(info: BestInfo) {
    this.reloading = false

    if(!info) {
      this.findModuleType()
      return
    }

    this.update.get('macAddress')?.setValue(info.macAddress)
    this.setProtocol(info.device.type, info.device.protocol)
    this.update.get('deviceAddress')?.setValue(info.device.address)
  }

  private handleError(message: string) {
    this.toastService.info(message)
    this.dialogRef.close()
  }

  private setProtocol(type: string, protocol: string) {
    this.setProtocolsBasedOnType(type)
    if(this.protocols.length) {
      let entry = this.protocols.find(it => it.value === protocol.toUpperCase()) || this.protocols[0]
      this.update.get('protocol')?.setValue(entry.value)
      this.onProtocolSelect()
    }
  }

  private findModuleType() {
    const idx = this.update.get('electronicModule')?.value?.indexOf('SN')
    if (idx === -1) return
    const moduleType = this.update.get('electronicModule')?.value?.substring(0, idx).trim()
    if (!moduleType) return
    this.setProtocolsBasedOnType(moduleType)
  }

  private setProtocolsBasedOnType(type: string) {
    switch (type) {
      case "CM-RC-02":
      case "CM-SW-01":
      case "CM-RC-01": {
        this.protocols.push(this.leprotEntry)
        this.protocols.push(this.modbusEntry)
        break
      }
      case "CSV":
      case "SE-i1": {
        this.protocols.push(this.modbusEntry)
        this.protocols.push(this.leprotEntry)
        break
      }
      case "VARIPACK":
      case "VARIPACK2":
      case "ECOLITE":
      case "ECOLITE.A2L":
      case "ECOSTAR-LHV5E7E": {
        this.protocols.push(this.modbusEntry)
        break
      }
    }
  }
}
