import { Component, Input } from '@angular/core';
import { TableCardGroup } from "../model/card-api";

@Component({
  selector: 'app-grouped-table-card',
  templateUrl: './grouped-table-card.component.html',
  styleUrl: './grouped-table-card.component.scss'
})
export class GroupedTableCardComponent {

  @Input()
  title: string = ''

  @Input()
  data: TableCardGroup[] = []

}
