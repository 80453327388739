<app-hierarchy [data]="hierarchy[0]"></app-hierarchy>

@if (loading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
  @for (e of equipments; track e.equipment.id) {
    <mat-dialog-content class="dialog-container">
      <mat-checkbox (change)="onEquipmentSelect(e.equipment)"
                    [checked]="checkStatus(e.equipment)">
        {{ e.equipment.name }}
      </mat-checkbox>
    </mat-dialog-content>
  }
}

<mat-paginator *ngIf="totalSize > 5"
               [length]="totalSize"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               showFirstLastButtons
               [pageSizeOptions]="[5,10,50,100]"
               (page)="handlePaginatorEvent($event)">
</mat-paginator>
