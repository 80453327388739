import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { ElectronicModule, ElectronicModuleValidationRequest } from "./module-api";

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/module', logging, zone)
  }

  findByEquipmentId(equipmentId: number, page: number = 0, size: number = 10): Observable<Page<ElectronicModule>> {
    return this.getPaged('' + equipmentId, page, size)
  }

  validate(request: ElectronicModuleValidationRequest): Observable<boolean> {
    return this.post('validate', request)
  }
}
