import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { ThingTemplate } from "./thing-template-api";

@Injectable({
  providedIn: 'root'
})
export class ThingTemplateService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/template', logging, zone)
    this.retryCount = 1
  }


  getEquipmentTemplate(equipmentId: number): Observable<ThingTemplate> {
    return this.get(equipmentId + '')
  }

}
