<table [dataSource]="dataSource" mat-table matSort>

  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell>
      <div class="flex flex-row gap-3 justify-start items-center">
        {{ 'description' | translate }}
      </div>
    </th>
    <td *matCellDef="let entry" mat-cell>{{ entry.description }}</td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th *matHeaderCellDef mat-header-cell>{{ 'part' | translate }}</th>
    <td *matCellDef="let entry" mat-cell>{{ parseInt(entry.code) }}</td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th *matHeaderCellDef mat-header-cell>{{ 'order.item.net' | translate }}</th>
    <td *matCellDef="let entry" mat-cell>
      {{ entry.price | currency: service.cart?.info?.currency }}
    </td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th *matHeaderCellDef mat-header-cell>{{ 'spareParts.quantity' | translate }}</th>
    <td *matCellDef="let entry" mat-cell>
      <div class="flex flex-row justify-start items-center flex-auto">
        <mat-form-field appearance="outline" class="flex dense-1" subscriptSizing="dynamic">
          <input (change)="update(entry, $event)" matInput min="1" type="number"
                 value="{{entry.quantity}}">
        </mat-form-field>
      </div>
    </td>
    <td *matFooterCellDef mat-footer-cell> Total</td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th *matHeaderCellDef mat-header-cell>{{ 'total' | translate }}</th>
    <td *matCellDef="let entry"
        mat-cell>{{ entry.totalPrice | currency: service.cart?.info?.currency }}
    </td>
    <td *matFooterCellDef mat-footer-cell>
      @if (service.cart) {
        {{ service.cart.info.totalPrice | currency: service.cart.info.currency }}
      }
    </td>
  </ng-container>


  <ng-container matColumnDef="cmd">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let entry" mat-cell>
      <div class="flex flex-row gap-3 justify-end items-center">
        <button (click)="delete(entry)" mat-mini-fab>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" class="header" mat-header-row></tr>
  <tr *matRowDef="let info; columns: displayedColumns;" class="hover-effect" mat-row></tr>
  <tr *matFooterRowDef="displayedColumns" mat-footer-row></tr>
</table>
