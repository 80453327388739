import { Component, Input } from '@angular/core';
import { allCountries } from "country-region-data";
import { MatOptionSelectionChange } from "@angular/material/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Site, SiteAddress, SiteAddressChangeRequest } from "../model/site-api";
import { SiteService } from "../model/site.service";

@Component({
  selector: 'app-site-details-address',
  templateUrl: './site-details-address.component.html',
  styleUrls: ['./site-details-address.component.scss']
})
export class SiteDetailsAddressComponent {

  @Input()
  set data(data: Site | undefined) {
    this.site = data
    this.updateData()
  }

  @Input()
  canWrite = false

  site: Site | undefined
  reloading: boolean = false

  address: SiteAddress | undefined

  countries: string [] = []
  regions: string[] = []
  editMode: boolean = false
  form = this.fb.group({
    streetLine1: this.fb.control('', [Validators.required]),
    streetLine2: this.fb.control('', []),
    city: this.fb.control('', [Validators.required]),
    postalCode: this.fb.control('', [Validators.required]),
    country: this.fb.control('', [Validators.required]),
    region: this.fb.control('', []),
    additionalInformation: this.fb.control('', []),
  })

  constructor(
    public service: SiteService,
    private fb: FormBuilder
  ) {
  }

  private updateData() {
    if (!this.site) return
    if (this.reloading) return
    this.reloading = true
    this.service.getAddress(this.site.id).subscribe(data => this.handleData(data))
  }

  private handleData(data: SiteAddress) {
    this.address = data
    this.initCountries()
    this.setFormData()
    this.reloading = false
  }

  selectCountry(event: MatOptionSelectionChange<string>) {
    if (!event.isUserInput) return
    this.form.get('country')?.setValue(event.source.value)
    this.initRegions()
  }

  selectRegion(event: MatOptionSelectionChange<string>) {
    if (!event.isUserInput) return
    this.form.get('region')?.setValue(event.source.value)
  }

  setFormData() {
    if (!this.address) {
      this.form.reset()
      return
    }

    this.form.get('streetLine1')?.setValue(this.address.line1)
    this.form.get('streetLine2')?.setValue(this.address.line2)
    this.form.get('city')?.setValue(this.address.city)
    this.form.get('postalCode')?.setValue(this.address.postalCode)
    this.form.get('country')?.setValue(this.address.country)
    this.initRegions()
    this.form.get('region')?.setValue(this.address.region)
    this.form.get('additionalInformation')?.setValue(this.address.additionalInformation)
  }

  save() {
    if (!this.site) return
    if (!this.address) return
    if (!this.form.valid) return

    const request: SiteAddressChangeRequest = {
      line1: this.form.get('streetLine1')?.value || '',
      line2: this.form.get('streetLine2')?.value || '',
      city: this.form.get('city')?.value || '',
      postalCode: this.form.get('postalCode')?.value || '',
      country: this.form.get('country')?.value || '',
      region: this.form.get('region')?.value || '',
      additionalInformation: this.form.get('additionalInformation')?.value || ''
    }

    this.editMode = false
    this.reloading = true
    this.service.updateAddress(this.site.id, request).subscribe(d => this.handleData(d))

  }

  reset() {
    this.editMode = false
    this.setFormData()
  }


  private initCountries() {
    this.countries = allCountries.map(countryData => countryData[0])
    this.initRegions()
  }

  private initRegions() {
    const countryData = allCountries.find(countryData => {
      return countryData.findIndex(country => country === this.form.get('country')?.value) !== -1
    }) || []
    this.regions = countryData.length ? countryData[2].map(regionData => regionData[0]) : []
  }

}
