import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { OperationalSearchRequest } from "./operational-graph-api";

@Injectable({
  providedIn: 'root'
})
export class OperationalDataService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/operational', logging, zone)
    this.retryCount = 1
  }

  downloadData(equipmentId: number, request: OperationalSearchRequest, lang: string): Observable<any> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.downloadPayload(`${equipmentId}/download`, request, params)
  }
}
