import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../auth/auth.service";
import { Observable } from "rxjs";
import { Category, RootCategories } from "./category-api";

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    super(http, 'api/knowledge/category', logging, zone)
    this.retryCount = 1
  }

  getMain(country: string): Observable<Category[]> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.get('', params)
  }

  getChildren(categoryId: string, country: string): Observable<Category[]> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.get(categoryId + '/children', params)
  }

  getCategory(categoryId: string): Observable<Category> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('' + categoryId, params)
  }

  getCategories(categoryIds: string[], country: string): Observable<Category[]> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.post('', categoryIds, params)
  }

  getPath(categoryId: string, country: string): Observable<Category[]> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('country', country)
    return this.get(categoryId + '/path', params)
  }


  getApplicationRoot(): Observable<Category> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('application', params)
  }

  getTechnologyRoot(): Observable<Category> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('technology', params)
  }

  getRootCategories(): Observable<RootCategories> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.get('root', params)
  }
}
