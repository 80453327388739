import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Image } from "./image-api";
import { AuthService } from "../../auth/auth.service";
import { IMAGE_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class ImageService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/image', logging, zone)
    this.retryCount = 1
  }

  upload(itemId: number, file: FormData): Observable<HttpEvent<Image>> {
    const url = this.createUrl('by/item/' + itemId)
    return this.http.post<Image>(url, file, {
      reportProgress: true,
      observe: 'events'
    })
  }

  downloadImage(itemId: number, id: number) {
    const path = itemId + '/download/' + id
    return this.download(path)
  }

  findByItemId(itemId: number): Observable<Image> {
    return this.get('by/item/' + itemId)
  }

  hasUploadPermissions(): boolean {
    return this.authService.hasRole(IMAGE_WRITE)
  }
}
