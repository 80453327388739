<mat-toolbar color="primary" class="gap-2">


  <div class="flex flex-row gap-2" *ngIf="showSwitchButton">
    <button mat-mini-fab routerLink="map" routerLinkActive="selected" style="background-color: white; color: black;"
            matTooltip="{{'mapView' | translate}}"
    >
      <mat-icon>map</mat-icon>
    </button>
    <button mat-mini-fab routerLink="list" routerLinkActive="selected" style="background-color: white; color: black;"
            matTooltip="{{'listView' | translate}}"
    >
      <mat-icon>grid_on</mat-icon>
    </button>
  </div>

  <app-search-form (searchEvent)="search($event)" class="ps-3 flex-auto flex flex-row"></app-search-form>

  <ng-container *ngIf="isHandset$ | async; else toolbarMenu">
    <button mat-icon-button [matMenuTriggerFor]="mobileMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #mobileMenu="matMenu">
      <button mat-menu-item (click)="register()" *ngIf="isRegistrationButtonVisible()">
        <mat-icon>app_registration</mat-icon>
        {{'newProductTitle' | translate}}
      </button>
      <button mat-menu-item (click)="showRegisterCompanyDialog()" *ngIf="showRegisterCompany">
        <mat-icon>domain_add</mat-icon>
        {{'registerCompany' | translate}}
      </button>
      <mat-divider *ngIf="isRegistrationButtonVisible() || showRegisterCompanyDialog()"></mat-divider>

      <app-generic-filter-menu></app-generic-filter-menu>

    </mat-menu>

  </ng-container>
  <ng-template #toolbarMenu>
    <div class="ps-3 flex flex-row gap-2">
      <button mat-flat-button (click)="register()" color="accent" style="color: white" *ngIf="isRegistrationButtonVisible()">
        <mat-icon>app_registration</mat-icon>
        {{'newProductTitle' | translate}}
      </button>

      <button mat-flat-button (click)="showRegisterCompanyDialog()" *ngIf="showRegisterCompany">
        <mat-icon>domain_add</mat-icon>
        {{'registerCompany' | translate}}
      </button>
    </div>


    <div class="ps-5 flex-auto">
    </div>

    <app-generic-filter class="ps-5"></app-generic-filter>
  </ng-template>

</mat-toolbar>


