import { Component } from '@angular/core';
import { CommerceService } from "../model/commerce.service";
import { ActivatedRoute } from "@angular/router";
import { SharedCart, SharedCartEntry } from "../model/commerce-api";
import { HotToastService } from "@ngxpert/hot-toast";
import { SharedCartImportDialogComponent } from "../shared-cart-import-dialog/shared-cart-import-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CommerceCartService } from "../model/commerce-cart.service";

@Component({
  selector: 'app-shared-cart-info',
  templateUrl: './shared-cart-info.component.html',
  styleUrl: './shared-cart-info.component.scss'
})
export class SharedCartInfoComponent {
  reloading: boolean = false
  displayedColumns: string[] = ['code', 'description', 'quantity']
  data: SharedCart | undefined
  entries: SharedCartEntry[] = []

  constructor(
    public cartService: CommerceCartService,
    public service: CommerceService,
    private toast: HotToastService,
    private route: ActivatedRoute,
    private dialog: MatDialog
    ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let id = params.get('id') || ''
      this.reloading = true
      this.service.getSharedCart(id).subscribe({
        next: value => this.handleData(value),
        error: err => this.handleError(err)
      })
    })
  }

  private handleData(value: SharedCart) {
    this.data = value
    this.entries = value.entries
    this.reloading = false
  }

  private handleError(err: any) {
    this.reloading = false
  }

  protected readonly parseInt = parseInt;

  importCart() {
    this.dialog.open(SharedCartImportDialogComponent, {
    }).afterClosed().subscribe(accepted => {
      if(accepted) {
        this.import()
      }
    })
  }

  private import() {
    if(!this.data) {
      this.toast.error("Error while importing cart")
      return
    }
    this.service.importSharedCartIntoCart(this.data.id).subscribe(_ =>
      this.toast.info("Successfully imported cart")
    )
  }
}
