import {Component, Input} from '@angular/core';
import {HotToastService} from "@ngxpert/hot-toast";
import {Observable} from "rxjs";
import {saveAs} from "file-saver";
import {
  WISHLIST_ROLES
} from "../../portal/product-config/product-config-wishlist/wishlist-config.model";

@Component({
  selector: 'app-file-download-button',
  templateUrl: './file-download-button.component.html',
  styleUrl: './file-download-button.component.scss'
})
export class FileDownloadButtonComponent {

  @Input() provider!: () => Observable<any>
  @Input() text: string = 'download'
  @Input() color: string = 'primary'
  @Input() icon: string = 'download'
  @Input() disabled: boolean = false
  @Input() mode: 'fab' | 'button' = 'button'
  DEFAULT_NAME: string = 'unknown'
  downloading: boolean = false
  protected readonly WISHLIST_ROLES = WISHLIST_ROLES;

  constructor(private toast: HotToastService) {
  }

  startDownload() {
    if (this.downloading) return
    this.downloading = true
    this.provider().subscribe({
      next: value => this.handleResult(value),
      error: err => this.handleError(err)
    })
  }

  private handleResult(response: any) {
    const contentDisposition = response?.headers.get('Content-Disposition')

    if (contentDisposition) {
      const filenameRegex = new RegExp(`filename\*?=(?:UTF-8''|")?([^;"]+)`)
      const match = filenameRegex.exec(contentDisposition)

      if (match != null && match[1]) {
        this.saveFile(response?.body, match[1])
      } else {
        this.saveFile(response?.body, this.DEFAULT_NAME)
      }
    }

    this.downloading = false
  }

  private saveFile(data: any, fileName: string) {
    if (!data) return;

    const blob = new Blob([data], {
      type: data.mediaType
    })

    saveAs(blob, fileName)
  }

  private handleError(err: any) {
    this.toast.error("Download failed")
    this.downloading = false
  }
}
