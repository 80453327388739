import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDefaultImage]'
})
export class DefaultImageDirective {

  @Input() defaultImage: string = ''


  constructor(private elementRef: ElementRef) { }

  @HostListener('error')
  loadDefaultOnError() {
    const element: HTMLImageElement = this.elementRef.nativeElement
    element.src = this.defaultImage || 'assets/img/logo-flat-black.png'
  }

}
