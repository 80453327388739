import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { TranslateService } from "@ngx-translate/core";
import {
  DocumentSearchRequest,
  DocumentSearchResponse,
  GeneralSearchRequest,
  GeneralSearchResponse,
  ProductSearchRequest,
  ProductSearchResponse,
  SeriesSearchRequest,
  SeriesSearchResponse,
  SparePartSearchRequest,
  SparePartSearchResponse
} from "./search-api";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private translateService: TranslateService,
  ) {
    super(http, 'api/knowledge/search', logging, zone)
    this.retryCount = 0
  }

  searchGeneral(query: string): Observable<GeneralSearchResponse> {
    let request = new GeneralSearchRequest(query, this.translateService.currentLang)
    return this.post<GeneralSearchResponse>('general', request)
  }

  searchDocuments(request: DocumentSearchRequest, page: number, size: number): Observable<DocumentSearchResponse> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.post<DocumentSearchResponse>('document', request, params)
  }

  searchProducts(request: ProductSearchRequest, page: number, size: number): Observable<HttpResponse<ProductSearchResponse>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.postResponse<ProductSearchResponse>('product', request, params)
  }

  searchSeries(request: SeriesSearchRequest, page: number, size: number): Observable<HttpResponse<SeriesSearchResponse>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.postResponse<SeriesSearchResponse>('series', request, params)
  }

  searchSpareParts(request: SparePartSearchRequest, page: number, size: number): Observable<HttpResponse<SparePartSearchResponse>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.postResponse<SparePartSearchResponse>('sparepart', request, params)
  }


}
