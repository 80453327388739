import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModuleService} from "../../module/model/module.service";
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from "@angular/forms";
import {ElectronicModule} from "../../module/model/module-api";
import {Equipment} from "../../equipment/model/equipment-api";
import {Page} from "../../utils/page";
import {TranslateService} from "@ngx-translate/core";
import {ActivateElectronicModuleRequest} from "../../process/model/process-api";
import {ProcessService} from "../../process/model/process.service";
import {interval, Subscription} from "rxjs";
import { HotToastService } from "@ngxpert/hot-toast";
import {ProtocolEntry} from "../model/activation-api";

@Component({
  selector: 'app-activation-dialog',
  templateUrl: './activation-dialog.component.html',
  styleUrls: ['./activation-dialog.component.scss']
})
export class ActivationDialogComponent implements OnInit {

  modules: Map<number, string> = new Map()
  protocols: ProtocolEntry[] = []
  private modbusEntry = new ProtocolEntry("protocol_Modbus", "MODBUS")
  private leprotEntry = new ProtocolEntry("protocol_LeProt", "LEPROT")

  reloading: boolean = false
  advanced: boolean = false
  modbus: boolean = false
  pageIndex: number = 0
  pageSize: number = 10
  totalSize: number = -1

  private processSubscription: Subscription | null = null

  activation = this.fb.group({
    electronicModule: this.fb.control('', [Validators.required, this.serialNumberValidator]),
    deviceAddress: this.fb.control('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    macAddress: this.fb.control('', [Validators.required, Validators.pattern(/^([0-9A-Fa-f]{2}){5}([0-9A-Fa-f]{2})$/)]),
    protocol: this.fb.control('', [Validators.required]),
  })


  constructor(
    @Inject(MAT_DIALOG_DATA) public equipment: Equipment,
    private moduleService: ModuleService,
    private translateService: TranslateService,
    private processService: ProcessService,
    private toastService: HotToastService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ActivationDialogComponent>
  ) {
  }

  ngOnInit() {
    this.moduleService.findByEquipmentId(this.equipment.id, 0, this.pageSize).subscribe(data => this.handleData(data))
  }

  ngOnDestroy() {
    this.stopPolling()
  }

  onProtocolSelect() {
    this.modbus = this.activation.get('protocol')?.value === 'MODBUS'
    if(this.modbus){
      this.activation.controls.deviceAddress.addValidators(Validators.required)
    } else {
      this.activation.controls.deviceAddress.removeValidators(Validators.required)
    }
    this.activation.controls.deviceAddress.updateValueAndValidity()
  }

  cancel() {
    this.dialogRef.close(null)
  }

  submit() {
    this.reloading = true
    let moduleId: number = 0
    this.modules.forEach((val, key) => {
      if (val === this.activation.get('electronicModule')?.value) moduleId = key
    })
    const request = new ActivateElectronicModuleRequest(
      moduleId,
      this.activation.get('macAddress')?.value || '',
      parseInt(this.activation.get('deviceAddress')?.value || '0', 10),
      this.activation.get('protocol')?.value || '',
      null
    )
    this.handleProcess(request)
  }

  onScroll(event: any) {
    const element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      this.moduleService.findByEquipmentId(this.equipment.id, ++this.pageIndex, this.pageSize).subscribe(data => this.handleData(data))
    }
  }

  private handleData(data: Page<ElectronicModule>) {
    if (!data) return
    data.content.forEach(module => {
      this.modules.set(module.id, module.type + ' SN: ' + module.serialNumber)
      this.setProtocol(module.type)
    })
    if (this.modules.size === 1) {
      this.activation.get('electronicModule')?.setValue(this.modules.values().next().value)
    }
    this.pageSize = data.pageable.size
    this.pageIndex = data.pageable.number
    this.totalSize = data.totalSize
  }

  private handleProcess(request: ActivateElectronicModuleRequest) {
    this.processService.activate(this.equipment.id, request).subscribe({
      next: data => {
        if (data.status.code === 0) {
          this.processSubscription = interval(3000).subscribe(() => {
            this.processService.getById(data.processId).subscribe({
              next: value => {
                if (value.status.code === 0 || value.status.code === 300) return
                if (value.status.code === 200) {
                  this.toastService.info("Activation completed")
                  this.dialogRef.close(data)
                } else {
                  this.toastService.info("Activation failed")
                  this.dialogRef.close(null)
                }
                this.stopPolling()
              },
              error: err => this.handleError()
            })
          })
        }
      },
      error: err => this.handleError()
    })
  }

  private stopPolling(): void {
    if (this.processSubscription) {
      this.processSubscription.unsubscribe();
      this.processSubscription = null
    }
  }

  private handleError() {
    this.toastService.info("Activation failed")
    this.dialogRef.close(null)
  }

  private setProtocol(type: string) {
    switch (type) {
      case "CM-RC-02":
      case "CM-SW-01":
      case "CM-RC-01": {
        this.protocols.push(this.leprotEntry)
        this.protocols.push(this.modbusEntry)
        break
      }
      case "CSV":
      case "SE-i1": {
        this.protocols.push(this.modbusEntry)
        this.protocols.push(this.leprotEntry)
        break
      }
      case "VARIPACK":
      case "VARIPACK2":
      case "ECOLITE":
      case "ECOLITE.A2L":
      case "ECOSTAR-LHV5E7E": {
        this.protocols.push(this.modbusEntry)
        break
      }
    }
    if(this.protocols.length) {
      this.activation.get('protocol')?.setValue(this.protocols[0].value)
      this.onProtocolSelect()
    }
  }

  private serialNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || ''
    const regex = /^.*SN:\s*\S+/
    return regex.test(value) ? null : { 'missingSerialNumberValue': true }
  }
}
