<div class="border border-gray-300 rounded-md mat-elevation-z1">
  <div class="flex flex-col">
    <div class="flex flex-row gap-2 justify-between items-center flex-auto p-2 bg-stone-200">
      <div class="font-medium text-2xl">
        {{ title | translate }}
      </div>
    </div>
    <div class="flex flex-col">
      @if (selectedCategories.length > 0) {
        <div class="flex flex-col">
          @for (category of selectedCategories; track category; let last = $last; let i = $index) {
            <div class="flex flex-col px-3 py-2 flex-grow">
              <mat-chip-option color="primary" selected selectable="false">
                <div [innerHtml]="category.title"></div>
                <button matChipRemove (click)="unselectCategory(category)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-option>
            </div>
          }
        </div>
        <mat-divider></mat-divider>
      }
      @if (categoryEntries.length > 0) {
        <div class="flex flex-col">
          @for (category of categoryEntries; track category; let last = $last) {
            <div class="flex flex-row gap-1 justify-between items-center flex-wrap px-3 hover:bg-stone-100 py-2" (click)="selectCategory(category.category)">
              <div [innerHtml]="category.category.title" class="text-wrap basis-auto"></div>
              <div *ngIf="category.amount > 0" class="amount basis-5">{{ category.amount }}</div>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          }
        </div>
      }
    </div>

  </div>
</div>
