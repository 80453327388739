import { Component, Input } from '@angular/core';
import { Company, CompanyProfile, CompanyProfileChangeRequest } from "../model/company-api";
import { CompanyService } from "../model/company.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-company-details-profile',
  templateUrl: './company-details-profile.component.html',
  styleUrls: ['./company-details-profile.component.scss']
})
export class CompanyDetailsProfileComponent {

  @Input()
  set data(data: Company | undefined) {
    this.company = data
    if (this.company) {
      this.updateData()
    }
  }

  company: Company | undefined
  contact: CompanyProfile | undefined
  reloading: boolean = false
  editMode: boolean = false

  form = this.service.createProfileForm(this.fb)

  constructor(private service: CompanyService, private fb: FormBuilder) {
  }

  private updateData() {
    if (!this.company) return;
    if (this.reloading) return
    this.reloading = true
    this.service.getProfile(this.company.id).subscribe(
      {
        next: value => this.handleData(value),
        complete: () => this.reloading = false
      }
    )
  }

  private handleData(data: CompanyProfile) {
    this.contact = data
    this.form.setValue(data)
    this.reloading = false
  }

  save() {
    if (!this.company) return
    if (!this.form.valid) return
    if (this.reloading) return
    this.reloading = true

    let value = this.form.value
    let request = new CompanyProfileChangeRequest(
      value.street ?? "",
      value.zip ?? "",
      value.city ?? "",
      value.country ?? "",
      value.state ?? "",
      value.phoneNumber ?? "",
      value.website ?? "",
    )
    this.service.updateProfile(this.company.id, request).subscribe(
      {
        next: value => this.handleData(value),
        complete: () => this.reloading = false
      }
    )
  }

}
