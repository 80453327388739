import { Component, Inject } from '@angular/core';
import { WishlistService } from "../model/wishlist.service";
import { Wishlist, WishlistEntry, WishlistEntryChangeRequest } from "../model/wishlist-api";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { WishlistCreateDialogComponent } from "../wishlist-create-dialog/wishlist-create-dialog.component";

@Component({
  selector: 'app-wishlist-add-entry-dialog',
  templateUrl: './wishlist-add-entry-dialog.component.html',
  styleUrl: './wishlist-add-entry-dialog.component.scss'
})
export class WishlistAddEntryDialogComponent {

  wishlists: Wishlist[] = []
  selected: Wishlist | undefined
  active: WishlistEntry[] = []
  fg

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      entry: WishlistAddEntry,
      entries: WishlistAddEntry[]
    },
    private service: WishlistService,
    fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.setWishlists()

    this.fg = fb.group({
      selected: [],
      amount: [1],
      note: [''],
    })
  }

  setWishlists() {
    this.service.getAllWishlists(0, 100).subscribe(wishlists => {
      this.wishlists = wishlists.content
    })
    this.updateWishlistActive()
  }

  addToWishlist(wishlist: Wishlist) {
    if (!wishlist) return
    const request = new WishlistEntryChangeRequest(this.data.entry.id, this.data.entry.description, 1, '')
    this.service.addWishlistEntry(wishlist.id, request).subscribe(_ => this.setWishlists())
  }

  removeFromWishlist(wishlist: Wishlist) {
    if (!wishlist) return
    const entry = this.active.find(entry =>
      entry.wishlistId == wishlist.id && parseInt(entry.reference) == parseInt(this.data.entry.id))
    if (!entry) return
    this.service.removeWishlistEntry(wishlist.id, entry.id).subscribe(_ => this.setWishlists())
  }

  create() {
    const dialogRef = this.dialog.open(WishlistCreateDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.setWishlists()
    })
  }

  isSelected(row: Wishlist) {
    return this.active.find(entry =>
      entry.wishlistId == row.id && parseInt(entry.reference) == parseInt(this.data.entry.id)) != undefined
  }

  private updateWishlistActive() {
    const references = this.data.entries.map(entry => entry.id)
    if (references.length) this.service.checkWishlist(references).subscribe(data => {
      this.active = data
    })
  }
}

export class WishlistAddEntry {
  constructor(
    public id: string,
    public description: string,
  ) {
  }
}
