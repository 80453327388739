import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlarmHintsComponent } from './alarm-hints/alarm-hints.component';
import { TranslateModule } from "@ngx-translate/core";
import { AlarmHintsDialogComponent } from './alarm-hints-dialog/alarm-hints-dialog.component';
import { MaterialModule } from "../material/material.module";
import { AlarmInfoComponent } from "./alarm-info/alarm-info.component";
import { AlarmLevelComponent } from "./alarm-level/alarm-level.component";
import { AlarmInfoListComponent } from './alarm-info-list/alarm-info-list.component';


@NgModule({
  declarations: [
    AlarmHintsComponent,
    AlarmHintsDialogComponent,
    AlarmInfoComponent,
    AlarmLevelComponent,
    AlarmInfoListComponent
  ],
  exports: [
    AlarmHintsComponent,
    AlarmHintsDialogComponent,
    AlarmInfoComponent,
    AlarmLevelComponent,
    AlarmInfoListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ]
})
export class AlarmModule {
}
