import { Component, Input } from '@angular/core';
import { Location } from "@angular/common";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent {

  @Input() showBack: boolean = true
  @Input() title: string | undefined
  @Input() subtitleFirst: string | undefined
  @Input() subtitleLast: string | undefined
  isHandset$: Observable<boolean> = this.responsive.observe(['(max-width: 1000px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private location: Location, private responsive: BreakpointObserver,) {

  }

  back() {
    this.location.back()
  }
}
