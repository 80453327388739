import { Component } from '@angular/core';
import { ProductConfigSelectService } from "../model/product-config-select.service";
import {ConfigSelectionType} from "../model/product-config-api";

@Component({
  selector: 'app-product-config-select-input',
  templateUrl: './product-config-select-input.component.html',
  styleUrl: './product-config-select-input.component.scss'
})
export class ProductConfigSelectInputComponent {

  constructor(public service: ProductConfigSelectService) {
  }

  protected readonly ConfigSelectionType = ConfigSelectionType;

}
