import { Component, ContentChildren, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { Page } from "../../utils/page";
import { Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { BoardListDataProvider } from "./board-list-data-provider";

@Component({
  selector: 'app-board-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.scss']
})
export class BoardListComponent<T> implements OnInit {

  @Input()
  provider: BoardListDataProvider<T> | null = null
  dataSource: MatTableDataSource<T> = new MatTableDataSource();
  @Input() displayedColumns: string[] = []
  reloading: boolean = false;
  pageSize = 20
  resultsLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  filter = '';
  @ViewChild(MatTable, {static: true}) table: MatTable<T> | undefined;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef> | undefined;

  private subject: Subject<string> = new Subject();

  constructor() {
  }

  ngAfterContentInit() {
    if (!this.columnDefs) return
    this.columnDefs.forEach(columnDef => this.table?.addColumnDef(columnDef));
  }

  ngOnInit(): void {
    this.loadPage(0);

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(searchTextValue => {
      this.filter = searchTextValue
      this.loadPage(0)
    });

  }

  ngAfterViewInit() {
    if (!this.paginator) return
    this.paginator.page.subscribe(p => this.loadPage(p.pageIndex))
  }


  private handleData(p: Page<T>) {
    this.dataSource.data = p.content
    this.resultsLength = p.totalSize
    this.reloading = false
  }

  onKeyUp(searchTextValue: string) {
    this.subject.next(searchTextValue);
  }

  private loadPage(page: number) {
    if (!this.provider || this.reloading) return
    this.reloading = true
    if (this.filter && this.filter.length > 0) {
      this.provider.loadFilteredPage(this.filter, page, this.pageSize).subscribe(p => this.handleData(p))
    } else {
      this.provider.loadPage(page, this.pageSize).subscribe(p => this.handleData(p))
    }
  }

  clearFilter() {
    this.filter = ''
    this.loadPage(0)
  }
}
