import { Component, Input } from '@angular/core';
import { EquipmentStartsReport } from "../model/operational-report-starts-api";

@Component({
  selector: 'app-operational-report-equipment-starts',
  templateUrl: './operational-report-equipment-starts.component.html',
  styleUrl: './operational-report-equipment-starts.component.scss'
})
export class OperationalReportEquipmentStartsComponent {

  @Input()
  set startsData(data: EquipmentStartsReport | undefined) {
    this.starts = data
  }

  starts: EquipmentStartsReport | undefined

  constructor() { }
}
