import { Injectable, NgZone } from '@angular/core';
import { BaseService, PatchRequest } from "../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Company, CompanyContact, CompanyContactChangeRequest, CompanyProfile, CompanyProfileChangeRequest, CompanyValidationResult } from "./company-api";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Page } from "../../utils/page";

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/company', logging, zone)
    this.retryCount = 0
  }

  getCompany(companyId: number): Observable<Company> {
    return this.get('' + companyId)
  }

  getOwnCompany(): Observable<Company> {
    return this.get('')
  }

  validate(): Observable<CompanyValidationResult> {
    return this.get('validate')
  }

  getProfile(id: number): Observable<CompanyProfile> {
    return this.get('' + id + '/profile')
  }

  updateProfile(id: number, request: CompanyProfileChangeRequest): Observable<CompanyProfile> {
    return this.put('' + id + '/profile', request)
  }

  getAllCompanies(page: number, size: number): Observable<Page<Company>> {
    return this.getPaged('all', page, size)
  }

  getFilteredCompanies(filter: string, page: number, size: number): Observable<Page<Company>> {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged('filtered', page, size, params)
  }

  createProfileForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(''),
      street: fb.control(''),
      zip: fb.control(''),
      city: fb.control(''),
      country: fb.control(''),
      state: fb.control(''),
      phoneNumber: fb.control(''),
      website: fb.control(''),
    })
  }

  getContact(id: number): Observable<CompanyContact> {
    return this.get('' + id + '/contact')
  }

  updateContact(id: number, request: CompanyContactChangeRequest): Observable<CompanyContact> {
    return this.put('' + id + '/contact', request)
  }

  createContactForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(('')),
      title: fb.control(''),
      firstName: fb.control(''),
      lastName: fb.control(''),
      phoneNumber: fb.control(''),
      email: fb.control('')
    })
  }

  setName(id: number, name: string): Observable<Company> {
    return this.put('' + id + '/name', new PatchRequest(name))
  }
}
