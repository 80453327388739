<div class="flex flex-row gap-5 justify-end">
  <div *ngIf="service.customHeaderActions.length > 0" class="flex flex-row gap-2 justify-end">
    @for (action of service.customHeaderActions; track action) {
      <app-main-header-action [value]="action"></app-main-header-action>
    }
  </div>

  <div *ngIf="service.headerActions.length > 0" class="flex flex-row gap-2 justify-end">
    @for (action of service.headerActions; track action) {
      <app-main-header-action [value]="action"></app-main-header-action>
    }
  </div>
</div>
