import {
  CompanyChangeRequest,
  CompanyContactChangeRequest,
  CompanyProfileChangeRequest,
  Requester
} from "../../company/model/company-api";

export interface ProcessResponse {
  processId: number,
  status: ProcessStatus
}


export interface ProcessStatus {
  code: number,
  description: Description,
  messages: Message[]
}

export interface Description {
  key: string,
  text: string
}

export interface Message {
  code: number,
  description: Description,
  level: string,
  timestamp: string,
  note: string
}

export interface Process {
  id: number,
  userId: number,
  status: ProcessStatus,
  active: boolean,
  operation: string,
  type: string,
  timestamp: string,
  runtime: string
}

export class ActivateElectronicModuleRequest {
  public constructor(
    public electronicModuleId: number,
    public macAddress: string,
    public deviceAddress: number,
    public protocol: string,
    public modbus: ModbusSettings | null
  ) {
  }

}

export class ModbusSettings {
  public constructor(
    public baudRate: number,
    public dataBits: number,
    public parity: string,
    public stopbits: number
  ) {
  }
}

export class TransitionRequest {
  constructor(
    public company: CompanyChangeRequest,
    public profile: CompanyProfileChangeRequest,
    public contact: CompanyContactChangeRequest,
    public requester: Requester
  ) {
  }
}

export class OnboardRequest {
  constructor(
    public requester: Requester,
    public company: CompanyChangeRequest,
    public profile: CompanyProfileChangeRequest,
    public contact: CompanyContactChangeRequest,
    public accounts: AccountInfoChangeRequest[]
  ) {
  }
}

export class AccountInfoChangeRequest {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public entitlement: EntitlementChangeRequest,
  ) {
  }
}

export class EntitlementChangeRequest {
  constructor(
    public userLevel: string,
    public roles: string[]
  ) {
  }
}
