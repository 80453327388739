<div class="border border-gray-300 rounded-md mat-elevation-z2">
  <div class="flex flex-row items-stretch flex-auto">
    <div class="flex flex-col justify-center content-center">
      <img class="drawing mx-auto px-2 py-2"
           src="{{ imageUrl }}"
           appDefaultImage defaultImage="assets/img/picto_j_01_recip1.png" alt="Entry Image"
      />
    </div>
    <mat-divider vertical></mat-divider>
    <div class="flex flex-col gap-2 flex-auto justify-center items-center py-2">
      <div class="text-xl font-bold">{{ title }}</div>
      <div class="flex flex-row gap-2 justify-center text-xs">{{ subtitle }}</div>
    </div>
    <mat-divider vertical></mat-divider>
    <div class="flex flex-row gap-2 items-center px-2 hover:bg-stone-200">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>
