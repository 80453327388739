import { TranslateService } from "@ngx-translate/core";
import { BaseLoader, LoaderCallback } from "./base-loader";
import { SeriesOption } from "echarts";
import { Subject } from "rxjs";
import { SystemTimeService } from "../../../../system/system-time/system-time.service";
import { WeatherSeriesEntry, WeatherSeriesRequest } from "../../../../weather/model/weather-api";
import { WeatherService } from "../../../../weather/model/weather.service";

export class WeatherTempLoader extends BaseLoader<WeatherSeriesEntry> {

  override readonly PAGE_SIZE = 50000

  constructor(
    private service: WeatherService,
    private translate: TranslateService,
    private systemTime: SystemTimeService,
  ) {
    super()
  }

  protected loadPage(id: number, from: string, to: string, page: number, data: WeatherSeriesEntry[], callback: Subject<LoaderCallback>): void {
    this.service.getWeatherSeries(id, 'temp', new WeatherSeriesRequest(from, to), page, this.PAGE_SIZE)
      .subscribe(d => this.handlePage(id, from, to, page, data, callback, d))
  }

  protected processData(data: WeatherSeriesEntry[], callback: Subject<LoaderCallback>): void {
    let primarySeries = this.createSeries(data)
    callback.next({ primary: [primarySeries], secondary: []})
  }

  private createSeries(data: WeatherSeriesEntry[]): SeriesOption {
    let values = data.map(p => [this.systemTime.formatTimestamp(p.timestamp), (p.temp - 273.15).toFixed(2)])

    return {
      name: this.translate.instant('bdnTemp'),
      yAxisId: 'UNIT_C',
      animation: false,
      type: 'line',
      showSymbol: false,
      data: values,
      tooltip: {
        valueFormatter: value => `${value} °C`
      },
    }
  }

}
