import { Component } from '@angular/core';

@Component({
  selector: 'app-add-to-cart-dialog',
  templateUrl: './add-to-cart-dialog.component.html',
  styleUrl: './add-to-cart-dialog.component.scss'
})
export class AddToCartDialogComponent {

}
