/** @deprecated use specific roles instead */
export const ADMIN = 'ADMIN';
/** @deprecated use specific roles instead */
export const MANAGER = 'MANAGER';
/** @deprecated use specific roles instead */
export const DATA_EXPORTER = 'DATA_EXPORTER';

export const ENTITLEMENT_READ = "bdn.entitlement.read"
export const ENTITLEMENT_WRITE = "bdn.entitlement.write"
export const ENTITLEMENT_ADMIN = "bdn.entitlement.admin"

export const EQUIPMENT_READ = "bdn.equipment.read"
export const EQUIPMENT_WRITE = "bdn.equipment.write"
export const EQUIPMENT_ADMIN = "bdn.equipment.admin"
export const EQUIPMENT_OPERATE = "bdn.equipment.operate"
export const EQUIPMENT_MAINTAIN = "bdn.equipment.maintain"
export const EQUIPMENT_EXEMPT = "bdn.equipment.exempt"

export const COMPANY_READ = "bdn.company.read"
export const COMPANY_WRITE = "bdn.company.write"
export const COMPANY_ADMIN = "bdn.company.admin"

export const SITE_READ = "bdn.site.read"
export const SITE_WRITE = "bdn.site.write"
export const SITE_ADMIN = "bdn.site.admin"

export const SYSTEM_READ = "bdn.system.read"
export const SYSTEM_WRITE = "bdn.system.write"
export const SYSTEM_ADMIN = "bdn.system.admin"
export const SYSTEM_OPERATION = "bdn.system.operate"

export const SHARING_READ = "bdn.share.read"
export const SHARING_WRITE = "bdn.share.write"
export const SHARING_ADMIN = "bdn.share.admin"

export const REGISTRATION_CREATE = "bdn.registration.create"

export const DOCUMENT_READ = "bdn.image.read"
export const DOCUMENT_WRITE = "bdn.image.write"

export const IMAGE_READ = "bdn.image.read"
export const IMAGE_WRITE = "bdn.image.write"

export const NOTE_READ = "bdn.image.read"
export const NOTE_WRITE = "bdn.image.write"

export const ACCOUNTING_ACCOUNT_ADMIN = "bdn.accounting.account.admin"
export const ACCOUNTING_ACCOUNT_READ = "bdn.accounting.account.read"

export const ACCOUNTING_CHARGE_ADMIN = "bdn.accounting.charge.admin"
export const ACCOUNTING_CHARGE_READ = "bdn.accounting.charge.read"

export const ACCOUNTING_LICENSE_ADMIN = "bdn.accounting.license.admin"
export const ACCOUNTING_LICENSE_READ = "bdn.accounting.license.read"

export const ACCOUNTING_MODEL_ADMIN = "bdn.accounting.model.admin"
export const ACCOUNTING_MODEL_READ = "bdn.accounting.model.read"

export const ACCOUNTING_PERMISSION_ADMIN = "bdn.accounting.permission.admin"
export const ACCOUNTING_VOUCHER_ADMIN = "bdn.accounting.voucher.admin"

export const PROCESS_READ = "bdn.process.read"
export const PROCESS_WRITE = "bdn.process.write"
export const PROCESS_ADMIN = "bdn.process.admin"

export const BETA_ACCESS = "bdn.beta.access"
export const PUBLIC_ACCESS = "public"

export const COMMERCE_READ = "bdn.commerce.read"
export const COMMERCE_WRITE = "bdn.commerce.write"
export const COMMERCE_ADMIN = "bdn.commerce.admin"

export const ACCOUNTING_ADMIN = "bdn.accounting.admin"
export const SEARCH_ADMIN = "bdn.search.admin"
export const FEEDBACK_ADMIN = "bdn.feedback.admin"
export const SALES_ADMIN = "bdn.sales.admin"
export const CUSTOMER_ADMIN = "bdn.customer.admin"
export const STOCKLEVEL_IMPORT = "bdn.commerce.stocklevel.import"
export const INGEST_ADMIN = "bdn.ingest.admin"
export const INGEST_ADMIN_CMS = "bdn.ingest.admin.cms"
export const INGEST_ADMIN_WBITZER = "bdn.ingest.admin.wbitzer"
export const KB_EQUIPMENT_READ = "bdn.knowledge.equipment.read"
export const KB_EQUIPMENT_ADMIN = "bdn.knowledge.equipment.admin"

export const SALES_ORDER_CUSTOMER = "bdn.order.customer"
export const SALES_ORDER_SUBSIDIARY = "bdn.order.subsidiary"
export const SALES_ORDER_HUB = "bdn.order.hub"
export const SALES_ORDER_ADMIN = "bdn.order.admin"

export const PRODUCT_CONFIG_READ = "bdn.knowledge.pid.read"
export const PRODUCT_CONFIG_ADMIN = "bdn.knowledge.pid.admin"

export const THING_TEMPLATE_ADMIN = "bdn.thing-template.admin"
