import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlarmInfo } from "../model/alarm-api";

@Component({
  selector: 'app-alarm-hints-dialog',
  templateUrl: './alarm-hints-dialog.component.html',
  styleUrls: ['./alarm-hints-dialog.component.scss']
})
export class AlarmHintsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlarmInfo
  ) {
  }

}
