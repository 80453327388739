<mat-card class="system-card">
  <mat-card-header class="mb-3">
    <mat-card-title>{{'action.Edit' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="systemData">
      <div class="flex flex-col">
        <mat-form-field class="smaller-gap">
          <mat-label>{{'name' | translate}}</mat-label>
          <input matInput formControlName="name" type="string">
        </mat-form-field>
        <div></div>

        <mat-form-field class="smaller-gap">
          <mat-label>{{'systemType' | translate}}</mat-label>
          <mat-select panelClass="mySelectedPanel" formControlName="type">
            <mat-option *ngFor="let type of systemTypes" [value]="type">
              {{ 'products.filter.TypeFilter.'+type | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div></div>

        <mat-form-field class="smaller-gap">
          <mat-label>{{'refrigerant' | translate}}</mat-label>
          <mat-select panelClass="mySelectedPanel" formControlName="refrigerant">
            <mat-option *ngFor="let refrigerant of refrigerants" [value]="refrigerant">
              {{refrigerant}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div></div>

        <mat-form-field class="grow shrink basis-full mb-2 mb-sm-0">
          <mat-label>{{'localTimezone' | translate}}</mat-label>
          <mat-select panelClass="mySelectedPanel" formControlName="timezone">
            <mat-option *ngFor="let timezone of timeZones" [value]="timezone">
              {{timezone}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="footer flex flex-row justify-between items-center content-center">
      <button mat-button mat-dialog-close class="ms-2">{{'cancel' | translate}}</button>
      <button mat-button [disabled]="!systemData.valid" class="me-2" (click)="update()">{{'action.Save' | translate}}</button>
    </div>
  </mat-card-actions>
</mat-card>
