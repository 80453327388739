<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="fg">
    <div class="flex flex-col gap-5 justify-between items-stretch">

      <div class="flex flex-row gap-2 justify-center">
        @for (p of points; track p) {
          <label for="point-{{p}}" class="rating-radio">
            <input type="radio" id="point-{{p}}" value="{{p}}" formControlName="score" name="score">
            <span class="radio-text">{{ p }}</span>
            <span class="checkmark"></span>
          </label>
        }
      </div>
      <div class="flex flex-row justify-stretch">
        <mat-form-field appearance="outline" class="flex-auto">
          <mat-label>{{ 'feedback.comment' | translate }}</mat-label>
          <textarea matInput formControlName="comment"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button (click)="submit()" [disabled]="!fg.valid || processing" color="primary" mat-flat-button>
    <mat-icon>
      @if (processing) {
        <mat-spinner diameter="18" color="accent" mode="indeterminate"></mat-spinner>
      } @else {
        check
      }
    </mat-icon>
    {{ "feedback.submit" | translate }}
  </button>
  <button color="warn" mat-dialog-close mat-flat-button>
    <mat-icon>cancel</mat-icon>
    {{ "feedback.cancel" | translate }}
  </button>
</mat-dialog-actions>
