import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Account, AccountChangeRequest, AccountInfo, Transaction, TransactionChangeRequest } from "./account-api";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { map } from "rxjs/operators";
import { ChargeEntry } from "./charge-api";
import { License, LicenseInfo } from "./license-api";
import { Company } from "../../company/model/company-api";

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor(http: HttpClient, logging: LoggingService, zone: NgZone) {
    super(http, 'api/gateway/accounting/account', logging, zone);
  }

  getAllAccounts(page: number, size: number = 20): Observable<Page<Account>> {
    return this.getPaged<Account>('', page, size)
  }

  private allInfoCache = new Map<string, Observable<Page<AccountInfo>>>();

  getAllAccountInfos(page: number, size: number): Observable<Page<AccountInfo>> {
    let cacheKey = page + '' + size
    let hit = this.allInfoCache.get(cacheKey)
    if (hit) return hit

    let result = this.getPaged<AccountInfo>('info', page, size)
    this.allInfoCache.set(cacheKey, result)
    return result
  }

  getFilteredAccountInfos(page: number, size: number, filter: string): Observable<Page<AccountInfo>> {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged<AccountInfo>('info/filter', page, size, params)
  }

  getAccount(accountId: number): Observable<Account> {
    return this.get('' + accountId)
  }

  private infoCache = new Map<number, Observable<AccountInfo>>();

  getAccountInfo(accountId: number): Observable<AccountInfo> {
    let hit = this.infoCache.get(accountId)
    if (hit) return hit

    let result = this.get<AccountInfo>('' + accountId + '/info')
    this.infoCache.set(accountId, result)
    return result
  }


  private byCompanyCache = new Map<number, Observable<AccountInfo>>();

  findInfoByCompany(company: Company): Observable<AccountInfo> {
    let hit = this.byCompanyCache.get(company.id)
    if (hit) return hit

    let result = this.get<AccountInfo>('find/by/company/' + company.id + '/info')
    this.byCompanyCache.set(company.id, result)
    return result
  }


  findByCompany(companyId: number): Observable<Account> {
    return this.get('find/by/company/' + companyId)
  }

  createAccount(request: AccountChangeRequest): Observable<Account> {
    let observable = this.post<Account>('', request)
    return observable.pipe(
      map(a => {
        this.infoCache.delete(a.id)
        this.allInfoCache.clear()
        return a
      })
    )
  }

  getTransactions(accountId: number, page: number, size: number = 20): Observable<Page<Transaction>> {
    return this.getPaged<Transaction>(accountId + '/transaction', page, size)
  }

  getChargeEntries(accountId: number, page: number, size: number = 20): Observable<Page<ChargeEntry>> {
    return this.getPaged<ChargeEntry>(accountId + '/charge', page, size)
  }

  getLicenses(accountId: number, page: number, size: number = 20): Observable<Page<License>> {
    return this.getPaged<License>(accountId + '/license', page, size)
  }

  getLicenseInfos(accountId: number, page: number, size: number): Observable<Page<LicenseInfo>> {
    return this.getPaged<LicenseInfo>(accountId + '/license/info', page, size)
  }

  getFilteredLicenseInfos(accountId: number, page: number, size: number, filter: string) {
    let params = new HttpParams()
    params = params.append('filter', filter)
    return this.getPaged<LicenseInfo>(accountId + '/license/info/filter', page, size, params)
  }

  getUserAccountInfo(): Observable<AccountInfo> {
    return this.get('user/info')
  }

  accountOrder(accountId: number, request: TransactionChangeRequest): Observable<Transaction> {
    return this.post(accountId + '/transaction', request)
  }

  recalculate(accountId: number): Observable<Transaction> {
    return this.post(accountId + '/recalculate', {})
  }
}
