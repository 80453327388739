import { Component, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-company-contact-form',
  templateUrl: './company-contact-form.component.html',
  styleUrls: ['./company-contact-form.component.scss']
})
export class CompanyContactFormComponent {

  @Input() readonly: boolean = false
  @Input() form: FormGroup | undefined

  constructor() {
  }
}
