import { Component, Input } from '@angular/core';
import { EquipmentAlarmsGraph } from "../../model/operational-report-alarms-api";
import { SeriesOption } from "echarts";
import { AlarmType } from "../../../../site/model/site-api";

@Component({
  selector: 'app-alarm-graph',
  templateUrl: './alarm-graph.component.html',
  styleUrl: './alarm-graph.component.scss'
})
export class AlarmGraphComponent {

  @Input()
  set graphData(data: EquipmentAlarmsGraph | undefined) {
    this.graph = data
    this.initSeries()
  }

  graph: EquipmentAlarmsGraph | undefined
  series: SeriesOption[] = []

  constructor() { }

  private initSeries() {
    const fault = this.defineSeries(AlarmType.FAULT)
    const critical = this.defineSeries(AlarmType.CRITICAL)
    const warning = this.defineSeries(AlarmType.WARNING)

    this.series = [fault, critical, warning]
  }

  private defineSeries(type: AlarmType): SeriesOption {
    const alarms = this.filterAlarms(type)
    const data = alarms.map(e =>
      [e.properties[0].value, e.properties[1].value, e.alarm.alarm.timestamp, e.alarm.description])
    const color = this.defineColor(type)

    return {
      type: 'scatter',
      data: data,
      name: type,
      label: {
        show: false,
      },
      itemStyle: {
        color: color
      }
    }
  }

  private filterAlarms(type: AlarmType) {
    if (!this.graph) return []
    return this.graph.entries.filter(e => e.alarm.alarm.metadata.level === type)
  }

  private defineColor(type: AlarmType) {
    switch (type) {
      case AlarmType.FAULT:
        return '#ff3838'
      case AlarmType.WARNING:
        return '#fce83a'
      case AlarmType.CRITICAL:
        return '#ffb302'
      default:
        return '#3aaa35'
    }
  }
}
