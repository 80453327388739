import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { Page } from "../../utils/page";
import { Company } from "../../company/model/company-api";
import { AccessEntryEquipment, AccessEntryItem, ShareChangeRequest, SiteShareItem, SiteTree, ValidateShareEdit, ValidationResult } from "./access-api";
import { AuthService } from "../../auth/auth.service";
import { SHARING_ADMIN, SHARING_WRITE } from "../../auth/roles";

@Injectable({
  providedIn: 'root'
})
export class AccessService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private authService: AuthService
  ) {
    super(http, 'api/gateway/access', logging, zone)
  }

  getAvailableForSites(siteId: number, page: number = 0, size: number = 10): Observable<Page<Company>> {
    return this.getPaged('site/' + siteId + '/available', page, size)
  }

  getSharedForSites(siteId: number, page: number = 0, size: number = 10): Observable<Page<Company>> {
    return this.getPaged('site/' + siteId + '/shared', page, size)
  }

  searchForSiteCompanies(itemId: number, query: string): Observable<Page<Company>> {
    const params = new HttpParams()
    params.append('query', query)

    return this.postPaged('site/' + itemId + '/search', { query }, 0, 10)
  }

  shareItems(itemId: number, request: ShareChangeRequest): Observable<AccessEntryItem> {
    return this.put('site/' + itemId, request)
  }

  getSiteTree(siteId: number): Observable<SiteTree> {
    return this.get('tree/site/' + siteId)
  }

  validate(request: ValidateShareEdit): Observable<ValidationResult> {
    return this.post('validate', request)
  }

  hasSharingEditPermissions(): boolean {
    return this.authService.hasRole(SHARING_WRITE) || this.authService.hasRole(SHARING_ADMIN)
  }

  getAllItemAccessForCompany(itemId: number[], companyId: number): Observable<AccessEntryItem[]> {
    return this.post('item/company/' + companyId, itemId)
  }

  getAllEquipmentAccessForCompany(equipmentId: number[], companyId: number): Observable<AccessEntryEquipment[]> {
    return this.post('equipment/company/' + companyId, equipmentId)
  }

  getItemAccess(itemId: number): Observable<AccessEntryItem> {
    return this.get(`item/${itemId}/access`)
  }

  getEquipmentAccess(equipmentId: number): Observable<AccessEntryEquipment> {
    return this.get(`equipment/${equipmentId}/access`)
  }
}
