import {Component, Input} from '@angular/core';
import {EquipmentRunningTimeReport} from "../../model/operational-report-activity-api";
import {OperationalReportService} from "../../model/operational-report.service";
import {ECElementEvent, ECharts, EChartsOption} from "echarts";
import {RunningChart} from "./running-chart";
import {TranslateService} from "@ngx-translate/core";
import {AlarmInfo} from 'src/app/alarm/model/alarm-api';
import {Equipment} from "../../../model/equipment-api";
import {map} from "rxjs/operators";
import {AlarmService} from "../../../../alarm/model/alarm.service";
import {
  AlarmInfoDialogComponent
} from "../../../../alarm/alarm-info-dialog/alarm-info-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivityRunningService} from "./activity-running.service";
import {SystemTimeService} from "../../../../system/system-time/system-time.service";
import {DateTime} from "luxon";

@Component({
  selector: 'app-activity-running',
  templateUrl: './activity-running.component.html',
  styleUrl: './activity-running.component.scss'
})
export class ActivityRunningComponent {

  report: EquipmentRunningTimeReport | undefined
  chart: ECharts | undefined
  initialValue: EChartsOption | null
  dynamicData: EChartsOption | null

  constructor(
    private service: OperationalReportService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private alarmService: AlarmService,
    private runningService: ActivityRunningService,
    private systemTime: SystemTimeService
  ) {
    this.initialValue = null
    this.dynamicData = null
    this.initialValue = new RunningChart().build()
    this.service.changeEvent.subscribe(reload => {
      if (reload) this.chart?.showLoading()
    })
  }

  @Input()
  set data(data: EquipmentRunningTimeReport | undefined) {
    if (!data) {
      this.chart?.hideLoading()
      return
    }
    this.chart?.showLoading()
    this.report = data
    this.process(data)
  }

  process(data: EquipmentRunningTimeReport) {
    const series = this.runningService.process(data)
    this.dynamicData = {
      legend: {
        show: false
      },
      series: series,
      xAxis: {
        min: () => this.service.calculateMin(),
        max: () => this.service.calculateMax()
      }
    }
    this.chart?.hideLoading()
  }


  handleChartClick(event: ECElementEvent) {
    if (!this.report?.info.equipment) return
    if (event.componentType === 'markArea') {
      let data = event?.data as any
      let title = (data).text as string
      let coords = (data).coord as any[]
      let values = coords.map(i => i[0]) as string[]
      if (title === 'Running') {
        this.openEventDetailsDialog(values)
      }
      if (title === 'Alarm' && values.length) {
        this.getAlarmDetailsForTimestamp(this.report?.info.equipment, values[0], 0).subscribe(a => {
          this.openAlarmDetailsDialog(a)
        })
      }
    }
  }

  getAlarmDetailsForTimestamp(equipment: Equipment, timestamp: string, page: number) {
    let from = DateTime.fromISO(timestamp).startOf('day').toISO() ?? ''
    let to = DateTime.fromISO(timestamp).endOf('day').toISO() ?? ''
    let min = DateTime.fromISO(timestamp).minus({minutes: 30})
    let max = DateTime.fromISO(timestamp).plus({minutes: 30})

    return this.alarmService.search(equipment.id, from, to, this.translate.currentLang, page, 1000)
    .pipe(map(data => data.content.filter((a) => {
      const alarmTimestamp = DateTime.fromISO(this.systemTime.formatTimestamp(a.alarm.timestamp))
      return alarmTimestamp >= min && alarmTimestamp <= max
    })))
  }

  setChart(chart: ECharts) {
    this.chart = chart
  }

  private openEventDetailsDialog(values: string[]) {
    //TODO move to op graph, running events only
  }

  private openAlarmDetailsDialog(alarms: AlarmInfo[]) {
    if (!alarms.length) return
    const dialogRef = this.dialog.open(AlarmInfoDialogComponent, {
      width: '85%',
      data: alarms
    })
  }
}
